import type { DateTime, Interval } from "luxon";
import type { DaysEnabled } from "./admin/weekdayPicker";
import type {
  CalendarDate,
  Concierge,
  DateString,
  InferReturnType,
  TimeInterval,
} from "zynq-shared";
import type { DATE_FMT, RESOURCE_TYPES, VACCINATION_STATUS } from "./constants";
import type { APIRequest } from "./hooks";

export type ISODateString = DateString<typeof DATE_FMT>;

export type TimeRange = {
  start: string;
  end: string;
  nightShift: boolean;
};

export const SeatEventKind = {
  OCCUPIED: "OCCUPIED" as const,
  RESERVED: "RESERVED" as const,
  CHECKIN: "CHECKIN" as const,
  SHUTDOWN: "SHUTDOWN" as const,
  YOURS_ALLOWED: "YOURS_ALLOWED" as const, // Used for "Allowed events", whatever that is
};

export type BookingSeatInfo = Pick<SeatInfo, "id" | "resourceType">;
export type BookingSeatEvent = Pick<
  SeatEvent,
  "bookingID" | "startDate" | "endDate" | "startTime" | "endTime" | "avatar"
>;

export type SeatEventKind = keyof typeof SeatEventKind;

export type SeatEvent = {
  bookingID?: number;
  kind: SeatEventKind;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  avatar?: {
    name: string;
    email: string;
    src?: string;
    groupID?: number;
    isBuddy?: boolean;
  };
  reason?: string;
  scanCount?: number;
};

export type SeatInfo = {
  id: number;
  name: string;
  enabled: boolean;
  events: SeatEvent[];
  resourceType: RESOURCE_TYPES;
  seatType: string;
  svgX: number;
  svgY: number;
  svgAngle: number;
  svgScale: number;
  imgURL?: string;
  description?: string;
  scanCount?: number;
};

export enum RelativeDate {
  Past,
  Today,
  Future,
}

export type Domain = {
  name: string;
  isSandbox: boolean;
  timeBasedBookingsEnabled: boolean;
  buddySyncEnabled: boolean;
  meetingWizardEnabled: boolean;
  managerBookingEnabled: boolean;
  roomsEnabled: boolean;
  gsCalAccessGranted: boolean;
  allowFloorplanMeetingBooking: boolean;
  gsEnabled: boolean;
  scheduledVisitorsEnabled: boolean;
  logoSrc: string;
  logoInvertedSrc: string;
  headerColor: string;
  primaryColor: string;
  secondaryColor: string;
  syncIDP?: string;
  tenantID?: string;
  showOverdueWarning: boolean;
  defaultWorkingHoursStart: string;
  defaultWorkingHoursEnd: string;
};

export type ConciergeDeviceInfo = InferReturnType<typeof Concierge.Login>;

export type UserInfo = {
  avatar: { name: string; email: string; src?: string };
  allowedHours: TimeInterval;
  domain: Domain;
  userSchedule: DaysEnabled[];
  isTestUser: boolean;
  isManager: boolean;
  isAdmin: boolean;
  isUserAdmin: boolean;
  isSuperAdmin: boolean;
  isStaff: boolean;
  vaccinationStatus: VACCINATION_STATUS;
  languageCode: string;
  defaultFloorplanID?: number;
  defaultFloorplanName?: string;
};

export type DomainSettings = {
  roomsEnabled: boolean;
  dropinEnabled: boolean;
  conciergeEnabled: boolean;
  officeManagementEnabled: boolean;
  workspaceEnabled: boolean;
  buddySyncEnabled: boolean;
  meetingWizardEnabled: boolean;
  contactTracingEnabled: boolean;
  externalUsersEnabled: boolean;
  broadcastEnabled: boolean;
  qrEnabled: boolean;
  questionnaireResultsEnabled: boolean;
  scheduledVisitorsEnabled: boolean;
  managersEnabled: boolean;
  slackEnabled: boolean;
  reportsEnabled: boolean;
  accessControlEnabled: boolean;
  actionCenterEnabled: boolean;
  cleaningEnabled: boolean;
  slackEnabledTeams: string[];
  resourceTypes: RESOURCE_TYPES[];
  weekendsBookable: boolean;
  isStripeBilled: boolean;
  stripeUserPlan: string;
  stripeRoomPlan: string;
  stripeVisitorPlan: string;
};

export type RoomEvent = {
  id: string;
  subject: string;
  interval: Interval;
  roomName: string;
  roomID: number;
  roomAcceptedTime: DateTime;
  checkedIn: boolean;
  organizerEmail: string;
  attendees: {
    name: string;
    email: string;
    status: "tentative" | "decline" | "accept" | "none";
  }[];
  iCalUId: string;
  webLink?: string;
};

export type RoomInfo = {
  id: number;
  name: string;
  description?: string;
  imgURL: string;
  calendarID: string;
  bookable: boolean;
  viewable: boolean;
  // events: RoomEvent[];
  svgPoints: string;
  // points: { x: number; y: number }[];
  timezone: string;
  maxAllowedMins?: number;
  maxCapacity?: number;
};

export type BookableRoomInfo = {
  name: string;
  id: number;
  maxAllowedMins?: number;
  maxCapacity?: number;
};

export type FloorplanInfo = {
  id: number;
  name: string;
  buildingName?: string;
  today: CalendarDate;
  date: CalendarDate;
  defaultResourceType: RESOURCE_TYPES;
  img: string | null;
  floorplanDimensions?: { width: number; height: number };
  timezone: string;
  weekBookings: Partial<Record<RESOURCE_TYPES, CalendarDate[][]>>;
  myBookings: {
    floorplan: {
      id: number;
      name: string;
    };
    seat: {
      id: number;
      name: string;
      resourceType: RESOURCE_TYPES;
    };
    startTime: DateTime;
    endTime: DateTime;
    scanCount?: number | undefined;
    bookingID: number;
  }[];
  userVisible: boolean;
  dropinEnabled: boolean;
  allowFutureDropin: boolean;
  hideAutoBookButton: boolean;
  dropinData: {
    hasDropin: boolean;
    needsCheckin: boolean;
    bookingID?: number;
    dropinVerifyQRURL?: string;
  };
  seats: SeatInfo[];
  rooms: RoomInfo[];
  autoReleasedSeat: boolean;
  weekendsBookable: boolean;
  checkinQREnabled: boolean;
  maxWeeklyBookings: number;
  bookAheadDaysMin: number;
  bookAheadDaysMax: number;
  checkinEvents: { seat: SeatInfo; event: SeatEvent }[];
  seatVerifyQRURL?: string;
  hasDesks: boolean;
  checkinRequired: boolean;
  todaysNumVisits: number;
  maxDailyVisits: number;
  allResourceTypes: RESOURCE_TYPES[];
  nightShiftEnabled: boolean;
  questionnaireEnabled: boolean;
  onBehalfBulkBookingsEnabled: boolean;
  defaultWorkingHours: { start: string; end: string };
  streetAddress?: string;
};

export type Actions = {
  onReleaseDropin: (bookingID: number) => void;
  onAdminReleaseDropin: (bookingID: number) => void;
  onDropin: () => void;
  onAutobook: (params: {
    hint?: SeatInfo;
    dropin: boolean;
    addAutoSelectDate: boolean;
  }) => void;
  onSelectSeat: (
    date: CalendarDate,
    floorplanID: number,
    seatID: number,
    timeInterval?: TimeInterval
  ) => void;
  onShowQR?: () => void;
  onAutobookToday: (s?: SeatInfo) => void;
  onBook: (s: SeatInfo) => void;
  onAdminBook: (s: SeatInfo) => void;
  onCheckin: (s: SeatInfo, e: SeatEvent) => void;
  onCheckinAll: () => void;
  onRelease: (bookingID: number) => void;
  onAdminRelease: (bookingID: number) => void;
  setTimeRange: (r: TimeInterval) => void;
  refreshFloorplan: () => APIRequest<unknown>;
  onBookMeeting: () => void;
};
