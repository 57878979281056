import { Button } from "@mui/material";
import { Alert } from "@mui/material";
import React from "react";
import { APIError } from "../hooks";
import { QueryErrorResetBoundary } from "react-query";
import i18next from "i18next";

export default class ErrorGuard extends React.Component<{
  onError?: (retry: () => void) => React.ReactNode;
}> {
  state: { error: null | APIError };
  constructor(props: any) {
    super(props);
    this.state = { error: null };
    this.render = this.render.bind(this);
  }
  render() {
    const { error } = this.state;
    return (
      <QueryErrorResetBoundary>
        {({ reset }) =>
          error ? (
            <div
              css={{
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Alert severity="error" css={{ alignItems: "center" }}>
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <div>{i18next.t("it-looks-like-we-ran-into-an-issue")}</div>
                  <div>{error.reason}</div>
                  <Button
                    css={{ marginLeft: "auto", marginTop: "1rem" }}
                    onClick={() => {
                      reset();
                      this.setState({ error: null });
                    }}
                    variant="contained"
                  >
                    {i18next.t("retry")}
                  </Button>
                </div>
              </Alert>
            </div>
          ) : (
            this.props.children
          )
        }
      </QueryErrorResetBoundary>
    );
  }
  componentDidCatch(error: Error) {
    if (error instanceof APIError) {
      this.setState({ error });
    } else {
      throw error;
    }
  }
}
