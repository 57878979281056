import { People } from "@mui/icons-material";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import Avatar from "./avatar";
import type { AutocompleteProps, AutocompleteValue } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";

import type { InferReturnType, Seating } from "zynq-shared";
import { useAPIQuery } from "./hooks";
import type { Patch } from "ts-toolbelt/out/Object/Patch";
import type { Partial } from "ts-toolbelt/out/Object/Partial";
import type { Optional } from "ts-toolbelt/out/Object/Optional";

type Usergroup = InferReturnType<typeof Seating.GroupSearch>["groups"][0];
type User = InferReturnType<typeof Seating.UserSearch>["users"][0];
export type UserSearchValue = Patch<
  Optional<User, "isDeleted">,
  Partial<Usergroup>
>;

type UserSearchConfigProps = {
  visitors?: boolean;
  deleted?: boolean;
  disabled?: boolean;
  directReportsOnly?: boolean;
  sameDepartmentOnly?: boolean;
  idpOnly?: boolean;
};

/**
 * A search box for users and groups.
 *
 * Properties will be passed along to the underlying Autocomplete Component.
 * You cannot override "options".
 *
 * The following should be passed in for customization:
 *  - noOptionsText
 *  - renderOption
 *  - renderInput
 *  - getOptionLabel
 */
export default function UserSearch<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>(
  props: Optional<
    AutocompleteProps<UserSearchValue, Multiple, DisableClearable, FreeSolo>,
    "renderInput" | "options"
  > & {
    users?: boolean | UserSearchConfigProps;
    groups?: boolean;
    hideOptions?: boolean;
    clearInputOnSelect?: boolean;
    selection?: AutocompleteValue<
      UserSearchValue,
      Multiple,
      DisableClearable,
      FreeSolo
    >;
    onSelection: (
      result: AutocompleteValue<
        UserSearchValue,
        Multiple,
        DisableClearable,
        FreeSolo
      >
    ) => void;
  }
) {
  const { t } = useTranslation();
  const [query, setQuery] = React.useState("");

  const { data: { users } = {}, isLoading: usersLoading } = useAPIQuery<
    typeof Seating.UserSearch
  >(
    "/apis/seating/user-search",
    {
      query,
      ...(props.users && props.users !== true
        ? {
            idpOnly: props.users.idpOnly,
            directReportsOnly: props.users.directReportsOnly,
            sameDepartmentOnly: props.users.sameDepartmentOnly,
            includeTempVisitors: props.users.visitors,
            includeRecentlyDeleted: props.users.deleted,
            includeDisabledUsers: props.users.disabled,
          }
        : {}),
    },
    { enabled: !props.hideOptions && props.users !== false }
  );

  const { data: { groups } = {}, isLoading: groupsLoading } = useAPIQuery<
    typeof Seating.GroupSearch
  >(
    "/apis/seating/group-search",
    {
      query,
    },
    { enabled: !props.hideOptions && props.groups }
  );

  const loading = usersLoading || groupsLoading;
  const options = [
    ...(!props.hideOptions && props.users !== false && users ? users : []),
    ...(!props.hideOptions && props.groups && groups ? groups : []),
  ] as UserSearchValue[];

  React.useEffect(() => {
    setQuery("");
  }, [props.hideOptions]);

  const {
    hideOptions: _h,
    users: _u,
    groups: _g,
    selection: _s,
    onSelection: _os,
    clearInputOnSelect: _cios,
    ...acProps
  } = props;

  return (
    <Autocomplete<UserSearchValue, Multiple, DisableClearable, FreeSolo>
      inputValue={query}
      onInputChange={(_, v) => setQuery(v)}
      isOptionEqualToValue={(option, value) =>
        option.email == value.email ||
        // For groups we can check for groupID equality.
        (!!option.groupID && option.groupID == value.groupID)
      }
      onChange={(_, v) => {
        props.onSelection(v);
        if (props.clearInputOnSelect) {
          setQuery("");
        }
      }}
      loading={loading}
      loadingText={t("loading")}
      filterOptions={function (options, state) {
        const searchRegex = new RegExp(
          state.inputValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
          "i"
        );

        if (props.filterOptions) {
          options = props.filterOptions(options, state);
        }

        return options
          .filter(
            (option) =>
              searchRegex.test(option.name) ||
              searchRegex.test(
                state.inputValue.includes("@")
                  ? option.email
                  : option.email.split("@")[0]
              ) ||
              (option.groupID &&
                searchRegex.test(
                  state.inputValue.includes("@")
                    ? option.groupID
                    : option.groupID.split("@")[0]
                ))
          )
          .slice(0, 20);
      }}
      getOptionLabel={(option) =>
        option.email ? option.name + " - " + option.email : option.name
      }
      renderOption={
        props.renderOption ??
        ((props, option) => {
          const name = option.isDeleted
            ? t("name-deleted", { name: option.name })
            : option.visitID
            ? t("name-visitor", { name: option.name })
            : option.name;
          return (
            <li {...props}>
              <Grid alignItems="center" container={true} spacing={2}>
                <Grid item={true}>
                  {option.groupID ? (
                    <People titleAccess={option.email ?? option.groupID} />
                  ) : (
                    <Avatar
                      avatar={{
                        src: option.src ?? undefined,
                        name: option.name,
                      }}
                    />
                  )}
                </Grid>
                <Grid item={true} xs={true}>
                  <span>{name}</span>
                  <Typography color="textSecondary" variant="body2">
                    {option.email ? option.email : option.groupID}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        })
      }
      value={props.selection}
      noOptionsText={t("no-employees-found")}
      renderInput={(props) => <TextField {...props} label={t("employee")} />}
      {...acProps}
      options={options}
    />
  );
}
