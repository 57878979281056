import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import type { PaletteColor } from "@mui/material/styles";
import type { Domain } from "./types";

const whiteContrastOverrideColors = ["#00a0e0", "#05c3de"];

function Theme({
  domain,
  children,
}: {
  domain?: Domain;
  children: JSX.Element;
}) {
  const colors = createTheme({});

  function color(c: string): PaletteColor;
  function color(
    c: string | undefined,
    defaultColor: PaletteColor
  ): PaletteColor;
  function color(
    c: string | undefined,
    defaultColor?: PaletteColor
  ): PaletteColor {
    if (c == undefined) return defaultColor!;
    return {
      ...colors.palette.augmentColor({ color: { main: c } }),
      ...(whiteContrastOverrideColors.includes(c.toLowerCase())
        ? { contrastText: "#ffffff" }
        : undefined),
    };
  }

  const headerColor = color(domain?.headerColor, colors.palette.primary);
  const primaryColor = color(domain?.primaryColor, colors.palette.primary);
  const secondaryColor = color(
    domain?.secondaryColor,
    colors.palette.secondary
  );
  const redColor = color("#d90000");
  const grey = color(colors.palette.grey[300]);
  const theme = createTheme({
    palette: {
      header: headerColor,
      error: redColor,
      primary: primaryColor,
      secondary: secondaryColor,
      defaultGrey: { ...grey, contrastText: "black" },
    },
    components: {
      MuiButton: {
        defaultProps: { color: "defaultGrey" },
        variants: [
          {
            props: { color: "defaultGrey", variant: "outlined" },
            style: { color: "black", borderColor: "black" },
          },
          {
            props: { color: "defaultGrey", variant: "text" },
            style: { color: "black" },
          },
        ],
      },
      MuiTextField: { defaultProps: { margin: "normal" } },
      MuiUseMediaQuery: { defaultProps: { noSsr: true } },

      MuiDialog: {
        styleOverrides: {
          paper: {
            [colors.breakpoints.down("sm")]: { maxHeight: "calc(100% - 32px)" },
          },
          paperFullWidth: {
            [colors.breakpoints.down("sm")]: {
              width: "calc(100% - 32px)",
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            [colors.breakpoints.down("sm")]: {
              padding: "8px",
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          colorDefault: {
            backgroundColor: secondaryColor.main,
            color: secondaryColor.contrastText,
          },
          circular: {
            border: "2px solid " + secondaryColor.contrastText,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorDefault: {
            backgroundColor: headerColor.main,
            color: headerColor.contrastText,
          },
        },
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default Theme;
