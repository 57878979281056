import { LinearProgress, List, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAPIMutation, useAPIQuery } from "src/hooks";
import LocationPicker from "src/locationPicker";

import type { UserInfo } from "src/types";
import type { Seating } from "zynq-shared";

export default function General(props: { userInfo: UserInfo }) {
  const { t } = useTranslation();
  const floorplanName = useAPIQuery<typeof Seating.FloorplanName>(
    "/apis/seating/floorplan-name",
    { floorplanID: props.userInfo.defaultFloorplanID ?? 0 },
    { enabled: props.userInfo.defaultFloorplanID !== undefined }
  );
  const setDefaultFloorplan = useAPIMutation<typeof Seating.SetDefaultLocation>(
    "/apis/seating/set-default-location",
    {
      onSuccess: () => {
        // Hard reload to re-fetch user info -- shouldn't happen often
        window.location.reload();
      },
    }
  );

  const isLoading = floorplanName.isLoading;
  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isLoading && (
        <LinearProgress
          css={{ "&.MuiLinearProgress-root": { marginBottom: "-4px" } }}
        />
      )}
      <h1
        style={{
          fontSize: "200%",
          fontWeight: 600,
          marginBottom: "1rem",
          textAlign: "center",
        }}
      >
        {t("general-preferences")}
      </h1>
      <List>
        <ListItem css={{ display: "flex", gap: "1rem" }}>
          <b>{t("name")}:</b> {props.userInfo.avatar.name}
        </ListItem>
        <ListItem css={{ display: "flex", gap: "1rem" }}>
          <b>{t("email")}:</b> {props.userInfo.avatar.email}
        </ListItem>
        {(props.userInfo.domain.name == "amexgbt.com" ||
          props.userInfo.domain.name == "zynq.io") && (
          <ListItem css={{ display: "flex", gap: "1rem" }}>
            <b>{t("default-location")}:</b>
            <LocationPicker
              allowRegion={false}
              selected={
                props.userInfo.defaultFloorplanID
                  ? {
                      id: props.userInfo.defaultFloorplanID,
                      name: floorplanName.data?.name ?? "",
                      isRegion: false,
                      userVisible: true,
                    }
                  : null
              }
              onChange={(v) => {
                setDefaultFloorplan.mutate({ floorplanID: v?.id ?? undefined });
              }}
              nullable
              emptyText={t("no-default-location")}
            />
          </ListItem>
        )}
      </List>
    </div>
  );
}
