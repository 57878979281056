import type { ButtonProps } from "@mui/material";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function DemoConfirmationButton(
  props: { confirmationText: string; confirmationTitle: string } & ButtonProps
) {
  const { t } = useTranslation();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  return (
    <>
      <Button {...props} onClick={() => setConfirmationOpen(true)}></Button>
      <Dialog
        open={confirmationOpen}
        onClose={(_) => {
          setConfirmationOpen(false);
        }}
        scroll={"paper"}
        aria-labelledby="demo-confirmation-dialog-title"
      >
        <DialogTitle
          id="demo-confirmation-dialog-title"
          css={{ textAlign: "center" }}
        >
          {props.confirmationTitle}
        </DialogTitle>
        <DialogContent css={{ maxWidth: "25rem" }}>
          {props.confirmationText}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(_) => {
              setConfirmationOpen(false);
            }}
            color="primary"
            variant="contained"
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
