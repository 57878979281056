import React from "react";
import { RESOURCE_TYPES } from "./constants";

export function SeatWithSymbol(props: {
  id: string;
  name?: string;
  children?: JSX.Element | JSX.Element[];
}) {
  return (
    <g id={props.id}>
      {props.name && <title>{props.name}</title>}
      <rect x="-95" y="-240" width="203" height="200" />
      <rect x="-395" y="-135" width="785" height="410" rx="40" />
      <rect x="-105" y="-275" width="225" height="45" rx="30" />
      {props.children ?? null}
    </g>
  );
}

export function TCHoodWithSymbol(props: {
  id: string;
  name?: string;
  children?: JSX.Element | JSX.Element[];
}) {
  return (
    <g id={props.id} transform="translate(-22.5, -24.5)">
      {props.name && <title>{props.name}</title>}

      <circle
        cx="22.37"
        cy="12.57"
        r="10.34"
        transform="rotate(-22.37 22.37 12.57)"
      />
      <path
        d="M33.18 12.57A10.82 10.82 0 1122.37 1.75a10.83 10.83 0 0110.81 10.82zm-20.68 0a9.87 9.87 0 109.87-9.87 9.88 9.88 0 00-9.87 9.87z"
        fill="#1e0e00"
      />
      <path d="M22.37.48a12.1 12.1 0 0112.09 12.09 1.75 1.75 0 01-3.5 0 8.6 8.6 0 10-17.19 0 1.75 1.75 0 01-3.5 0A12.1 12.1 0 0122.37.47z" />
      <path
        d="M34.93 12.57a2.23 2.23 0 11-4.45 0 8.12 8.12 0 00-16.23 0 2.23 2.23 0 11-4.45 0 12.57 12.57 0 0125.13 0zm-24.18 0a1.27 1.27 0 102.55 0 9.07 9.07 0 0118.13 0 1.27 1.27 0 102.55 0 11.62 11.62 0 00-23.23 0z"
        fill="#1e0e00"
      />
      <path d="M5.57 48.95h33.8c2.63 0 4.78-1.52 4.78-3.38V20.4c0-1.86-2.15-3.38-4.79-3.38H5.57c-2.63 0-4.79 1.52-4.79 3.38v25.17c0 1.86 2.16 3.38 4.8 3.38z" />
      <path
        d="M0 45.57V20.4c0-2.17 2.5-3.93 5.57-3.93h33.8c3.06 0 5.56 1.76 5.56 3.93v25.17c0 2.17-2.5 3.93-5.57 3.93H5.57C2.5 49.5 0 47.74 0 45.57zM43.37 20.4c0-1.56-1.8-2.83-4-2.83H5.56c-2.2 0-4 1.27-4 2.83v25.17c0 1.56 1.8 2.83 4 2.83h33.8c2.2 0 4-1.27 4-2.83z"
        fill="#1e0e00"
      />
      {props.children ?? null}
    </g>
  );
}

export function LabBenchWithSymbol(props: {
  id: string;
  name?: string;
  children?: JSX.Element | JSX.Element[];
}) {
  return (
    <g id={props.id} transform="translate(-35, -15.5)">
      {props.name && <title>{props.name}</title>}
      <rect x="27.95" y="16.91" width="14.1" height="13.49" rx="1.91" />
      <path
        d="M40.14 30.82H29.86a2.33 2.33 0 01-2.33-2.33v-9.67a2.33 2.33 0 012.33-2.33h10.28a2.33 2.33 0 012.33 2.33v9.67a2.33 2.33 0 01-2.33 2.33zm-10.28-13.5a1.5 1.5 0 00-1.5 1.5v9.67a1.5 1.5 0 001.5 1.5h10.28a1.5 1.5 0 001.5-1.5v-9.67a1.5 1.5 0 00-1.5-1.5z"
        fill="#1e0e00"
      />
      <path d="M69.48 19.99V2.76c0-1.34-1.79-2.44-3.97-2.44H4.5C2.31.32.52 1.42.52 2.76V20c0 1.34 1.79 2.44 3.97 2.44h61.02c2.18 0 3.97-1.1 3.97-2.44z" />
      <path
        d="M65.51 22.75H4.5C2.01 22.75 0 21.51 0 20V2.76C0 1.24 2.01 0 4.49 0h61.02C68 0 70 1.24 70 2.76V20c0 1.52-2.01 2.76-4.49 2.76zM4.5.64c-1.9 0-3.45.95-3.45 2.12V20c0 1.17 1.55 2.12 3.45 2.12h61.02c1.9 0 3.45-.95 3.45-2.12V2.76c0-1.17-1.55-2.12-3.45-2.12z"
        fill="#1e0e00"
      />
      {props.children ?? null}
    </g>
  );
}

export function MothersRoomWithSymbol(props: {
  id: string;
  name?: string;
  children?: JSX.Element | JSX.Element[];
}) {
  return (
    <g id={props.id} transform="translate(-65, -65)">
      {props.name && <title>{props.name}</title>}
      <path
        css={{ strokeWidth: "3" }}
        d="M83.4,34.1h-39c-6.6,0-12,5.4-12,12v38c0,6.6,5.4,12,12,12h39c6.6,0,12-5.4,12-12v-38
	C95.4,39.5,90,34.1,83.4,34.1z"
      />
      <g>
        <path
          css={{
            fill: "none",
            stroke: "#000000",
            strokeWidth: "2",
            strokeMiterlimit: 10,
          }}
          d="M68.7,57.5c0.4-0.4,0.9,0,1.3,0.3c1.5,1,3,1.8,4.3,2.9c3,2.3,5.6,5,7.7,8.2c0.6,1,1.1,2.1,1.6,3.1
			c0.6,1.5,0.6,3,0.2,4.6c-0.9,3.2-2.5,6-5.1,8.2c-1.3,1.1-2.8,1.8-4.5,1.7s-3.1-1.2-3.1-2.8c0-0.8,0.2-1.6,0.6-2.3
			c0.5-1,1.2-2.3,1.9-3.3 M64,73.4c-0.5-0.7-1.2-1.5-1.8-2.1c-0.4-0.4-0.8-0.7-1.3-1c-0.9-0.5-1.8-0.9-2.7-0.3c-1,0.7-1.1,1.7-1,2.7
			c0.2,1.3,0.8,2.5,1.7,3.4c0.2,0.2,0.5,0.4,0.6,0.6c0.2,0.3,0.5,0.7,0.7,1.1c-0.4,0.2-0.8,0.5-1.3,0.5c-1.7,0.2-3.3-0.1-5-0.7
			c0.2,0.2,0.3,0.4,0.4,0.5c1.1,0.9,2.2,1.8,3.3,2.7c2.1,1.5,4.4,2.2,7,1.8c1.2-0.2,2.5-0.1,3.6,0.4c2,0.8,2.5,2.7,1,4.4
			c-1.1,1.2-2.5,1.8-4,2.1c-4.7,0.8-9.1,0.3-13.2-2.2c-4.4-2.7-6.6-6.8-6.8-12c0-2,0.6-3.9,1.4-5.7c1.9-4.1,4.6-7.6,8-10.6
			c0.5-0.4,1.1-0.9,1.8-0.5 M64,74.2c0,3,2.4,5.4,5.4,5.4c3,0,5.4-2.4,5.4-5.3c0-3-2.4-5.4-5.4-5.4C66.4,68.8,64,71.2,64,74.2z"
        />
        <path
          css={{
            fill: "none",
            stroke: "#000000",
            strokeWidth: "2",
            strokeMiterlimit: 10,
          }}
          d="M62,60c-5.4,0-9.8-4.3-9.8-9.7c0-5.3,4.4-9.7,9.7-9.7c5.4,0,9.8,4.3,9.8,9.7C71.7,55.7,67.4,60,62,60z"
        />
      </g>
      {props.children ?? null}
    </g>
  );
}

export function EquipmentWithSymbol(props: {
  id: string;
  name?: string;
  children?: JSX.Element | JSX.Element[];
}) {
  return (
    <g id={props.id}>
      {props.name && <title>{props.name}</title>}
      <rect
        x="-18.09328"
        y="-26.481285"
        width="36.18656"
        height="52.96257"
        fillOpacity={0.5}
      />
      {props.children ?? null}
    </g>
  );
}

const basic = (
  <SeatWithSymbol
    key="basic"
    id={RESOURCE_TYPES.DESK + "-basic"}
    name="Standard Desk"
  >
    <rect
      stroke="black"
      strokeWidth="15"
      strokeLinejoin="round"
      width="175"
      height="175"
      x="-75"
      y="-25"
    />
  </SeatWithSymbol>
);
const standing = (
  <SeatWithSymbol
    key="standing"
    id={RESOURCE_TYPES.DESK + "-standing"}
    name="Sit/Stand Desk"
  >
    <polygon
      stroke="black"
      strokeWidth="15"
      strokeLinejoin="round"
      points="10,-75 -65,45 85,45"
    />
    <polygon
      stroke="black"
      strokeWidth="15"
      strokeLinejoin="round"
      points="10,200 -65,80 85,80"
    />
  </SeatWithSymbol>
);

const standingDualmonitor = (
  <SeatWithSymbol
    key="standing-dualmonitor"
    id={RESOURCE_TYPES.DESK + "-standing-dualmonitor"}
    name="Dual Monitor Sit/Stand Desk"
  >
    <polygon
      stroke="black"
      strokeWidth="15"
      strokeLinejoin="round"
      points="100,-75 25,50 175,50"
    />
    <polygon
      stroke="black"
      strokeWidth="15"
      strokeLinejoin="round"
      points="100,200 25,75 175,75"
    />
    <polygon
      stroke="black"
      strokeWidth="15"
      strokeLinejoin="round"
      points="-100,-75 -25,50 -175,50"
    />
    <polygon
      stroke="black"
      strokeWidth="15"
      strokeLinejoin="round"
      points="-100,200 -25,75 -175,75"
    />
  </SeatWithSymbol>
);

const dualMonitor = (
  <SeatWithSymbol
    key="dualmonitor"
    id={RESOURCE_TYPES.DESK + "-dualmonitor"}
    name="Dual Monitor Desk"
  >
    <rect
      stroke="black"
      strokeWidth="15"
      strokeLinejoin="round"
      width="175"
      height="175"
      x="-200"
      y="-25"
    />
    <rect
      stroke="black"
      strokeWidth="15"
      strokeLinejoin="round"
      width="175"
      height="175"
      x="50"
      y="-25"
    />
  </SeatWithSymbol>
);

export const ccSymbols = [
  { symbol: basic, name: "Standard Desk" },
  { symbol: dualMonitor, name: "Dual Monitor Desk" },
  { symbol: standing, name: "Sit/Stand Desk" },
  { symbol: standingDualmonitor, name: "Dual Monitor Sit/Stand Desk" },
];
