import React, { useEffect, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useHistory } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Alert } from "@mui/material";
import mixpanel from "mixpanel-browser";
import { DateTime, Interval } from "luxon";
import i18next from "i18next";

import { CalendarDate, SIMPLE_ISO_TIME, Time } from "zynq-shared";
import BookingsListDialog from "./bookingsListDialog";
import MeetingBookingDialog from "./meeting-app/meetingBookingDialog";
import ScheduledVisitorDialog from "./scheduledVisitorDialog";
import Header from "./header";
import FloorplanContent from "./floorplanContent";
import GroupBookFloorplanContent from "./groupBookFloorplanContent";
import MultidayBookCalendar from "./multidayBookCalendar";
import GroupBookCalendar from "./groupBookCalendar";
import Questionnaire, { initialState } from "./questionnaire";
import QuestionnaireFailure from "./questionnaireFailure";
import ShowQRDialog from "./showQRDialog";
import type { QRDialogInfo } from "./showQRDialog";
import BuddyListDialog from "./buddyListDialog";
import * as Sentry from "@sentry/react";
import Blueprint from "./blueprint";

import {
  RESOURCE_TYPES,
  VACCINATION_STATUS,
  ZYNQ_DEMO_DOMAINS,
} from "./constants";
import {
  useAPIRequest,
  useLocalStorage,
  usePrevious,
  useError,
  useWindowFocus,
  useAPIMutation,
  useLiveRooms,
} from "./hooks";
import type {
  UserInfo,
  SeatInfo,
  FloorplanInfo,
  SeatEvent,
  BookingSeatEvent,
  BookingSeatInfo,
} from "./types";
import { SeatEventKind } from "./types";
import type { Dictionary } from "zynq-shared";
import { Seating } from "zynq-shared";
import { useTranslation } from "react-i18next";
import { TimeInterval } from "zynq-shared";
import { google } from "calendar-link";
import { timeIntervalToString, calcRelativeDate } from "./luxonUtil";
import { useQueryClient } from "react-query";
import type { DaysEnabled } from "./admin/weekdayPicker";

type CheckinState = {
  timeRange?: TimeInterval;
  bookingID?: number;
  ready: boolean;
  valid: boolean;
  extraData: Dictionary<string, any>;
  onSuccess: () => void;
};

function FloorplanPage(props: {
  userInfo: UserInfo;
  floorplanID?: number;
  date?: CalendarDate;
}) {
  const { t } = useTranslation();

  const showError = useError();

  // TODO: Remove this once we have migrated everything to APIMutation and APIQuery.
  const [loadingAction, setLoadingAction] = React.useState(false);

  const { mutate: makeBookingRequest } = useAPIMutation<
    typeof Seating.MakeBookings
  >("/apis/seating/make-bookings", {
    onError: (e) => showError(t("error-with-msg", { msg: e.reason })),
    onSettled: () => setLoadingAction(false),
  });
  const { mutate: releaseBookingRequest } = useAPIMutation<
    typeof Seating.ReleaseBooking
  >("/apis/seating/release-booking", {
    onError: (e) => showError(t("error-with-msg", { msg: e.reason })),
    onSuccess: () => refetchFloorplan(false).promise,
    onSettled: () => setLoadingAction(false),
  });
  const { mutate: makeDropinRequest } = useAPIMutation<
    typeof Seating.MakeDropin
  >("/apis/seating/make-dropin", {
    onError: (e) => showError(t("error-with-msg", { msg: e.reason })),
    onSettled: () => setLoadingAction(false),
  });
  const { mutate: releaseDropinRequest } = useAPIMutation<
    typeof Seating.ReleaseDropin
  >("/apis/seating/release-dropin", {
    onError: (e) => showError(t("error-with-msg", { msg: e.reason })),
    onSuccess: () => refetchFloorplan(false).promise,
    onSettled: () => setLoadingAction(false),
  });

  const [lastCheckinDate, setLastCheckinDate] = useLocalStorage(
    "lastCheckinDate",
    DateTime.fromMillis(0).toISODate()
  );
  const lastCheckinParsed = CalendarDate.fromISODate(lastCheckinDate);
  const api = useAPIRequest();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [today, setToday] = useState(CalendarDate.today());
  const [selectedSeatID, setSelectedSeatID] = useState<{
    id?: number;
    roomID?: number;
    zoom?: boolean;
    sidebarOpen: boolean;
  }>({ sidebarOpen: false });
  const [floorplan, setFloorplan] = React.useState<FloorplanInfo>();
  const floorplanIDRef = useRef(props.floorplanID);
  const [checkinState, setCheckinState] = useState<CheckinState | null>(null);
  const [releaseConfirmation, setReleaseConfirmation] = useState<null | {
    bookings: FloorplanInfo["myBookings"];
    onConfirm: () => void;
  }>(null);
  const [questionnaireFailure, setQuestionnaireFailure] = useState(false);
  const [showBookingsList, setShowBookingsList] = useState(false);
  const [showBuddyList, setShowBuddyList] = useState(false);
  const [questionnaireTitleText, setQuestionnaireTitleText] =
    useState("Coming in?");
  const [showScheduledVisitors, setShowScheduledVisitors] = useState(false);
  const [multidayBook, setMultidayBook] = useState<{
    hint?: SeatInfo;
    dropin?: boolean;
    showing: boolean;
    autoselect?: CalendarDate;
  }>({ showing: false });
  const [selectedAutoBookDays, setSelectedAutoBookDays] = useState<
    CalendarDate[] | null
  >(null);
  const [groupBook, setGroupBook] = useState<{
    showing: boolean;
    autoselect?: CalendarDate;
  }>({ showing: false });
  const [selectedGroupBookDays, setSelectedGroupBookDays] = useState<{
    days: CalendarDate[];
    timeInterval: TimeInterval;
    floorplanID: number;
  } | null>(null);
  const [groupBookSeatData, setGroupBookSeatData] = useState<SeatInfo[] | null>(
    null
  );
  const [showCheckinRequiredNotification, setShowCheckinRequiredNotification] =
    useState(false);
  const [autoBookResult, setAutoBookResult] = useState<{
    warning?: string;
    error?: string;
    failedDays?: CalendarDate[];
    bookedDays?: CalendarDate[];
    floorplanID?: number;
  }>({});
  const [qrInfo, setQRInfo] = useState<QRDialogInfo>({ open: false });
  const [error, setError] = useState<string | null>(null);
  const [showBookMeetingDialog, setShowBookMeetingDialog] =
    React.useState(false);
  const [bookingLoading, setBookingLoading] = useState<boolean>(false);
  const [defaultTimeRange, setDefaultTimeRange] = useState<TimeInterval>(
    (props.userInfo.domain.timeBasedBookingsEnabled
      ? !props.userInfo.allowedHours.isAllDay()
        ? props.userInfo.allowedHours
        : TimeInterval.fromISOTimes(
            floorplan?.defaultWorkingHours.start ??
              props.userInfo.domain.defaultWorkingHoursStart,
            floorplan?.defaultWorkingHours.end ??
              props.userInfo.domain.defaultWorkingHoursEnd
          )
      : TimeInterval.allDay
    ).assertValid()
  );
  const [groupBookOutcome, setGroupBookOutcome] = useState<
    {
      asUser: string | undefined;
      failedDays: CalendarDate[];
      bookedDays: CalendarDate[];
      warnings?: string | undefined;
    }[]
  >();

  const date = floorplan?.date ?? props.date ?? today;

  const { events: roomEvents, error: liveError } = useLiveRooms({
    rooms: floorplan?.rooms ?? [],
    dateFilter: date,
    domainName: props.userInfo.domain.name,
    includeWebLink: props.userInfo.domain.gsEnabled,
  });

  React.useEffect(() => {
    if (floorplan) {
      const localNow = DateTime.now().setZone(floorplan.timezone);
      const id = setTimeout(() => {
        refetchFloorplan(true);
      }, localNow.plus({ day: 1 }).startOf("day").diff(localNow).toMillis() + 1000);
      return () => clearTimeout(id);
    }
    return;
  }, [floorplan?.today.toISODate(), floorplan?.timezone]);

  const [timeRange, setTimeRange] = React.useState<{
    interval: TimeInterval;
    default: boolean;
    live: boolean;
  }>({
    interval: defaultTimeRange.isValid ? defaultTimeRange : TimeInterval.allDay,
    default: true,
    live:
      today.hasSame(date, "day") &&
      props.userInfo.domain.timeBasedBookingsEnabled,
  });

  React.useEffect(() => {
    const newTimeRange = (
      props.userInfo.domain.timeBasedBookingsEnabled
        ? !props.userInfo.allowedHours.isAllDay()
          ? props.userInfo.allowedHours
          : TimeInterval.fromISOTimes(
              floorplan?.defaultWorkingHours.start ??
                props.userInfo.domain.defaultWorkingHoursStart,
              floorplan?.defaultWorkingHours.end ??
                props.userInfo.domain.defaultWorkingHoursEnd
            )
        : TimeInterval.allDay
    ).assertValid();
    if (!newTimeRange.equals(defaultTimeRange)) {
      setDefaultTimeRange(newTimeRange);
      if (timeRange.default) {
        // Only update if we're displaying the default time range & default changed
        setTruncatedTimeRange(newTimeRange, true, true);
      }
    }
  }, [
    floorplan?.defaultWorkingHours.start,
    floorplan?.defaultWorkingHours.end,
  ]);

  React.useEffect((): void | (() => void) => {
    function updateTimeRange(tr: {
      interval: TimeInterval;
      default: boolean;
      live: boolean;
    }) {
      if (tr.live) {
        const now = Time.now(floorplan?.timezone);
        const newTimeInterval = TimeInterval.fromTimes(
          Time.min(
            Time.max(defaultTimeRange.start, now.plus({ minutes: 1 })),
            Time.endOfDay.minus({ minutes: 1 })
          ),
          Time.max(tr.interval.end, now.plus({ hours: 2 }))
        );
        if (newTimeInterval.isValid) {
          return {
            interval: newTimeInterval.constrainBy(props.userInfo.allowedHours),
            live: true,
            default: tr.default || tr.interval.end.equals(defaultTimeRange.end),
          };
        }
      }
      return {
        ...tr,
        default: tr.default || tr.interval.equals(defaultTimeRange),
      };
    }
    if (
      today.hasSame(date, "day") &&
      props.userInfo.domain.timeBasedBookingsEnabled
    ) {
      setTimeRange(updateTimeRange);
      const id = setInterval(() => {
        setTimeRange(updateTimeRange);
      }, 1000 * 30); // update every 30 s
      return () => clearInterval(id);
    }
  }, [
    floorplan?.timezone,
    date.toISODate(),
    today.toISODate(),
    props.userInfo.domain.timeBasedBookingsEnabled,
    defaultTimeRange,
  ]);

  const setTruncatedTimeRange = (
    range: TimeInterval,
    tryLive?: boolean,
    asDefault?: boolean
  ) => {
    const live = !!tryLive && today.hasSame(date, "day");
    setTimeRange({
      interval: range
        .set({ start: live ? Time.now(floorplan?.timezone) : undefined })
        .orDefault(defaultTimeRange)
        .constrainBy(props.userInfo.allowedHours),
      default:
        !!asDefault || live
          ? range.start.equals(defaultTimeRange.start)
          : range.equals(defaultTimeRange),
      live,
    });
  };

  const queryParams = new URLSearchParams(history.location.search);
  const queryParamSeatID = queryParams.get("seatID");
  const queryParamDialog = queryParams.get("dialog");
  const queryParamTrackingID = queryParams.get("trackingID");
  const queryParamTimeInterval = queryParams.get("timeInterval");

  const doRedirect = (
    opts: {
      date?: CalendarDate;
      floorplanID?: string | number;
      seatID?: number;
      today?: CalendarDate;
      timeInterval?: TimeInterval;
      replace?: boolean;
    } = {}
  ) => {
    let url = "/seating/floorplan";
    const id = opts.floorplanID || props.floorplanID;
    if (id) {
      url += "/" + id;
    }
    const combinedDate = opts.date || date || today;
    const localToday = opts.today || today;
    if (
      combinedDate &&
      localToday &&
      !combinedDate.hasSame(localToday, "day")
    ) {
      url += "/" + combinedDate.toISODate();
    }

    const queryString = new URLSearchParams({
      ...(!!opts.seatID && { seatID: opts.seatID?.toString() }),
      ...(opts.timeInterval && {
        timeInterval: opts.timeInterval
          .constrainBy(TimeInterval.allDay)
          .toSerialized(),
      }),
    }).toString();

    if (queryString) {
      url += "?" + queryString;
    }

    const floorplanChanged =
      opts.floorplanID != props.floorplanID &&
      opts.floorplanID &&
      props.floorplanID != null;
    const dateOrLocalToday = date ?? localToday;
    const dateChanged =
      opts.date != null &&
      dateOrLocalToday != null &&
      !opts.date.hasSame(dateOrLocalToday, "day");
    if (floorplanChanged || dateChanged) {
      setFloorplan(undefined);
    }

    if ((floorplanChanged || dateChanged) && !opts.replace) {
      console.log("Pushing", url, opts.replace);
      // Reset timerange to default if it was live-updating
      if (dateChanged && !localToday.hasSame(combinedDate, "day")) {
        setTimeRange((tr) =>
          tr.live
            ? { interval: defaultTimeRange, live: false, default: true }
            : tr
        );
      } else if (dateChanged && localToday.hasSame(combinedDate, "day")) {
        setTimeRange((tr) =>
          tr.interval.equals(defaultTimeRange)
            ? { interval: defaultTimeRange, live: true, default: true }
            : tr
        );
      }
      setSelectedSeatID({ sidebarOpen: false });
      history.push(url);
    } else if (url != window.location.pathname + window.location.search) {
      console.log("Replacing", url);
      history.replace(url);
    }
  };

  useEffect(() => {
    let shouldRedirect = false;
    if (queryParamSeatID) {
      setSelectedSeatID({
        id: parseInt(queryParamSeatID),
        zoom: true,
        sidebarOpen: true,
      });
      shouldRedirect = true;
    }
    if (queryParamTimeInterval) {
      const interval = TimeInterval.fromSerialized(queryParamTimeInterval);
      if (interval.isValid && props.userInfo.domain.timeBasedBookingsEnabled) {
        setTruncatedTimeRange(interval, false);
      }
      shouldRedirect = true;
    }
    if (queryParamDialog == "buddy") {
      setShowBuddyList(true);
    }
    if (shouldRedirect) {
      doRedirect();
    }
  }, [queryParamSeatID, queryParamTimeInterval]);

  useEffect(() => {
    if (queryParamTrackingID) {
      try {
        const trackingData = JSON.parse(
          atob(
            // Replace base64url characters with base64 equivalents
            queryParamTrackingID.replace(/\-/g, "+").replace(/_/g, "/")
          )
        );
        mixpanel.track("Email Inbound", {
          domain: props.userInfo.domain.name,
          ...trackingData,
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }, [queryParamTrackingID]);

  const refetchFloorplan = (updateSelectedSeat: boolean) => {
    queryClient.invalidateQueries("/apis/seating/employees-present");

    updateSelectedSeat = updateSelectedSeat && !queryParamSeatID;

    const params: {
      date?: string;
      floorplanID?: number;
      timezone: string;
    } = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (props.date) {
      params.date = props.date?.toISODate();
    }
    if (props.floorplanID) {
      params.floorplanID = props.floorplanID;
    }

    const { promise, cancel } = api.getNode(Seating.FloorplanInfo, params);
    promise.then((floorplan) =>
      unstable_batchedUpdates(() => {
        if (floorplan.status == "failed") {
          return setError(
            i18next.t("error-loading-floorplan", { reason: floorplan.reason })
          );
        }
        const userSeats = floorplan.seats.filter((s) =>
          s.events.some(
            (e: SeatEvent) => e.avatar?.email == props.userInfo.avatar.email
          )
        );

        const newDate = CalendarDate.fromISODate(floorplan.day);
        const newToday = CalendarDate.fromISODate(floorplan.curDay);

        doRedirect({
          date: newDate,
          floorplanID: floorplan.id,
          today: newToday,
          replace: true,
        });

        const hasDesks = floorplan.seats.length > 0;
        const checkinEvents = userSeats.flatMap((seat) =>
          (seat.events as SeatEvent[])
            .filter(
              (e) =>
                e.kind == SeatEventKind.CHECKIN &&
                e.avatar?.email == props.userInfo.avatar.email
            )
            .map((e) => ({ seat: seat, event: e }))
        );
        const newFloorplanInfo = {
          id: floorplan.id,
          name: floorplan.name,
          buildingName: floorplan.buildingName,
          img: floorplan.floorplanImg ?? null,
          floorplanDimensions: floorplan.floorplanDimensions,
          timezone: floorplan.timezone,
          seats: floorplan.seats,
          rooms: floorplan.rooms,
          autoReleasedSeat: floorplan.autoReleasedSeat,
          allowFutureDropin: floorplan.settings.allowFutureDropin,
          hideAutoBookButton: floorplan.settings.hideAutoBookButton,
          date: newDate,
          today: newToday,
          weekBookings: Object.fromEntries(
            Object.entries(floorplan.weekBookings).map(([key, weeks]) => [
              key,
              weeks.map((week) => week.map(CalendarDate.fromISODate)),
            ])
          ),
          myBookings: floorplan.myBookings.map((el) => {
            return {
              ...el,
              startTime: DateTime.fromISO(el.startTime, { setZone: true }),
              endTime: DateTime.fromISO(el.endTime, { setZone: true }),
            };
          }),
          dropinEnabled: floorplan.settings.dropinEnabled,
          defaultResourceType: RESOURCE_TYPES.DESK,
          maxWeeklyBookings: floorplan.settings.maxWeeklyBookings,
          bookAheadDaysMin: floorplan.settings.bookAheadDaysMin,
          bookAheadDaysMax: floorplan.settings.bookAheadDaysMax,
          weekendsBookable: floorplan.settings.weekendsBookable,
          checkinQREnabled: floorplan.settings.checkinQREnabled,
          checkinRequired: floorplan.settings.checkinRequired,
          maxDailyVisits: floorplan.settings.maxDailyVisits,
          nightShiftEnabled: floorplan.settings.nightShiftEnabled,
          questionnaireEnabled: floorplan.settings.questionnaireEnabled,
          onBehalfBulkBookingsEnabled:
            floorplan.settings.onBehalfBulkBookingsEnabled,
          defaultWorkingHours: {
            start: floorplan.settings.defaultWorkingHoursStart,
            end: floorplan.settings.defaultWorkingHoursEnd,
          },
          streetAddress: floorplan.settings.streetAddress,
          checkinEvents,
          seatVerifyQRURL: floorplan.verifyQRURL,
          dropinData: floorplan.dropinData,
          hasDesks: hasDesks,
          todaysNumVisits: floorplan.todaysNumVisits,
          allResourceTypes: [
            ...new Set(floorplan.seats.map((s) => s.resourceType)),
          ],
          userVisible: floorplan.userVisible,
        };
        setFloorplan(newFloorplanInfo);
        floorplanIDRef.current = floorplan.id;

        setToday(newToday);

        if (newToday.hasSame(newDate, "day") && !selectedSeatID.sidebarOpen) {
          const bookings = floorplan.weekBookings.DESK?.[0] || [];
          const hasAssignedSeatToday = bookings.indexOf(floorplan.curDay) >= 0;
          if (
            userSeats.length == 0 &&
            !queryParamSeatID &&
            !hasAssignedSeatToday &&
            !floorplan.dropinData.needsCheckin
          ) {
            setSelectedSeatID({ sidebarOpen: true });
          } else if (updateSelectedSeat) {
            if (floorplan.dropinData.hasDropin) {
              setSelectedSeatID({ sidebarOpen: true });
            } else {
              setSelectedSeatID({
                // TODO(tbb) what to DO with userseat?
                id: userSeats[0]?.id,
                zoom: true,
                sidebarOpen: true,
              });
            }

            const localNow = DateTime.now().setZone(floorplan.timezone);
            if (
              (checkinEvents.filter(
                (e) =>
                  Time.fromISOTime(e.event.endTime)
                    .assertValid()
                    .toDateTime(
                      CalendarDate.fromISODate(e.event.endDate).toDateTime({
                        zone: floorplan.timezone,
                      })
                    ) > localNow
              ).length > 0 ||
                floorplan.dropinData.needsCheckin) &&
              newToday.hasSame(newDate, "day")
            ) {
              checkinWithCompletion(
                [newDate],
                { floorplan: newFloorplanInfo },
                () =>
                  doAllCheckins({ today: newToday, floorplanID: floorplan.id })
              );
            }
          }
        } else if (updateSelectedSeat && !selectedSeatID.sidebarOpen) {
          if (userSeats[0] !== undefined) {
            setSelectedSeatID({
              id: userSeats[0]?.id,
              zoom: true,
              sidebarOpen: true,
            });
          } else {
            setSelectedSeatID({ sidebarOpen: true });
          }
        }
      })
    );
    return { cancel, promise };
  };

  React.useEffect(() => {
    if (selectedGroupBookDays) {
      // Fetch the data for those days:
      setLoadingAction(true);
      const { promise, cancel } = api.getNode(Seating.MultiBookFloorplanInfo, {
        floorplanID: selectedGroupBookDays.floorplanID,
        dates: selectedGroupBookDays.days.map((d) => d.toISODate()).join(","),
        startTime: selectedGroupBookDays.timeInterval.start.toISOTime(),
        endTime: selectedGroupBookDays.timeInterval.end.toISOTime(),
      });
      promise.then((result) => {
        setLoadingAction(false);
        if (result.status == "failed") {
          return showError("Error: " + result.reason);
        }
        setGroupBookSeatData(result.seats);
        setGroupBook({ ...groupBook, showing: false });
      });
      return () => {
        setLoadingAction(false);
        cancel();
      };
    }
    return;
  }, [selectedGroupBookDays]);

  const focused = useWindowFocus();
  useEffect((): void | (() => void) => {
    if (focused || !floorplan) {
      return refetchFloorplan(!floorplan).cancel;
    }
  }, [focused]);

  const completedQuestionnaire = (
    passed: boolean,
    extraData: any,
    floorplanID: number
  ) => {
    api.post("/visitor/api/completed_questionnaire", {
      passed,
      extraData,
      floorplanID,
    });
  };

  const prevFloorplanID = usePrevious(props.floorplanID);
  const prevDate = usePrevious(date);
  useEffect(() => {
    let cancel = undefined;

    if (
      props.floorplanID &&
      (!date.isValid || (date < today && !props.userInfo.isAdmin))
    ) {
      doRedirect({ today, floorplanID: props.floorplanID });
    } else if (
      (prevDate != null && prevDate != date) ||
      (prevFloorplanID != null && prevFloorplanID != props.floorplanID)
    ) {
      cancel = refetchFloorplan(true).cancel;
    }
    mixpanel.track("Floorplan view", {
      floorplan: props.floorplanID,
      date: date && date.toISODate(),
      domain: props.userInfo.domain.name,
    });
    return cancel;
  }, [
    date.isValid && date.toISODate(),
    today.isValid && today.toISODate(),
    props.floorplanID,
  ]);

  // TODO pass the correct resourceType when there is no hint
  const autoBook = (
    days: CalendarDate[],
    timeRange: TimeInterval,
    hint: SeatInfo | undefined
  ) => {
    setLoadingAction(true);
    const curFloorplanID = floorplanIDRef.current;
    api
      .postNode(Seating.MakeBookings, {
        floorplanID: curFloorplanID,
        days: days.map((d) => d.toISODate()),
        seatID: hint?.id,
        canSubstituteSeat: true,
        resourceType:
          hint?.resourceType ??
          floorplan?.defaultResourceType ??
          RESOURCE_TYPES.DESK,
        startTime: timeRange.start.toISOTime(SIMPLE_ISO_TIME),
        endTime: timeRange.end.toISOTime(SIMPLE_ISO_TIME),
        bookingLengthDays: timeRange.days,
      })
      .promise.then((result) => {
        if (result.status == "failed") {
          setAutoBookResult({
            error: result.reason,
            failedDays: days,
            bookedDays: [],
            floorplanID: Number(curFloorplanID),
          });
        } else {
          setAutoBookResult({
            warning: result.warnings,
            failedDays: result.failedDays.map(CalendarDate.fromISODate),
            bookedDays: result.bookedDays.map(CalendarDate.fromISODate),
            floorplanID: Number(curFloorplanID),
          });
        }
        setShowBookingsList(true);

        // TODO: reenable this check once we don't need it to fix booked days refresh for the smart book cal view.
        // // Refresh floorplan if we booked over the currently viewed day.
        // let formattedDate = formatDate(date, DATE_FMT);
        // if (days.indexOf(formattedDate) >= 0) {
        //   refetchFloorplan(true);
        // }
        refetchFloorplan(true);
      })
      .finally(() => setLoadingAction(false))
      .finally(() => setCheckinState(null))
      .finally(() => setMultidayBook({ ...multidayBook, showing: false }));
  };

  function makeDropin(
    floorplanID: number,
    dates: CalendarDate | CalendarDate[],
    options?: {
      openDialog?: boolean;
      date?: CalendarDate;
    }
  ) {
    const openDialog = !!options?.openDialog;
    const datesArray = Array.isArray(dates) ? dates : [dates];
    setLoadingAction(true);

    makeDropinRequest(
      {
        floorplanID,
        dates: datesArray.map((d) => d.toISODate()),
        markAsArrived: false,
      },
      {
        onSuccess: (res) =>
          refetchFloorplan(false).promise.then(() => {
            unstable_batchedUpdates(() => {
              setLoadingAction(false);
              setCheckinState(null);
              setMultidayBook({ ...multidayBook, showing: false });
              if (openDialog) {
                if (
                  res.qrURL &&
                  res.qrURL.length > 0 &&
                  dates instanceof DateTime
                ) {
                  setQRInfo({
                    open: true,
                    verificationURL: res.qrURL,
                    date: date ?? options?.date ?? dates,
                    domainName: props.userInfo.domain.name,
                  });
                } else if (multidayBook.showing || Array.isArray(dates)) {
                  // This is a multibook
                  setAutoBookResult({
                    bookedDays: datesArray,
                    floorplanID: floorplanID,
                    warning: res.warning,
                    failedDays: res.failedDays.map(CalendarDate.fromISODate),
                  });
                  setShowBookingsList(true);
                } else {
                  setShowCheckinRequiredNotification(true);
                }
              }
            });
          }),
      }
    );
  }

  function releaseDropin(bookingID: number, opts?: { asAdmin: boolean }) {
    setLoadingAction(true);
    releaseDropinRequest({
      bookingID,
      asAdmin: !!opts?.asAdmin,
    });
  }

  const releaseBooking = (bookingID: number, opts?: { asAdmin: boolean }) => {
    setLoadingAction(true);
    releaseBookingRequest({
      bookingID,
      asAdmin: !!opts?.asAdmin,
    });
  };

  const makeBooking = (
    seatID: number | undefined,
    resourceType: RESOURCE_TYPES,
    opts?: { event?: BookingSeatEvent; timeRange?: TimeInterval }
  ) => {
    const internalDate = opts?.event?.startDate
      ? CalendarDate.fromISODate(opts.event.startDate)
      : date;
    const bookingLengthDays = opts?.event
      ? DateTime.fromISO(opts.event.endDate).diff(
          DateTime.fromISO(opts.event.startDate),
          ["days"]
        ).days
      : timeRange.interval.days;
    if (!internalDate || !internalDate.isValid) {
      return showError(
        t("error-invalid-booking-date", {
          date: internalDate.toLocaleString(DateTime.DATE_MED),
        })
      );
    }
    if (!floorplanIDRef.current) {
      return;
    }
    setLoadingAction(true);
    makeBookingRequest(
      {
        floorplanID: floorplanIDRef.current,
        seatID,
        canSubstituteSeat: false,
        days: [internalDate.toISODate()],
        resourceType: resourceType,
        startTime:
          opts?.event?.startTime ??
          opts?.timeRange?.start.toISOTime(SIMPLE_ISO_TIME) ??
          timeRange.interval.start.toISOTime(SIMPLE_ISO_TIME),
        endTime:
          opts?.event?.endTime ??
          opts?.timeRange?.end.toISOTime(SIMPLE_ISO_TIME) ??
          timeRange.interval.end.toISOTime(SIMPLE_ISO_TIME),
        bookingLengthDays,
      },
      {
        onSuccess(res) {
          refetchFloorplan(false).promise.then(() =>
            unstable_batchedUpdates(() => {
              setCheckinState(null);
              setMultidayBook({ ...multidayBook, showing: false });
              if (res.verifyURL && res.verifyURL.length > 0) {
                setQRInfo({
                  date: internalDate,
                  open: true,
                  verificationURL: res.verifyURL,
                  domainName: props.userInfo.domain.name,
                });
              } else if (res.checkinRequired) {
                setShowCheckinRequiredNotification(true);
              }
            })
          );
        },
      }
    );
  };

  const doAllCheckins = (opts?: {
    today?: CalendarDate;
    floorplanID?: number;
  }) => {
    setLoadingAction(true);
    api
      .post("/seating/api/checkin", {
        date: opts?.today?.toISODate() ?? floorplan?.today.toISODate(),
        floorplanID: opts?.floorplanID ?? floorplan?.id,
      })
      .promise.then((res) => {
        if (res.status == "failed") {
          setLoadingAction(false);
          return showError("Error: " + res.reason);
        }
        refetchFloorplan(false).promise.then(() =>
          unstable_batchedUpdates(() => {
            setLoadingAction(false);
            setCheckinState(null);
            setMultidayBook({ ...multidayBook, showing: false });
            if (res.verifyURL && res.verifyURL.length > 0) {
              setQRInfo({
                date: opts?.today ?? floorplan!.today,
                open: true,
                verificationURL: res.verifyURL,
                domainName: props.userInfo.domain.name,
              });
            } else if (res.checkinRequired) {
              setShowCheckinRequiredNotification(true);
            }
          })
        );
      });
  };

  const executeBooking = (args: {
    autoBookDays?: CalendarDate[];
    newSelectedSeat?: BookingSeatInfo;
    resourceType?: RESOURCE_TYPES;
    timeRange?: TimeInterval;
    event?: BookingSeatEvent;
  }) => {
    const days = args.autoBookDays || selectedAutoBookDays;
    const localTimeRange =
      (args.event && TimeInterval.fromISODatesAndTimes(args.event)) ??
      args.timeRange ??
      timeRange.interval;
    if (days) {
      autoBook(days, localTimeRange, multidayBook.hint);
    } else {
      const seat = selectedSeat || args.newSelectedSeat;
      makeBooking(
        seat?.id,
        seat?.resourceType ?? args.resourceType ?? RESOURCE_TYPES.DESK,
        { timeRange: localTimeRange }
      );
    }
  };

  function releaseNotificationWithCompletion(
    seat: SeatInfo,
    bookingInterval: Interval,
    completion: () => void
  ) {
    const overlappingBookings = floorplan?.myBookings.filter((b) => {
      return (
        seat.resourceType == b.seat.resourceType &&
        seat.resourceType != RESOURCE_TYPES.EQUIPMENT && // You can book multiple equipment spots at a time
        bookingInterval.overlaps(Interval.fromDateTimes(b.startTime, b.endTime))
      );
    });

    if (overlappingBookings?.length) {
      setReleaseConfirmation({
        bookings: overlappingBookings,
        onConfirm: completion,
      });
      return false;
    } else {
      return completion();
    }
  }

  function checkinWithCompletion(
    bookingDates: CalendarDate[],
    opts: {
      bookingID?: number;
      timeRange?: TimeInterval;
      floorplan?: FloorplanInfo;
    },
    completion: () => void
  ) {
    const latestFloorplanInfo = opts.floorplan ?? floorplan;

    // If you're not booking for today, and checkin is required, don't show questionnaire
    if (
      latestFloorplanInfo?.checkinRequired &&
      !bookingDates.some((d) => today.hasSame(d, "day"))
    ) {
      completion();
      return true;
    }

    if (
      latestFloorplanInfo?.questionnaireEnabled &&
      (!lastCheckinParsed.isValid ||
        lastCheckinParsed < today ||
        ["deciem.com", ...ZYNQ_DEMO_DOMAINS].includes(
          props.userInfo.domain.name
        ))
    ) {
      setCheckinState({
        ...initialState,
        bookingID: opts.bookingID,
        timeRange: opts.timeRange,
        onSuccess: completion,
      });
      return false;
    } else {
      completion();
      return true;
    }
  }

  const selectedSeat: SeatInfo | undefined = selectedSeatID.id
    ? floorplan?.seats?.find((s) => s.id === selectedSeatID.id)
    : undefined;

  const selectedRoom = selectedSeatID.roomID
    ? floorplan?.rooms.find((s) => s.id == selectedSeatID.roomID)
    : undefined;

  let meetingBookingInterval = timeRange.interval.toInterval(
    today.toDateTime({ zone: floorplan?.timezone })
  );
  if (floorplan && timeRange.live) {
    const nowDateTime = DateTime.now().setZone(
      selectedRoom ? selectedRoom.timezone : floorplan.timezone
    );
    const nowTime = nowDateTime.plus({
      minutes: 15 - (nowDateTime.minute % 15),
    });
    meetingBookingInterval = Interval.fromDateTimes(
      nowTime,
      DateTime.min(nowTime.endOf("day"), nowTime.plus({ minutes: 60 }))
    );
  }

  const relativeDate = calcRelativeDate(today, date);

  return (
    <>
      <Header
        userInfo={props.userInfo}
        floorplan={floorplan}
        today={today}
        selectSeatCallback={(seatID, floorplanID) => {
          if (!seatID && !floorplanID) return;
          if (floorplanID == props.floorplanID) {
            setSelectedSeatID({ id: seatID, zoom: true, sidebarOpen: true });
          } else {
            doRedirect({ date, floorplanID, seatID });
          }
        }}
        selectRoomCallback={(id) => {
          if (!id) return;
          setSelectedSeatID({ roomID: id, zoom: true, sidebarOpen: true });
        }}
        timeRange={timeRange.interval}
        defaultTimeRange={defaultTimeRange}
        timeRangeEnabled={
          floorplan != null &&
          floorplan.img != null &&
          props.userInfo.domain.timeBasedBookingsEnabled
        }
        setTimeRange={setTruncatedTimeRange}
        maxDate={today.plus({ days: floorplan?.bookAheadDaysMax ?? 0 })}
        date={date}
        setDate={(date) => doRedirect({ date: date })}
        onSelectFloorplanLoc={(floorplanLoc) => {
          doRedirect({
            date: date,
            floorplanID: floorplanLoc.id,
            today: today,
          });
        }}
        groupBookFreeze={selectedGroupBookDays}
        onClickScheduleBook={({ group }) => {
          if (group) {
            setGroupBookSeatData(null);
            setSelectedGroupBookDays(null);
            setGroupBook({
              showing: true,
            });
          } else {
            setMultidayBook({
              showing: true,
              dropin: floorplan != null && floorplan?.img == null,
            });
          }
        }}
        defaultResourceType={
          floorplan?.defaultResourceType ?? RESOURCE_TYPES.DESK
        }
        onClickListBookings={() => setShowBookingsList(true)}
        onClickBuddySync={() => setShowBuddyList(true)}
        onClickScheduledVisitors={() => setShowScheduledVisitors(true)}
        hasDesks={floorplan?.hasDesks ?? false}
      />
      {floorplan && selectedGroupBookDays && groupBookSeatData ? (
        <GroupBookFloorplanContent
          roomEvents={roomEvents}
          floorplan={{ ...floorplan, seats: groupBookSeatData }}
          groupSeatData={groupBookSeatData}
          selectedGroupBookDays={selectedGroupBookDays}
          userInfo={props.userInfo}
          loadingAction={loadingAction}
          zoomSeat={selectedSeatID}
          setZoomSeat={setSelectedSeatID}
          refreshFloorplan={() => {
            return refetchFloorplan(false);
          }}
          closeGroupBook={() => {
            setGroupBookSeatData(null);
            setSelectedGroupBookDays(null);
          }}
          setGroupBookOutcome={(outcomesList) =>
            setGroupBookOutcome(
              outcomesList.flatMap((outcome) => {
                if (!outcome.asUser) return []; // Every booking should have an 'asUser' here but filter out in case

                return [
                  {
                    bookedDays: outcome.bookedDays.map(
                      CalendarDate.fromISODate
                    ),
                    failedDays: outcome.failedDays.map(
                      CalendarDate.fromISODate
                    ),
                    warnings: outcome.warnings,
                    asUser: outcome.asUser,
                  },
                ];
              })
            )
          }
        />
      ) : (
        <FloorplanContent
          roomEvents={roomEvents}
          floorplan={floorplan}
          timeRange={timeRange.interval}
          defaultTimeRange={defaultTimeRange}
          setTimeRange={setTruncatedTimeRange}
          userInfo={props.userInfo}
          loadingAction={loadingAction}
          zoomSeat={selectedSeatID}
          setZoomSeat={setSelectedSeatID}
          today={today}
          date={date}
          relativeDate={relativeDate}
          showQRDialog={setQRInfo}
          onReleaseDropin={(bookingID: number, asAdmin: boolean) =>
            releaseDropin(bookingID, { asAdmin })
          }
          onBook={(seat: SeatInfo) => {
            releaseNotificationWithCompletion(
              seat,
              timeRange.interval.toInterval(
                date.toDateTime({ zone: floorplan?.timezone })
              ),
              () =>
                checkinWithCompletion([date], {}, () =>
                  executeBooking({
                    newSelectedSeat: seat,
                    resourceType: seat.resourceType,
                  })
                )
            );
          }}
          onBookMeeting={() => {
            if (
              props.userInfo.domain.gsEnabled &&
              !props.userInfo.domain.allowFloorplanMeetingBooking
            ) {
              if (selectedRoom) {
                const nowLocal = DateTime.now().setZone(selectedRoom.timezone);
                console.log(
                  nowLocal.plus({ minutes: 60 - nowLocal.minute }).toISO()
                );
                window.open(
                  google({
                    title: "",
                    start: nowLocal
                      .plus({ minutes: 60 - nowLocal.minute })
                      .toISO(),
                    duration: [1, "hour"],
                    guests: [selectedRoom.calendarID],
                  })
                );
              } else {
                window.open("https://calendar.google.com/");
              }
            } else {
              mixpanel.track("FP Room Book Click", {
                floorplan: props.floorplanID,
                room: selectedRoom?.name,
                domain: props.userInfo.domain.name,
              });
              setShowBookMeetingDialog(true);
            }
          }}
          onRelease={(bookingID: number, asAdmin: boolean) =>
            releaseBooking(bookingID, { asAdmin })
          }
          onAutobook={(options: {
            hint?: SeatInfo;
            dates?: CalendarDate[];
            dropin?: boolean;
            autoselect?: CalendarDate;
          }) => {
            if (options.dates) {
              setSelectedAutoBookDays(options.dates);
              checkinWithCompletion(options.dates, {}, () =>
                executeBooking({ autoBookDays: options.dates })
              );
            } else {
              setMultidayBook({
                showing: true,
                hint: options.hint,
                dropin: options.dropin,
                autoselect: options.autoselect,
              });
            }
          }}
          onCheckin={(seat: SeatInfo, e: SeatEvent) =>
            checkinWithCompletion(
              [date],
              {
                bookingID: e.bookingID ?? undefined,
                timeRange: TimeInterval.fromISODatesAndTimes({
                  ...e,
                  relativeTo: date,
                }).assertValid(),
              },
              () =>
                executeBooking({
                  newSelectedSeat: seat,
                  resourceType: seat.resourceType,
                  event: e,
                })
            )
          }
          onCheckinAll={() => checkinWithCompletion([date], {}, doAllCheckins)}
          onDropin={() => {
            return (
              floorplan &&
              checkinWithCompletion([date], {}, () =>
                makeDropin(floorplan.id, date, {
                  openDialog: true,
                })
              )
            );
          }}
          refreshFloorplan={() => refetchFloorplan(false)}
          onNavigate={(date, floorplanID, seatID, timeInterval) => {
            doRedirect({ date, floorplanID, seatID, timeInterval });
          }}
        />
      )}
      <Dialog
        open={releaseConfirmation != null}
        onClose={(_) => {
          setReleaseConfirmation(null);
          setSelectedAutoBookDays(null);
        }}
        scroll={"paper"}
        aria-labelledby="release-confirmation-dialog-title"
      >
        <DialogTitle
          id="release-confirmation-dialog-title"
          css={{ textAlign: "center" }}
        >
          {t("confirm-release")}
        </DialogTitle>
        <DialogContent css={{ maxWidth: "25rem" }}>
          {releaseConfirmation && (
            <>
              {t("you-have-conflicting-bookings", {
                count: releaseConfirmation.bookings.length,
              })}
              <ul
                css={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  margin: "1rem 0",
                }}
              >
                {releaseConfirmation.bookings.map((b) => (
                  <li key={b.startTime.toISO() + b.seat.id}>
                    <div>{`${b.seat.name} (${b.floorplan.name})`}</div>
                    <div>
                      {`${timeIntervalToString(
                        TimeInterval.fromDateTimes(b.startTime, b.endTime, date)
                      )}`}
                    </div>
                  </li>
                ))}
              </ul>
              {t("click-confirm-to-release")}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(_) => {
              setReleaseConfirmation(null);
              setSelectedAutoBookDays(null);
            }}
            color="secondary"
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button
            type="submit"
            disabled={loadingAction}
            startIcon={
              loadingAction ? (
                <CircularProgress style={{ marginRight: "10px" }} size={20} />
              ) : null
            }
            onClick={(_) => {
              releaseConfirmation?.onConfirm();
              setReleaseConfirmation(null);
            }}
            color="primary"
            variant="contained"
          >
            {i18next.t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!groupBookOutcome}
        onClose={(_) => {
          setGroupBookOutcome(undefined);
        }}
        scroll={"paper"}
        aria-labelledby="release-confirmation-dialog-title"
      >
        <DialogTitle
          id="release-confirmation-dialog-title"
          css={{ textAlign: "center" }}
        >
          {!groupBookOutcome?.some((r) => r.failedDays.length > 0)
            ? t("group-booking-successful")
            : groupBookOutcome?.some((r) => r.bookedDays.length > 0)
            ? t("group-booking-mixed")
            : t("something-went-wrong")}
        </DialogTitle>
        <DialogContent css={{ maxWidth: "25rem" }}>
          {groupBookOutcome?.map((outcome) => {
            const failedDaysMessage =
              outcome.failedDays.length > 0
                ? t("failed-to-book-resource-on-days", {
                    resource: t("desk"),
                    days: outcome.failedDays
                      .map((d) =>
                        d.toLocaleString({
                          month: "long",
                          day: "numeric",
                        })
                      )
                      .join(", "),
                  })
                : undefined;
            if (!failedDaysMessage && !outcome.warnings) return undefined;

            return (
              <div key={outcome.asUser}>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {outcome.asUser}
                </div>
                {outcome.warnings && (
                  <Alert
                    severity="warning"
                    style={{
                      margin: "1rem",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {outcome.warnings}
                  </Alert>
                )}
                {failedDaysMessage && (
                  <Alert
                    severity="error"
                    style={{
                      margin: "1rem",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {failedDaysMessage}
                  </Alert>
                )}
              </div>
            );
            return;
          })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(_) => {
              setGroupBookOutcome(undefined);
            }}
            color="secondary"
            variant="contained"
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={checkinState != null}
        onClose={(_) => {
          setCheckinState(null);
          setSelectedAutoBookDays(null);
        }}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle id="scroll-dialog-title" css={{ textAlign: "center" }}>
          {questionnaireTitleText}
        </DialogTitle>
        <DialogContent css={{ maxWidth: "25rem" }}>
          {floorplan && (
            <Questionnaire<CheckinState>
              state={
                checkinState ?? {
                  ready: false,
                  valid: false,
                  extraData: {},
                  onSuccess: () => null,
                }
              }
              setState={setCheckinState}
              domainName={props.userInfo?.domain?.name}
              floorplanName={floorplan.name}
              setTitle={(title) => setQuestionnaireTitleText(title)}
              userVaccinationStatus={
                props.userInfo?.vaccinationStatus ?? VACCINATION_STATUS.UNKNOWN
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          {checkinState && checkinState.bookingID && selectedSeat ? (
            <Button
              onClick={(_) => {
                setCheckinState(null);
                releaseBooking(checkinState.bookingID!);
              }}
              color="secondary"
              variant="contained"
            >
              {t("release")}
            </Button>
          ) : (
            <Button
              onClick={(_) => {
                setCheckinState(null);
                setSelectedAutoBookDays(null);
              }}
              color="secondary"
              variant="contained"
            >
              {t("cancel")}
            </Button>
          )}
          <Button
            type="submit"
            disabled={!checkinState?.ready || loadingAction}
            onClick={(_) => {
              completedQuestionnaire(
                !!checkinState?.valid,
                checkinState?.extraData,
                props.floorplanID!
              );
              if (checkinState?.valid) {
                checkinState?.onSuccess();
                setLastCheckinDate(today.toISODate());
              } else {
                setCheckinState(null);
                setQuestionnaireFailure(true);
              }
            }}
            color="primary"
            variant="contained"
          >
            {loadingAction ? (
              <CircularProgress
                data-test-id="loading-questionnaire-submission"
                style={{ marginRight: "10px" }}
                size={20}
              />
            ) : null}
            {props.userInfo.domain.name == "mollie.com"
              ? i18next.t("check-in")
              : props.userInfo?.domain?.name == "seismic.com"
              ? i18next.t("continue")
              : i18next.t("submit")}
          </Button>
        </DialogActions>
      </Dialog>
      {floorplan && props.userInfo.domain.scheduledVisitorsEnabled && (
        <ScheduledVisitorDialog
          userInfo={props.userInfo}
          isOpen={showScheduledVisitors}
          today={today}
          floorplan={{
            floorplanID: floorplan.id,
            name: floorplan.name,
            timezone: floorplan.timezone,
          }}
          onClose={() => {
            setShowScheduledVisitors(false);
          }}
          defaultStartTime={
            defaultTimeRange.start.equals(Time.zero)
              ? "08:00"
              : defaultTimeRange.start.toISOTime(SIMPLE_ISO_TIME)
          }
        />
      )}
      <BookingsListDialog
        isOpen={showBookingsList}
        today={today}
        timeBasedBookingEnabled={props.userInfo.domain.timeBasedBookingsEnabled}
        autoBookResult={autoBookResult}
        qrEnabled={!!floorplan && floorplan.checkinQREnabled}
        onNavigate={(floorplanID, date, seatID, timeInterval) => {
          setShowBookingsList(false);
          setSelectedAutoBookDays(null);
          setAutoBookResult({});
          doRedirect({
            date,
            floorplanID,
            seatID,
            timeInterval,
          });
        }}
        onShowQR={(verificationURL, date) => {
          setQRInfo({
            date,
            verificationURL,
            open: true,
            domainName: props.userInfo.domain.name,
          });
        }}
        onClose={() => {
          setShowBookingsList(false);
          setSelectedAutoBookDays(null);
          setAutoBookResult({});
        }}
        onClickBook={() => {
          setShowBookingsList(false);
          setSelectedAutoBookDays(null);
          setMultidayBook({ showing: true });
        }}
        onEditDate={(editedDate) => {
          // TODO: reenable this check once we don't need it to fix booked days refresh for the smart book cal view.
          // if (editedDate == formatDate(date, DATE_FMT)) {
          refetchFloorplan(true);
          // }
        }}
        defaultResourceType={
          floorplan?.defaultResourceType ?? RESOURCE_TYPES.DESK
        }
        hasDesks={floorplan?.hasDesks ?? false}
      />
      {floorplan && floorplan.rooms.length > 0 && (
        <MeetingBookingDialog
          open={showBookMeetingDialog}
          floorplan={floorplan}
          timezone={selectedRoom?.timezone ?? floorplan.timezone}
          initialRoom={selectedRoom}
          rooms={floorplan?.rooms}
          events={(selectedRoom && roomEvents[selectedRoom.calendarID]) ?? []}
          initialDay={date}
          initialTimeRange={meetingBookingInterval}
          meetingWizardEnabled={
            !selectedRoom && props.userInfo.domain.meetingWizardEnabled
          }
          allowDayToggle={true}
          loading={bookingLoading}
          onClose={() => {
            setBookingLoading(false);
            setShowBookMeetingDialog(false);
          }}
          organizer={props.userInfo.avatar}
          domainName={props.userInfo.domain.name}
        />
      )}
      {floorplan && (
        <MultidayBookCalendar
          shouldShow={multidayBook.showing}
          buddySyncEnabled={props.userInfo.domain.buddySyncEnabled}
          weekBookings={floorplan.weekBookings}
          resourceType={
            selectedSeat?.resourceType ??
            floorplan?.defaultResourceType ??
            RESOURCE_TYPES.DESK
          }
          allResourceTypes={floorplan.allResourceTypes}
          selectedSeat={selectedSeat}
          userSchedule={props.userInfo.userSchedule}
          dropin={!!multidayBook.dropin}
          today={today}
          timezone={floorplan.timezone}
          outerTimeRange={timeRange}
          defaultTimeRange={defaultTimeRange}
          showTimeRange={
            !multidayBook.dropin &&
            props.userInfo.domain.timeBasedBookingsEnabled
          }
          autoselect={multidayBook.autoselect}
          allowedHours={props.userInfo.allowedHours}
          onClose={() => setMultidayBook({ ...multidayBook, showing: false })}
          loading={loadingAction}
          bookAheadDaysMin={floorplan.bookAheadDaysMin}
          bookAheadDaysMax={floorplan.bookAheadDaysMax}
          nightShiftEnabled={floorplan.nightShiftEnabled}
          maxWeeklyBookings={floorplan.maxWeeklyBookings}
          weekendsBookable={floorplan.weekendsBookable}
          onSuccess={(days, timeRange) => {
            setSelectedAutoBookDays(days);

            const res = checkinWithCompletion(
              days,
              {
                timeRange: timeRange,
              },
              multidayBook.dropin
                ? () => makeDropin(floorplan?.id, days, { openDialog: true })
                : () => executeBooking({ autoBookDays: days, timeRange })
            );

            if (!res) {
              setMultidayBook({ ...multidayBook, showing: false });
            } else {
              setLoadingAction(true);
            }
          }}
        />
      )}
      {floorplan && (
        <GroupBookCalendar
          shouldShow={groupBook.showing}
          buddySyncEnabled={props.userInfo.domain.buddySyncEnabled}
          weekBookings={floorplan.weekBookings}
          resourceType={
            selectedSeat?.resourceType ??
            floorplan?.defaultResourceType ??
            RESOURCE_TYPES.DESK
          }
          allResourceTypes={floorplan.allResourceTypes}
          selectedSeat={undefined}
          userSchedule={
            [[true, true, true, true, true, true, true]] as DaysEnabled[]
          }
          dropin={false}
          today={today}
          timezone={floorplan.timezone}
          outerTimeRange={timeRange}
          defaultTimeRange={defaultTimeRange}
          showTimeRange={
            !multidayBook.dropin &&
            props.userInfo.domain.timeBasedBookingsEnabled
          }
          autoselect={multidayBook.autoselect}
          allowedHours={props.userInfo.allowedHours}
          onClose={() => setGroupBook({ ...groupBook, showing: false })}
          loading={loadingAction}
          bookAheadDaysMin={floorplan.bookAheadDaysMin}
          bookAheadDaysMax={floorplan.bookAheadDaysMax}
          nightShiftEnabled={false} // TODO: consider adding support for night shift
          maxWeeklyBookings={floorplan.maxWeeklyBookings}
          weekendsBookable={floorplan.weekendsBookable}
          onSuccess={(days, timeRange) => {
            if (days.length && props.floorplanID) {
              setSelectedGroupBookDays({
                days: days,
                timeInterval: timeRange,
                floorplanID: props.floorplanID,
              });
            }
          }}
        />
      )}
      <ShowQRDialog
        info={qrInfo}
        location={floorplan?.name || i18next.t("the-office")}
        onClose={() => setQRInfo({ open: false })}
      />
      <QuestionnaireFailure
        isOpen={questionnaireFailure}
        setOpen={setQuestionnaireFailure}
        domainName={props.userInfo?.domain?.name}
        floorplanName={floorplan?.name || "The office"}
      />
      <BuddyListDialog
        open={showBuddyList}
        onClose={() => setShowBuddyList(false)}
        userInfo={props.userInfo}
        onNavigate={(params) => {
          setShowBuddyList(false);
          doRedirect(params);
        }}
        refetchFloorplan={() => refetchFloorplan(false).promise}
      />
      <Dialog
        open={showCheckinRequiredNotification}
        onClose={(_) => {
          setShowCheckinRequiredNotification(false);
        }}
        scroll={"paper"}
        aria-labelledby="checkin-required-scroll-dialog-title"
      >
        <DialogTitle
          id="checkin-required-scroll-dialog-title"
          css={{ textAlign: "center" }}
        >
          {t("reserved")}
        </DialogTitle>
        <DialogContent css={{ maxWidth: "25rem" }}>
          <DialogContentText>
            {today.hasSame(date, "day")
              ? i18next.t("booking-successful")
              : `${i18next.t("booking-successful")} ${i18next.t(
                  props.userInfo.domain.name == "udemy-sandbox.com"
                    ? "check-in-on-day-to-get-badge-access"
                    : "check-in-on-day-to-get-entry-code"
                )}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={(_) => setShowCheckinRequiredNotification(false)}
            color="primary"
            variant="contained"
          >
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!error}
        onClose={(_) => {
          setError(null);
        }}
        scroll={"paper"}
        aria-labelledby="floorplan-error-dialog-title"
      >
        <DialogTitle
          id="floorplan-error-dialog-title"
          css={{ textAlign: "center" }}
        >
          {t("error")}
        </DialogTitle>
        <DialogContent css={{ maxWidth: "25rem" }}>
          <Alert severity="error">{error}</Alert>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={(_) => window.location.reload()}
            color="primary"
            variant="contained"
          >
            {t("refresh")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FloorplanPage;
