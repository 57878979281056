import React from "react";

import {
  Dialog,
  DialogContent,
  Button,
  TextField,
  IconButton,
  LinearProgress,
  Alert,
  DialogTitle,
  Grid,
  Typography,
  List,
  ListItem,
  Badge,
} from "@mui/material";
import {
  NotificationsActive as NotificationsActiveIcon,
  NotificationsOff as NotificationsOffIcon,
  CalendarToday as CalendarIcon,
  KeyboardArrowUp as UpIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useAPIMutation, useAPIQuery } from "./hooks";
import { TimeInterval, CalendarDate } from "zynq-shared";
import type { Buddy } from "zynq-shared";
import { useQueryClient } from "react-query";
import type { UserInfo } from "./types";
import { DateTime } from "luxon";
import { timeIntervalToString } from "./luxonUtil";
import { Link, useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import UserSearch from "./userSearch";

function PersonTitle(props: {
  info: { email: string; name: string };
  className?: string;
}) {
  return (
    <Grid item={true} xs={true} className={props.className}>
      <span>{props.info.name}</span>
      <Typography color="textSecondary" variant="body2">
        {props.info.email}
      </Typography>
    </Grid>
  );
}

export default function BuddyListDialog(props: {
  userInfo: UserInfo;
  open: boolean;
  refetchFloorplan: () => Promise<unknown>;
  onClose: () => void;
  onNavigate: (params: {
    floorplanID: number;
    date: CalendarDate;
    seatID?: number;
    timeInterval?: TimeInterval;
  }) => void;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const refetchBuddiesInfo = React.useMemo(
    () => ({
      onSettled: () =>
        queryClient.invalidateQueries("/apis/buddy/buddies-info"),
      onError: () => {
        // don't toast the error
      },
    }),
    [queryClient]
  );
  const refetchFollowerInfo = React.useMemo(
    () => ({
      onSettled: () => queryClient.invalidateQueries("/apis/buddy/followers"),
      onError: () => {
        // don't toast the error
      },
    }),
    [queryClient]
  );

  const buddiesInfo = useAPIQuery<typeof Buddy.BuddiesInfo>(
    "/apis/buddy/buddies-info",
    {},
    { enabled: props.open }
  );

  const followersInfo = useAPIQuery<typeof Buddy.Followers>(
    "/apis/buddy/followers",
    {},
    { enabled: props.open }
  );

  const followBuddy = useAPIMutation<typeof Buddy.Follow>(
    "/apis/buddy/follow",
    { ...refetchBuddiesInfo, onSuccess: props.refetchFloorplan }
  );

  const unfollowBuddy = useAPIMutation<typeof Buddy.Unfollow>(
    "/apis/buddy/unfollow",
    { ...refetchBuddiesInfo, onSuccess: props.refetchFloorplan }
  );

  const blockBuddy = useAPIMutation<typeof Buddy.Block>(
    "/apis/buddy/block",
    refetchFollowerInfo
  );

  const updateNotify = useAPIMutation<typeof Buddy.UpdateNotify>(
    "/apis/buddy/update-notify",
    refetchBuddiesInfo
  );

  const [buddyToAdd, setBuddyToAdd] = React.useState<{
    email: string;
    name: string;
    src?: string;
  } | null>(null);
  const [expandedBuddyEmail, setExpandedBuddyEmail] = React.useState<string>();

  React.useEffect(() => {
    setBuddyToAdd(null);
    setExpandedBuddyEmail(undefined);
  }, [props.open]);

  const errorMessage =
    unfollowBuddy.error?.reason ??
    updateNotify.error?.reason ??
    blockBuddy.error?.reason ??
    buddiesInfo.error?.reason ??
    followersInfo.error?.reason;

  const loading =
    followersInfo.isFetching ||
    buddiesInfo.isFetching ||
    followBuddy.isLoading ||
    unfollowBuddy.isLoading ||
    updateNotify.isLoading ||
    blockBuddy.isLoading;

  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      scroll="paper"
      fullWidth={true}
      maxWidth={"sm"}
    >
      {loading && (
        <LinearProgress
          css={{ "&.MuiLinearProgress-root": { marginBottom: "-4px" } }}
        />
      )}
      <DialogTitle css={{ textAlign: "center" }}>{t("buddy-sync")}</DialogTitle>
      <DialogContent css={{ textAlign: "center" }}>
        {errorMessage && (
          <Alert
            severity="error"
            style={{
              margin: "1rem",
              whiteSpace: "pre-line",
            }}
          >
            {errorMessage}
          </Alert>
        )}
        <div
          css={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <UserSearch
            css={{ width: "100%" }}
            autoComplete
            filterSelectedOptions
            renderInput={(props) => (
              <TextField
                {...props}
                label={t("search-colleagues")}
                fullWidth
                InputProps={{
                  ...props.InputProps,
                }}
                error={!!followBuddy.error}
                helperText={followBuddy.error?.reason}
              />
            )}
            hideOptions={!props.open}
            selection={buddyToAdd}
            onSelection={(s) => setBuddyToAdd(s)}
          />
          <Button
            variant="contained"
            css={{ height: "3.5rem", marginTop: "8px" }}
            disabled={!buddyToAdd}
            onClick={() => {
              setBuddyToAdd(null);
              followBuddy.mutate({ email: buddyToAdd!.email });
              mixpanel.track("Buddy Follow", { buddyEmail: buddyToAdd?.email });
            }}
          >
            {t("follow")}
          </Button>
        </div>
        {buddiesInfo.data?.buddies.length === 0 && (
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <p>{t("buddy-sync-no-buddies-1")}</p>
            <p>{t("buddy-sync-no-buddies-2")}</p>
          </div>
        )}

        <List
          css={{
            "& > li:nth-of-type(odd)": { background: "#f5f5f5" },
            "& > li:nth-of-type(even)": { background: "white" },
            maxHeight: "min(20rem, 30%)",
            overflowY: "auto",
          }}
        >
          {buddiesInfo?.data?.buddies.map((b) => (
            <ListItem
              key={b.email}
              css={{ display: "flex", flexDirection: "column" }}
            >
              <div
                css={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <IconButton
                  onClick={() =>
                    setExpandedBuddyEmail((e) =>
                      e == b.email ? undefined : b.email
                    )
                  }
                  title={
                    expandedBuddyEmail == b.email
                      ? t("collapse-bookings")
                      : t("expand-bookings")
                  }
                  disabled={b.bookings.length === 0}
                >
                  {expandedBuddyEmail == b.email ? (
                    <UpIcon />
                  ) : (
                    <Badge badgeContent={b.bookings.length} color="primary">
                      <CalendarIcon />
                    </Badge>
                  )}
                </IconButton>
                <PersonTitle info={b} />
                <IconButton
                  css={{ marginLeft: "auto" }}
                  onClick={() => {
                    updateNotify.mutate({ email: b.email, notify: !b.notify });
                    mixpanel.track("Buddy Change Notification", {
                      buddyEmail: b?.email,
                      notify: !b.notify,
                    });
                  }}
                  title={
                    b.notify
                      ? t("buddy-notification-enabled")
                      : t("buddy-notification-disabled")
                  }
                >
                  {b.notify ? (
                    <NotificationsActiveIcon color="primary" />
                  ) : (
                    <NotificationsOffIcon />
                  )}
                </IconButton>
                <Button
                  variant={"contained"}
                  onClick={() => {
                    unfollowBuddy.mutate({ email: b.email });
                    mixpanel.track("Buddy Unfollow", {
                      buddyEmail: b?.email,
                    });
                  }}
                >
                  {t("unfollow")}
                </Button>
              </div>
              {expandedBuddyEmail == b.email && (
                <List
                  css={{
                    width: "100%",
                    marginTop: "1rem",
                    boxShadow:
                      "inset 0px 8px 10px -7px #0003, inset 0px -6px 10px -7px #0003",
                    borderLeft: "10px solid grey",
                  }}
                >
                  {b.bookings.map((booking) => (
                    <ListItem
                      key={
                        booking.startDate + booking.startTime + booking.seat?.id
                      }
                      css={{ display: "flex", gap: "1rem" }}
                    >
                      <div css={{ display: "flex", flexDirection: "column" }}>
                        <div>
                          <b>
                            {DateTime.fromISO(booking.startDate).toLocaleString(
                              {
                                month: "short",
                                day: "numeric",
                                weekday: "short",
                              }
                            )}
                          </b>
                          {props.userInfo.domain.timeBasedBookingsEnabled &&
                            ` (${timeIntervalToString(
                              TimeInterval.fromISODatesAndTimes(booking)
                            )})`}
                        </div>
                        <div>{booking.seat?.name ?? t("drop-in")}</div>
                        <div>{booking.floorplan.name}</div>
                      </div>
                      <Button
                        variant={"contained"}
                        color={"primary"}
                        css={{ marginLeft: "auto" }}
                        onClick={() => {
                          const navigationInfo = {
                            floorplanID: booking.floorplan.id,
                            date: CalendarDate.fromISODate(booking.startDate),
                            seatID: booking.seat?.id,
                            timeInterval:
                              TimeInterval.fromISODatesAndTimes(booking),
                          };
                          props.onNavigate(navigationInfo);
                          mixpanel.track("Buddy View Booking", {
                            buddyEmail: b.email,
                            ...navigationInfo,
                          });
                        }}
                      >
                        {t("view")}
                      </Button>
                    </ListItem>
                  ))}
                </List>
              )}
            </ListItem>
          ))}
        </List>
        <h3>{t("following-you")}</h3>
        {followersInfo.data?.followers.length === 0 &&
          t("buddy-sync-no-follows")}
        <List
          css={{
            "& li:nth-of-type(odd)": { background: "#f5f5f5" },
            "& li:nth-of-type(even)": { background: "white" },
            maxHeight: "min(20rem, 30%)",
            overflowY: "auto",
          }}
        >
          {followersInfo.data?.followers.map((b) => (
            <ListItem key={b.email} css={{ display: "flex", gap: "1rem" }}>
              <PersonTitle info={b} css={{ marginRight: "auto" }} />

              {buddiesInfo.data &&
                buddiesInfo.data?.buddies.every(
                  (existingBuddy) => existingBuddy.email != b.email
                ) && (
                  <Button
                    variant={"contained"}
                    onClick={() => {
                      followBuddy.mutate({ email: b.email });
                      mixpanel.track("Buddy Follow Back", {
                        buddyEmail: b.email,
                      });
                    }}
                  >
                    {t("follow")}
                  </Button>
                )}
              <Button
                variant={"contained"}
                onClick={() => {
                  blockBuddy.mutate({ email: b.email });
                  mixpanel.track("Buddy Block Following", {
                    buddyEmail: b.email,
                  });
                }}
              >
                {t("block")}
              </Button>
            </ListItem>
          ))}
        </List>
        <Link
          onClick={() => {
            history.replace({
              ...history.location,
              search: "?dialog=buddy",
            });
            mixpanel.track("Buddy Manage Blocked Colleagues");
          }}
          to={"/preferences/block"}
        >
          {t("manage-blocked-colleagues")}
        </Link>
      </DialogContent>
    </Dialog>
  );
}
