import { CircularProgress } from "@mui/material";

function AdminLoader() {
  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        verticalAlign: "middle",
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: "400%",
        height: "100%",
      }}
    >
      <CircularProgress />
    </div>
  );
}

export default AdminLoader;
