import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useAPIMutation, useAPIQuery } from "src/hooks";
import type { Buddy } from "zynq-shared";
import VirtualTable from "../admin/virtualTable";
import {
  Alert,
  Button,
  TextField,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { Block as BlockIcon } from "@mui/icons-material";
import type { UserInfo } from "src/types";
import mixpanel from "mixpanel-browser";
import UserSearch from "../userSearch";

export default function Block(props: { userInfo: UserInfo }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const refetchBlockList = React.useMemo(
    () => ({
      onSettled: () => queryClient.invalidateQueries("/apis/buddy/block-list"),
      onError: () => {
        // don't toast the error
      },
    }),
    [queryClient]
  );

  const blockedInfo = useAPIQuery<typeof Buddy.BlockList>(
    "/apis/buddy/block-list",
    {}
  );

  const blockBuddy = useAPIMutation<typeof Buddy.Block>(
    "/apis/buddy/block",
    refetchBlockList
  );

  const unblockBuddy = useAPIMutation<typeof Buddy.Unblock>(
    "/apis/buddy/unblock",
    refetchBlockList
  );

  const [buddyToBlock, setBuddyToBlock] = React.useState<{
    email: string;
    name: string;
    src?: string;
  } | null>(null);

  const errorMessage = unblockBuddy.error ?? blockedInfo.error;
  const isLoading =
    blockBuddy.isLoading || unblockBuddy.isLoading || blockedInfo.isFetching;

  return (
    <div
      css={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isLoading && (
        <LinearProgress
          css={{ "&.MuiLinearProgress-root": { marginBottom: "-4px" } }}
        />
      )}
      <h1
        style={{
          fontSize: "200%",
          fontWeight: 600,
          marginBottom: "1rem",
          textAlign: "center",
        }}
      >
        {t("blocked-list")}
      </h1>
      <p css={{ textAlign: "center" }}>{t("block-list-description")}</p>
      {errorMessage && (
        <Alert
          severity="error"
          style={{
            margin: "1rem",
            whiteSpace: "pre-line",
          }}
        >
          {errorMessage}
        </Alert>
      )}
      <div
        css={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          margin: "1rem",
        }}
      >
        <UserSearch
          css={{ maxWidth: "30rem", width: "100%", marginLeft: "auto" }}
          autoComplete
          filterSelectedOptions
          renderInput={(props) => (
            <TextField
              {...props}
              label={t("search-colleagues")}
              fullWidth
              InputProps={{
                ...props.InputProps,
              }}
              error={!!blockBuddy.error}
              helperText={blockBuddy.error?.reason}
            />
          )}
          selection={buddyToBlock}
          onSelection={(v) => setBuddyToBlock(v)}
        />
        <Button
          variant="contained"
          css={{ height: "3.5rem", marginTop: "8px" }}
          disabled={!buddyToBlock}
          onClick={() => {
            if (buddyToBlock) {
              setBuddyToBlock(null);
              blockBuddy.mutate({ email: buddyToBlock.email });
              mixpanel.track("Preferences Block", {
                buddyToBlock: buddyToBlock.email,
              });
            }
          }}
        >
          {t("block")}
        </Button>
      </div>
      <div style={{ flexGrow: 1, display: "flex" }}>
        {blockedInfo.data == undefined && (
          <CircularProgress css={{ margin: "auto" }} />
        )}
        {blockedInfo.data && blockedInfo.data.blockedUsers.length == 0 && (
          <p css={{ margin: "5rem auto" }}>{t("block-list-empty")}</p>
        )}
        {blockedInfo.data && blockedInfo.data.blockedUsers.length > 0 && (
          <VirtualTable
            data={blockedInfo.data?.blockedUsers ?? []}
            columns={[
              { key: "name", header: t("name") },
              { key: "email", header: t("email") },
              {
                key: "block-btn",
                width: 160,
                content: ({ row }) => {
                  return (
                    <Button
                      variant={"contained"}
                      startIcon={<BlockIcon />}
                      onClick={() => {
                        unblockBuddy.mutate({ email: row.email });
                        mixpanel.track("Preferences Unblock", {
                          buddyToUnblock: row.email,
                        });
                      }}
                    >
                      {t("unblock")}
                    </Button>
                  );
                },
              },
            ]}
          />
        )}
      </div>
    </div>
  );
}
