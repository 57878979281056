import * as React from "react";
import { Alert } from "@mui/material";
import { useAPIRequest } from "./hooks";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  TextField,
} from "@mui/material";
import { PermIdentityOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

type DialogProps = {
  actions?: JSX.Element | null;
  content: React.ReactNode;
  title?: string | null;
};

function DialogInner({ actions, content, title }: DialogProps) {
  const { t } = useTranslation();
  return (
    <>
      <DialogTitle> {title ?? t("vendor-login")} </DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {content}
      </DialogContent>
      {actions}
    </>
  );
}

type Mode =
  | { mode: "Closed" }
  | { mode: "Login"; data: { email: string; code: string } }
  | { mode: "Resend"; email: string }
  | { mode: "Loading" }
  | { mode: "Resent"; email: string }
  | { mode: "ResendError"; error: string };

export default function VendorLogin(props: {
  login: (v: { accessToken: string; email: string }) => Promise<void>;
  disabled?: boolean;
}) {
  const [state, setState] = React.useState<Mode>({ mode: "Closed" });

  const { t } = useTranslation();
  const api = useAPIRequest();

  let tmp: JSX.Element | null = null;

  const resendAccessCode = (email: string) => {
    setState({ mode: "Loading" });
    api
      .post("/seating/api/resend_access_code", { email })
      .promise.then((res) => {
        if (res.status == "failed")
          return setState({ mode: "ResendError", error: res.reason });
        setState({ mode: "Resent", email });
      });
  };

  switch (state.mode) {
    case "Closed":
      break;
    case "Loading":
      tmp = (
        <DialogInner
          content={
            <CircularProgress
              style={{
                margin: "auto",
                marginBottom: "2rem",
              }}
            />
          }
        />
      );
      break;
    case "Login":
      tmp = (
        <form
          onSubmit={function (e) {
            e.preventDefault();
            console.log("SUBMITTING");
            setState({ mode: "Loading" });
            props
              .login({
                email: state.data.email,
                accessToken: state.data.code,
              })
              .then(() => setState({ mode: "Closed" }));
          }}
        >
          <DialogInner
            actions={
              <DialogActions>
                <Button
                  type="button"
                  onClick={() => setState({ mode: "Closed" })}
                  color="primary"
                >
                  {t("cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={
                    state.data.email.length === 0 ||
                    state.data.code.length === 0
                  }
                >
                  {t("vendor-login")}
                </Button>
              </DialogActions>
            }
            content={
              <>
                <TextField
                  label={t("email")}
                  margin="normal"
                  onChange={(e) =>
                    setState({
                      mode: "Login",
                      data: {
                        email: e.target.value,
                        code: state.data.code,
                      },
                    })
                  }
                  type="email"
                  autoComplete="username"
                  name="email"
                  id="email"
                  required
                  value={state.data.email}
                />
                <TextField
                  label={t("access-code")}
                  margin="normal"
                  onChange={(e) =>
                    setState({
                      mode: "Login",
                      data: {
                        email: state.data.email,
                        code: e.target.value,
                      },
                    })
                  }
                  type="password"
                  autoComplete="current-password"
                  id="current-password"
                  required
                  value={state.data.code}
                />
                <Link
                  onClick={() =>
                    setState({ mode: "Resend", email: state.data.email })
                  }
                >
                  {t("i-forgot-my-code")}
                </Link>
              </>
            }
          />
        </form>
      );
      break;
    case "Resend":
      // var email = state[0];
      tmp = (
        <DialogInner
          actions={
            <DialogActions>
              <Button
                onClick={() =>
                  setState({
                    mode: "Login",
                    data: {
                      email: state.email,
                      code: "",
                    },
                  })
                }
                color="primary"
              >
                {t("cancel")}
              </Button>
              <Button
                onClick={() => resendAccessCode(state.email)}
                color="primary"
                disabled={state.email.length === 0}
              >
                {t("send")}
              </Button>
            </DialogActions>
          }
          title={t("recover-access-code")}
          content={
            <>
              <p>{t("enter-account-email-to-recover-access-code")}</p>
              <TextField
                label={t("email")}
                margin="normal"
                onChange={(e) =>
                  setState({ mode: "Resend", email: e.target.value })
                }
                value={state.email}
              />
            </>
          }
        />
      );
      break;
    case "Resent":
      tmp = (
        <DialogInner
          title={t("recovery-email-sent")}
          content={
            <div
              style={{
                display: "flex",
                padding: "0 1rem",
                paddingBottom: "2rem",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {t(
                "if-matching-account-found-email-sent-to-recover-access-code",
                { email: state.email }
              )}
            </div>
          }
        />
      );
      break;
    case "ResendError":
      tmp = (
        <DialogInner
          title={t("recovery-error")}
          content={
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <Alert severity="error">{state.error}</Alert>
            </div>
          }
        />
      );
      break;
  }

  return (
    <>
      <Button
        onClick={() =>
          setState({ mode: "Login", data: { email: "", code: "" } })
        }
        startIcon={<PermIdentityOutlined />}
        variant="outlined"
        disabled={props.disabled}
      >
        {t("vendor-login")}
      </Button>
      <Dialog
        fullWidth
        maxWidth="xs"
        onClose={(_e, _reason) => setState({ mode: "Closed" })}
        open={state.mode != "Closed"}
      >
        {tmp}
      </Dialog>
    </>
  );
}
