import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";
import {
  SENTRY_SESSION_ID,
  CLIENT_VERSION,
  NODEJS_BASE_URL,
  ZYNQ_APP_AUTH_CONTEXT_HEADER,
} from "./constants";
import { AppAuthContext, ErrorDialog } from "./providers";
import type { Infer, Struct } from "superstruct";
import { useMutation, useQuery, useQueryClient } from "react-query";
import type { UseMutationOptions, UseQueryOptions } from "react-query";
import i18next from "i18next";
import type * as H from "history";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import { Rooms } from "zynq-shared";
import type { Dictionary, CalendarDate } from "zynq-shared";
import type { RoomEvent } from "./types";
import { DateTime, Interval } from "luxon";

type MaybeInfer<T, Else = unknown> = T extends Struct<any> ? Infer<T> : Else;

const hasFocus = () => typeof document !== "undefined" && document.hasFocus();

export const useWindowFocus = () => {
  const [focused, setFocused] = useState(hasFocus);

  useEffect(() => {
    setFocused(hasFocus());

    const onFocus = () => setFocused(true);
    const onBlur = () => setFocused(false);

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  return focused;
};

function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [value]);
  return debouncedValue;
}

export function doLoginRedirect(
  redirect: boolean,
  debugInfo: string | undefined,
  history: H.History,
  location: H.Location
) {
  const iosWkwebview = Cookies.get("ios-wkwebview");
  const androidWebview = Cookies.get("android-webview");

  let loginPath = "/seating/login";
  console.log(location.pathname);
  let includeRedirect = true;
  if (location.pathname.startsWith("/admin/onboarding")) {
    loginPath = "/seating/admin-login?onboarding";
    includeRedirect = true;
  } else if (location.pathname.startsWith("/admin/")) {
    loginPath = "/seating/admin-login";
  } else if (location.pathname.startsWith("/meeting-app/")) {
    loginPath = "/meeting-app/login";
  } else if (location.pathname.startsWith("/concierge/")) {
    loginPath = "/concierge/login";
  }
  if (redirect && includeRedirect) {
    if (loginPath.includes("?")) {
      loginPath += "&";
    } else {
      loginPath += "?";
    }
    loginPath += "redirect=" + location.pathname;
    if (debugInfo) {
      loginPath += "&dInfo=" + encodeURIComponent(debugInfo);
    }
  }

  if (iosWkwebview || androidWebview) {
    window.location.replace(loginPath);
  } else {
    history.push(loginPath);
  }
}

export function useLoginRedirect() {
  const history = useHistory();
  const location = useLocation();

  return (redirect: boolean, debugInfo: string | undefined = undefined) => {
    doLoginRedirect(redirect, debugInfo, history, location);
  };
}

const defaultHeadersWithoutContentType = {
  "X-Session-ID": SENTRY_SESSION_ID,
  "X-Client-Version": CLIENT_VERSION ?? "0-0",
};
const defaultHeaders = {
  "Content-Type": "application/json",
  ...defaultHeadersWithoutContentType,
};

function headersWithAuthContext(props: {
  includeContentType: boolean;
  authContext: string;
}) {
  return {
    ...(props.includeContentType
      ? defaultHeaders
      : defaultHeadersWithoutContentType),
    [ZYNQ_APP_AUTH_CONTEXT_HEADER]: props.authContext,
  };
}

function handleRequest<T extends { [k: string]: any }>(
  route: string,
  r: Promise<Response>
): Promise<T> {
  return r
    .then((res) => {
      if (
        res &&
        res.headers.get("content-type")?.includes("application/json")
      ) {
        return Promise.all([res, res.json()]) as Promise<[Response, any]>;
      } else {
        return [
          res,
          {
            status: "failed",
            reason: res.ok
              ? i18next.t("something-went-wrong")
              : i18next.t("http-error", {
                  status: res.status,
                  statusText: res.statusText,
                }),
          },
        ] as [Response, any];
      }
    })
    .then(([response, res]) => {
      // Unifying message formats
      if ("success" in res) {
        res.status = res.success ? "success" : "failed";
      }
      if (res.status == "failed") {
        res.reason =
          res.reason || res.errorMsg || i18next.t("something-went-wrong");
      }
      if (res.status != "failed") res.status = "success";
      if (res.status == "failed") {
        throw new APIError(
          i18next.t("failure-in-call-to-route", {
            route: route,
            reason: res.reason as string,
          }),
          {
            status: response.status,
            reason: res.reason,
            route: route,
          }
        );
      }
      return res;
    });
}

export type APIResponse<T> =
  | { status: "failed"; reason: string }
  | ({ status: "success" } & T);

export type APIRequest<T> = {
  promise: Promise<APIResponse<T>>;
  cancel: () => void;
};

function doGET<
  T extends {
    method: "GET";
    route: string;
    types: { query?: Struct<any>; return?: Struct<any> };
  }
>(
  route: T["route"],
  query: MaybeInfer<T["types"]["query"]>,
  signal: AbortSignal | undefined,
  authContext: string
): Promise<MaybeInfer<T["types"]["return"]>> {
  const params = new URLSearchParams(query);
  const paramsStr = params.toString();
  const urlWithParams = paramsStr == "" ? route : route + "?" + paramsStr;
  const headers = headersWithAuthContext({
    includeContentType: false,
    authContext,
  });
  return handleRequest<MaybeInfer<T["types"]["return"], void>>(
    NODEJS_BASE_URL + route,
    fetch(NODEJS_BASE_URL + urlWithParams, {
      headers,
      credentials: "include",
      signal: signal,
    })
  );
}

export function doPOST<
  T extends {
    method: "POST";
    route: string;
    types: { body?: Struct<any>; return?: Struct<any>; multipart?: boolean };
  }
>(
  route: T["route"],
  body: T["types"]["multipart"] extends true
    ? FormData
    : MaybeInfer<T["types"]["body"]>,
  authContext: string
): Promise<MaybeInfer<T["types"]["return"]>> {
  const headers = headersWithAuthContext({
    includeContentType: !(body instanceof FormData),
    authContext,
  });
  return handleRequest<MaybeInfer<T["types"]["return"], void>>(
    route,
    fetch(route, {
      credentials: "include",
      method: "POST",
      headers,
      body: body instanceof FormData ? body : JSON.stringify(body),
    })
  );
}

export class APIError extends Error {
  status: number;
  route: string;
  reason: string;
  key?: any;
  constructor(
    message: string,
    opt: { status: number; reason: string; route: string; key?: any }
  ) {
    super(message);
    this.name = "Server Error";
    this.status = opt.status;
    this.reason = opt.reason;
    this.route = opt.route;
    this.key = opt.key;
  }
}

export function useAPIQuery<
  T extends {
    method: "GET";
    route: string;
    types: { query?: Struct<any>; return?: Struct<any> };
  }
>(
  route: T["route"],
  query: MaybeInfer<T["types"]["query"]>,
  options?: Omit<
    UseQueryOptions<MaybeInfer<T["types"]["return"]>, APIError>,
    "queryKey"
  >
) {
  const authContext = React.useContext(AppAuthContext);
  const queryClient = useQueryClient();

  return {
    ...useQuery(
      [route, query],
      ({ signal }) => doGET<T>(route, query, signal, authContext),
      options
    ),
    mutate: (
      data:
        | MaybeInfer<T["types"]["return"]>
        | ((
            before: MaybeInfer<T["types"]["return"]> | undefined
          ) => MaybeInfer<T["types"]["return"]>)
    ) =>
      queryClient.setQueryData(
        [route, query],
        data instanceof Function
          ? data(queryClient.getQueryData([route, query]))
          : data
      ),
  };
}

export function useAPIMutation<
  T extends {
    method: "POST";
    route: string;
    types: { body?: Struct<any>; return?: Struct<any>; multipart?: boolean };
  },
  Context = undefined
>(
  route: T["route"],
  options?: UseMutationOptions<
    MaybeInfer<T["types"]["return"]>,
    APIError,
    T["types"]["multipart"] extends true
      ? FormData
      : MaybeInfer<T["types"]["body"]>,
    Context
  >
) {
  const authContext = React.useContext(AppAuthContext);
  return useMutation(
    [route],
    (params) => doPOST<T>(route, params, authContext),
    {
      ...options,
      meta: { debugInfo: route, ...options?.meta },
    }
  );
}

/****** USE useAPIQuery and useAPIMutation INSTEAD OF useAPIRequest ******/
function useAPIRequest(noAutoCancel = false) {
  const loginRedirect = useLoginRedirect();
  const globalCancelled = React.useRef(false);
  const authContext = React.useContext(AppAuthContext);

  React.useEffect(() => {
    globalCancelled.current = false;
    return () => {
      globalCancelled.current = true;
    };
  }, []);

  function handleRequest<T extends { [k: string]: any }>(
    url: string,
    r: Promise<Response>
  ): APIRequest<T> {
    let localCancelled = false;
    const promise = r
      .then((res) => {
        if (
          res &&
          res.headers.get("content-type")?.includes("application/json")
        ) {
          return res.json();
        } else {
          return {
            status: "failed",
            reason: res.ok
              ? i18next.t("something-went-wrong")
              : i18next.t("http-error", {
                  status: res.status,
                  statusText: res.statusText,
                }),
          };
        }
      })
      .then((res) => {
        if ((!noAutoCancel && globalCancelled.current) || localCancelled) {
          return Promise.reject("Ignore cancel");
        }
        // Unifying message formats
        if (res.success != null) {
          res.status = res.success ? "success" : "failed";
        }
        if (res.status == "failed") {
          res.reason =
            res.reason || res.errorMsg || i18next.t("something-went-wrong");
        }
        if (res.status != "failed") res.status = "success";

        if (res.status === "failed" && res.reason === "login_required") {
          loginRedirect(true, url);
          console.log("Not logged in.");
          return Promise.reject(new Error("Login required"));
        } else if (
          res.status === "failed" &&
          res.reason === "refresh_required"
        ) {
          if (res.info)
            console.log(
              `Client version out of date (${url} ${res?.info as string})`
            );
          return Promise.reject(new Error("Refresh required"));
        } else if (res.status == "failed") {
          console.warn(`Failure in call to ${url}: ${res.reason as string}`);
        }
        return res;
      });
    return {
      promise,
      cancel: () => {
        localCancelled = true;
      },
    };
  }

  return {
    raw: <T extends { [k: string]: any }>(
      url: string,
      method: "POST" | "GET",
      body: any
    ) => {
      const headers = {
        [ZYNQ_APP_AUTH_CONTEXT_HEADER]: authContext,
      };
      return handleRequest<T>(
        url,
        fetch(url, {
          credentials: "include",
          method,
          headers,
          body: body,
        })
      );
    },
    post: <T extends { [k: string]: any }>(url: string, body = {}) => {
      const headers = headersWithAuthContext({
        includeContentType: true,
        authContext,
      });
      return handleRequest<T>(
        url,
        fetch(url, {
          credentials: "include",
          method: "POST",
          headers,
          body: JSON.stringify(body),
        })
      );
    },
    postNode: <
      T extends {
        method: "POST";
        route: string;
        types: {
          body?: Struct<any>;
          return?: Struct<any>;
          multipart?: boolean;
        };
      }
    >(
      endpoint: T,
      body: T["types"]["multipart"] extends true
        ? FormData
        : MaybeInfer<T["types"]["body"]>
    ) =>
      handleRequest<MaybeInfer<T["types"]["return"], void>>(
        NODEJS_BASE_URL + endpoint.route,
        fetch(NODEJS_BASE_URL + endpoint.route, {
          credentials: "include",
          method: "POST",
          headers: headersWithAuthContext({
            includeContentType: !(body instanceof FormData),
            authContext,
          }),
          body: body instanceof FormData ? body : JSON.stringify(body),
        })
      ),
    get: <T extends { [k: string]: any }>(url: string, paramsObj = {}) => {
      let paramsStr = "";
      if (paramsObj) {
        paramsStr = new URLSearchParams(paramsObj).toString();
      }
      const urlWithParams = paramsStr == "" ? url : url + "?" + paramsStr;
      const headers = headersWithAuthContext({
        includeContentType: true,
        authContext,
      });
      return handleRequest<T>(url, fetch(urlWithParams, { headers }));
    },
    getNode: <
      T extends {
        method: "GET";
        route: string;
        types: { query?: Struct<any>; return?: Struct<any> };
      }
    >(
      endpoint: T,
      paramsObj: MaybeInfer<T["types"]["query"]>
    ) => {
      let paramsStr = "";
      if (paramsObj) {
        paramsStr = new URLSearchParams(paramsObj).toString();
      }
      const urlWithParams =
        paramsStr == "" ? endpoint.route : endpoint.route + "?" + paramsStr;
      const headers = headersWithAuthContext({
        includeContentType: true,
        authContext,
      });
      return handleRequest<MaybeInfer<T["types"]["return"]>>(
        NODEJS_BASE_URL + endpoint.route,
        fetch(NODEJS_BASE_URL + urlWithParams, {
          headers,
          credentials: "include",
        })
      );
    },
  };
}

function useRefEffect<T>(prop: any) {
  const ref = useRef<T>(prop);
  useEffect(() => {
    ref.current = prop;
  }, [prop]);
  return ref;
}

const DEFAULT_EVENTS = [
  "mousemove",
  "keydown",
  "wheel",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "MSPointerDown",
  "MSPointerMove",
  "visibilitychange",
];

/**
 * Detects when user is idle.
 * @param onIdle Callback to call when the user is idle.
 * @param timeout Timeout in milliseconds.
 */
export function useIdleTimer({
  timeout,
  onIdle,
}: {
  timeout: number;
  onIdle: () => void;
}) {
  const [idle, setIdle] = React.useState(false);
  const timeoutRef = useRefEffect<number>(timeout);
  const timeoutID = useRef<ReturnType<typeof setTimeout> | null>(null);
  const emitOnIdle = useRefEffect<() => void>(onIdle);
  const destroyTimeout = (): void => {
    if (timeoutID.current != null) {
      clearTimeout(timeoutID.current);
      timeoutID.current = null;
    }
  };

  const createTimeout = () => {
    destroyTimeout();
    timeoutID.current = setTimeout(() => {
      setIdle(true);
      emitOnIdle.current();
    }, timeoutRef.current);
  };

  const bindEvents = (handleEvent: () => void): void => {
    DEFAULT_EVENTS.forEach((e) => {
      document.addEventListener(e, handleEvent, {
        capture: true,
        passive: true,
      });
    });
  };

  const unbindEvents = (handleEvent: () => void): void => {
    DEFAULT_EVENTS.forEach((e) => {
      document.removeEventListener(e, handleEvent, {
        capture: true,
      });
    });
  };

  React.useEffect(() => {
    function handleEvent() {
      setIdle(false);
      createTimeout();
    }
    bindEvents(handleEvent);
    return () => {
      destroyTimeout();
      unbindEvents(handleEvent);
    };
  }, []);

  return idle;
}

export function useLiveFloorplan({
  floorplanID,
  domainName,
}: {
  floorplanID?: number;
  domainName?: string;
}) {
  const [lastUpdated, setLastUpdated] = React.useState<DateTime>();
  const { data, error } = useLive(
    domainName && floorplanID
      ? [
          {
            key: `${floorplanID}`,
            collection: `domains/${domainName}/floorplans-data`,
            doc: `${floorplanID}`,
          },
        ]
      : []
  );

  React.useEffect(() => {
    if (floorplanID && data) {
      const results = data[`${floorplanID}`];
      if (!results) {
        return;
      }
      const doc = results[0];
      const date = doc.lastUpdated
        ? DateTime.fromISO(doc.lastUpdated as string)
        : undefined;
      setLastUpdated(date);
    }
  }, [data, floorplanID]);

  return {
    lastUpdated,
    error,
  };
}

export function useLiveRooms({
  rooms,
  dateFilter,
  domainName,
  includeWebLink,
}: {
  rooms: { calendarID: string; timezone: string }[];
  dateFilter?: CalendarDate;
  domainName?: string;
  includeWebLink?: boolean;
}) {
  const [roomEvents, setRoomEvents] = React.useState<
    Dictionary<string, RoomEvent[]>
  >({});
  const { data, error } = useLive(
    domainName
      ? rooms.map((room) => ({
          key: room.calendarID,
          collection: `domains/${domainName}/rooms-data/${room.calendarID}/events`,
        }))
      : []
  );

  React.useEffect(() => {
    if (data) {
      Object.entries(data).forEach((entry) => {
        const [key, data] = entry;
        const room = rooms.find((room) => room.calendarID == key);
        if (room && data) {
          const dateLocal = dateFilter?.toDateTime({ zone: room.timezone });
          const events = data
            .filter((d) => !!d.organizerEmail)
            .filter((e) => e.roomAcceptedTime)
            .map(
              (e: any) =>
                ({
                  ...e,
                  organizerEmail: e.organizerEmail,
                  attendees: e.attendees ?? [],
                  interval: Interval.fromDateTimes(
                    DateTime.fromISO(e.start, { zone: "UTC" }).setZone(
                      room.timezone
                    ), // MSFT provides a naive UTC iso string
                    DateTime.fromISO(e.end, { zone: "UTC" }).setZone(
                      room.timezone
                    ) // MSFT provides a naive UTC iso string
                  ),
                  roomAcceptedTime: DateTime.fromISO(e.roomAcceptedTime, {
                    zone: room.timezone,
                  }),
                  webLink: includeWebLink ? e.webLink : undefined,
                } as RoomEvent)
            )
            .filter((e) => {
              if (!e.interval.isValid) {
                console.error(
                  `Event ${e.id} has invalid start/end: ${e.interval
                    .invalidExplanation!}`
                );
              }
              return true;
            })
            .filter((e) => {
              if (dateLocal) {
                const allDayInterval = Interval.fromDateTimes(
                  dateLocal.startOf("day"),
                  dateLocal.endOf("day")
                );
                return allDayInterval.overlaps(e.interval);
              } else {
                return true;
              }
            })
            .sort(
              (e1, e2) =>
                e1.interval.start.toMillis() - e2.interval.start.toMillis()
            );
          setRoomEvents((e) => ({ ...e, [room.calendarID]: events }));
        }
      });
    }
  }, [data, dateFilter?.toISODate()]);

  return {
    events: roomEvents,
    error: error,
  };
}

function useLive(info: { key: string; collection: string; doc?: string }[]) {
  const api = useAPIRequest();
  const [token, setToken] = React.useState<firebase.auth.UserCredential>();
  const [tokenError, setTokenError] = React.useState(false);
  const [data, setData] =
    React.useState<Dictionary<string, firebase.firestore.DocumentData[]>>();
  const authContext = React.useContext(AppAuthContext);

  const requestID = info.map((v) => v.key).join(":");

  React.useEffect(() => {
    Sentry.addBreadcrumb({
      message: "page load",
      data: {
        requestID,
      },
    });

    Promise.resolve().then(async () => {
      if (firebase.apps.length == 0) {
        Sentry.addBreadcrumb({
          message: "loading firestore info",
          data: {
            requestID,
          },
        });

        const firestoreInfo = await api.getNode(Rooms.FirestoreInfo, {})
          .promise;
        if (firestoreInfo.status != "success") {
          return console.error(
            "Error getting firebase token",
            firestoreInfo.reason
          );
        }
        firebase.initializeApp({
          apiKey: firestoreInfo.apiKey,
          authDomain: "zynq-app.firebaseapp.com",
          databaseURL:
            firestoreInfo.endpoint ?? "https://zynq-app.firebaseio.com",
          projectId: firestoreInfo.appID,
          storageBucket: "zynq-app.appspot.com",
          messagingSenderId: "330652174175",
          appId: "1:330652174175:web:c0e569781ee4763dc858ea",
          measurementId: "G-GMYYV8Q6SK",
        });
      }
      if ((firebase.app().options as any).projectId == "demo-zynq-firestore") {
        console.log("Not connecting to fake firebase");
        return setData({});
      }
      api.postNode(Rooms.TokenRefresh, {}).promise.then((res) => {
        if (res.status == "failed") {
          console.error("TokenRefresh failed:", res.reason);
          return setTokenError(true);
        }
        try {
          firebase.auth().onAuthStateChanged((user) => {
            Sentry.addBreadcrumb({
              message: "User auth state change",
              data: { user: user },
            });
          });
          firebase
            .auth()
            .setPersistence("none")
            .then(() => {
              console.log("Persistence got set");
              firebase
                .auth()
                .signInWithCustomToken(res.token)
                .then((t) => {
                  Sentry.addBreadcrumb({
                    message: "Setting token",
                    data: { token: t, requestID },
                  });
                  console.log("Signed in with token: ", t);
                  setToken(t);
                })
                .catch((error) => {
                  console.error("Error signing in with custom token:", error);
                  setTokenError(true);
                });
            });
        } catch (e) {
          console.error("Error logging in with firebase:", e);
        }
      });
    });
  }, []);

  React.useEffect(() => {
    if (token && info.length > 0) {
      Sentry.addBreadcrumb({
        message: "onSnapshot useEffect triggered, listening",
        data: {
          token,
          requestID,
        },
      });
      const listeners = info.map(({ key, collection, doc }) => {
        const collectionRef = firebase.firestore().collection(collection);
        const onError = (error: firebase.firestore.FirestoreError) => {
          Sentry.captureException(error, {
            extra: {
              context: authContext,
              token: token,
              collection: collection,
              doc: doc,
              docType: typeof doc,
            },
          });
          setTokenError(true);
        };

        if (doc) {
          Sentry.addBreadcrumb({
            message: "listening to doc",
            data: {
              token,
              requestID,
            },
          });

          return collectionRef.doc(doc).onSnapshot((data) => {
            setData((d) => ({ ...d, [key]: [data] }));
          }, onError);
        } else {
          Sentry.addBreadcrumb({
            message: "listening to collection",
            data: {
              token,
              requestID,
            },
          });

          return collectionRef.onSnapshot((s) => {
            const results = s.docs.map((d) => d.data());
            setData((d) => ({ ...d, [key]: results }));
          }, onError);
        }
      });
      return () => listeners.forEach((listener) => listener());
    }
    return;
  }, [token, requestID]);

  return {
    data,
    error: tokenError,
  };
}

function useLocalStorage<T extends string>(
  key: string,
  initialValue: T
): [T, (cb: React.SetStateAction<T>) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: T | ((old: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
}

function useError() {
  const [_error, setError] = React.useContext(ErrorDialog);
  return setError;
}

export function useForceUpdate() {
  const [, setState] = React.useState({});
  return React.useCallback(() => setState({}), []);
}

export function useUserMedia(requestedMedia: MediaStreamConstraints) {
  const [mediaStream, setMediaStream] = React.useState<MediaStream | null>(
    null
  );
  const [isPlaying, setIsPlaying] = React.useState(false);
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
    videoRef.current.onplaying = () => {
      setIsPlaying(true);
    };
  }
  const [error, setError] = React.useState<string>();

  React.useLayoutEffect(() => {
    async function enableStream() {
      try {
        setMediaStream(
          await navigator.mediaDevices.getUserMedia(requestedMedia)
        );
      } catch (err: unknown) {
        setError(String(err instanceof Error ? err.message : err));
      }
    }
    if (!mediaStream) {
      enableStream();
      return;
    }

    return function cleanup() {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
        if (videoRef.current) {
          videoRef.current.srcObject = null;
        }
        setIsPlaying(false);
        setMediaStream(null);
      }
    };
  }, [mediaStream, JSON.stringify(requestedMedia)]);

  return { mediaStream: mediaStream, error, ref: videoRef, isPlaying };
}

export {
  useDebounce,
  useAPIRequest,
  useLocalStorage,
  useWindowWidth,
  usePrevious,
  useError,
};
