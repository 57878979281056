export function removeField<T, K extends keyof T>(t: T, k: K): Omit<T, K> {
  const copy = { ...t };
  delete copy[k];
  return copy;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}

type OmitUndefinedOrNever<T> = {
  [P in keyof T as T[P] extends undefined | never ? never : P]: T[P];
};

export type Patch<T, P> = Pick<T, Exclude<keyof T, keyof P>> & P;

export type Result<Val, Err = string> =
  | OmitUndefinedOrNever<{ ok: true; val: Val }>
  | { ok: false; error: Err };
// export type Result = { ok: true } | { ok: false; error: string };

export type Dictionary<K extends keyof any, T> = { [P in K]?: T };

export function assertNever(s: never | string): never {
  throw new Error(`Failed assertion: ${s as string}`);
}

// Polyfill for Object.fromEntries
export function objectFromEntries<Key extends string | number | symbol, Val>(
  iterable: Iterable<[Key, Val]>
) {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {} as Dictionary<Key, Val>);
}

export function maybeField<T, K extends keyof T>(
  obj: T,
  field: K
): Record<K, NonNullable<T[K]>> | undefined {
  return (
    obj[field] &&
    ({
      [field]: obj[field],
    } as Record<K, NonNullable<T[K]>>)
  );
}

export function partition<T, TT extends T>(
  arr: T[],
  pred: (v: T) => v is TT
): { true: TT[]; false: T[] };
export function partition<T>(
  arr: T[],
  pred: (v: T) => boolean
): { true: T[]; false: T[] };
export function partition<T>(
  arr: T[],
  pred: (v: T) => boolean
): { true: T[]; false: T[] } {
  const trueArr = [];
  const falseArr = [];
  for (let a of arr) {
    if (pred(a)) {
      trueArr.push(a);
    } else {
      falseArr.push(a);
    }
  }
  return { true: trueArr, false: falseArr };
}
