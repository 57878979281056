import React from "react";

import { Button, CircularProgress, TextField } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Concierge } from "zynq-shared";
import { useAPIRequest, useError } from "./hooks";
import { useTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";

export default function ConciergeLogin() {
  const { t } = useTranslation();
  const api = useAPIRequest();
  const history = useHistory();
  const showError = useError();
  const [accessCode, setAccessCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const logIn = (accessCode: string) => {
    setLoading(true);
    api.postNode(Concierge.Login, { accessCode }).promise.then((res) => {
      setLoading(false);
      if (res.status == "failed") {
        showError(res.reason);
        return;
      }
      mixpanel.identify(res.deviceID);
      mixpanel.track("Concierge Login");
      if (res.deviceType == "LIVE-FLOORPLAN") {
        history.push("/concierge/floorplan/live");
      } else {
        showError(t("please-download-zynq-concierge-app"));
      }
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        logIn(accessCode);
        return false;
      }}
      css={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <a href="/" target="_blank">
        <img
          css={{ width: "13rem" }}
          src="/static/img/logo.png"
          alt={t("zynq-logo")}
        />
      </a>
      <h1 css={{ color: "#4A4A4A" }}>{t("concierge-device-login")}</h1>
      <TextField
        label="Access Code"
        value={accessCode}
        disabled={loading}
        onChange={(e) => setAccessCode(e.target.value)}
        inputProps={{
          autoCorrect: "off",
          autoCapitalize: "none",
        }}
      />
      <Button
        variant="contained"
        disabled={loading}
        type="submit"
        css={{ margin: "2rem" }}
      >
        {loading && (
          <CircularProgress size="1rem" css={{ marginRight: "1rem" }} />
        )}
        {t("login")}
      </Button>
    </form>
  );
}
