import React from "react";
import { Alert } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAPIRequest } from "./hooks";
import { DomainSetup } from "zynq-shared";
import { Trans, useTranslation } from "react-i18next";

function MsftAdminRegister({}) {
  const { t } = useTranslation();
  const location = useLocation();
  const api = useAPIRequest();
  const params = new URLSearchParams(location.search);
  const consented = params.get("admin_consent") === "True";
  const [consentRegistered, setConsentRegistered] =
    React.useState<boolean>(false);

  let error: JSX.Element | string | null = consented
    ? null
    : t("error-granting-domain-permissions");

  if (consented && !consentRegistered) {
    api
      .postNode(DomainSetup.AuthenticatedMicrosoft, {
        tenantID: params.get("tenant") ?? "N/A",
        scope: params.get("scope") ?? "N/A",
      })
      .promise.then((res) => {
        if (res.status == "success") {
          setConsentRegistered(true);
        }
      });
  }

  if (params.get("error")) {
    error = t("error-with-msg", { msg: params.get("error") });
  }

  const errorURI = params.get("error_uri");
  if (errorURI) {
    error = (
      <>
        <Trans i18nKey="error-with-link">
          Error: <a href={errorURI}>{{ url: errorURI }}</a>
        </Trans>
      </>
    );
  }

  if (params.get("error_description")) {
    error = t("error-with-msg", { msg: params.get("error_description") });
  }

  return consentRegistered || !consented ? (
    <div
      css={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <h1>{t("zynq-admin-registration")}</h1>
      {!!error ? (
        <Alert severity="error" style={{ whiteSpace: "pre-line" }}>
          {error}
        </Alert>
      ) : (
        <Alert severity="success">{t("domain-permissions-granted")}</Alert>
      )}
    </div>
  ) : (
    <div></div>
  );
}

export default MsftAdminRegister;
