import * as React from "react";
import { Alert, Checkbox, Tooltip } from "@mui/material";
import { useAPIMutation } from "./hooks";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@mui/material";
import type { RESOURCE_TYPES } from "src/constants";
import TimeRangePicker from "src/timeRangePicker";
import { DatePicker } from "@mui/lab";
import { DateTime, Interval } from "luxon";
import { useTranslation } from "react-i18next";
import { SIMPLE_ISO_TIME } from "zynq-shared";
import type { TimeInterval, Seating, CalendarDate } from "zynq-shared";
import UserSearch from "./userSearch";

const Styles = {
  dialogTitle: { textAlign: "center" } as const,
};

export default function BookForButton(
  props: (
    | {
        kind: "seat";
        selectedSeatID: number;
        resourceType: RESOURCE_TYPES;
        onBook: (seatID: number) => Promise<unknown>;
      }
    | {
        kind: "dropin";
        onBook: () => Promise<unknown>;
      }
  ) & {
    date: CalendarDate;
    today: CalendarDate;
    timezone: string;
    floorplanID: number;
    adminOverrideText?: string;
    disabled?: boolean;
    buttonText?: string;
    timeRange: TimeInterval;
    defaultTimeRange: TimeInterval;
    timeBasedBookingEnabled: boolean;
    limitToDirectReports?: boolean;
    scanningEnabled: boolean;
  }
) {
  if (props.kind == "seat") {
    props.selectedSeatID;
  }
  const isPast =
    props.timeRange.toInterval(props.date.toDateTime({ zone: props.timezone }))
      .start < DateTime.now().setZone(props.timezone);
  const isToday = props.date.hasSame(props.today, "day");
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] =
    React.useState<{ name: string; email: string; src?: string }>();
  const [dateRange, setDateRange] = React.useState({
    startDate: props.date.toDateTime({ zone: props.timezone }),
    endDate: props.date.toDateTime({ zone: props.timezone }),
  });
  const [timeRange, setTimeRange] = React.useState(props.timeRange);
  const [error, setError] = React.useState<string>();
  const [successText, setSuccessText] = React.useState<string>();
  const [isOpen, setIsOpen] = React.useState(false);
  const [markAsArrived, setMarkAsArrived] = React.useState(false);

  const { mutate: makeBooking, isLoading: bookingInProgress } = useAPIMutation<
    typeof Seating.MakeBookings
  >("/apis/seating/make-bookings", {
    onError: (e) => setError(e.reason),
  });
  const { mutate: makeDropin, isLoading: dropinInProgress } = useAPIMutation<
    typeof Seating.MakeDropin
  >("/apis/seating/make-dropin", {
    onError: (e) => setError(e.reason),
  });
  const loading = bookingInProgress || dropinInProgress;

  React.useEffect(() => {
    if (isOpen) {
      setError(undefined);
      setSelectedUser(undefined);
      setSuccessText(undefined);
      setTimeRange(props.timeRange);
      setDateRange({
        startDate: props.date.toDateTime({ zone: props.timezone }),
        endDate: props.date.toDateTime({ zone: props.timezone }),
      });
      setMarkAsArrived(isToday || isPast);
    }
  }, [isOpen]);
  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        disabled={props.disabled}
        onClick={() => setIsOpen(true)}
      >
        {props.buttonText ?? t("book-for")}
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        scroll="paper"
      >
        <DialogTitle css={Styles.dialogTitle}>{t("book-for")}</DialogTitle>
        <DialogContent
          css={{ display: "flex", flexDirection: "column", margin: "0 1rem" }}
          dividers={false}
        >
          <UserSearch
            users={{
              directReportsOnly: props.limitToDirectReports,
            }}
            selection={selectedUser ?? null}
            onSelection={(result) => setSelectedUser(result ?? undefined)}
          />
          {props.kind != "dropin" && props.timeBasedBookingEnabled && (
            <>
              <TimeRangePicker
                timeRange={timeRange}
                defaultTimeRange={props.defaultTimeRange}
                setTimeRange={setTimeRange}
                css={{ marginTop: "1rem" }}
              />
              <DatePicker<DateTime>
                label={t("start-date")}
                value={dateRange.startDate}
                onChange={(v) => {
                  if (v?.isValid) {
                    setDateRange({
                      endDate: dateRange.endDate < v ? v : dateRange.endDate,
                      startDate: v,
                    });
                  }
                }}
                minDate={
                  props.limitToDirectReports // For non-admins, block booking in the past
                    ? props.today.toDateTime({ zone: props.timezone })
                    : undefined
                }
                renderInput={(props) => <TextField {...props} />}
              />
              <DatePicker<DateTime>
                label={t("end-date")}
                value={dateRange.endDate}
                minDate={dateRange.startDate}
                onChange={(v) =>
                  v?.isValid &&
                  setDateRange({
                    ...dateRange,
                    endDate: v,
                  })
                }
                renderInput={(props) => <TextField {...props} />}
                showTodayButton
              />
            </>
          )}
          {props.adminOverrideText != null && (
            <div style={{ margin: "1rem 0" }}>
              <Alert severity="warning">
                {t("warning-with-msg", { msg: props.adminOverrideText })}
              </Alert>
            </div>
          )}
          {loading && (
            <div style={{ margin: "1rem auto" }}>
              <CircularProgress />
            </div>
          )}
          {!loading && successText != null && (
            <div style={{ margin: "1rem 0" }}>
              <Alert severity="success">{successText}</Alert>
            </div>
          )}
          {!loading && error && (
            <div style={{ margin: "1rem 0" }}>
              <Alert severity="error" style={{ whiteSpace: "pre-line" }}>
                {t("error-with-msg", { msg: error })}
              </Alert>
            </div>
          )}
          {props.scanningEnabled && (
            <div css={{ display: "flex", flexFlow: "row-reverse" }}>
              <Tooltip
                title={
                  isPast
                    ? t("past-bookings-always-marked-as-arrived")
                    : t("will-mark-as-arrived-if-havent-already")
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={markAsArrived}
                      disabled={isPast}
                      onChange={(e, checked) =>
                        setMarkAsArrived(checked ?? false)
                      }
                      name={t("bookings-enabled")}
                    />
                  }
                  sx={{ color: "grey.700" }}
                  label={t("mark-as-arrived")}
                  labelPlacement="start"
                />
              </Tooltip>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsOpen(false)}
            color="secondary"
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              if (selectedUser) {
                if (props.kind == "dropin") {
                  makeDropin(
                    {
                      floorplanID: props.floorplanID,
                      dates: [props.date.toISODate()],
                      asUserEmail: selectedUser.email,
                      markAsArrived,
                    },
                    {
                      onSuccess: () =>
                        props.onBook().then(() => {
                          setSuccessText(
                            t("made-booking-for-name", {
                              name: selectedUser.name,
                            })
                          );
                          setSelectedUser(undefined);
                        }),
                    }
                  );
                } else if (props.kind == "seat") {
                  const selectedSeatID = props.selectedSeatID;
                  const days = Interval.fromDateTimes(
                    dateRange.startDate,
                    dateRange.endDate.plus({ days: 1 })
                  )
                    .splitBy({ days: 1 })
                    .map((d) => d.start.toISODate());
                  makeBooking(
                    {
                      floorplanID: props.floorplanID,
                      seatID: props.selectedSeatID,
                      days: days,
                      asUser: selectedUser.email,
                      resourceType: props.resourceType,
                      startTime: timeRange.start.toISOTime(SIMPLE_ISO_TIME),
                      endTime: timeRange.end.toISOTime(SIMPLE_ISO_TIME),
                      bookingLengthDays: timeRange.days,
                      markAsArrived,
                    },
                    {
                      onSuccess: (result) => {
                        if (result.failedDays && result.failedDays.length > 0) {
                          // Show in the dialog what failed & what succeeded
                          if (result.bookedDays) {
                            setSuccessText(
                              t("success-to-book-days", {
                                days: result.bookedDays.join(", "),
                              })
                            );
                          }
                          setError(
                            t("failed-to-book-days", {
                              days: result.failedDays.join(", "),
                            })
                          );
                        } else {
                          props.onBook(selectedSeatID).then(() => {
                            setIsOpen(false);
                          });
                        }
                      },
                    }
                  );
                }
              } else {
                return setError(t("cant-book-without-a-user"));
              }
            }}
            color="primary"
            disabled={selectedUser == null || loading}
            variant="contained"
          >
            {loading ? t("booking") : t("book")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
