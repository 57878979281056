import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Button,
  MenuItem,
  Select,
  Grid,
  Typography,
} from "@mui/material";
import { Person as PersonIcon, Group as GroupIcon } from "@mui/icons-material";
import { CalendarPicker, PickersDay } from "@mui/lab";
import type { PickersDayProps } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import type { ISODateString, SeatInfo } from "./types";
import type { DaysEnabled } from "src/admin/weekdayPicker";
import TimeRangePicker from "src/timeRangePicker";
import { useAPIQuery, useAPIRequest, useError } from "./hooks";
import type { BookingInfo } from "./bookingInfoRow";
import type { RESOURCE_TYPES } from "./constants";
import { resourceName } from "./constants";
import type { Buddy, Dictionary, DateString } from "zynq-shared";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { TimeInterval, CalendarDate } from "zynq-shared";
import { isWeekend, weeksSinceBaseDate } from "./luxonUtil";
import { getDistinctLabelColors } from "./colorUtil";
import type { DateTime } from "luxon";

function differenceInWeeks(d1: CalendarDate, d2: CalendarDate) {
  return Math.floor(
    d1.startOf("week").diff(d2.startOf("week"), ["weeks"]).weeks
  )!;
}

function selectedDaysInWeek(
  today: CalendarDate,
  weekIndex: number,
  selectedDays: Set<ISODateString>
) {
  return [...selectedDays]
    .map(CalendarDate.fromISODate)
    .reduce(
      (count, day) =>
        weekIndex === differenceInWeeks(day, today.startOf("week"))
          ? count + 1
          : count,
      0
    );
}

function isPreviouslyBooked(
  bookingsData: BookingInfo[],
  formattedDay: ISODateString,
  timeRange: TimeInterval,
  dropin: boolean,
  resourceType: RESOURCE_TYPES
) {
  return bookingsData.some(
    (b) =>
      b.startDate == formattedDay &&
      (!b.resourceType || b.resourceType == resourceType) &&
      b.isDropin == dropin &&
      TimeInterval.fromISODatesAndTimes(b).assertValid().engulfs(timeRange)
  );
}

function getErrorString(bookAheadDaysMin: number, maxWeeklyBookings: number) {
  const bookAheadStr =
    bookAheadDaysMin <= 0
      ? null
      : i18next.t("bookings-must-be-x-days-in-the-future", {
          min: bookAheadDaysMin,
        });
  const maxWeeklyStr =
    maxWeeklyBookings >= 7
      ? null
      : i18next.t("limits-are-x-bookings-per-week", { max: maxWeeklyBookings });

  if (bookAheadStr !== null) {
    if (maxWeeklyStr !== null) {
      return (
        <i>
          {"* " + i18next.t("a-and-b", { a: bookAheadStr, b: maxWeeklyStr })}
        </i>
      );
    } else {
      return <i>{"* " + bookAheadStr}</i>;
    }
  } else if (maxWeeklyStr !== null) {
    return <i>{"* " + maxWeeklyStr}</i>;
  }
  return null;
}

function Dot(props: { color: string }) {
  return (
    <div
      css={{
        height: "4px",
        width: "4px",
        borderRadius: "50%",
        backgroundColor: props.color,
      }}
    />
  );
}

export function BuddyPickersDay(props: {
  pickerProps: PickersDayProps<DateTime>;
  style?: React.CSSProperties;
  className?: string;
  dotColors?: string[];
  buddyNumber: number;
}) {
  const { children: _, ...pickerProps } = props.pickerProps;
  return (
    <PickersDay
      {...pickerProps}
      style={props.style}
      className={props.className}
    >
      <div
        css={{ display: "flex", flexFlow: "column", justifyContent: "center" }}
      >
        <div>
          {props.pickerProps.day.day}
          {props.buddyNumber > 1 && (
            <GroupIcon
              color={"inherit"}
              fontSize={"inherit"}
              css={{
                opacity: 0.6,
                fontSize: "12px",
              }}
            />
          )}
          {props.buddyNumber == 1 && (
            <PersonIcon
              color={"inherit"}
              fontSize={"inherit"}
              css={{
                opacity: 0.6,
                fontSize: "11px",
              }}
            />
          )}
        </div>
        <div
          css={{
            display: "flex",
            gap: "2px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props.dotColors && props.dotColors.length > 0 ? (
            props.dotColors
              .slice(0, 3)
              .map((color, index) => <Dot key={index} color={color} />)
          ) : (
            <Dot color="transparent" />
          )}
        </div>
      </div>
    </PickersDay>
  );
}

export default function MultidayBookCalendar(props: {
  bookAheadDaysMax: number;
  bookAheadDaysMin: number;
  selectedSeat?: SeatInfo;
  loading: boolean;
  resourceType: RESOURCE_TYPES;
  allResourceTypes: RESOURCE_TYPES[];
  maxWeeklyBookings: number;
  outerTimeRange: { interval: TimeInterval; live: boolean };
  defaultTimeRange: TimeInterval;
  onClose: () => void;
  onSuccess: (days: CalendarDate[], timeRange: TimeInterval) => void;
  shouldShow: boolean;
  today: CalendarDate;
  timezone: string;
  dropin: boolean;
  userSchedule: DaysEnabled[];
  weekendsBookable: boolean;
  weekBookings: Dictionary<RESOURCE_TYPES, CalendarDate[][]>;
  showTimeRange: boolean;
  allowedHours: TimeInterval;
  autoselect?: CalendarDate;
  nightShiftEnabled: boolean;
  buddySyncEnabled: boolean;
}) {
  const buddies = useAPIQuery<typeof Buddy.BuddiesInfo>(
    "/apis/buddy/buddies-info",
    {},
    { enabled: props.shouldShow && props.buddySyncEnabled }
  );

  const [focusedDay, setFocusedDay] = React.useState<CalendarDate | null>(
    props.today
  );
  const [selectedResourceType, setSelectedResourceType] = React.useState(
    props.resourceType
  );
  const [bookingsData, setBookingsData] = React.useState<BookingInfo[]>([]);
  const [selectedDays, setSelectedDays] = React.useState(
    new Set<ISODateString>()
  );
  const [timeRange, setTimeRange] = React.useState(
    (props.autoselect && props.autoselect.hasSame(props.today, "day")) ||
      !props.outerTimeRange.live
      ? props.outerTimeRange
      : { interval: props.defaultTimeRange, live: false }
  );
  const [showLoading, setShowLoading] = React.useState(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const maxDate = props.today.plus({ days: props.bookAheadDaysMax });
  const api = useAPIRequest();
  const showError = useError();

  const refreshBookingsData = () => {
    return api
      .get<{
        bookings: BookingInfo[];
        dropins: BookingInfo[];
        verifyURL?: string;
      }>("/seating/api/list_seat_bookings", {
        today: props.today.toISODate(),
        includePastWeek: true,
      })
      .promise.then(function (res) {
        if (res.status == "failed") return showError(res.reason);
        const bookings = res.bookings.map((b: BookingInfo) => ({
          ...b,
          isDropin: false,
        }));
        const dropins = res.dropins.map((b: BookingInfo) => ({
          ...b,
          isDropin: true,
        }));
        const both = bookings
          .concat(dropins)
          .sort((a, b) => a.startDate.localeCompare(b.startDate));
        setBookingsData(both);
      });
  };

  React.useEffect(() => {
    refreshBookingsData();
  }, []);

  const isDateDisabled = function (day: CalendarDate | null) {
    if (day == null) return false;
    if (day > maxDate) return true;
    const weekIndex = differenceInWeeks(day, props.today.startOf("week"));
    const daysBooked =
      props.weekBookings[selectedResourceType]?.[weekIndex] ?? [];
    const numSelectedDaysInWeek = selectedDaysInWeek(
      props.today,
      weekIndex,
      selectedDays
    );
    const formattedDay = day.toISODate() as ISODateString;
    const weeks = weeksSinceBaseDate(day);
    const scheduleIndex = weeks % props.userSchedule.length;
    const scheduleEnabled = props.userSchedule[scheduleIndex][day.weekday % 7];

    const isBooked = isPreviouslyBooked(
      bookingsData,
      formattedDay,
      timeRange.interval,
      props.dropin,
      selectedResourceType
    );

    if (
      !scheduleEnabled ||
      (!props.weekendsBookable && isWeekend(day)) ||
      Math.floor(day.diff(props.today, ["days"]).days!) <
        props.bookAheadDaysMin ||
      isBooked
    ) {
      return true;
    } else {
      return !(
        daysBooked.length + numSelectedDaysInWeek < props.maxWeeklyBookings ||
        selectedDays.has(formattedDay)
      );
    }
  };

  React.useEffect(() => {
    const autoselectedToday =
      props.autoselect && props.autoselect.hasSame(props.today, "day");
    const selectedDaysBeyondToday = [...selectedDays].some(
      (d) => d != (props.today.toISODate() as ISODateString)
    );

    if (autoselectedToday && timeRange.live && selectedDaysBeyondToday) {
      setTimeRange({ interval: props.defaultTimeRange, live: false });
    }
  }, [selectedDays]);

  React.useEffect(
    function () {
      if (props.shouldShow) {
        refreshBookingsData();
        setShowLoading(false);
        setTimeRange(
          (props.autoselect && props.autoselect.hasSame(props.today, "day")) ||
            !props.outerTimeRange.live
            ? props.outerTimeRange
            : { interval: props.defaultTimeRange, live: false }
        );
        setFocusedDay(props.today);
        setSelectedDays(
          props.autoselect && !isDateDisabled(props.autoselect)
            ? new Set([props.autoselect.toISODate() as ISODateString])
            : new Set()
        );
        setSelectedResourceType(props.resourceType);
      }
    },
    [props.shouldShow]
  );

  return (
    <Dialog
      aria-describedby="scroll-dialog-description"
      aria-labelledby="scroll-dialog-title"
      fullScreen={screen.width < 375}
      onClose={props.onClose}
      open={props.shouldShow}
      scroll="paper"
    >
      {showLoading && (
        <>
          <DialogTitle id="scroll-dialog-title" style={{ textAlign: "center" }}>
            {t("finding-you-the-perfect-spot")}
          </DialogTitle>
          <DialogContent dividers={false}>
            <div
              style={{
                padding: "5em",
                textAlign: "center",
              }}
            >
              <CircularProgress />
            </div>
          </DialogContent>
        </>
      )}
      {!showLoading && (
        <>
          <DialogTitle id="scroll-dialog-title" style={{ textAlign: "center" }}>
            {t("when-are-you-coming-in")}
          </DialogTitle>
          <DialogContent dividers={false}>
            <CalendarPicker<DateTime>
              onChange={function (dateDT) {
                const date = dateDT ? CalendarDate.fromDateTime(dateDT) : null;
                setFocusedDay(date);
                if (date) {
                  const dateStr = date.toISODate() as ISODateString;
                  return setSelectedDays(function (s) {
                    if (s.has(dateStr)) {
                      const newSet = new Set(s);
                      newSet.delete(dateStr);
                      return newSet;
                    } else {
                      const newSet = new Set(s);
                      newSet.add(dateStr);
                      return newSet;
                    }
                  });
                }
              }}
              date={focusedDay?.date ?? null}
              maxDate={maxDate.date}
              shouldDisableDate={(d) =>
                isDateDisabled(CalendarDate.fromDateTime(d))
              }
              renderDay={function (dayDT, _selected, dayComponentProps) {
                const day = dayDT ? CalendarDate.fromDateTime(dayDT) : null;

                if (!day || !day.isValid) {
                  return <PickersDay {...dayComponentProps} />;
                }

                const formattedDay = day.toISODate() as ISODateString;
                const isCurrentlySelected = selectedDays.has(
                  day.toISODate() as ISODateString
                );
                const isBooked = isPreviouslyBooked(
                  bookingsData,
                  formattedDay,
                  timeRange.interval,
                  props.dropin,
                  selectedResourceType
                );

                const isToday = formattedDay === props.today.toISODate();
                const isDisabled = isDateDisabled(day);

                const buddyNumber =
                  buddies.data?.buddies.filter((buddy) =>
                    buddy.bookings.some(
                      (booking) => booking.startDate == day.toISODate()
                    )
                  ).length ?? 0;

                dayComponentProps.selected = false;
                dayComponentProps.today = isToday;
                if (isBooked) {
                  return (
                    <BuddyPickersDay
                      pickerProps={dayComponentProps}
                      key={formattedDay}
                      buddyNumber={buddyNumber}
                      className={isToday ? "autobook-today" : ""}
                      style={{
                        backgroundColor: theme.palette.secondary.main + "55",
                        display: "inline-flex",
                        height: "36px",
                        margin: "0px 2px",
                        width: "36px",
                      }}
                    />
                  );
                }
                if (isDisabled) {
                  return (
                    <BuddyPickersDay
                      pickerProps={dayComponentProps}
                      key={formattedDay}
                      buddyNumber={buddyNumber}
                      style={{
                        opacity: 0.5,
                      }}
                    />
                  );
                }
                if (isCurrentlySelected) {
                  return (
                    <BuddyPickersDay
                      pickerProps={dayComponentProps}
                      key={formattedDay}
                      buddyNumber={buddyNumber}
                      style={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      }}
                    />
                  );
                }

                return (
                  <BuddyPickersDay
                    pickerProps={dayComponentProps}
                    key={formattedDay}
                    buddyNumber={buddyNumber}
                    style={{
                      backgroundColor: "transparent",
                      color: isToday ? "black" : undefined,
                    }}
                  />
                );
              }}
            />
            <p
              style={{
                fontSize: "14px",
                maxWidth: "20rem",
                textAlign: "center",
              }}
            >
              {getErrorString(props.bookAheadDaysMin, props.maxWeeklyBookings)}
            </p>
            {props.allResourceTypes.length > 1 &&
              !props.selectedSeat &&
              !props.dropin && (
                <div
                  css={{
                    margin: "2rem 0",
                    textAlign: "center",
                  }}
                >
                  <span>{t("resource-type")}: </span>
                  <Select
                    css={{ marginLeft: "1rem" }}
                    value={selectedResourceType}
                    onChange={(e) => {
                      setSelectedResourceType(e.target.value as RESOURCE_TYPES);
                    }}
                  >
                    {props.allResourceTypes.map((r) => (
                      <MenuItem value={r} key={r}>
                        {resourceName(r)}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}
            {props.showTimeRange && (
              <TimeRangePicker
                css={{ margin: "0 1rem", ".Alert": { marginTop: 0 } }}
                timeRange={timeRange.interval}
                setTimeRange={(interval, live) =>
                  setTimeRange({ interval, live: !!live })
                }
                defaultTimeRange={props.defaultTimeRange}
                allowedHours={props.allowedHours}
                vertical={true}
                showNightshiftToggle={props.nightShiftEnabled}
              />
            )}
            {selectedDays.size > 1 && (
              <div css={{ textAlign: "right", marginTop: "1.5rem" }}>
                <b>{t("days-selected", { days: selectedDays.size })}</b>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <DialogActions>
              <Button
                onClick={props.onClose}
                color="secondary"
                variant="contained"
              >
                {t("cancel")}
              </Button>
            </DialogActions>
            <Button
              onClick={() =>
                props.onSuccess(
                  Array.from(selectedDays).map(CalendarDate.fromISODate),
                  timeRange.interval
                )
              }
              color="primary"
              disabled={
                selectedDays.size == 0 ||
                props.loading ||
                (!props.allowedHours.engulfs(timeRange.interval) &&
                  !props.allowedHours.isAllDay())
              }
              type="submit"
              variant="contained"
            >
              {props.loading && (
                <CircularProgress size={20} style={{ marginRight: "10px" }} />
              )}
              {t("submit")}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
