import * as React from "react";
import { DateTime } from "luxon";
import { IconButton, CircularProgress } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useAPIRequest } from "./hooks";
import { Meetings } from "zynq-shared";

const Styles = {
  cell: (deleted: boolean) =>
    ({
      padding: "0.5rem",
      textAlign: "left",
      opacity: deleted ? "0.5" : "1",
    } as const),
  editCell: {
    padding: "0.5rem",
    textAlign: "center",
    display: "flex",
  } as const,
  loader: { padding: "10px 12px", textAlign: "center" } as const,
};

enum State {
  Active,
  Deleting,
  Deleted,
}

export type MeetingInfo = {
  start: DateTime;
  end: DateTime;
  eventID: string;
  eventICalUId: string | null;
  subject: string;
  roomName: string;
  roomID: number;
  eventLink: string;
};

export default function MeetingInfoRow({
  meetingInfo,
  isMobile,
  onDelete,
}: {
  meetingInfo: MeetingInfo;
  isMobile: boolean;
  onDelete: () => Promise<unknown>;
}) {
  const [state, setState] = React.useState<State>(State.Active);
  const [editLoading, setEditLoading] = React.useState(false);
  const api = useAPIRequest();

  const timeRange = `${meetingInfo.start.toLocaleString(
    DateTime.TIME_SIMPLE
  )} - ${meetingInfo.end.toLocaleString(DateTime.TIME_SIMPLE)}`;

  function onEdit() {
    if (meetingInfo.eventLink) {
      window.open(meetingInfo.eventLink, "_blank", "noreferrer");
    } else if (meetingInfo.eventICalUId) {
      setEditLoading(true);
      api
        .getNode(Meetings.GetEventLink, {
          iCalUId: meetingInfo.eventICalUId,
          eventID: meetingInfo.eventID,
          start: meetingInfo.start.toISO(),
          end: meetingInfo.end.toISO(),
        })
        .promise.then(function (res) {
          setEditLoading(false);
          if (res.status == "failed") return;
          window.open(res.webLink, "_blank", "noreferrer");
        });
    }
  }

  return (
    <tr>
      <td css={[Styles.cell(state == State.Deleted)]}>
        <div>
          {meetingInfo.start.toLocaleString({
            month: "long",
            day: "numeric",
            weekday: isMobile ? undefined : "long",
          })}
          <div css={{ fontWeight: "lighter", opacity: "0.5" }}>{timeRange}</div>
          <div>
            {meetingInfo.subject}
            {meetingInfo.roomName && (
              <span css={{ fontWeight: "lighter", opacity: "0.5" }}>
                {" "}
                ({meetingInfo.roomName})
              </span>
            )}
          </div>
          <div>{meetingInfo.roomName}</div>
        </div>
      </td>
      <td css={Styles.editCell}>
        {meetingInfo.roomID != 0 &&
          (meetingInfo.eventLink || meetingInfo.eventICalUId) &&
          (editLoading ? (
            <CircularProgress css={{ marginLeft: "auto"}} style={Styles.loader} size="24" />
          ) : (
            <IconButton
              css={{
                marginLeft: "auto",
              }}
              onClick={onEdit}
              color="primary"
              disabled={state == State.Deleted}
              size="large"
            >
              <EditIcon />
            </IconButton>
          ))}

        {(meetingInfo.eventLink || meetingInfo.eventICalUId) &&
          (state != State.Deleting ? (
            <IconButton
              onClick={() => {
                setState(State.Deleting);
                onDelete().then(() => setState(State.Deleted));
              }}
              color="primary"
              disabled={state == State.Deleted}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <CircularProgress css={Styles.loader} size={24} />
          ))}
      </td>
    </tr>
  );
}
