import type { CSSObject } from "@emotion/react";
import type { RoomInfo, RoomEvent } from "./types";
import type { CalendarDate } from "zynq-shared";
import { TimeInterval } from "zynq-shared";

export default function Room(props: {
  roomInfo: RoomInfo;
  roomEvents: RoomEvent[];
  date: CalendarDate;
  timeRange: TimeInterval;
  highlighted: boolean;
  onSelectRoomID: (id: number) => void;
}) {
  const currentEvents = props.roomEvents.filter((e) =>
    TimeInterval.fromInterval(e.interval, props.date).overlaps(props.timeRange)
  );

  let style: CSSObject = {};
  let strokeColor = "#C6C4C4";
  if (!props.roomInfo.viewable) {
    style = {
      fill: "#C6C4C4",
    };
    strokeColor = "#C6C4C4";
  } else if (currentEvents.length > 0) {
    style = {
      fill: "#F87170",
    };
    strokeColor = "#F87170";
  } else {
    style = {
      fill: "#3DD8BB",
    };
    strokeColor = "#3DD8BB";
  }

  return (
    <g
      key={props.roomInfo.id}
      role={"button"}
      tabIndex={0}
      focusable={"true"}
      aria-label={props.roomInfo.name}
      className={"room-touch-target"} // Needs to be here for click to work
      css={{
        cursor: "pointer",
        outline: "none",
        stroke: props.highlighted ? "black" : strokeColor,
        "&:focus": { stroke: props.highlighted ? "black" : "greenyellow" },
      }}
      onKeyDown={(e) => {
        if (e.key == " " || e.key == "Spacebar" || e.key == "Enter") {
          props.onSelectRoomID(props.roomInfo.id);
          document.getElementById("seat-info-panel")?.focus();
        }
      }}
      {...{ roomid: props.roomInfo.id }} // Needs to be here for click to work
    >
      <defs>
        <clipPath id={`${props.roomInfo.id}-clip-path`}>
          <polygon
            css={[
              {
                strokeWidth: "50px",
                fillOpacity: 0.6,
                strokeOpacity: 0.8,
                strokeLinejoin: "round",
              },
              style,
            ]}
            points={props.roomInfo.svgPoints}
          />
        </clipPath>
      </defs>
      <polygon
        css={[
          {
            strokeWidth: "5px",
            fillOpacity: 0.6,
            strokeOpacity: 0.8,
          },
          style,
        ]}
        clipPath={`url(#${props.roomInfo.id}-clip-path)`}
        points={props.roomInfo.svgPoints}
      />
    </g>
  );
}
