import React from "react";
import { DatePicker } from "@mui/lab";
import { TextField, Button, Snackbar } from "@mui/material";

import { useAPIRequest } from "./hooks";
import type { UserInfo } from "./types";
import { DateTime } from "luxon";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

function ScheduleVisitPage({ userInfo }: { userInfo: UserInfo }) {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [toast, setToast] = React.useState("");
  const { post } = useAPIRequest();

  const today = DateTime.now().startOf("day").plus({ hours: 6 });
  const [endDate, setEndDate] = React.useState(today);
  const [startDate, setStartDate] = React.useState(today);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setToast("Scheduling...");
    post("/seating/api/schedule_visit", {
      visitorEmail: email,
      visitorName: name,
      start: startDate,
      end: endDate,
    }).promise.then((res) => {
      if (res.status === "success") {
        setEmail("");
        setName("");
        setToast(i18next.t("scheduled-visit"));
      } else {
        setToast(i18next.t("error-with-msg", { msg: res.reason }));
      }
    });
    return false;
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!toast}
        autoHideDuration={2000}
        onClose={(_) => setToast("")}
        message={toast}
      />
      <form
        onSubmit={handleSubmit}
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "25rem",
          padding: "1rem",
          margin: "auto",
          marginTop: "2rem",
          "& > * + *": {
            marginTop: "2rem !important",
          },
        }}
      >
        <TextField
          required
          id="standard-basic-name"
          label={t("visitor-name")}
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
        />
        <TextField
          required
          id="standard-basic-email"
          label={t("visitor-email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
        />
        <DatePicker<DateTime>
          views={["day"]}
          showToolbar={false}
          inputFormat="MM/dd/yyyy"
          label={t("start-date")}
          css={{ width: "100%", textAlign: "center" }}
          value={startDate}
          onChange={(date) => {
            if (date != null) {
              setStartDate(date);
            }
          }}
          renderInput={(params) => <TextField {...params} />}
          OpenPickerButtonProps={{
            "aria-label": t("change-start-date"),
          }}
        />
        <DatePicker<DateTime>
          views={["day"]}
          showToolbar={false}
          inputFormat="MM/dd/yyyy"
          label={t("end-date")}
          css={{ width: "100%", textAlign: "center" }}
          value={endDate}
          onChange={(date) => {
            if (date != null) {
              setEndDate(date);
            }
          }}
          renderInput={(params) => <TextField {...params} />}
          OpenPickerButtonProps={{
            "aria-label": t("change-end-date"),
          }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          // @ts-ignore
          css={{
            paddingLeft: "15px!important",
            paddingRight: "15px!important",
            width: "40%!important",
            letterSpacing: "2px!important",
            cursor: "pointer!important",
            textTransform: "uppercase!important",
            fontWeight: "600!important",
            fontSize: "100%!important",
            paddingTop: "8px!important",
            paddingBottom: "8px!important",
            borderRadius: "10px!important",
          }}
        >
          {t("submit")}
        </Button>
      </form>
    </>
  );
}

export default ScheduleVisitPage;
