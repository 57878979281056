import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { IconButton, useMediaQuery } from "@mui/material";
import type React from "react";
import { Table as InternalTable } from "react-fluid-table";
import type {
  TableProps,
  CellProps,
  ColumnProps,
  Generic,
  SubComponentProps,
  ExpanderProps,
} from "react-fluid-table";
import type { Patch } from "zynq-shared";

type PatchedExpanderProps = Patch<ExpanderProps, { onClick: () => void }>;

type CellPropsGen<T> = Patch<CellProps, { row: T }>;
type ColumnPropsGen<T> = Patch<
  ColumnProps,
  {
    content?: string | number | React.ElementType<CellPropsGen<T>>;
    cell?: string | number | React.ElementType<CellPropsGen<T>>;
    noMobile?: boolean;
    expander?: boolean | React.ElementType<PatchedExpanderProps>;
  }
>;
type SubComponentPropsGen<T, Context> = Patch<
  SubComponentProps,
  { row: T; style: React.CSSProperties; context: Context }
>;
type TablePropsGen<T, Context> = Context extends undefined
  ? Patch<
      TableProps,
      {
        data: T[];
        columns: ColumnPropsGen<T>[];
        subComponent?: React.ElementType<SubComponentPropsGen<T, Context>>;
        subComponentContext?: undefined;
      }
    >
  : Patch<
      TableProps,
      {
        data: T[];
        columns: (ColumnPropsGen<T> | undefined)[];
        subComponent?: React.ElementType<SubComponentPropsGen<T, Context>>;
        subComponentContext: Context;
      }
    >;

export const rowStyle = (index: number) => ({
  padding: "0.5rem 0rem",
  backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
});

export function Expander(props: {
  isExpanded: boolean;
  onClick: () => void;
  style?: React.CSSProperties;
}) {
  const isNotMobile = useMediaQuery("@media (min-width:420px)");
  return (
    <IconButton
      onClick={props.onClick}
      title={props.isExpanded ? "Collapse" : "Expand"}
      size={isNotMobile ? undefined : "small"}
      css={isNotMobile ? { width: 45, height: 45, marginLeft: "2px" } : {}}
    >
      {props.isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    </IconButton>
  );
}

function subcomponentStyle(index: number): React.CSSProperties {
  return {
    padding: "1rem",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
    boxShadow: "inset 0px 8px 10px -7px #0003, inset 0px -6px 10px -7px #0003",
  };
}

export default function Table<T, Context = undefined>(
  props: TablePropsGen<T, Context> & { tableRef?: any }
) {
  const isNotMobile = useMediaQuery("@media (min-width:600px)");
  const SC = props.subComponent;

  return (
    <div
      css={{
        display: "flex",
        alignItems: "stretch",
        margin: isNotMobile ? "0 1rem" : "0",
        width: "100%",
        "& .header-cell-text": { color: "black" },
      }}
    >
      <InternalTable
        {...({ ref: props.tableRef } as any)}
        headerStyle={{
          borderBottom: "2px solid lightgray",
          padding: "0",
          paddingTop: "1.5rem",
          backgroundColor: "white",
          color: "black",
        }}
        tableStyle={{ border: "none" }}
        {...(props as TableProps)}
        rowStyle={(i) => ({
          ...rowStyle(i),
          ...(typeof props.rowStyle == "function"
            ? props.rowStyle(i)
            : props.rowStyle),
        })}
        columns={
          (isNotMobile
            ? props.columns.filter(Boolean)
            : props.columns.filter((c) => c && !c.noMobile)) as ColumnProps[]
        }
        subComponent={
          typeof SC == "function"
            ? (scProps) => {
                return (
                  <SC
                    {...(scProps as SubComponentPropsGen<T, Context>)}
                    style={subcomponentStyle(scProps.index)}
                    context={props.subComponentContext!}
                  />
                );
              }
            : undefined
        }
      />
    </div>
  );
}
