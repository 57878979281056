import * as React from "react";
import { IconButton, CircularProgress } from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import type { ISODateString } from "./types";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { CalendarDate, TimeInterval } from "zynq-shared";
import { timeIntervalToString } from "./luxonUtil";
import type { RESOURCE_TYPES } from "./constants";

const Styles = {
  cell: (deleted: boolean) =>
    ({
      padding: "0.5rem",
      textAlign: "left",
      opacity: deleted ? "0.25" : "1",
    } as const),
  editCell: {
    padding: "0.5rem",
    textAlign: "center",
    display: "flex",
  } as const,
  loader: { padding: "10px 12px", textAlign: "center" } as const,
};

export type BookingInfo = {
  bookingID: number;
  startDate: ISODateString;
  endDate: ISODateString;
  startTime: string;
  endTime: string;
  floorplanID: number;
  floorplanName: string;
  floorplanShortName: string | null;
  seatID: number;
  seatName: string;
  resourceType: RESOURCE_TYPES;
  seatType: string;
  isDropin: boolean;
};

enum State {
  Active,
  Deleting,
  Deleted,
}

export default function BookingInfoRow({
  bookingInfo,
  onNavigate,
  highlight,
  isMobile,
  onDelete,
  timeBasedBookingEnabled,
}: {
  timeBasedBookingEnabled: boolean;
  bookingInfo: BookingInfo;
  onNavigate: (
    floorplanID: number,
    date: CalendarDate,
    seatID: number,
    timeInterval?: TimeInterval
  ) => void;
  highlight: boolean;
  isMobile: boolean;
  onDelete: () => Promise<unknown>;
}) {
  const { t } = useTranslation();
  const [state, setState] = React.useState<State>(State.Active);

  const floorplanName = bookingInfo.floorplanName;

  const seatName = bookingInfo.isDropin ? (
    <>
      {t("drop-in")}
      <span css={{ fontWeight: "lighter", opacity: "0.5" }}>
        {" "}
        {t("no-desk-in-parens")}
      </span>
    </>
  ) : (
    <>
      {bookingInfo.seatName}{" "}
      <span
        css={{ fontWeight: "lighter", opacity: "0.5" }}
      >{` (${bookingInfo.seatType})`}</span>
    </>
  );

  return (
    <tr
      data-test-id="booking-info-row"
      css={{
        backgroundColor: highlight ? "#b0ffdf" : undefined,
        hover: {
          backgroundColor: highlight ? "#85ffcd" : "#FAFAFA",
        },
      }}
    >
      <td css={[Styles.cell(state == State.Deleted)]}>
        <div>
          {DateTime.fromISO(bookingInfo.startDate).toLocaleString({
            month: "short",
            day: "numeric",
            weekday: isMobile ? undefined : "short",
          })}
          {timeBasedBookingEnabled && (
            <span css={{ fontWeight: "lighter", opacity: "0.5" }}>
              {` (${timeIntervalToString(
                TimeInterval.fromISODatesAndTimes(bookingInfo)
              )})`}
            </span>
          )}
          <div>{seatName}</div>
          <div>{floorplanName}</div>
        </div>
      </td>
      <td css={Styles.editCell}>
        <IconButton
          css={{
            visibility: bookingInfo.isDropin ? "hidden" : "visible",
            marginLeft: "auto",
          }}
          onClick={function () {
            return onNavigate(
              bookingInfo.floorplanID,
              CalendarDate.fromISODate(bookingInfo.startDate),
              bookingInfo.seatID,
              TimeInterval.fromISODatesAndTimes(bookingInfo)
            );
          }}
          color="primary"
          disabled={state == State.Deleted}
          size="large"
        >
          <EditIcon />
        </IconButton>
        {state != State.Deleting ? (
          <IconButton
            onClick={() => {
              setState(State.Deleting);
              onDelete().then(() => setState(State.Deleted));
            }}
            color="primary"
            disabled={state == State.Deleted}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          <CircularProgress css={Styles.loader} size={24} />
        )}
      </td>
    </tr>
  );
}
