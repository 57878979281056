import React, { useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Avatar from "./avatar";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import {
  SportsTennis,
  Work,
  PeopleAlt,
  ChevronLeft,
  Refresh,
  CheckCircle,
  Error,
  Person,
  AccessTime,
} from "@mui/icons-material";
import type { Infer } from "superstruct";

import Questionnaire from "./questionnaire";
import type { initialState as initialQuestionnaireState } from "./questionnaire";
import QuestionnaireFailure from "./questionnaireFailure";
import {
  useAPIMutation,
  useAPIQuery,
  useIdleTimer,
  useUserMedia,
} from "./hooks";
import { CalendarDate } from "zynq-shared";
import type { Concierge, InferReturnType } from "zynq-shared";
import { VACCINATION_STATUS } from "./constants";
import { useTranslation } from "react-i18next";
import { Global } from "@emotion/react";
import ValidatedTextField, { validateEmail } from "./validatedTextField";
import UserSearch from "./userSearch";

export const KINDS = [
  { name: "SOCIAL", icon: <SportsTennis /> },
  { name: "MEETING", icon: <PeopleAlt /> },
  { name: "OTHER", icon: <Work /> },
] as const;

type RegistrationInfo = Infer<typeof Concierge.GetRegisterInfo.types.return>;

export function getImageSize(
  videoWidth: number,
  videoHeight: number,
  sizeFactor: number
) {
  // calc the imageWidth
  const imageWidth = videoWidth * sizeFactor;
  // calc the ratio
  const ratio = videoWidth / imageWidth;
  // calc the imageHeight
  const imageHeight = videoHeight / ratio;

  return {
    imageWidth,
    imageHeight,
  };
}
function drawVideoImage(videoElement: HTMLVideoElement): Promise<Blob | null> {
  const { videoWidth, videoHeight } = videoElement;
  const { imageWidth, imageHeight } = getImageSize(videoWidth, videoHeight, 2);

  // Build the canvas size and draw image from videoElement
  const canvas = document.createElement("canvas");
  canvas.width = imageWidth;
  canvas.height = imageHeight;
  const context = canvas.getContext("2d");

  context?.drawImage(videoElement, 0, 0, imageWidth, imageHeight);

  return new Promise((resolve) => canvas.toBlob(resolve, "png"));
}

function Photo(props: { image: Blob; className?: string; alt: string }) {
  const [imageURL, setImageURL] = React.useState<string>();

  React.useEffect(() => {
    if (props.image) {
      setImageURL(URL.createObjectURL(props.image));
    }
    return () => {
      imageURL && URL.revokeObjectURL(imageURL);
      setImageURL(undefined);
    };
  }, [props.image]);

  if (!imageURL) {
    return null;
  }

  return (
    <img
      className={props.className}
      srcSet={`${imageURL} 2x`}
      alt={props.alt}
    />
  );
}

function CameraStep(props: {
  facingMode: "user" | "environment";
  image?: Blob;
  setImage: (img: Blob | null | undefined) => void;
  showFaceOverlay?: boolean;
  kind: string;
}) {
  const { t } = useTranslation();
  const {
    ref: videoRef,
    isPlaying,
    error: cameraError,
  } = useUserMedia({
    audio: false,
    video: { facingMode: props.facingMode },
  });

  if (cameraError) {
    return <Alert severity="error">{String(cameraError)}</Alert>;
  }

  if (props.image) {
    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <Photo image={props.image} alt={`Preview of ${props.kind}`} />
        <Button
          css={{ margin: "1rem" }}
          variant="contained"
          onClick={() => props.setImage(undefined)}
        >
          Retake
        </Button>
      </div>
    );
  }

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div css={{ position: "relative" }}>
        {!isPlaying && (
          <div css={{ textAlign: "center" }}>
            <CircularProgress size={"5rem"} css={{ margin: "5rem" }} />
          </div>
        )}
        <video
          css={{
            width: "100%",
            height: isPlaying ? "auto" : "1rem",
          }}
          ref={videoRef}
          onCanPlay={() => videoRef.current?.play()}
          autoPlay
          playsInline
          muted
        />
        {isPlaying && props.showFaceOverlay && (
          <svg
            preserveAspectRatio="none"
            css={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <ellipse
              css={{
                rx: "min(30vw, 20%)",
                ry: "35%",
                cx: "50%",
                cy: "45%",
                strokeWidth: "0.75rem",
                strokeOpacity: 0.7,
                stroke: "white",
                fill: "none",
              }}
            />
          </svg>
        )}
      </div>
      <Button
        disabled={!isPlaying}
        css={{ margin: "1rem" }}
        variant="contained"
        onClick={() =>
          videoRef.current &&
          drawVideoImage(videoRef.current).then((i) => props.setImage(i))
        }
      >
        {t("take-photo")}
      </Button>
    </div>
  );
}

function QuestionnaireStep(props: {
  registrationInfo: RegistrationInfo;
  vaccinationStatus: VACCINATION_STATUS;
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const [questionnaireState, setQuestionnaireState] =
    React.useState<typeof initialQuestionnaireState>();
  const [questionnaireFailure, setQuestionnaireFailure] = React.useState(false);

  return (
    <>
      <Typography
        variant="h1"
        css={{
          alignSelf: "start",
          fontSize: "2rem",
          fontWeight: 400,
          marginTop: "1rem",
          marginBottom: "2rem",
          color: "#02133E",
        }}
      >
        {t("please-answer-these-questions")}
      </Typography>
      <div
        css={{
          overflowY: "auto",
          maxHeight: "min(80%, 100rem)",
          boxSizing: "border-box",
          margin: "1rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Questionnaire
          state={questionnaireState!}
          setState={setQuestionnaireState}
          domainName={props.registrationInfo.domainName}
          floorplanName={""}
          userVaccinationStatus={props.vaccinationStatus}
        />
        {questionnaireState && (
          <Button
            type="submit"
            disabled={!questionnaireState.ready}
            onClick={(_) => {
              if (questionnaireState.valid) {
                props.onSuccess();
              } else {
                setQuestionnaireState(undefined);
                setQuestionnaireFailure(true);
              }
            }}
            color="primary"
            variant="contained"
          >
            {t("submit")}
          </Button>
        )}
      </div>
      <QuestionnaireFailure
        isOpen={questionnaireFailure}
        setOpen={setQuestionnaireFailure}
        domainName={"concierge"}
        floorplanName={"concierge"}
      />
    </>
  );
}

function CompanyNameStep(props: {
  form: Form;
  setForm: React.Dispatch<React.SetStateAction<Form>>;
  registrationInfo: RegistrationInfo;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Typography
        variant="h1"
        css={{
          fontSize: "2rem",
          fontWeight: 400,
          marginBottom: "3rem",
          color: "#02133E",
        }}
      >
        {t("what-company")}
      </Typography>
      <TextField
        label={t("company-name")}
        value={props.form.companyName}
        onChange={(e) => {
          props.setForm({ ...props.form, companyName: e.target.value });
        }}
        fullWidth
      />
    </>
  );
}

function TableCellHeader(props: { children: React.ReactNode }) {
  return (
    <TableCell
      css={{ textAlign: "right", verticalAlign: "center", width: "40%" }}
    >
      <Typography variant="body2" color="textSecondary" fontSize={"80%"}>
        {props.children}
      </Typography>
    </TableCell>
  );
}

function SubmissionStep(props: {
  form: Form;
  registrationInfo: RegistrationInfo;
  resetForm: () => void;
}) {
  const { t } = useTranslation();
  const [printJob, setPrintJob] = React.useState<{
    state: RecordVisitStatus;
    errorMessage?: string;
  }>({ state: "SENDING" });
  const [showVisitorGuideConfirmation, setShowVisitorGuideConfirmation] =
    React.useState<boolean>(false);
  const [visitID, setVisitID] = React.useState<number | null>(null);

  const { data: { jobID } = {}, mutate: recordVisit } = useAPIMutation<
    typeof Concierge.RecordVisit
  >("/apis/concierge/record-visit", {
    onSuccess: (res) => {
      if (!res.jobID) {
        setPrintJob((j) => ({ ...j, state: "SUCCESS" }));
      }
      if (res.visitID) {
        setShowVisitorGuideConfirmation(!!res.sentVisitorGuide);
        setVisitID(res.visitID);
      }
    },
    onError: (res) => {
      setPrintJob({ state: "FAILED", errorMessage: res.reason });
    },
  });

  const { mutate: acknowledgeVisitorGuideReceipt } = useAPIMutation<
    typeof Concierge.AcknowledgeVisitorGuideReceipt
  >("/apis/concierge/acknowledge-visitor-guide-receipt", {
    onSuccess: () => {
      setShowVisitorGuideConfirmation(false);
    },
  });

  const submissionPending =
    printJob?.state != "SUCCESS" && printJob?.state != "FAILED";

  // Using react-query for the polling/refetch logic
  useAPIQuery<typeof Concierge.PrintStatus>(
    "/apis/concierge/get-print-status",
    { jobID: jobID ?? 0 },
    {
      refetchInterval: 5000,
      enabled: !!jobID && submissionPending,
      onSuccess: (data) => {
        setPrintJob((j) => ({
          state: data.printStatus,
          errorMessage: data.errorMessage,
        }));
      },
      onError: (data) => {
        setPrintJob((j) => ({
          state: "FAILED",
          errorMessage: data.reason,
        }));
      },
    }
  );

  useEffect(() => {
    const formData = new FormData();
    formData.append("name", props.form.name.trim());
    formData.append("visitType", props.form.kind);
    props.form.host &&
      formData.append("hostEmail", props.form.host?.email.trim());
    props.form.vaccinationImage &&
      formData.append("vaccinationImage", props.form.vaccinationImage);
    props.form.visitorImage &&
      formData.append("visitorImage", props.form.visitorImage);
    formData.append("email", props.form.email.trim());
    formData.append("companyName", props.form.companyName?.trim() ?? "");
    formData.append("visitorType", props.form.visitorType);
    recordVisit(formData);
  }, []);

  return (
    <>
      <Typography
        variant="h1"
        css={{
          fontSize: "2rem",
          fontWeight: 400,
          marginBottom: "1rem",
          color: "#02133E",
        }}
      >
        {props.registrationInfo.friendlyName
          ? t("welcome-to", { name: props.registrationInfo.friendlyName })
          : t("welcome-exclamation")}
      </Typography>
      <div
        css={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <>
          {submissionPending && <CircularProgress />}
          {printJob.state == "FAILED" && (
            <Error color="error" fontSize="large" />
          )}
          {printJob.state == "SUCCESS" && (
            <CheckCircle color="success" fontSize="large" />
          )}
          <div css={{ margin: "1rem", textAlign: "center" }}>
            {printJob.state == "SENDING"
              ? props.registrationInfo.mode == "PRINTER" ||
                props.registrationInfo.printEnabled
                ? t("sending-badge-to-printer")
                : t("sending")
              : printJob.state == "SUBMITTED" &&
                (props.registrationInfo.mode == "PRINTER" ||
                  props.registrationInfo.printEnabled)
              ? t("waiting-for-print")
              : printJob.state == "ACCEPTED" &&
                (props.registrationInfo.mode == "PRINTER" ||
                  props.registrationInfo.printEnabled)
              ? t("badge-being-printed")
              : null}
          </div>
        </>
        {printJob.state == "FAILED" && (
          <Alert
            severity="error"
            css={{
              margin: "1rem",
              fontSize: "large",
              display: "flex",
              alignItems: "center",
            }}
          >
            {props.registrationInfo.mode == "PRINTER" ||
            props.registrationInfo.printEnabled
              ? t("badge-printing-error", { msg: printJob.errorMessage })
              : t("error-with-msg", { msg: printJob.errorMessage })}
          </Alert>
        )}
        {printJob.state == "SUCCESS" && (
          <>
            <div>
              {props.registrationInfo.mode == "PRINTER" ||
              props.registrationInfo.printEnabled
                ? t("badge-printed-success")
                : t("check-in-success")}
            </div>
            {props.form.host?.name && (
              <div>{t("host-notified-will-arrive-soon")}</div>
            )}
          </>
        )}
        {showVisitorGuideConfirmation && visitID && (
          <>
            <div css={{ margin: "1rem", textAlign: "center" }}>
              <p>
                Thank you for registering your visit today, please collect your
                appropriate lanyard badge from reception or your host and wear
                this during your visit and return when you leave. We have
                emailed you a site guide which includes a site introduction,
                procedures for emergencies, incident and hazard reporting and
                other helpful information.
              </p>
              <Button
                css={{ marginTop: "2rem" }}
                variant={"contained"}
                onClick={() => {
                  acknowledgeVisitorGuideReceipt({
                    visitID: visitID!,
                  });
                }}
              >
                {t("acknowledge-receipt")}
              </Button>
            </div>
          </>
        )}
        <Table css={{ maxWidth: "30rem", marginTop: "2rem" }}>
          <TableBody>
            <TableRow css={{ verticalAlign: "baseline" }}>
              <TableCellHeader>{t("name")}</TableCellHeader>
              <TableCell>{props.form.name}</TableCell>
            </TableRow>
            {props.form.email && (
              <TableRow>
                <TableCellHeader>{t("email")}</TableCellHeader>
                <TableCell>{props.form.email}</TableCell>
              </TableRow>
            )}
            {props.form.host?.name && (
              <TableRow>
                <TableCellHeader>{t("host")}</TableCellHeader>
                <TableCell>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <Avatar
                        avatar={{
                          src: props.form.host.pictureUrl ?? undefined,
                          name: props.form.host.name,
                        }}
                      />{" "}
                    </Grid>
                    <Grid item xs>
                      <span style={{ fontWeight: 400 }}>
                        {props.form.host.name}
                      </span>

                      <Typography variant="body2" color="textSecondary">
                        {props.form.host.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCellHeader>{t("visit-type")}</TableCellHeader>
              <TableCell>{props.form.kind}</TableCell>
            </TableRow>
            {!!props.form.companyName && (
              <TableRow>
                <TableCellHeader>{t("company")}</TableCellHeader>
                <TableCell>{props.form.companyName}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {printJob.state == "SUCCESS" && (
          <Button
            css={{ marginTop: "2rem" }}
            variant={"contained"}
            onClick={() => props.resetForm()}
          >
            {t("done")}
          </Button>
        )}
      </div>
    </>
  );
}

type RecordVisitStatus =
  | "SUCCESS"
  | "SENDING"
  | "SUBMITTED"
  | "ACCEPTED"
  | "FAILED";

type FormState =
  | "NAME"
  | "CHECK_VISIT"
  | "HOST"
  | "EMAIL"
  | "COMPANY_NAME"
  | "REASON"
  | "VACCINATION_IMAGE"
  | "QUESTIONNAIRE"
  | "VISITOR_IMAGE"
  | "CONTRACTOR_OR_VISITOR"
  | "SUBMIT";

type Visitor = InferReturnType<
  typeof Concierge.SearchVisitors
>["visitors"][number];

type Form = {
  state: { current: FormState; stack: FormState[] }; // most recent state is first
  name: string;
  email: string;
  kind: typeof KINDS[number]["name"];
  visitorType: "CONTRACTOR" | "VISITOR";
  host: {
    email: string;
    pictureUrl: string | null;
    name: string;
  } | null;
  companyName?: string;
  vaccinationImage?: Blob;
  visitorImage?: Blob;
  visitInfo?: Visitor;
  questionnaireCompleted: boolean;
  notMeEmails: string[];
};

function FormStep(props: {
  curState: FormState;
  nextState?: FormState;
  form: Form;
  nextButtonTextOverride?: string;
  nextButtonColorOverride?: "error";
  dontAddStateToHistory?: boolean;
  nextIsDisabled?: boolean;
  hideBackButton?: boolean;
  setForm: React.Dispatch<React.SetStateAction<Form>>;
  onNext?: () => void;
  isLoading?: boolean;
  children: React.ReactNode;
}) {
  const { t } = useTranslation();
  if (props.curState != props.form.state.current) return null;
  return (
    <>
      <div
        css={{
          marginTop: "0.5rem",
          width: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          flexGrow: "1",
        }}
      >
        {props.children}
      </div>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginBottom: "0.5rem",
        }}
      >
        <div css={{ flex: "1", display: "flex", justifyContent: "start" }}>
          {!props.hideBackButton && !!props.form.state.stack.length && (
            <Button
              css={{ justifySelf: "start" }}
              startIcon={<ChevronLeft />}
              onClick={() =>
                props.setForm((form) => ({
                  ...form,
                  state: {
                    current: form.state.stack[0],
                    stack: form.state.stack.slice(1),
                  },
                }))
              }
            >
              {t("back-btn-txt")}
            </Button>
          )}
        </div>
        <div
          css={{
            flex: "1",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            startIcon={<Refresh />}
            css={{ justifySelf: "center" }}
            onClick={() => props.setForm(defaultForm)}
          >
            {t("start-over")}
          </Button>
        </div>
        <div
          css={{
            flex: "1",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div></div>
          {props.nextState && (
            <Button
              startIcon={
                props.isLoading ? (
                  <CircularProgress style={{ marginRight: "10px" }} size={20} />
                ) : null
              }
              color={props.nextButtonColorOverride}
              disabled={props.isLoading || props.nextIsDisabled}
              onClick={async () => {
                props.onNext?.();
                props.setForm((form) => ({
                  ...form,
                  state: {
                    current: props.nextState!,
                    stack: props.dontAddStateToHistory
                      ? form.state.stack
                      : [props.curState, ...form.state.stack],
                  },
                }));
              }}
            >
              {props.nextButtonTextOverride ?? t("next")}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

function ChooseVisitorListItemContent(props: {
  visitor: Visitor;
  pastVisit?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <List
      css={{
        fontSize: props.pastVisit ? "70%" : undefined,
        fontWeight: "normal",
        maxWidth: "100%",
      }}
    >
      <ListItem
        css={{
          fontWeight: "bold",
          maxWidth: "100%",
        }}
      >
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ fontSize: "inherit" }}
          secondaryTypographyProps={{ maxWidth: "100%" }}
          secondary={
            <p
              css={{
                maxWidth: "100%",
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                margin: 0,
              }}
            >
              {props.visitor.email}
            </p>
          }
        >
          {props.visitor.name}
        </ListItemText>
      </ListItem>
      {!props.pastVisit && (
        <ListItem>
          <ListItemIcon>
            <AccessTime color="primary" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: "inherit", color: "primary" }}
          >
            {t("visit-scheduled-for-today")}
          </ListItemText>
        </ListItem>
      )}
    </List>
  );
}

function CheckVisit(props: {
  searchResults: Visitor[];
  form: Form;
  questionnaireEnabled: boolean;
  vaccinationImageEnabled: boolean;
  visitorImageEnabled: boolean;
  setForm: React.Dispatch<React.SetStateAction<Form>>;
}) {
  const { t } = useTranslation();

  function selectUser(visitor: Visitor) {
    let nextState: FormState;

    if (!visitor.scheduledVisit) {
      nextState = "HOST";
    } else if (
      props.vaccinationImageEnabled &&
      visitor.vaccinationStatus != VACCINATION_STATUS.YES
    ) {
      nextState = "VACCINATION_IMAGE";
    } else if (
      props.questionnaireEnabled &&
      !props.form.questionnaireCompleted
    ) {
      nextState = "QUESTIONNAIRE";
    } else if (props.visitorImageEnabled && !visitor.visitorImage) {
      nextState = "VISITOR_IMAGE";
    } else {
      nextState = "SUBMIT";
    }

    props.setForm({
      ...props.form,
      email: visitor.email,
      name: visitor.name,
      host: visitor.scheduledVisit?.host ?? props.form.host,
      kind: (visitor.scheduledVisit?.reason as Form["kind"]) ?? props.form.kind,
      visitInfo: visitor,
      state: { ...props.form.state, current: nextState }, // Don't insert check into stack
    });
  }

  return (
    <>
      <Typography
        variant="h1"
        css={{
          fontSize: "3rem",
          fontWeight: 400,
          marginLeft: "1rem",
          color: "#02133E",
          marginBottom: "2rem",
          "@media (max-height: 550px)": {
            display: "none",
          },
        }}
      >
        {t("is-this-you")}
      </Typography>
      <List css={{ width: "100%", maxWidth: "30rem", overflowY: "auto" }}>
        {props.searchResults
          // bring scheduled visits to the top
          .sort((a, b) => +!!b.scheduledVisit - +!!a.scheduledVisit)
          .map((r) => (
            <ListItem
              css={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
              key={`${r.name}-${r.email}`}
              divider
              secondaryAction={
                <Button variant={"outlined"} onClick={() => selectUser(r)}>
                  {t("this-is-me")}
                </Button>
              }
            >
              {r.scheduledVisit ? (
                <ChooseVisitorListItemContent visitor={r} />
              ) : (
                <div
                  css={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "55%",
                  }}
                >
                  <div
                    css={{
                      marginRight: "2rem",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontSize: "90%",
                      minWidth: "5rem",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("past-visit")}
                  </div>
                  <ChooseVisitorListItemContent visitor={r} pastVisit={true} />
                </div>
              )}
            </ListItem>
          ))}
      </List>
    </>
  );
}

const defaultForm: Form = {
  state: { current: "NAME", stack: [] },
  name: "",
  email: "",
  kind: KINDS[0].name,
  visitorType: "VISITOR",
  host: null,
  questionnaireCompleted: false,
  notMeEmails: [],
};

export default function ConciergeRegisterVisitPage({}) {
  const { t } = useTranslation();
  const [form, setForm] = React.useState(defaultForm);

  const {
    error,
    data: registrationInfo,
    isLoading: isRegistrationInfoLoading,
  } = useAPIQuery<typeof Concierge.GetRegisterInfo>(
    "/apis/concierge/get-register-info",
    {}
  );

  const {
    data: { visitors: visitorsUnfiltered } = {},
    isFetching: visitorsIsFetching,
  } = useAPIQuery<typeof Concierge.SearchVisitors>(
    "/apis/concierge/search-visitors",
    {
      name: form.name,
      date: CalendarDate.today().toISODate(),
      ...(form.email.length ? { email: form.email } : undefined),
      ...(form.host?.email ? { hostEmail: form.host.email } : undefined),
    },
    { enabled: form.name.length > 0, refetchOnWindowFocus: false }
  );

  useIdleTimer({
    timeout: 1000 * 60 * 5, // 5 minutes
    onIdle: () => {
      setForm(defaultForm);
    },
  });

  const visitors = visitorsUnfiltered?.filter(
    (v) => !form.notMeEmails.includes(v.email)
  );

  if (error) {
    return (
      <div
        css={{
          height: "60%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Alert
          severity="error"
          css={{
            fontSize: "larger",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>{error?.reason ?? t("unknown-error")}</div>
        </Alert>
        <Button onClick={() => location.reload()} startIcon={<Refresh />}>
          {t("refresh")}
        </Button>
      </div>
    );
  }

  if (isRegistrationInfoLoading || registrationInfo == undefined) {
    return (
      <div css={{ display: "flex", height: "60%" }}>
        <CircularProgress size={"5rem"} css={{ margin: "auto" }} />
      </div>
    );
  }

  const vaccinationImageEnabled = false; // registrationInfo.domainName == "zynq.io"; // TODO
  const visitorImageEnabled = false; // registrationInfo.domainName == "zynq.io"; // TODO

  const requiresCompanyName =
    registrationInfo.domainName == "amexgbt.com" ||
    registrationInfo.domainName == "zynq.io";
  const requiresContractorOrVisitor =
    // registrationInfo.domainName == "amexgbt.com" ||
    registrationInfo.domainName == "zynq.io";
  const requiresVaccinationImage =
    vaccinationImageEnabled &&
    form.visitInfo?.vaccinationStatus != VACCINATION_STATUS.YES;
  const requiresQuestionnaire =
    registrationInfo.questionnaireEnabled && !form.questionnaireCompleted;
  const requiresVisitorImage =
    visitorImageEnabled && !form.visitInfo?.visitorImage;
  const requiresEmail =
    registrationInfo.domainName == "sunbeltrentals.com" ||
    registrationInfo.domainName == "zynq.io";

  return (
    <>
      <Global styles={{ html: { fontSize: "1.3rem" } }} />
      <div
        css={{
          margin: "0rem auto",
          maxWidth: "50rem",
          width: "90%",
          height: "100%",
          maxHeight: "50rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormStep
          curState="NAME"
          nextState={
            visitors?.length && !form.visitInfo ? "CHECK_VISIT" : "HOST"
          }
          form={form}
          setForm={setForm}
          nextIsDisabled={form.name.length <= 2}
          isLoading={visitorsIsFetching && !form.visitInfo}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "1rem",
              marginBottom: "5rem",
              gap: "2rem",
              "@media (max-height: 500px)": {
                display: "none",
              },
            }}
          >
            {registrationInfo.logo != null && (
              <img
                alt={registrationInfo.friendlyName + " Logo"}
                src={registrationInfo.logo}
                css={{
                  height: "4rem",
                  "@media (max-height: 650px)": {
                    display: "none",
                  },
                }}
              />
            )}
            <Typography
              variant="h1"
              css={{
                fontSize: "3rem",
                fontWeight: 400,
                marginLeft: "1rem",
                color: "#02133E",
                "@media (max-height: 600px)": {
                  display: "none",
                },
              }}
            >
              {registrationInfo.friendlyName
                ? t("welcome-to", { name: registrationInfo.friendlyName })
                : t("welcome-exclamation")}
            </Typography>
            <Typography
              variant="h2"
              css={{
                fontSize: "2rem",
                fontWeight: 400,
                marginLeft: "1rem",
                color: "#02133E",
              }}
            >
              {requiresContractorOrVisitor
                ? t("register")
                : t("register-as-a-visitor")}
            </Typography>

            {requiresContractorOrVisitor && (
              <ToggleButtonGroup
                value={form.visitorType}
                color="primary"
                exclusive
                onChange={(_e, v) => v && setForm({ ...form, visitorType: v })}
                aria-label="text alignment"
              >
                <ToggleButton
                  key="VISITOR"
                  value="VISITOR"
                  aria-label="VISITOR"
                >
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "column",
                      width: "7rem",
                    }}
                  >
                    <div>{t("visitor")}</div>
                  </div>
                </ToggleButton>
                <ToggleButton
                  key="CONTRACTOR"
                  value="CONTRACTOR"
                  aria-label="CONTRACTOR"
                >
                  <div
                    css={{
                      display: "flex",
                      flexDirection: "column",
                      width: "7rem",
                    }}
                  >
                    <div>{t("contractor")}</div>
                  </div>
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          </div>
          <TextField
            required
            label={t("your-name")}
            value={form.name}
            onChange={(e) => {
              setForm({ ...form, name: e.target.value });
            }}
            fullWidth
          />
        </FormStep>
        <FormStep
          curState="CHECK_VISIT"
          nextState={
            form.state.stack.includes("EMAIL")
              ? "REASON"
              : form.state.stack.includes("HOST")
              ? "EMAIL"
              : "HOST"
          }
          dontAddStateToHistory={true}
          nextButtonTextOverride={t("none-of-these-are-me")}
          nextButtonColorOverride="error"
          onNext={() =>
            setForm((form) => ({
              ...form,
              notMeEmails: [
                ...form.notMeEmails,
                ...(visitors?.map((v) => v.email) ?? []),
              ],
            }))
          }
          form={form}
          setForm={setForm}
        >
          <CheckVisit
            searchResults={visitors ?? []}
            form={form}
            setForm={setForm}
            questionnaireEnabled={registrationInfo.questionnaireEnabled}
            vaccinationImageEnabled={vaccinationImageEnabled}
            visitorImageEnabled={visitorImageEnabled}
          />
        </FormStep>
        <FormStep
          curState="HOST"
          nextState={
            visitors?.length && !form.visitInfo
              ? "CHECK_VISIT"
              : form.visitInfo?.email
              ? "REASON"
              : "EMAIL"
          }
          form={form}
          setForm={setForm}
          nextIsDisabled={!form.host}
          isLoading={visitorsIsFetching && !form.visitInfo}
        >
          <Typography
            variant="h1"
            css={{
              alignSelf: "start",
              fontSize: "2rem",
              fontWeight: 400,
              marginBottom: "3rem",
              color: "#02133E",
              "@media (max-height: 300px)": {
                display: "none",
              },
            }}
          >
            {t("who-are-you-visiting")}
          </Typography>
          <UserSearch
            style={{ width: "100%" }}
            css={{
              "@media (max-height: 300px)": {
                marginBottom: "auto",
                marginTop: "0.5rem",
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={t("host")}
                variant="standard"
                fullWidth
              />
            )}
            users={true}
            groups={false}
            selection={
              form.host
                ? {
                    email: form.host.email,
                    name: form.host.name,
                    src: form.host.pictureUrl ?? undefined,
                  }
                : undefined
            }
            onSelection={(result) =>
              setForm({
                ...form,
                host: result
                  ? {
                      email: result.email,
                      pictureUrl: result.src ?? null,
                      name: result.name,
                    }
                  : null,
              })
            }
          />
        </FormStep>
        <FormStep
          curState="EMAIL"
          nextState={
            visitors?.length && !form.visitInfo ? "CHECK_VISIT" : "REASON"
          }
          nextIsDisabled={
            !(
              (form.email.trim() == "" && !requiresEmail) ||
              validateEmail(form.email)
            )
          }
          form={form}
          setForm={setForm}
          isLoading={visitorsIsFetching && !form.visitInfo}
        >
          <Typography
            variant="h1"
            css={{
              alignSelf: "start",
              fontSize: "2rem",
              fontWeight: 400,
              marginBottom: "3rem",
              color: "#02133E",
              "@media (max-height: 300px)": {
                display: "none",
              },
            }}
          >
            {t("how-can-we-reach-you")}
          </Typography>
          <ValidatedTextField
            label={
              requiresEmail ? t("email") : `${t("email")} (${t("optional")})`
            }
            type="email"
            value={form.email}
            validator={(v) =>
              v.trim() == "" || validateEmail(v)
                ? undefined
                : t("error-invalid-email")
            }
            onChange={(e) => {
              setForm({ ...form, email: e.target.value });
            }}
            fullWidth
          />
          <p
            css={{
              "@media (max-height: 300px)": {
                display: "none",
              },
            }}
          >
            {t("email-disclaimer")}
          </p>
        </FormStep>
        <FormStep
          curState="REASON"
          nextState={
            requiresCompanyName && !form.companyName
              ? "COMPANY_NAME"
              : requiresVaccinationImage
              ? "VACCINATION_IMAGE"
              : requiresQuestionnaire
              ? "QUESTIONNAIRE"
              : requiresVisitorImage
              ? "VISITOR_IMAGE"
              : "SUBMIT"
          }
          form={form}
          setForm={setForm}
        >
          <Typography
            variant="h1"
            css={{
              fontSize: "2rem",
              fontWeight: 400,
              marginBottom: "3rem",
              color: "#02133E",
            }}
          >
            {t("what-are-you-here-for")}
          </Typography>
          <ToggleButtonGroup
            value={form.kind}
            color="primary"
            exclusive
            onChange={(_e, v) => v && setForm({ ...form, kind: v })}
            aria-label="text alignment"
          >
            {KINDS.map((v) => (
              <ToggleButton key={v.name} value={v.name} aria-label={v.name}>
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    width: "4.5rem",
                  }}
                >
                  <div>{v.icon}</div>
                  <div>{t(v.name)}</div>
                </div>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </FormStep>
        <FormStep
          curState="VACCINATION_IMAGE"
          nextState={
            requiresQuestionnaire
              ? "QUESTIONNAIRE"
              : form.visitInfo?.visitorImage
              ? "SUBMIT"
              : "VISITOR_IMAGE"
          }
          nextIsDisabled={!form.vaccinationImage}
          form={form}
          setForm={setForm}
        >
          {t("vaccination-image-instructions")}
          <CameraStep
            image={form.vaccinationImage}
            setImage={(i) =>
              setForm((f) => ({ ...f, vaccinationImage: i ?? undefined }))
            }
            facingMode={
              registrationInfo.cameraDir == "FRONT" ? "user" : "environment"
            }
            kind="vaccination photo"
          />
        </FormStep>

        <FormStep curState="QUESTIONNAIRE" form={form} setForm={setForm}>
          <QuestionnaireStep
            registrationInfo={registrationInfo}
            vaccinationStatus={
              form?.visitInfo?.vaccinationStatus ?? VACCINATION_STATUS.UNKNOWN
            }
            onSuccess={() =>
              setForm({
                ...form,
                questionnaireCompleted: true,
                state: {
                  ...form.state,
                  current:
                    form.visitInfo?.visitorImage || !requiresVisitorImage
                      ? "SUBMIT"
                      : "VISITOR_IMAGE",
                },
              })
            }
          />
        </FormStep>
        <FormStep
          curState="VISITOR_IMAGE"
          nextState="SUBMIT"
          nextIsDisabled={!form.visitorImage}
          form={form}
          setForm={setForm}
        >
          {t("visitor-image-instructions")}
          <CameraStep
            image={form.visitorImage}
            setImage={(i) =>
              setForm((f) => ({ ...f, visitorImage: i ?? undefined }))
            }
            facingMode={
              registrationInfo.cameraDir == "FRONT" ? "user" : "environment"
            }
            showFaceOverlay={true}
            kind="visitor"
          />
        </FormStep>
        <FormStep
          curState="SUBMIT"
          hideBackButton={true}
          form={form}
          setForm={setForm}
        >
          <SubmissionStep
            form={form}
            registrationInfo={registrationInfo}
            resetForm={() => setForm(defaultForm)}
          />
        </FormStep>
        <FormStep
          curState="COMPANY_NAME"
          nextState={
            requiresVaccinationImage
              ? "VACCINATION_IMAGE"
              : requiresQuestionnaire
              ? "QUESTIONNAIRE"
              : requiresVisitorImage
              ? "VISITOR_IMAGE"
              : "SUBMIT"
          }
          form={form}
          setForm={setForm}
        >
          <CompanyNameStep
            form={form}
            setForm={setForm}
            registrationInfo={registrationInfo}
          />
        </FormStep>
      </div>
    </>
  );
}
