import { CalendarDate } from "zynq-shared";
import { DateTime } from "luxon";
import i18next from "i18next";

export enum SEAT_STATES {
  AVAILABLE = "AVAILABLE",
  DISABLED = "DISABLED",
  OCCUPIED = "OCCUPIED",
  RESERVED = "RESERVED",
  YOURS = "YOURS",
  YOURS_RESERVED = "YOURS_RESERVED",
  CHECKIN = "CHECKIN",
  AUTO_ASSIGN = "AUTO_ASSIGN",
}

export const RESOURCE_TYPES = {
  DESK: "DESK" as const,
  PARKING: "PARKING" as const,
  TRAINING_DESK: "TRAINING_DESK" as const,
  LOCKER: "LOCKER" as const,
  LAB_BENCH: "LAB_BENCH" as const,
  TC_HOOD: "TC_HOOD" as const,
  MOTHERS_ROOM: "MOTHERS_ROOM" as const,
  EQUIPMENT: "EQUIPMENT" as const,
};
export type RESOURCE_TYPES = keyof typeof RESOURCE_TYPES;

export enum VACCINATION_STATUS {
  UNKNOWN = 0,
  YES = 1,
  NO = 2,
  DECLINE = 3,
}

export const VACCINATION_STATUS_MEMBERS = Object.values(
  VACCINATION_STATUS
).filter((v): v is VACCINATION_STATUS => !isNaN(Number(v)));

export const MEETING_ROOM_INFO_MAX = 21;

export const ZYNQ_APP_AUTH_CONTEXT_HEADER = "Zynq-App-Auth-Context";

export const HEADER_HEIGHT = "65px";

export const IS_DEV = process.env.REACT_APP_ZYNQ_ENV == "development";

export let SLACK_CLIENT_ID = "656570858002.1934049322613"; // Not secret

export let MS_CLIENT_ID = "786d3bc3-4cb0-4be3-b1d5-030dc883a58a"; // Fallback to prod;
if (process.env.REACT_APP_ZYNQ_ENV == "development") {
  MS_CLIENT_ID = "70799da4-6095-491f-9431-fa6c7a6c4824";
  SLACK_CLIENT_ID = "656570858002.1981183890005";
} else if (process.env.REACT_APP_ZYNQ_ENV == "production") {
  MS_CLIENT_ID = "786d3bc3-4cb0-4be3-b1d5-030dc883a58a";
} else {
  console.error(
    "No MS_CLIENT_ID set for REACT_APP_ZYNQ_ENV (environment variable): " +
      process.env.REACT_APP_ZYNQ_ENV
  );
}

export const ZYNQ_DEMO_DOMAINS = [
  "zynq-test.com",
  "zynq-workspace.com",
  "zynq-zach.com",
  "whitelabel-zach.com",
];

export const NODEJS_BASE_URL = "";

export const CLIENT_VERSION = process.env.ZYNQ_CLIENT_VERSION ?? "0-0";
if (!CLIENT_VERSION) {
  console.error("No CLIENT_VERSION set");
}

export const GOOGLE_CLIENT_ID =
  "330652174175-1e2pkndi5fmb0mf90dp75akj4lpfaa2p.apps.googleusercontent.com";

export const DEFAULT_LANDING = "/seating/floorplan";

export const DEFAULT_ONBOARDING_LANDING = "/admin/onboarding";

export const DATE_FMT = "yyyy-MM-dd";

export const SENTRY_SESSION_ID = Math.random().toString(36).substr(2, 9);

export const USA_DECIEM_FLOORPLAN_NAMES = [
  "Moonachie",
  "San Francisco, Union",
  "Chicago, Wicker Park",
  "New York Office",
  "Fifth Avenue",
  "Nolita",
  "Soho",
  "Flatiron",
  "Brooklyn",
  "Upper West Side",
  "Madison Avenue",
  "Spring Street",
];

export function resourceName(r?: RESOURCE_TYPES): string {
  if (!r) return "";
  switch (r) {
    case RESOURCE_TYPES.DESK:
      return i18next.t("desk");
    case RESOURCE_TYPES.PARKING:
      return i18next.t("parking-spot");
    case RESOURCE_TYPES.TRAINING_DESK:
      return i18next.t("training-desk");
    case RESOURCE_TYPES.LOCKER:
      return i18next.t("locker");
    case RESOURCE_TYPES.LAB_BENCH:
      return i18next.t("lab-bench");
    case RESOURCE_TYPES.TC_HOOD:
      return i18next.t("tc-hood");
    case RESOURCE_TYPES.MOTHERS_ROOM:
      return i18next.t("mothers-room");
    case RESOURCE_TYPES.EQUIPMENT:
      return i18next.t("equipment");
  }
}

export function resourceNamePlural(r: RESOURCE_TYPES): string {
  switch (r) {
    case RESOURCE_TYPES.DESK:
      return i18next.t("desks");
    case RESOURCE_TYPES.PARKING:
      return i18next.t("parking-spots");
    case RESOURCE_TYPES.TRAINING_DESK:
      return i18next.t("training-desks");
    case RESOURCE_TYPES.LOCKER:
      return i18next.t("lockers");
    case RESOURCE_TYPES.LAB_BENCH:
      return i18next.t("lab-benches");
    case RESOURCE_TYPES.TC_HOOD:
      return i18next.t("tc-hoods");
    case RESOURCE_TYPES.MOTHERS_ROOM:
      return i18next.t("mothers-rooms");
    case RESOURCE_TYPES.EQUIPMENT:
      return i18next.t("equipment");
  }
}

export default {
  headerHeight: HEADER_HEIGHT,
  seatStates: SEAT_STATES,
  dateFormat: DATE_FMT,
} as const;
