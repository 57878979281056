import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import {
  Snackbar,
  SnackbarContent,
  Button,
  Slide,
  CircularProgress,
} from "@mui/material";
import { Alert } from "@mui/material";
import mixpanel from "mixpanel-browser";
import { CalendarDate, Seating, TimeInterval } from "zynq-shared";

const Admin = React.lazy(
  () =>
    import(
      /* webpackChunkName: "admin" */
      /* webpackPrefetch: true */
      "./admin/index"
    )
);

const OnboardingMain = React.lazy(
  () =>
    import(
      /* webpackChunkName: "onboarding" */
      /* webpackPrefetch: true */
      "./admin/onboarding/main"
    )
);

const MeetingApp = React.lazy(
  () =>
    import(
      /* webpackChunkName: "meeting-app" */
      /* webpackPrefetch: true */
      "./meeting-app/meetingApp"
    )
);

const FloorplanLive = React.lazy(
  () =>
    import(
      /* webpackChunkName: "floorplan-live" */
      /* webpackPrefetch: true */
      "./floorplan-live/floorplanLive"
    )
);

import BasicHeader from "./basicHeader";
import FloorplanPage from "./floorplanPage";
import LoginPage from "./loginPage";
import MsftAdminRegister from "./msftAdminRegister";
import ConciergeRegisterVisitPage from "./conciergeRegisterVisitPage";
import TestEmail from "./testEmail";
import ScheduleVisitPage from "./scheduleVisitPage";
import ShowQRPage from "./showQRPage";
import ConciergeLogin from "./conciergeLogin";
import Theme from "./theme";
import VerifyVisitPage from "./verifyVisitPage";
import ScheduledVisitCheckin from "./scheduledVisitCheckin";
import { VACCINATION_STATUS } from "./constants";
import { useAPIRequest, useError } from "./hooks";
import { AppAuthContext, ErrorDialog } from "./providers";
import ConvertSvg from "./convertSVG";
import UploadImage from "./uploadImageSuperAdmin";
import SandboxCreator from "./sandboxCreator";
import EditSeats from "./editSeats";
import type { UserInfo } from "./types";
import { chunk } from "./admin/weekdayPicker";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import Preferences from "./preferences/preferencesIndex";

function Loader() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress size="5rem" />
    </div>
  );
}

function removeField<T, K extends keyof T>(t: T, k: K): Omit<T, K> {
  const copy = { ...t };
  delete copy[k];
  return copy;
}

function IfEnabled({
  enabled,
  children,
}: {
  enabled: boolean;
  children: any;
}): JSX.Element {
  return enabled ? children : <Redirect to={"/seating/floorplan/"} />;
}

function ThemedApp({}) {
  const { t } = useTranslation();
  const showQRToken = useRouteMatch<{ token?: string }>({
    path: "/visitor/showQR/:token",
    strict: true,
  });
  const verifyToken = useRouteMatch<{ token?: string }>({
    path: "/visitor/verify/:token",
    strict: true,
  });
  const visitorToken = useRouteMatch<{ token?: string }>({
    path: "/visitor/checkin/:token",
    strict: true,
  });

  const [userInfo, setUserInfo] = React.useState<
    UserInfo & { verifyError?: string }
  >();
  const { postNode } = useAPIRequest();
  const showError = useError();
  const { i18n } = useTranslation();

  const postBody = {
    registrationToken:
      showQRToken?.params?.token ||
      verifyToken?.params?.token ||
      visitorToken?.params?.token,
  };

  const loadUserData = () => {
    const { promise, cancel } = postNode(Seating.UserInfo, postBody);
    promise.then((res) => {
      if (res.status === "success") {
        mixpanel.identify(res.email);
        mixpanel.set_config({ test: res.isTestUser });
        setUserInfo({
          avatar: { name: res.name, src: res.pictureURL, email: res.email },
          domain: res.domain,
          isTestUser: res.isTestUser,
          isAdmin: res.isAdmin,
          isUserAdmin: res.isUserAdmin,
          isManager: res.isManager,
          isSuperAdmin: res.isSuperAdmin,
          isStaff: res.isStaff,
          userSchedule: chunk(res.userSchedule as boolean[], 7),
          allowedHours: TimeInterval.fromISOTimes(
            res.allowedHours.start,
            res.allowedHours.end
          ).assertValid(),
          vaccinationStatus: res.vaccinationStatus,
          languageCode: res.languageCode,
          defaultFloorplanID: res.defaultFloorplanID,
        });

        if (i18n.resolvedLanguage) {
          document.documentElement.lang = i18n.resolvedLanguage;
          if (i18n.resolvedLanguage != "en" || i18n.language.startsWith("en")) {
            // Either we found a matching language for you, or your top default is english, so we turn off translate
            // so that chrome doesn't mistreat our DOM (doesn't play nice with React)
            document.documentElement.translate = false;
          }
        }

        if (
          i18n.language &&
          res.languageCode &&
          !postBody.registrationToken &&
          i18n.language != res.languageCode
        ) {
          postNode(Seating.UpdateProfileLanguage, {
            languageCode: i18n.language,
          });
        } else if (postBody.registrationToken) {
          i18n.changeLanguage(res.languageCode);
        }
      } else {
        if (
          postBody.registrationToken != null &&
          res.reason == "invalid token"
        ) {
          // Careful now
          setUserInfo({
            avatar: { name: "?", src: "", email: "?@?" },
            domain: {
              name: "",
              isSandbox: false,
              timeBasedBookingsEnabled: false,
              scheduledVisitorsEnabled: false,
              buddySyncEnabled: false,
              meetingWizardEnabled: false,
              managerBookingEnabled: false,
              roomsEnabled: false,
              logoSrc: "",
              logoInvertedSrc: "",
              headerColor: "#1dd7ba",
              primaryColor: "#1dd7ba",
              secondaryColor: "#4A4A4A",
              gsCalAccessGranted: false,
              allowFloorplanMeetingBooking: false,
              showOverdueWarning: false,
              defaultWorkingHoursStart: "08:00",
              defaultWorkingHoursEnd: "17:00",
              gsEnabled: false,
            },
            isTestUser: false,
            isManager: false,
            isAdmin: false,
            isUserAdmin: false,
            isSuperAdmin: false,
            isStaff: false,
            userSchedule: [[false, false, false, false, false, false, false]],
            allowedHours: TimeInterval.allDay,
            vaccinationStatus: VACCINATION_STATUS.UNKNOWN,
            verifyError: res.reason,
            languageCode: "en",
            defaultFloorplanID: undefined,
          });
        } else {
          showError(res.reason);
        }
      }
    });
    return { promise, cancel };
  };

  const timeoutInfo = React.useRef<{
    id?: ReturnType<typeof setTimeout>;
    cancel?: () => void;
  }>({});
  function refetchUserData() {
    timeoutInfo.current.id = undefined;
    const { cancel, promise } = loadUserData();
    timeoutInfo.current.cancel = cancel;
    promise.then(() => {
      timeoutInfo.current.cancel = undefined;
      timeoutInfo.current.id = setTimeout(refetchUserData, 3600000 /*1 hour*/);
    });
  }

  React.useEffect(() => {
    refetchUserData();
    return () => {
      if (timeoutInfo.current.id) {
        clearTimeout(timeoutInfo.current.id);
      }
      if (timeoutInfo.current.cancel) {
        timeoutInfo.current.cancel();
      }
    };
  }, [postBody.registrationToken]);

  if (userInfo != null && userInfo.verifyError != null) {
    return (
      <Switch>
        <Route exact path="/visitor/verify/:token">
          <VerifyVisitPage
            error={userInfo.verifyError}
            userInfo={removeField(userInfo, "avatar")}
          />
        </Route>
        <Route>
          <h1 style={{ display: "flex", justifyContent: "center" }}>
            {t("error-loading-user-information")}
          </h1>
        </Route>
      </Switch>
    );
  }

  return userInfo ? (
    <Theme domain={userInfo.domain}>
      <React.Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path="/visitor/verify/:token">
            <AppAuthContext.Provider value="concierge">
              <VerifyVisitPage userInfo={removeField(userInfo, "avatar")} />
            </AppAuthContext.Provider>
          </Route>
          <Route exact path="/visitor/showQR/:token">
            <AppAuthContext.Provider value="concierge">
              <BasicHeader userInfo={userInfo} title="Entry Code" />
              <ShowQRPage />
            </AppAuthContext.Provider>
          </Route>
          <Route exact path="/visitor/checkin/:token">
            <AppAuthContext.Provider value="concierge">
              <ScheduledVisitCheckin
                userInfo={userInfo}
                visitToken={visitorToken?.params?.token}
              />
            </AppAuthContext.Provider>
          </Route>
          <Route exact path="/seating/floorplan/">
            <AppAuthContext.Provider value="user">
              <FloorplanPage userInfo={userInfo} />
            </AppAuthContext.Provider>
          </Route>
          <Route
            exact
            path="/seating/floorplan/:id/:date"
            render={({
              match: {
                params: { id, date },
              },
            }) => {
              const parsed = CalendarDate.fromISODate(date);
              return (
                <AppAuthContext.Provider value="user">
                  <FloorplanPage
                    userInfo={userInfo}
                    date={parsed.isValid ? parsed : undefined}
                    floorplanID={parseInt(id)}
                  />
                </AppAuthContext.Provider>
              );
            }}
          />
          <Route
            exact
            path="/seating/floorplan/:dateOrID"
            render={({
              match: {
                params: { dateOrID },
              },
            }) => {
              const parsed = CalendarDate.fromISODate(dateOrID);
              if (parsed.isValid) {
                return (
                  <AppAuthContext.Provider value="user">
                    <FloorplanPage userInfo={userInfo} date={parsed} />
                  </AppAuthContext.Provider>
                );
              } else {
                return (
                  <AppAuthContext.Provider value="user">
                    <FloorplanPage
                      userInfo={userInfo}
                      floorplanID={parseInt(dateOrID)}
                    />
                  </AppAuthContext.Provider>
                );
              }
            }}
          />
          <Route exact path="/seating/test-email">
            <AppAuthContext.Provider value="user">
              <IfEnabled enabled={userInfo.isSuperAdmin}>
                <BasicHeader userInfo={userInfo} title="Test Email" />
                <TestEmail />
              </IfEnabled>
            </AppAuthContext.Provider>
          </Route>
          <Route exact path="/seating/test-email/:token">
            <AppAuthContext.Provider value="user">
              <IfEnabled enabled={userInfo.isSuperAdmin}>
                <BasicHeader userInfo={userInfo} title="Test Email" />
                <TestEmail />
              </IfEnabled>
            </AppAuthContext.Provider>
          </Route>
          <Route exact path="/seating/convert-svg">
            <AppAuthContext.Provider value="user">
              <IfEnabled enabled={userInfo.isSuperAdmin || userInfo.isStaff}>
                <BasicHeader userInfo={userInfo} title="Convert SVG" />
                <ConvertSvg userInfo={userInfo} />
              </IfEnabled>
            </AppAuthContext.Provider>
          </Route>
          <Route exact path="/seating/create-sandbox">
            <AppAuthContext.Provider value="user">
              <IfEnabled enabled={userInfo.isSuperAdmin}>
                <BasicHeader userInfo={userInfo} title="Create Sandbox" />
                <SandboxCreator userInfo={userInfo} />
              </IfEnabled>
            </AppAuthContext.Provider>
          </Route>
          <Route exact path="/seating/upload-image">
            <AppAuthContext.Provider value="user">
              <IfEnabled enabled={userInfo.isSuperAdmin || userInfo.isStaff}>
                <BasicHeader userInfo={userInfo} title="Upload Image" />
                <UploadImage userInfo={userInfo} />
              </IfEnabled>
            </AppAuthContext.Provider>
          </Route>
          <Route exact path="/seating/edit-seats">
            <AppAuthContext.Provider value="user">
              <IfEnabled enabled={userInfo.isSuperAdmin || userInfo.isStaff}>
                <BasicHeader userInfo={userInfo} title="Edit Seats" />
                <EditSeats />
              </IfEnabled>
            </AppAuthContext.Provider>
          </Route>
          <Route exact path="/visitor/schedule">
            <AppAuthContext.Provider value="user">
              <BasicHeader
                userInfo={userInfo}
                title={t("schedule-a-visitor")}
              />
              <ScheduleVisitPage userInfo={userInfo} />
            </AppAuthContext.Provider>
          </Route>
          <Route path="/admin/onboarding">
            <AppAuthContext.Provider value="user">
              <OnboardingMain
                userInfo={userInfo}
                reloadUserInfo={loadUserData}
              />
            </AppAuthContext.Provider>
          </Route>
          <Route path="/admin/">
            <AppAuthContext.Provider value="user">
              <Admin userInfo={userInfo} reloadUserInfo={loadUserData} />
            </AppAuthContext.Provider>
          </Route>
          <Route path="/preferences/">
            <AppAuthContext.Provider value="user">
              <Preferences userInfo={userInfo} reloadUserInfo={loadUserData} />
            </AppAuthContext.Provider>
          </Route>
          <Route>
            <h1 style={{ display: "flex", justifyContent: "center" }}>404</h1>
          </Route>
        </Switch>
      </React.Suspense>
    </Theme>
  ) : (
    <Loader />
  );
}

function App({}) {
  const { t } = useTranslation();
  const [globalError, setGlobalError] = React.useState<string | null>(null);

  React.useEffect(() => {
    window.showError = (err: string) => {
      setGlobalError(err);
    };
  }, []);

  const handleClose = (
    event: Event | React.SyntheticEvent,
    reason: string | undefined = undefined
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setGlobalError(null);
  };

  return (
    <>
      <ErrorDialog.Provider
        value={[
          globalError,
          (e) => {
            setGlobalError(e);
          },
        ]}
      >
        <div css={{ width: "100%", height: "100%" }}>
          <Switch>
            <Route exact path="/seating/login">
              <AppAuthContext.Provider value="user">
                <LoginPage />
              </AppAuthContext.Provider>
            </Route>
            <Route exact path="/seating/admin-login">
              <AppAuthContext.Provider value="user">
                <LoginPage admin />
              </AppAuthContext.Provider>
            </Route>
            <Route exact path="/seating/msftadmin-register">
              <AppAuthContext.Provider value="user">
                <MsftAdminRegister />
              </AppAuthContext.Provider>
            </Route>
            <Route exact path="/concierge/login">
              <AppAuthContext.Provider value="concierge">
                <ConciergeLogin />
              </AppAuthContext.Provider>
            </Route>
            <Route exact path="/concierge/register">
              <AppAuthContext.Provider value="concierge">
                <ConciergeRegisterVisitPage />
              </AppAuthContext.Provider>
            </Route>
            <Route path="/concierge/register/:code">
              <AppAuthContext.Provider value="concierge">
                <ConciergeRegisterVisitPage />
              </AppAuthContext.Provider>
            </Route>
            <Route path="/concierge/floorplan/live">
              <AppAuthContext.Provider value="concierge">
                <React.Suspense fallback={<Loader />}>
                  <FloorplanLive />
                </React.Suspense>
              </AppAuthContext.Provider>
            </Route>
            <Route path="/meeting-app">
              <AppAuthContext.Provider value="rooms">
                <Theme>
                  <React.Suspense fallback={<Loader />}>
                    <MeetingApp />
                  </React.Suspense>
                </Theme>
              </AppAuthContext.Provider>
            </Route>
            <Route>
              <ThemedApp />
            </Route>
          </Switch>
        </div>
      </ErrorDialog.Provider>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={!!globalError}
        onClose={handleClose}
        TransitionComponent={Slide}
      >
        {globalError ? (
          globalError.match(/refresh/i) ? (
            <SnackbarContent
              message={globalError}
              action={
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => window.location.reload()}
                >
                  {t("refresh")}
                </Button>
              }
            />
          ) : (
            <Alert
              css={{ minWidth: "20rem" }}
              severity="error"
              onClose={handleClose}
            >
              {globalError}
            </Alert>
          )
        ) : (
          // need to do some funny casting here to get around MUI types
          (null as unknown as React.ReactElement<any, any>)
        )}
      </Snackbar>
    </>
  );
}

export default App;
