import React from "react";
import Cookies from "js-cookie";
import {
  Breadcrumbs,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  DialogActions,
  Tooltip,
} from "@mui/material";
import {
  Search as SearchIcon,
  LocationOn as LocationOnIcon,
  MoreHoriz as MoreIcon,
  Domain as DoneIcon,
  Clear as ClearIcon,
  VisibilityOff as AdminOnlyIcon,
} from "@mui/icons-material";
import { useAPIRequest, useAPIMutation, useError } from "./hooks";
import { useTranslation } from "react-i18next";
import { Seating } from "zynq-shared";

export type Region = {
  isRegion: true;
  name: string;
  id: number;
  tag?: string;
  children: LocationType[];
};

export type RegionInput = {
  isRegion: true;
  name: string;
  id: number;
  children?: (FloorplanInput | RegionInput)[];
};

export type FloorplanInput = {
  isRegion: false;
  name: string;
  id: number;
  tag?: string;
  timezone?: string;
  userVisible: boolean;
};

export type Floorplan = {
  isRegion: false;
  name: string;
  id: number;
  timezone: string;
  userVisible: boolean;
};

export type LocationType = Region | Floorplan;

type SelectedLocation = {
  current: LocationType;
  parents: Region[];
};

function LocationBreadcrumb(props: {
  building: SelectedLocation;
  setLocation: (l?: SelectedLocation) => void;
  enableSaveMyChoice: boolean;
  saveMyChoice: boolean;
  setSaveMyChoice: (b: boolean) => void;
}) {
  const { t } = useTranslation();
  return (
    <div
      css={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Breadcrumbs aria-label="breadcrumb" maxItems={3} itemsBeforeCollapse={1}>
        {props.building.parents
          .map((p, i) => (
            <Button
              size={"small"}
              key={p.id}
              css={{ textDecoration: "underline" }}
              onClick={() =>
                props.setLocation({
                  current: p,
                  parents: props.building.parents.slice(i + 1),
                })
              }
            >
              {p.name}
            </Button>
          ))
          .reverse()}
        <Button size={"small"} disabled>
          <span css={{ color: "rgba(0, 0, 0, 0.87)" }}>
            {props.building.current?.name}
          </span>
        </Button>
      </Breadcrumbs>
      {props.enableSaveMyChoice && (
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              checked={props.saveMyChoice}
              onChange={(e, checked) => {
                props.setSaveMyChoice(checked);
              }}
            />
          }
          sx={{ color: "grey.900" }}
          label={t("save-my-choice")}
          labelPlacement="end"
        />
      )}
    </div>
  );
}

// Normalizes case, unicode and removes accents
function normalizeString(str: string) {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

export function getFlatLocations(
  l: LocationType
): { key: string; floorplan: Floorplan; parents: Region[] }[] {
  if (l.isRegion == true) {
    return l.children
      .flatMap(getFlatLocations)
      .map(({ key: s, floorplan, parents }) => ({
        key: `${l.name} ${s}`,
        floorplan,
        parents: [l, ...parents],
      }));
  } else {
    return [{ key: l.name, floorplan: l, parents: [] }];
  }
}

function locationSearch(query: string, locations: LocationType[]) {
  const queryParts = normalizeString(query).split(" ");

  return locations
    .flatMap(getFlatLocations)
    .flatMap(({ key, floorplan, parents }) => {
      const locationName = normalizeString(key);
      const score = queryParts.filter((q) => locationName.includes(q)).length;
      return score ? [{ location: floorplan, parents, score }] : [];
    })
    .sort((a, b) => {
      const cmpScore = b.score - a.score;
      return cmpScore !== 0
        ? cmpScore
        : a.location.name.localeCompare(b.location.name, undefined, {
            numeric: true,
          });
    });
}

function CurrentName(props: { text: string; current: boolean }) {
  const { t } = useTranslation();
  if (!props.current) return <>{props.text}</>;
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div>{props.text}</div>
      <div
        css={{ textTransform: "capitalize", color: "gray", fontSize: "90%" }}
      >
        {t("current-in-parens")}
      </div>
    </div>
  );
}

function findCurrentLocation(
  locations: LocationType[],
  selected: { isRegion: boolean; id: number }
): SelectedLocation | undefined {
  for (const location of locations) {
    if (location.isRegion == selected.isRegion && location.id == selected.id) {
      return { current: location, parents: [] };
    }
    if (location.isRegion) {
      const current = findCurrentLocation(location.children, selected);
      if (current) {
        return { ...current, parents: [...current.parents, location] };
      }
    }
  }
  return undefined;
}

function calculateFloorplanCount(l: LocationType): number {
  return !l.isRegion
    ? 1
    : l.children.map(calculateFloorplanCount).reduce((a, b) => a + b, 0);
}

function findFirstFloorplan(locations: LocationType[]): Floorplan | null {
  for (const l of locations) {
    if (l.isRegion == false) return l;
    const maybe = findFirstFloorplan(l.children);
    if (maybe) {
      return maybe;
    }
  }
  return null;
}

function findFloorplanByID(
  id: string | null | undefined,
  locations: LocationType[]
): Floorplan | null {
  if (!id) {
    return null;
  }
  for (const l of locations) {
    if (l.isRegion == false) {
      if (l.id == +id) {
        return l;
      }
    } else {
      const maybe = findFloorplanByID(id, l.children);
      if (maybe) {
        return maybe;
      }
    }
  }
  return null;
}

function regionOnlyFilter(locations: LocationType[]): Region[] {
  const regions: Region[] = locations.filter((l): l is Region => l.isRegion);
  return regions.map((r) => ({
    ...r,
    children: regionOnlyFilter(r.children),
  }));
}

export default function LocationPicker(
  props: (
    | {
        allowRegion: false;
        selected: FloorplanInput | null;
        onChange: (location: Floorplan | null) => void;
        pickerRef?: React.Ref<{
          openWithLocation: (location: {
            id: number;
            isRegion: boolean;
          }) => void;
        }>;
      }
    | {
        allowRegion: true;
        regionSelectTextPrefix?: string;
        selected: FloorplanInput | RegionInput | null;
        onChange: (location: LocationType | null) => void;
        pickerRef?: React.Ref<{
          openWithLocation: (location: {
            id: number;
            isRegion: boolean;
          }) => void;
        }>;
      }
  ) & {
    nullable?: boolean;
    regionOnly?: boolean;
    className?: string;
    emptyText?: string;
    loadingText?: string;
    autoPickIfNull?: boolean;
    hideClearButton?: boolean;
    excludeDropin?: boolean;
    defaultOpen?: boolean;
    disabled?: boolean;
    makeDefaultButtons?: {
      renderMakeDefaultButton: boolean;
      defaultFloorplanID?: number; // If set, the current default floorplan ID
    };
  }
) {
  const { t } = useTranslation();
  const api = useAPIRequest();
  const showError = useError();
  const [allLocations, setAllLocations] = React.useState<Region>();
  const emptyText = props.emptyText ?? "No Location";
  const loadingText = props.loadingText ?? t("loading");
  const [open, setOpen] = React.useState(!!props.defaultOpen);
  const setDefaultFloorplan = useAPIMutation<typeof Seating.SetDefaultLocation>(
    "/apis/seating/set-default-location",
    {
      onSuccess: () => {
        // Hard reload to re-fetch user info -- shouldn't happen often
        window.location.reload();
      },
    }
  );
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const theme = useTheme();
  const lastFloorplanID = Cookies.get("floorplanAdminID");
  const [selectedLocation, setSelectedLocation] =
    React.useState<SelectedLocation>();
  const [openWithLocationRequest, setOpenWithLocationRequest] = React.useState<{
    id: number;
    isRegion: boolean;
  } | null>();
  const [saveDefaultFloorplan, setSaveDefaultFloorplan] = React.useState(false);

  React.useImperativeHandle(props.pickerRef, () => ({
    openWithLocation: (location: { id: number; isRegion: boolean }) => {
      setOpen(true);
      setOpenWithLocationRequest(location);
    },
  }));

  // useEffect, if locations are loaded & selectedLocationID is true, set the selected

  // Current floorplan can either be from props.selected,

  let currentFloorplan: SelectedLocation | undefined;
  if (props.selected && allLocations) {
    currentFloorplan = findCurrentLocation(
      allLocations.children,
      props.selected
    );
  }
  if (currentFloorplan && allLocations)
    currentFloorplan.parents.push(allLocations);

  const floorplanCount = allLocations && calculateFloorplanCount(allLocations);

  React.useEffect(() => {
    const { promise, cancel } = api.getNode(Seating.LocationPickerInfo, {
      excludeDropinOnly: props.excludeDropin ?? false,
    });
    promise.then((res) => {
      if (res.status == "failed")
        return showError(
          t("something-went-wrong-with-msg", { msg: res.reason })
        );
      setAllLocations({
        name: t("all-locations"),
        id: -1,
        children: props.regionOnly
          ? regionOnlyFilter(res.locations)
          : res.locations,
        isRegion: true,
      });

      if (
        props.nullable &&
        !props.allowRegion &&
        props.autoPickIfNull &&
        props.selected == null
      ) {
        let floorplan = findFloorplanByID(lastFloorplanID, res.locations);
        if (!floorplan) {
          floorplan = findFirstFloorplan(res.locations);
        }
        props.onChange(floorplan);
      }
    });
    return cancel;
  }, []);

  React.useEffect(() => {
    if (allLocations && openWithLocationRequest) {
      // Find the requested location and set as selected location:
      console.log("openWithLocationRequest: ", openWithLocationRequest);
      const newCurrentLocation = findCurrentLocation(
        [allLocations],
        openWithLocationRequest
      );
      console.log("Eval'd new curr loc: ", newCurrentLocation);
      console.log("ALL: ", allLocations);
      if (newCurrentLocation) {
        if (newCurrentLocation.current.isRegion) {
          setSelectedLocation(newCurrentLocation);
        } else if (newCurrentLocation?.parents.length) {
          // If opened the picker selecting a floorplan, select the parent
          setSelectedLocation({
            current: newCurrentLocation.parents[0],
            parents: newCurrentLocation.parents.slice(1),
          });
        } else {
          setSelectedLocation({ current: allLocations, parents: [] });
        }
      } else {
        setSelectedLocation({ current: allLocations, parents: [] });
      }
      setOpenWithLocationRequest(null);
    }
  }, [allLocations, openWithLocationRequest]);

  function openToParent(open?: boolean) {
    open && setOpen(true);
    setSearchQuery("");
    if (currentFloorplan) {
      setSelectedLocation({
        current: currentFloorplan.parents[0],
        parents: currentFloorplan.parents.slice(1),
      });
    } else if (allLocations) {
      setSelectedLocation({
        current: allLocations,
        parents: [],
      });
    }
  }

  React.useEffect(() => {
    open && openToParent();
  }, [props.selected?.id, allLocations]);

  const isSearch = searchQuery.length > 0;

  const buttonCss = {
    margin: "0.5rem",
    height: "6rem",
    width: "9rem",
    [theme.breakpoints.up("sm")]: {
      height: "7rem",
      width: "11rem",
    },
    backgroundColor: "white",
  } as const;

  const searchResults = locationSearch(
    searchQuery,
    selectedLocation?.current.isRegion ? selectedLocation.current.children : []
  );

  const locationIcon =
    props.selected &&
    !props.selected.isRegion &&
    !props.selected.userVisible ? (
      <Tooltip title={t("admin-only")}>
        <AdminOnlyIcon />
      </Tooltip>
    ) : (
      <LocationOnIcon css={{ color: "#c73828" }} />
    );

  return (
    <>
      <Button
        startIcon={
          props.nullable && !props.hideClearButton ? locationIcon : undefined
        }
        endIcon={
          props.nullable && !props.hideClearButton ? (
            props.selected ? (
              <ClearIcon
                titleAccess={t("clear")}
                onClick={(e) => {
                  props.onChange(null);
                  e.stopPropagation();
                }}
              />
            ) : undefined
          ) : (
            locationIcon
          )
        }
        onClick={() => openToParent(true)}
        disabled={props.disabled || (floorplanCount === 1 && !props.nullable)}
        variant="contained"
        className={props.className}
        style={{
          backgroundColor: floorplanCount === 1 ? "transparent" : undefined,
          color: floorplanCount === 1 ? "black" : undefined,
        }}
        css={{
          backgroundColor: floorplanCount === 1 ? "transparent" : "white",
          color: "black",
        }}
      >
        <span
          css={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {allLocations == undefined
            ? loadingText
            : props.selected?.name ?? emptyText}
        </span>
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth={"xs"}
        onClose={() => setOpen(false)}
        PaperProps={{ style: { backgroundColor: "#f9f9f9" } }}
      >
        <DialogTitle css={{ alignItems: "center" }}>
          {selectedLocation && (
            <>
              <LocationBreadcrumb
                building={selectedLocation}
                enableSaveMyChoice={
                  !!(
                    props.makeDefaultButtons?.renderMakeDefaultButton &&
                    !props.makeDefaultButtons.defaultFloorplanID
                  )
                }
                saveMyChoice={saveDefaultFloorplan}
                setSaveMyChoice={setSaveDefaultFloorplan}
                setLocation={(b) => {
                  setSelectedLocation(b);
                  setSearchQuery("");
                }}
              />
            </>
          )}
        </DialogTitle>
        <DialogContent
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflowX: "hidden",
            paddingBottom: "0rem",
          }}
        >
          <TextField
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: <SearchIcon css={{ marginRight: "0.5rem" }} />,
            }}
            label={
              selectedLocation?.current && selectedLocation.current.id != -1
                ? t("search-locations-name", {
                    name: selectedLocation.current.name,
                  })
                : t("search-locations")
            }
            fullWidth
            css={{ margin: "1rem", marginTop: "0.5rem" }}
          />
          <div css={{ paddingBottom: "1rem" }}>
            <div
              css={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
                minHeight: "8rem",
              }}
            >
              {isSearch &&
                searchResults.length > 0 &&
                searchResults.map((l) => (
                  <Button
                    variant="contained"
                    css={buttonCss}
                    key={l.location.name}
                    onClick={() => {
                      if (l.location.isRegion) {
                        setSelectedLocation({
                          current: l.location,
                          parents: l.parents,
                        });
                      } else {
                        setOpen(false);
                        props.onChange(l.location);
                      }
                    }}
                  >
                    {/* // TODO display better parent names? */}
                    {l.parents[0] ? (
                      <div css={{ display: "flex", flexDirection: "column" }}>
                        <div
                          css={{
                            textTransform: "capitalize",
                            fontStyle: "italic",
                            color: "gray",
                            fontSize: "90%",
                          }}
                        >
                          {l.parents[0].name}
                        </div>
                        <div>{l.location.name}</div>
                      </div>
                    ) : (
                      l.location.name
                    )}
                  </Button>
                ))}
              {isSearch && searchResults.length == 0 && (
                <div css={{ alignSelf: "center" }}>{t("no-results-found")}</div>
              )}
              {!isSearch &&
                selectedLocation?.current !== undefined &&
                selectedLocation.current.isRegion &&
                selectedLocation.current.children
                  .sort((l1, l2) =>
                    l1.name.localeCompare(l2.name, undefined, { numeric: true })
                  )
                  .map((l) => (
                    <Tooltip
                      key={`${l.isRegion ? "r" : "f"}-${l.id}`}
                      title={
                        !l.isRegion && !l.userVisible ? t("admin-only") : ""
                      }
                    >
                      <Button
                        variant="contained"
                        css={buttonCss}
                        onClick={() => {
                          if (l.isRegion) {
                            setSelectedLocation({
                              current: l,
                              parents: [
                                selectedLocation.current as Region, // We check above
                                ...selectedLocation.parents,
                              ],
                            });
                          } else {
                            if (saveDefaultFloorplan) {
                              setDefaultFloorplan.mutate({
                                floorplanID: l.id,
                              });
                            } else {
                              setOpen(false);
                            }
                            props.onChange(l);
                          }
                        }}
                      >
                        <div
                          css={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <CurrentName
                            text={l.name}
                            current={
                              currentFloorplan != undefined &&
                              [
                                currentFloorplan.current,
                                ...currentFloorplan.parents,
                              ].some(
                                (f) =>
                                  f != undefined &&
                                  f.isRegion == l.isRegion &&
                                  f.id == l.id
                              )
                            }
                          />
                          {l.isRegion ? (
                            <MoreIcon />
                          ) : l.userVisible ? (
                            <DoneIcon />
                          ) : (
                            <AdminOnlyIcon />
                          )}
                        </div>
                      </Button>
                    </Tooltip>
                  ))}
              {allLocations == undefined && (
                <CircularProgress
                  style={{ marginRight: "1rem", color: "inherit" }}
                  size={25}
                />
              )}
            </div>
          </div>
        </DialogContent>
        {props.nullable && !props.hideClearButton && (
          <DialogActions>
            {(!props.allowRegion || selectedLocation?.current.id == -1) && (
              <Button
                variant={"contained"}
                color="primary"
                onClick={() => {
                  props.onChange(null);
                  setOpen(false);
                }}
              >
                {t("clear-selection")}
              </Button>
            )}
            {props.allowRegion &&
              selectedLocation?.current &&
              selectedLocation?.current.id != -1 && (
                <Button
                  variant={"contained"}
                  color="primary"
                  onClick={() => {
                    props.onChange(selectedLocation?.current ?? null);
                    setOpen(false);
                  }}
                >
                  {props.regionSelectTextPrefix ?? t("filter-by")}{" "}
                  {selectedLocation?.current.name}
                </Button>
              )}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}
