import React from "react";
import {
  IconButton,
  Button,
  Popover,
  Slider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Schedule as ScheduleIcon } from "@mui/icons-material";
import TimeRangePicker from "./timeRangePicker";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import type { CalendarDate } from "zynq-shared";
import { Time, TimeInterval } from "zynq-shared";
import { timeIntervalToString } from "./luxonUtil";

export default function TimeRangePickerPopup(props: {
  date: CalendarDate;
  timeRange: TimeInterval;
  defaultTimeRange: TimeInterval;
  setTimeRange: (range: TimeInterval, live?: boolean) => void;
  allowedHours: TimeInterval;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const showTimeInButton = useMediaQuery("@media (min-width:1020px)");
  const [timeAnchorEl, setTimeAnchorEl] = React.useState<HTMLElement>();
  const [open, setOpen] = React.useState(false);
  const timeID = !!timeAnchorEl ? "simple-popover" : undefined;
  const theme = useTheme();
  const allDay = props.timeRange.isAllDay() && !open;

  const iconButtonStyle = {
    ...theme.typography.button,
    boxSizing: "border-box",
    minWidth: 50,
    padding: "6px 6px",
    marginLeft: "1rem",
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.getContrastText(theme.palette.grey[300]),
    backgroundColor: "white",

    boxShadow: theme.shadows[2],
    transition: theme.transitions.create(
      ["background-color", "box-shadow", "border"],
      {
        duration: theme.transitions.duration.short,
      }
    ),
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.grey.A100,
      "@media (hover: none)": {
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.grey[300],
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
      },
      boxShadow: theme.shadows[4],
    },
    "&.Mui-disabled": {
      color: theme.palette.action.disabled,
      boxShadow: theme.shadows[0],
      backgroundColor: theme.palette.action.disabledBackground,
    },
    "&.Mui-focusVisible": {
      boxShadow: theme.shadows[6],
    },
    "&:active": {
      boxShadow: theme.shadows[8],
    },
  };

  return (
    <>
      {showTimeInButton ? (
        <Button
          endIcon={<ScheduleIcon />}
          variant="contained"
          css={{
            backgroundColor: "white",
            marginLeft: "1rem",
            width: allDay ? "7.25rem" : "12.25rem",
            transition: "width 0.25s",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          disabled={props.disabled}
          onClick={(e) => {
            const t = e.currentTarget;
            setOpen(true);
            // TODO Improve this
            setTimeout(() => setTimeAnchorEl(t), 500);
          }}
        >
          {timeIntervalToString(props.timeRange)}
        </Button>
      ) : (
        <IconButton
          css={iconButtonStyle as any}
          disabled={props.disabled}
          onClick={(e) => {
            const t = e.currentTarget;
            setOpen(true);
            setTimeout(() => setTimeAnchorEl(t), 500);
          }}
          size="large"
        >
          <ScheduleIcon />
        </IconButton>
      )}
      <Popover
        id={timeID}
        open={!!timeAnchorEl}
        anchorEl={timeAnchorEl}
        onClose={() => {
          setOpen(false);
          setTimeAnchorEl(undefined);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div
          css={{
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2
            css={{
              marginBlockStart: "0.5rem",
              marginBlockEnd: "1rem",
            }}
          >
            {t("time-range")}
          </h2>

          <Slider
            css={{
              margin: "0rem 1rem",
              width: "min(75vw, 20rem)",
              textAlign: "center",
              ".MuiSlider-valueLabel": {
                top: "-25px",
                width: "70px",
                "& *": {
                  padding: "0 0.1rem",
                  width: "70px",

                  borderRadius: "1rem",
                  transform: "none",
                },
              },
              ".MuiSlider-thumb": {
                height: 24,
                width: 24,
                backgroundColor: "#fff",
                border: "2px solid currentColor",

                "&:focus, &:hover, &.Mui-active": {
                  boxShadow: "inherit",
                },
              },
            }}
            step={1000 * 60 * 15}
            min={props.allowedHours.start.toMillis()}
            max={props.allowedHours.end.toMillis()}
            valueLabelDisplay="auto"
            valueLabelFormat={(v) =>
              Time.fromMillis(v).toLocaleString(DateTime.TIME_SIMPLE)
            }
            getAriaValueText={(v) =>
              Time.fromMillis(v).toLocaleString(DateTime.TIME_SIMPLE)
            }
            value={[
              props.timeRange.start.toMillis(),
              props.timeRange.end.toMillis(),
            ]}
            onChange={(ev, val) =>
              Array.isArray(val) &&
              props.setTimeRange(
                TimeInterval.fromMillis(val[0], val[1]).assertValid()
              )
            }
          />
          <TimeRangePicker
            css={{ marginTop: "1.5rem" }}
            timeRange={props.timeRange}
            defaultTimeRange={props.defaultTimeRange}
            setTimeRange={props.setTimeRange}
            allowedHours={props.allowedHours}
            hideWarning
          />
        </div>
      </Popover>
    </>
  );
}
