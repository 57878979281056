import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  domainName: string;
  floorplanName: string;
};

export default function QuestionnaireFailure({
  isOpen,
  setOpen,
  domainName,
  floorplanName,
}: Props) {
  const { t } = useTranslation();
  const descriptionElementRef = React.createRef<HTMLElement>();
  let domainContent;
  switch (domainName) {
    case "concierge":
      domainContent = (
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
        >
          {t("your-answers-indicate-you-may-not-visit-office-today")}
        </DialogContentText>
      );
      break;
    case "deciem.com":
      domainContent = (
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
        >
          {t("do-not-enter-workplace-notify-supervisor-instructions")}
        </DialogContentText>
      );
      break;
    case "sunbeltrentals.com":
      domainContent = (
        <>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
          >
            {t(
              "you-are-ineligible-to-come-into-the-office-your-admin-notified"
            )}
          </DialogContentText>
          <DialogContentText>
            {t(
              "if-experiencing-any-listed-symptoms-reach-out-to-manager-prior-entering-building"
            )}
          </DialogContentText>
        </>
      );
      break;
    case "sgsco.com":
      domainContent = (
        <>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
          >
            {t(
              "you-are-ineligible-to-come-into-the-office-your-admin-notified"
            )}
          </DialogContentText>
          <DialogContentText>
            <ol>
              <li>{t("if-not-at-home-avoid-contact-and-go-home")};</li>
              <li>{t("call-pcp-country-health-services-for-instructions")};</li>
              <li>{t("contact-supervisor-and-hr-to-discuss-options")}.</li>
            </ol>
          </DialogContentText>
        </>
      );
      break;
    case "varicent.com":
      domainContent = (
        <>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
          >
            {t(
              "thank-you-for-completing-questionnaire-at-risk-cannot-come-to-office"
            )}
          </DialogContentText>
        </>
      );
      break;
    default:
      domainContent = (
        <>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
          >
            {t("answers-indicate-cannot-come-into-office-today")}
          </DialogContentText>
          <DialogContentText>
            {t("admin-notified-refer-to-company-policy-for-next-steps")}
          </DialogContentText>
        </>
      );
  }
  return (
    <Dialog
      aria-describedby="scroll-dialog-description"
      aria-labelledby="scroll-dialog-title"
      onClose={() => setOpen(false)}
      open={isOpen}
      scroll="paper"
    >
      <DialogTitle id="scroll-dialog-title">Warning</DialogTitle>
      <DialogContent>{domainContent}</DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          color="primary"
          variant="contained"
        >
          {t("dismiss")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
