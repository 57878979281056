import React from "react";
import { Event as EventIcon, Favorite } from "@mui/icons-material";
import { CalendarPicker } from "@mui/lab";
import type { DateTime } from "luxon";
import {
  useMediaQuery,
  Button,
  IconButton,
  useTheme,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { BuddyPickersDay } from "./multidayBookCalendar";
import { useAPIQuery } from "./hooks";
import type { Buddy } from "zynq-shared";
import { TimeInterval, CalendarDate } from "zynq-shared";
import { getDistinctLabelColors } from "./colorUtil";

export default function DatePicker(props: {
  maxDate: CalendarDate;
  date: CalendarDate;
  timezone: string;
  today: CalendarDate;
  setDate: (d: CalendarDate) => void;
  isAdmin: boolean;
  buddySyncEnabled: boolean;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const iconButtonStyle = {
    ...theme.typography.button,
    boxSizing: "border-box",
    minWidth: 50,
    padding: "6px 6px",
    marginLeft: "1rem",
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.getContrastText(theme.palette.grey[300]),
    backgroundColor: "white",

    boxShadow: theme.shadows[2],
    transition: theme.transitions.create(
      ["background-color", "box-shadow", "border"],
      {
        duration: theme.transitions.duration.short,
      }
    ),
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.grey.A100,
      "@media (hover: none)": {
        boxShadow: theme.shadows[2],
        backgroundColor: theme.palette.grey[300],
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
      },
      boxShadow: theme.shadows[4],
    },
    "&.Mui-disabled": {
      color: theme.palette.action.disabled,
      boxShadow: theme.shadows[0],
      backgroundColor: theme.palette.action.disabledBackground,
    },
    "&.Mui-focusVisible": {
      boxShadow: theme.shadows[6],
    },
    "&:active": {
      boxShadow: theme.shadows[8],
    },
  };

  // The dialog is used to present the date picker on mobile.
  const [openDialog, setOpenDialog] = React.useState(false);

  const buddies = useAPIQuery<typeof Buddy.BuddiesInfo>(
    "/apis/buddy/buddies-info",
    {},
    { enabled: openDialog && props.buddySyncEnabled }
  );

  const isNotMobile = useMediaQuery("@media (min-width:600px)");

  return (
    <>
      {isNotMobile ? (
        <Button
          endIcon={<EventIcon color="primary" />}
          variant="contained"
          css={{
            marginLeft: "20px",
            backgroundColor: "white",
            color: "black",
          }}
          onClick={() => setOpenDialog(true)}
        >
          <span css={{ whiteSpace: "nowrap", overflow: "hidden" }}>
            {props.date.hasSame(CalendarDate.today(), "day")
              ? t("today")
              : props.date.toLocaleString({
                  month: "long",
                  day: "numeric",
                })}
          </span>
        </Button>
      ) : (
        <IconButton
          css={iconButtonStyle as any}
          onClick={() => setOpenDialog(true)}
          size="large"
        >
          <EventIcon color="primary" />
        </IconButton>
      )}
      <Dialog
        fullScreen={screen.width < 375}
        onClose={() => setOpenDialog(false)}
        open={openDialog}
        scroll="paper"
      >
        <DialogTitle>
          <Typography
            variant="h4"
            css={{
              marginTop: "1.5rem",
              marginRight: "1.5rem",
              marginLeft: "1.5rem",
            }}
          >
            {props.date.toLocaleString({
              weekday: "short",
              month: "short",
              day: "numeric",
            })}
          </Typography>
        </DialogTitle>
        <DialogContent dividers={false}>
          <CalendarPicker<DateTime>
            shouldDisableDate={(day: DateTime | null) => {
              if (day == null) return false;
              return (
                !props.isAdmin && CalendarDate.fromDateTime(day) < props.today
              );
            }}
            maxDate={props.isAdmin ? undefined : props.maxDate.date}
            date={props.date.date}
            onChange={(newDateDT) => {
              const newDate = newDateDT
                ? CalendarDate.fromDateTime(newDateDT)
                : null;
              if (newDate && newDate.isValid) {
                setOpenDialog(false);
                if (!props.date.hasSame(newDate, "day")) {
                  props.setDate(newDate);
                }
              }
            }}
            renderDay={function (dayDT, _selected, dayComponentProps) {
              if (!dayDT) {
                return (
                  <BuddyPickersDay
                    pickerProps={dayComponentProps}
                    buddyNumber={0}
                  />
                );
              }
              const day = CalendarDate.fromDateTime(dayDT);
              const buddyNumber =
                buddies.data?.buddies.filter((buddy) =>
                  buddy.bookings.some(
                    (booking) => booking.startDate == day.toISODate()
                  )
                ).length ?? 0;

              return (
                <BuddyPickersDay
                  key={day.toISODate()}
                  pickerProps={{
                    ...dayComponentProps,
                    today: day.hasSame(props.today, "day"),
                    selected: day.hasSame(props.date, "day"),
                  }}
                  buddyNumber={buddyNumber}
                />
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            color="secondary"
            variant="contained"
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
