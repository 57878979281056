import React from "react";
import { Autocomplete, LinearProgress } from "@mui/material";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import simplify from "simplify-geometry";

import { useAPIMutation, useAPIQuery, useAPIRequest } from "./hooks";
import { RESOURCE_TYPES } from "./constants";
import Seat from "./seat";
import Room from "./room";
import { SeatWithSymbol } from "./seatSymbols";
import type { UserInfo } from "./types";
import { TimeInterval, CalendarDate } from "zynq-shared";
import type { SuperAdmin } from "zynq-shared";

const filter = (s: { id: string; name: string }) => true;

type GeneratedElements = {
  seats: {
    name: string;
    x: number;
    y: number;
    angle: number;
    scale: number;
    resourceType: RESOURCE_TYPES;
  }[];
  rooms: { name: string; path: string }[];
};

export default function ConvertSVG(props: { userInfo: UserInfo }) {
  const [selectedDomainName, setSelectedDomainName] = React.useState<string>();
  const [floorplanName, setFloorplanName] = React.useState<string>("");
  const [shortName, setShortName] = React.useState<string>("");
  const [timezone, setTimezone] = React.useState<string>("");
  const [confirmReport, setConfirmReport] = React.useState<string[]>([]);
  const [confirmReportDetails, setConfirmReportDetails] = React.useState<
    string[]
  >([]);
  const [blockSubmit, setBlockSubmit] = React.useState<boolean>(false);
  const [viewport, setViewport] = React.useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const [adjacency, setAdjacency] = React.useState(30);
  const [generatedElements, setGeneratedElements] =
    React.useState<GeneratedElements>({ seats: [], rooms: [] });
  const [fileText, setFileText] = React.useState<string>();
  const [success, setSuccess] = React.useState(false);
  const api = useAPIRequest();

  const { data: serverInfo } = useAPIQuery<typeof SuperAdmin.AllFloorplans>(
    "/apis/superadmin/all-floorplans",
    {}
  );

  const saveMutation = useAPIMutation<typeof SuperAdmin.SaveFloorplan>(
    "/apis/superadmin/save-floorplan",
    {
      onError(res) {
        alert("Error: " + res.reason);
      },
    }
  );

  function getResourceType(id: string): RESOURCE_TYPES {
    if (id.toLowerCase().startsWith("training")) {
      return RESOURCE_TYPES.TRAINING_DESK;
    }
    if (id.toLowerCase().startsWith("locker")) {
      return RESOURCE_TYPES.LOCKER;
    }
    if (id.toLowerCase().startsWith("bench")) {
      return RESOURCE_TYPES.LAB_BENCH;
    }
    if (id.toLowerCase().startsWith("hood")) {
      return RESOURCE_TYPES.TC_HOOD;
    }
    if (id.toLowerCase().startsWith("desk")) {
      return RESOURCE_TYPES.DESK;
    }
    if (id.toLowerCase().startsWith("equipment")) {
      return RESOURCE_TYPES.EQUIPMENT;
    }
    return RESOURCE_TYPES.DESK;
  }

  function getResourceScaleMultiplier(type: RESOURCE_TYPES): number {
    switch (type) {
      case RESOURCE_TYPES.DESK:
      case RESOURCE_TYPES.TRAINING_DESK:
        return 0.0036;
      case RESOURCE_TYPES.EQUIPMENT:
        return 0.0562;
      case RESOURCE_TYPES.LAB_BENCH:
      case RESOURCE_TYPES.MOTHERS_ROOM:
      case RESOURCE_TYPES.TC_HOOD:
        return 0.054;
      case RESOURCE_TYPES.LOCKER:
      case RESOURCE_TYPES.PARKING:
        return 0.01;
    }
  }

  function getAbsoluteCoordinates(element: SVGGraphicsElement): DOMPoint {
    // Look for the inner <rect> within the element
    const rectElem = element.querySelector("rect") as SVGRectElement;
    if (!rectElem) {
      throw new Error(`No <rect> found in element with id: ${element.id}`);
    }
    // Get the SVG root element from the <rect>
    const svg = rectElem.ownerSVGElement!;
    const pt = svg.createSVGPoint();
    // Use the rect's x and y attributes (defaulting to 0 if absent)
    pt.x = parseFloat(rectElem.getAttribute("x") || "0");
    pt.y = parseFloat(rectElem.getAttribute("y") || "0");
    // Apply the rect's current transformation matrix to the point

    return pt.matrixTransform(rectElem.getScreenCTM()!);
  }

  function generate() {
    console.log("GENERATING");
    function f3(n: number) {
      return +n.toFixed(3);
    }

    function dist(
      p1: { x: number; y: number },
      p2: { x: number; y: number }
    ): number {
      const dx = p1.x - p2.x;
      const dy = p1.y - p2.y;
      return Math.sqrt(dx * dx + dy * dy);
    }

    const svg = document.getElementsByTagName("svg")[0];
    function createPoint(x: number, y: number, matrix?: DOMMatrix) {
      const pt = svg.createSVGPoint();
      pt.x = x;
      pt.y = y;
      if (matrix) {
        return pt.matrixTransform(matrix);
      }
      return pt;
    }

    const rooms = [
      ...(document.getElementById("Meeting_Rooms")?.childNodes ?? []),
    ]
      .filter((e): e is SVGElement => e instanceof SVGElement)
      .filter((e) => !!(e.dataset.name ?? e.id))
      .map((e) => {
        const name = e.dataset.name ?? e.id!;
        if (e instanceof SVGRectElement) {
          const x = e.x.baseVal.value;
          const y = e.y.baseVal.value;
          const w = e.width.baseVal.value;
          const h = e.height.baseVal.value;

          const matrix = e.getCTM() ?? undefined;
          const topLeft = createPoint(x, y, matrix);
          const topRight = createPoint(x + w, y, matrix);
          const bottomLeft = createPoint(x, y + h, matrix);
          const bottomRight = createPoint(x + w, y + h, matrix);

          return {
            name,
            path: [topLeft, topRight, bottomRight, bottomLeft]
              .map((p) => `${f3(p.x)},${f3(p.y)}`)
              .join(" "),
          };
        } else if (e instanceof SVGPathElement) {
          const length = e.getTotalLength();
          const numPoints = Math.max(length, 100);

          const points: [number, number][] = [];
          for (
            let curLength = 0;
            curLength < length;
            curLength += length / numPoints
          ) {
            const pt = e.getPointAtLength(curLength);
            points.push([pt.x, pt.y]);
          }

          const filteredPoints = simplify(points, 2);

          return {
            name,
            path: filteredPoints.map(([x, y]) => `${f3(x)},${f3(y)}`).join(" "),
          };
        } else if (e instanceof SVGPolygonElement) {
          return {
            name,
            path: [...e.points].map((p) => `${f3(p.x)},${f3(p.y)}`).join(" "),
          };
        } else {
          console.log(`Can't parse ${name}: ${e.tagName}`);
        }
        return { name, path: "" };
      });

    const seats = [...(document.getElementById("Desks")?.childNodes ?? [])]
      .filter((e): e is SVGElement => e instanceof SVGElement)
      .map((e: SVGElement) => ({ id: e.id, name: e.dataset.name! }))
      .map((e) => document.getElementById(e.id) as Element)
      .filter((e): e is SVGGraphicsElement => e instanceof SVGGraphicsElement)
      .map((s) => {
        const children = [...s.childNodes];
        const centerElement = children.find(
          (c: any) =>
            (c.id && c.id.includes("center_dot")) ||
            (c.dataset?.name && c.dataset.name.includes("center_dot"))
        )! as SVGGraphicsElement;
        const edgeElement = children.find(
          (c: any) =>
            (c.id && c.id.includes("edge_dot")) ||
            (c.dataset?.name && c.dataset.name.includes("edge_dot"))
        )! as SVGGraphicsElement;

        const center = centerElement.getBoundingClientRect();
        const edge = edgeElement.getBoundingClientRect();

        const centerPoint = getAbsoluteCoordinates(centerElement);
        const edgePoint = getAbsoluteCoordinates(edgeElement);

        console.log("Next", s.dataset.name);
        console.log(centerPoint);
        console.log(center.x, center.y);

        const [bx, by] = [centerPoint.x, centerPoint.y];

        const [cx, cy] = [edgePoint.x, edgePoint.y];
        const [dx_dist, dy_dist] = [bx - cx, by - cy];
        const dist = Math.sqrt(dx_dist * dx_dist + dy_dist * dy_dist);
        const [dx, dy] = [bx - cx, cy - by];
        const angle = Math.atan2(dx, dy) * (180 / Math.PI);

        const { x: mx, y: my } = document
          .getElementById("map")!
          .getBoundingClientRect();
        if (getResourceType(s.id) == "EQUIPMENT") {
          const mult = getResourceScaleMultiplier(getResourceType(s.id));
          return {
            name: s.dataset.name ?? s.id!,
            x: +(bx - mx).toFixed(3),
            y: +(by - my).toFixed(3),
            resourceType: getResourceType(s.id),
            scale: +(
              dist * getResourceScaleMultiplier(getResourceType(s.id))
            ).toFixed(3),
            angle: +angle.toFixed(3),
          };
        } else {
          return {
            name: s.dataset.name ?? s.id!,
            x: +(bx - mx).toFixed(3),
            y: +(by - my).toFixed(3),
            resourceType: getResourceType(s.id),
            scale: +(
              dist * getResourceScaleMultiplier(getResourceType(s.id))
            ).toFixed(3),
            angle: +angle.toFixed(2),
          };
        }
      });

    const parkingSpots = [
      ...(document.getElementById("Parking_Lot")?.childNodes ?? []),
    ]
      .filter((e): e is SVGElement => e instanceof SVGElement)
      .map((e: SVGElement) => ({ id: e.id, name: e.dataset.name! }))
      .map((e) => document.getElementById(e.id) as Element)
      .filter((e): e is SVGRectElement => e instanceof SVGRectElement)
      .map((s) => {
        const x = s.x.baseVal.value;
        const y = s.y.baseVal.value;
        const w = s.width.baseVal.value;
        const h = s.height.baseVal.value;

        const matrix = s.getCTM() ?? undefined;
        const topLeft = createPoint(x, y, matrix);
        const bottomRight = createPoint(x + w, y + h, matrix);
        const [centerX, centerY] = [
          (topLeft.x + bottomRight.x) / 2,
          (topLeft.y + bottomRight.y) / 2,
        ];

        const angle =
          [...s.transform.animVal].find(
            (v) => v.type == SVGTransform.SVG_TRANSFORM_ROTATE
          )?.angle ?? 0;

        const { x: mx, y: my } = document
          .getElementById("map")!
          .getBoundingClientRect();
        return {
          name: s.dataset.name ?? s.id!,
          x: +centerX.toFixed(3),
          y: +centerY.toFixed(3),
          resourceType: RESOURCE_TYPES.PARKING,
          scale: +(
            s.height.animVal.value *
            getResourceScaleMultiplier(RESOURCE_TYPES.PARKING)
          ).toFixed(3),
          angle: +angle.toFixed(2),
        };
      });

    const distances = [];
    for (const seat1 of seats) {
      for (const seat2 of seats) {
        if (seat1 == seat2) continue;
        distances.push(Math.hypot(seat1.x - seat2.x, seat1.y - seat2.y));
      }
    }
    const sortedDistances = distances.sort((a, b) => a - b);
    if (sortedDistances.length > 0) {
      const adj = Math.max(...sortedDistances.slice(0, 25));
      setAdjacency(Math.round(1.75 * adj));
    }

    setGeneratedElements({ seats: [...seats, ...parkingSpots], rooms });
  }

  React.useEffect(() => {
    generate();
  }, [fileText]);

  const selectedDomain = serverInfo?.domains.find(
    (d) => d.name == selectedDomainName
  );

  const existingFloorplan = selectedDomain?.floorplans?.find(
    (f) => f.name == floorplanName
  );

  function renderSVGToBlob(cb: (b: Blob | null) => void) {
    const img = new Image();
    const svg = document.getElementById("bgimage");

    const svgData = new XMLSerializer().serializeToString(svg as Node);
    console.log("Serialized data");
    const svgBlob = new Blob([svgData], {
      type: "image/svg+xml;charset=utf-8",
    });
    const url = URL.createObjectURL(svgBlob);

    img.onload = function () {
      const canvas = document.getElementById("bgimagecanvas");
      if (canvas instanceof HTMLCanvasElement) {
        const ctx = canvas.getContext("2d");

        if (!ctx) return alert("No rendering context");

        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(img, 0, 0);
        URL.revokeObjectURL(url);
        canvas.toBlob(cb, "image/webp", 1);
      }
    };
    img.src = url;
  }

  const saveFloorplan = (dryRun: boolean) => {
    renderSVGToBlob((blob) =>
      blob
        ?.arrayBuffer()
        .then((a) => crypto.subtle.digest("SHA-256", a))
        .then((hash) => {
          const hashArray = Array.from(new Uint8Array(hash));
          const hashHex = hashArray
            .map((b) => b.toString(16).padStart(2, "0"))
            .join("");
          console.log(hashHex);
          const formData = new FormData();
          const args = {
            domain: selectedDomainName,
            floorplanName: floorplanName,
            seats: generatedElements.seats,
            rooms: generatedElements.rooms,
            height: viewport.height,
            width: viewport.width,
            bgImageSHA2ContentHash: hashHex,
            shortName: shortName,
            timezone: timezone,
            adjacencyDistance: adjacency,
            dryRun,
          };
          Object.entries(args).forEach(([k, v]) =>
            formData.append(k, JSON.stringify(v))
          );
          formData.append("bgImage", blob);
          saveMutation.mutate(formData, {
            onSuccess(res) {
              console.log("DONE!");

              if (dryRun) {
                setBlockSubmit(res.errorEncountered);
                setConfirmReport(res.actions ?? ["No changes"]);
                setConfirmReportDetails(res.detailedReport ?? []);
              } else {
                setConfirmReport([]);
                setConfirmReportDetails([]);
                setSuccess(true);
                setTimeout(() => setSuccess(false), 4000);
              }
            },
          });
        })
    );
  };

  return (
    <div>
      <div
        css={{
          position: "fixed",
          zIndex: 2000,
          pointerEvents: "none",
          backgroundImage:
            'url("https://cultofthepartyparrot.com/parrots/hd/congaparrot.gif"), ' +
            (success
              ? 'url("https://i.gifer.com/2r67.gif")'
              : 'url("data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==")'),
          backgroundRepeat: "repeat-x",
          backgroundSize: "auto, 400px",
          backgroundPositionY: success ? "100%, 100%" : "120%, 100%",
          transition: "background-position-y 1s",
          width: "100%",
          height: "calc(100% - 85px)",
        }}
      />
      <div
        css={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "30rem",
            margin: "1rem",
            gap: "0.5rem",
          }}
        >
          <Autocomplete
            value={selectedDomainName ?? null}
            onChange={(_e, v) => {
              setSelectedDomainName(v ?? undefined);
            }}
            renderInput={(props) => (
              <TextField
                {...props}
                label={
                  selectedDomainName
                    ? "Oh that the domain"
                    : "What the domain ???"
                }
              />
            )}
            options={serverInfo?.domains.map((d) => d.name) ?? []}
            loading={serverInfo == undefined}
          />
          <Autocomplete
            freeSolo
            options={
              (serverInfo && selectedDomainName
                ? selectedDomain?.floorplans.map((f) => f.name)
                : undefined) ?? []
            }
            value={floorplanName}
            onInputChange={(e, v) => {
              setFloorplanName(v);
              const existingFloorplan = selectedDomain?.floorplans.find(
                (f) => f.name == v
              );
              if (existingFloorplan) {
                setShortName(existingFloorplan.shortName);
                setTimezone(existingFloorplan.timezone);
              }
            }}
            renderInput={(props) => (
              <TextField
                {...props}
                label={"Floorplan name here ↓"}
                helperText={
                  !!existingFloorplan
                    ? "WARNING: This floorplan already exists! be careful."
                    : undefined
                }
              />
            )}
          />
          <TextField
            label="And the short name..."
            value={shortName}
            onChange={(e) => setShortName(e.target.value)}
          />
          <TextField
            label="timzone plz"
            value={timezone}
            onChange={(e) => setTimezone(e.target.value)}
          />
          <div>
            PUT A SVG IN ME{" "}
            <input
              type="file"
              accept=".svg,image/svg+xml"
              onChange={(e) =>
                e.target.files![0].text().then((t) => {
                  setFileText(t);
                  const match = t.match(/viewBox="0 0 ([0-9.]+) ([0-9.]+)"/);
                  if (match) {
                    setViewport({
                      width: parseInt(match[1]),
                      height: parseInt(match[2]),
                    });
                  } else {
                    console.log("COUDLNT lOAD CAUSE NO VIEBOX");
                  }
                })
              }
            />
          </div>

          <Button
            variant="contained"
            onClick={() => {
              saveFloorplan(true);
            }}
          >
            Save in the dee bee
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setSelectedDomainName(undefined);
              setViewport({ width: 0, height: 0 });
              setFileText(undefined);
              setGeneratedElements({ rooms: [], seats: [] });
              setFloorplanName("");
              setShortName("");
              setTimezone("");
            }}
          >
            clear
          </Button>
        </div>
        <textarea
          css={{ margin: "1rem", width: "30rem" }}
          value={JSON.stringify(generatedElements, null, 2)}
          onChange={(e) => {
            try {
              const parsed = JSON.parse(e.target.value) as GeneratedElements;
              const valid =
                Array.isArray(parsed.seats) &&
                Array.isArray(parsed.rooms) &&
                parsed.seats.every(
                  (v) =>
                    "name" in v &&
                    "x" in v &&
                    "y" in v &&
                    "angle" in v &&
                    "scale" in v
                ) &&
                parsed.rooms.every((v) => "name" in v && "path" in v);

              if (valid) {
                console.log("VALID");
                setGeneratedElements(parsed);
              } else {
                console.log("invalid json");
              }
            } catch {
              console.log("couldn't parse json");
            }
          }}
        />
      </div>

      <div
        css={{
          display: "none",
        }}
      >
        <RenderWithoutDesksOrRooms
          viewport={viewport}
          fileText={fileText}
          renderToImage={() =>
            renderSVGToBlob((blob) => {
              if (!blob) return;
              const reader = new FileReader();
              reader.readAsDataURL(blob);

              reader.onloadend = function () {
                const img = document.getElementById("testimg");
                if (img instanceof SVGImageElement) {
                  img.setAttribute("href", reader.result as string);
                }
              };
            })
          }
        />
      </div>
      <div css={{ overflow: "scroll", width: "100%" }}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox={`0 0 ${viewport.width} ${viewport.height}`}
          id="previewmap"
          height={viewport.height}
          width={viewport.width}
          overflow="visible"
          preserveAspectRatio="xMinYMin slice"
        >
          <defs>
            <SeatWithSymbol id={RESOURCE_TYPES.DESK} />
            <g id={RESOURCE_TYPES.PARKING}>
              <rect
                height={100}
                width={50}
                x={-25}
                y={-50}
                css={{
                  strokeWidth: "4px",
                  fillOpacity: 0.6,
                  strokeOpacity: 0.7,
                  strokeLinejoin: "round",
                }}
              ></rect>
            </g>
          </defs>
          <image
            id={"testimg"}
            cx="0"
            cy="0"
            width={viewport.width}
            height={viewport.height}
          />
          <g role="group" aria-label="all desks">
            {generatedElements.seats.map((seat, i) => (
              <Seat
                key={seat.name}
                userInfo={props.userInfo}
                seatID={i}
                highlighted={false}
                events={[]}
                date={CalendarDate.today()}
                timeRange={TimeInterval.allDay}
                seatInfo={{
                  id: i,
                  name: seat.name,
                  enabled: true,
                  events: [],
                  svgX: seat.x,
                  svgY: seat.y,
                  svgAngle: seat.angle,
                  svgScale: seat.scale,
                  resourceType: seat.resourceType,
                  seatType: "Standard",
                }}
                onSelectSeatID={() => undefined}
                useSymbols={false}
              />
            ))}
          </g>
          <g role="group" aria-label="all rooms">
            {generatedElements.rooms.map((room, i) => (
              <Room
                key={room.name}
                date={CalendarDate.today()}
                timeRange={TimeInterval.allDay}
                roomInfo={{
                  id: i,
                  calendarID: room.name,
                  bookable: true,
                  viewable: true,
                  name: room.name,
                  svgPoints: room.path,
                  imgURL: "",
                  timezone: "America/New_York",
                }}
                roomEvents={[]}
                highlighted={false}
                onSelectRoomID={() => undefined}
              />
            ))}
          </g>
        </svg>
      </div>
      <div css={{ visibility: "hidden" }}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox={`0 0 ${viewport?.width ?? 0} ${viewport?.height ?? 0}`}
          id="map"
          height={viewport?.height}
          width={viewport?.width}
          overflow="visible"
          preserveAspectRatio="xMinYMin slice"
        >
          {fileText != null && (
            <g dangerouslySetInnerHTML={{ __html: fileText }} />
          )}
        </svg>
      </div>
      <Dialog
        open={confirmReport.length > 0}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
      >
        {saveMutation.isLoading && (
          <LinearProgress
            css={{ "&.MuiLinearProgress-root": { marginBottom: "-4px" } }}
          />
        )}
        <DialogTitle id="scroll-dialog-title" css={{ textAlign: "center" }}>
          This will change:
        </DialogTitle>
        <DialogContent css={{ maxWidth: "25rem" }}>
          <DialogContentText css={{ margin: "1rem" }} component="div">
            <ul>
              {confirmReport.map((el) => {
                return <li key={el}>{el}</li>;
              })}
            </ul>
            <b>More Details:</b>
            <ul css={{ maxWidth: "25rem" }}>
              {confirmReportDetails.map((el) => {
                return <li key={el}>{el}</li>;
              })}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={(_) => {
              saveFloorplan(false);
            }}
            color="primary"
            variant="contained"
            disabled={saveMutation.isLoading || blockSubmit}
          >
            Continue
          </Button>
          <Button
            onClick={(_) => {
              setConfirmReport([]);
              setBlockSubmit(false);
            }}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function RenderWithoutDesksOrRooms(props: {
  fileText: string | undefined;
  viewport?: { width: number; height: number };
  renderToImage: () => void;
}) {
  const ref = React.useRef<SVGSVGElement>(null);
  React.useLayoutEffect(() => {
    const inner = [...(ref.current?.getElementsByTagName("svg") ?? [])];
    if (inner.length == 0) return;
    const innerSvg = inner[0];
    // Add font
    if (ref.current && !ref.current?.getElementById("roboto-styles")) {
      let defs = [...inner[0].childNodes].find((n) => n.nodeName == "defs");
      if (!defs) {
        defs = document.createElement("defs");
        inner[0].prepend(defs);
      }
      const style = document.createElement("style");
      style.id = "roboto-styles";
      defs.appendChild(style);
      import("./convertSVGFonts").then((m) => {
        style.innerText = m.fonts;
        props.renderToImage();
      });
    }
    // Remove desks and rooms
    for (const innerChild of inner) {
      for (const child of innerChild.childNodes) {
        if (
          child instanceof SVGGElement &&
          (child.id == "Desks" ||
            child.id == "Meeting_Rooms" ||
            child.id == "Parking_Lot")
        ) {
          innerChild.removeChild(child);
        } else {
          child.childNodes.forEach((n) => {
            if (
              n instanceof SVGGElement &&
              (n.id == "Desks" ||
                n.id == "Meeting_Rooms" ||
                n.id == "Parking_Lot")
            ) {
              child.removeChild(n);
            }
          });
        }
      }
    }
  });

  const vph = props.viewport?.height ?? 0;
  const vpw = props.viewport?.width ?? 0;

  return (
    <>
      <svg
        ref={ref}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox={`0 0 ${vpw} ${vph}`}
        id="bgimage"
        height={vph * 3}
        width={vpw * 3}
        overflow="visible"
        preserveAspectRatio="xMinYMin slice"
        // css={{ height: vph, width: vpw }}
      >
        {props.fileText != null && (
          <g dangerouslySetInnerHTML={{ __html: props.fileText }} />
        )}
      </svg>
      <canvas
        id="bgimagecanvas"
        height={vph * 3}
        width={vpw * 3}
        css={props.viewport}
      />
    </>
  );
}
