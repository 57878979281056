import mixpanel from "mixpanel-browser";
import { IS_DEV } from "./constants";
import { CLIENT_VERSION } from "./constants";
import i18next from "i18next";

export function resetMixpanel() {
  mixpanel.reset();
  registerSuperProperties();
}

export function setUpMixpanel() {
  if (process.env.REACT_APP_ZYNQ_ENV !== "development") {
    mixpanel.init("a9c5ffaf4eae372fd484dbbba9959753", { debug: IS_DEV });
  } else {
    mixpanel.init("beb6275db421a764e2700aa0387ff1b1", { debug: IS_DEV });
  }
  registerSuperProperties();
}

function registerSuperProperties() {
  mixpanel.register({
    client_version: CLIENT_VERSION,
    locale: i18next.language ?? navigator.language,
  });
}
