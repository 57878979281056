import React from "react";
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import { DateTime } from "luxon";

import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TimeInterval, Time, SIMPLE_ISO_TIME } from "zynq-shared";

export default function TimeRangePicker(props: {
  timeRange: TimeInterval;
  defaultTimeRange: TimeInterval;
  defaultTextOverride?: string;
  setTimeRange: (r: TimeInterval, live?: boolean) => void;
  allowedHours?: TimeInterval;
  className?: string;
  hideButton?: boolean;
  hideWarning?: boolean;
  maxRangeMins?: number;
  showNightshiftToggle?: boolean;
  vertical?: boolean;
}) {
  const { t } = useTranslation();
  const warningText =
    props.allowedHours &&
    !props.hideWarning &&
    !props.allowedHours.engulfs(props.timeRange) &&
    !props.allowedHours.isAllDay()
      ? t("schedule-only-allows-booking-between", {
          start: props.allowedHours.start.toLocaleString(DateTime.TIME_SIMPLE),
          end: props.allowedHours.end.toLocaleString(DateTime.TIME_SIMPLE),
        })
      : null;

  return (
    <div
      className={props.className}
      css={{
        display: "flex",
        flexDirection: "column",
        maxWidth: props.hideButton ? "15rem" : "25rem",
      }}
    >
      {warningText && (
        <Alert className="Alert" severity="warning" css={{ margin: "1rem" }}>
          {warningText}
        </Alert>
      )}
      <div
        css={{
          display: "flex",
          flexDirection: props.vertical ? "column" : "row",
          gap: props.vertical ? "0" : "0.5rem",
          justifyContent: "space-between",
          alignItems: "stretch",
        }}
      >
        <TextField
          label={t("start-time")}
          value={props.timeRange.start.toISOTime(SIMPLE_ISO_TIME)}
          InputProps={{
            inputProps: {
              min: props.allowedHours?.start,
              pattern: "[0-9]{2}:[0-9]{2}",
            },
            style: { textAlign: "center" },
          }}
          onChange={(v) => {
            const parsedStart = Time.fromISOTime(v.target.value);
            if (!parsedStart.isValid) {
              return;
            }

            const nightshift = props.timeRange.isMultiDay();
            const suggestedEnd = Time.min(
              parsedStart.plus({ minutes: 30 }),
              Time.fromObject({ days: nightshift ? 2 : 1, minutes: -1 })
            );
            let newTimeRange = TimeInterval.fromTimes(
              Time.min(Time.endOfDay, parsedStart),
              props.timeRange.end > parsedStart
                ? props.timeRange.end
                : suggestedEnd
            ).assertValid();

            if (
              props.maxRangeMins &&
              newTimeRange.toDuration(DateTime.fromMillis(0)).as("minutes") >
                props.maxRangeMins
            ) {
              newTimeRange = newTimeRange
                .set({
                  end: newTimeRange.start.plus({
                    minutes: props.maxRangeMins,
                  }),
                })
                .assertValid();
            }

            props.setTimeRange(newTimeRange);
          }}
          type="time"
        />
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "stretch",
          }}
        >
          <TextField
            label={t("end-time")}
            value={props.timeRange.end.toISOTime(SIMPLE_ISO_TIME)}
            css={{
              flexGrow: 1,
            }}
            InputProps={{
              inputProps: {
                max: props.allowedHours?.end,
                pattern: "[0-9]{2}:[0-9]{2}",
              },
              style: { textAlign: "center" },
              endAdornment: props.showNightshiftToggle && (
                <InputAdornment position="end">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.timeRange.isMultiDay()}
                        onChange={(_v, checked) =>
                          props.setTimeRange(
                            TimeInterval.fromTimes(
                              props.timeRange.start,
                              props.timeRange.end.set({
                                days: checked ? 1 : 0,
                              })
                            ).orDefault(
                              checked
                                ? TimeInterval.allDay
                                    .set({
                                      end: Time.endOfDay.plus({ days: 1 }),
                                    })
                                    .assertValid()
                                : TimeInterval.allDay
                            )
                          )
                        }
                      />
                    }
                    label="Next day"
                  />
                </InputAdornment>
              ),
            }}
            onChange={(v) => {
              const parsedEnd = Time.fromISOTime(v.target.value);
              if (!parsedEnd.isValid) {
                return;
              }
              const nightshift = props.timeRange.isMultiDay();
              const shiftedEnd = parsedEnd.plus({
                days: nightshift ? 1 : 0,
              });
              const suggestedStart = Time.max(
                shiftedEnd.minus({ minutes: 30 }),
                Time.zero
              );
              let newTimeRange = TimeInterval.fromTimes(
                props.timeRange.start < shiftedEnd
                  ? props.timeRange.start
                  : suggestedStart,
                Time.max(shiftedEnd, Time.fromObject({ minutes: 1 }))
              ).assertValid();

              if (
                props.maxRangeMins &&
                newTimeRange.toDuration(DateTime.fromMillis(0)).as("minutes") >
                  props.maxRangeMins
              ) {
                newTimeRange = newTimeRange
                  .set({
                    start: newTimeRange.end.minus({
                      minutes: props.maxRangeMins,
                    }),
                  })
                  .assertValid();
              }

              props.setTimeRange(newTimeRange);
            }}
            type="time"
          />
        </div>
        {!props.hideButton && (
          <Button
            onClick={() => props.setTimeRange(props.defaultTimeRange, true)}
            variant="contained"
            css={{
              marginLeft: "0.4rem",
              marginTop: "1rem",
              marginBottom: "0.5rem",
            }}
            disabled={
              !!props.maxRangeMins &&
              props.timeRange.toDuration(DateTime.fromMillis(0), "minutes")
                .minutes +
                30 >
                props.maxRangeMins
            }
            size="small"
          >
            {props.defaultTextOverride ?? t("default")}
          </Button>
        )}
      </div>
      {props.showNightshiftToggle &&
        props.timeRange.toDuration(DateTime.fromMillis(0), "minutes").minutes >
          12 * 60 &&
        !props.timeRange.equals(props.defaultTimeRange) && (
          <Alert severity="warning" css={{ marginTop: "1rem" }}>
            {t("you-are-booking-for-more-than-12-hours")}
          </Alert>
        )}
    </div>
  );
}
