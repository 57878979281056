import React, { useRef } from "react";
import {
  Menu,
  MenuItem,
  Button,
  Collapse,
  AppBar,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import Avatar from "./avatar";
import { Event as EventIcon } from "@mui/icons-material";
import FiberNewIcon from "@mui/icons-material/FiberNew";

import constants, { RESOURCE_TYPES } from "./constants";
import LocationPicker from "./locationPicker";
import type { Floorplan, LocationType } from "./locationPicker";
import SearchBar from "./searchBar";
import DatePicker from "./datePicker";
import type { FloorplanInfo, UserInfo } from "./types";
import mixpanel from "mixpanel-browser";
import { useAPIRequest, useLoginRedirect } from "./hooks";
import TimeRangePicker from "./timeRangePickerPopup";
import { useTranslation } from "react-i18next";
import type { CalendarDate, TimeInterval } from "zynq-shared";
import { resetMixpanel } from "./tracker";
import { DateTime } from "luxon";

// Global var used for knowing if location picker has been prompted since load / refresh
let LOCATION_PICKED_IN_SESSION = false;

export default function Header({
  userInfo,
  selectSeatCallback,
  selectRoomCallback,
  date,
  setDate,
  onSelectFloorplanLoc,
  maxDate,
  defaultResourceType,
  onClickScheduleBook,
  onClickListBookings,
  onClickScheduledVisitors,
  floorplan,
  hasDesks,
  timeRange,
  defaultTimeRange,
  timeRangeEnabled,
  setTimeRange,
  today,
  ...props
}: {
  userInfo: UserInfo;
  selectSeatCallback: (seatID: number | undefined, floorplanID: number) => void;
  selectRoomCallback: (id: number | undefined) => void;
  date: CalendarDate;
  hasDesks: boolean;
  setDate: (d: CalendarDate) => void;
  onSelectFloorplanLoc: (location: Floorplan) => void;
  maxDate: CalendarDate;
  defaultResourceType: RESOURCE_TYPES;
  onClickScheduleBook: (params: { group: boolean }) => void;
  onClickListBookings: () => void;
  onClickBuddySync: () => void;
  onClickScheduledVisitors: () => void;
  timeRange: TimeInterval;
  groupBookFreeze: {
    days: CalendarDate[];
    timeInterval: TimeInterval;
  } | null;
  floorplan?: FloorplanInfo;
  defaultTimeRange: TimeInterval;
  timeRangeEnabled: boolean;
  setTimeRange: (range: TimeInterval, live?: boolean) => void;
  today: CalendarDate;
}) {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement>();
  const [searchExpanded, setSearchExpanded] = React.useState(false);
  const locationRef = React.useRef<{
    openWithLocation: (location: { id: number; isRegion: boolean }) => void;
  }>(null);

  const { t } = useTranslation();
  const api = useAPIRequest();
  const loginRedirect = useLoginRedirect();

  const isNotMobile = useMediaQuery("@media (min-width:600px)");
  const searchOpenMobile = !isNotMobile && searchExpanded;

  const onLogout = () => {
    resetMixpanel();
    if (!userInfo.isTestUser) {
      api.post("/seating/api/logout").promise.then((_) => loginRedirect(false));
    } else {
      window.location.href = "/secret-zynq-test/stop";
    }
  };

  // TODO: remove this once the date's past
  const showNewGroupBookingIcon =
    DateTime.now() < DateTime.fromISO("2025-08-01T00:00:00.000Z");

  return (
    <>
      <AppBar
        component={"header"}
        position="fixed"
        id="header-wrapper"
        css={{
          height: constants.headerHeight,
          top: "0px",
          left: "0px",
          width: "100%",
          backgroundColor: "#f9f9f9",
          color: "black",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <img
          css={{
            "@media (max-width: 600px)": {
              display: "none",
            },
            margin: "15px",
            height: "46px",
          }}
          src="/static/img/logo_small.png"
          alt="Zynq"
        />
        {floorplan && !searchOpenMobile && (
          <>
            {props.groupBookFreeze ? (
              <Button
                endIcon={<EventIcon color="primary" />}
                variant="contained"
                css={{
                  marginLeft: "20px",
                  backgroundColor: "white",
                  color: "black",
                }}
                disabled={true}
              >
                <span css={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                  {props.groupBookFreeze.days[0].toLocaleString({
                    month: "long",
                    day: "numeric",
                  })}

                  {props.groupBookFreeze.days.length > 1 &&
                    " - " +
                      props.groupBookFreeze.days[
                        props.groupBookFreeze.days.length - 1
                      ].toLocaleString({
                        month: "long",
                        day: "numeric",
                      })}
                </span>
              </Button>
            ) : (
              <DatePicker
                maxDate={maxDate}
                today={today}
                date={date}
                timezone={floorplan.timezone}
                setDate={setDate}
                isAdmin={userInfo.isAdmin}
                buddySyncEnabled={userInfo.domain.buddySyncEnabled}
              />
            )}

            {timeRangeEnabled &&
              (props.groupBookFreeze ? (
                <TimeRangePicker
                  date={props.groupBookFreeze.days[0]}
                  timeRange={props.groupBookFreeze.timeInterval}
                  defaultTimeRange={defaultTimeRange}
                  disabled={true}
                  setTimeRange={() => {
                    // No-op
                  }}
                  allowedHours={userInfo.allowedHours}
                />
              ) : (
                <TimeRangePicker
                  date={date}
                  timeRange={timeRange}
                  defaultTimeRange={defaultTimeRange}
                  setTimeRange={setTimeRange}
                  allowedHours={userInfo.allowedHours}
                />
              ))}
            <LocationPicker
              allowRegion={false}
              pickerRef={locationRef}
              disabled={!!props.groupBookFreeze}
              selected={{
                isRegion: false,
                name: floorplan.name,
                id: floorplan.id,
                userVisible: floorplan.userVisible,
              }}
              defaultOpen={
                !LOCATION_PICKED_IN_SESSION &&
                !userInfo.defaultFloorplanID &&
                userInfo.domain.name == "amexgbt.com"
              }
              makeDefaultButtons={{
                renderMakeDefaultButton: userInfo.domain.name == "amexgbt.com",
                defaultFloorplanID: userInfo.defaultFloorplanID,
              }}
              onChange={(l) => {
                if (l) {
                  LOCATION_PICKED_IN_SESSION = true;
                  onSelectFloorplanLoc(l);
                }
              }}
              css={{
                marginLeft: "1rem",
              }}
            />
          </>
        )}
        <div css={{ marginRight: "auto" }} />
        {!props.groupBookFreeze && (
          <SearchBar
            date={date ?? undefined}
            selectSeatCallback={selectSeatCallback}
            selectRoomCallback={selectRoomCallback}
            onSelectFloorplanLoc={(loc) =>
              locationRef.current?.openWithLocation?.(loc)
            }
            floorplan={floorplan}
            setExpanded={setSearchExpanded}
            expanded={searchExpanded}
          />
        )}
        {!searchOpenMobile && (
          <Button
            css={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
            aria-controls="user-menu"
            aria-haspopup="true"
            onClick={(e) => {
              setMenuAnchorEl(e.currentTarget);
            }}
          >
            <Avatar css={{ margin: "0px 5px" }} avatar={userInfo.avatar} />

            <div
              css={{
                "@media (max-width: 600px)": {
                  display: "none",
                },
                margin: "15px 5px",
                fontSize: "1rem",
                textTransform: "none",
                letterSpacing: "initial",
                color: "black",
              }}
            >
              {userInfo.avatar.name}
            </div>
          </Button>
        )}
      </AppBar>
      <Menu
        id="user-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={!!menuAnchorEl}
        onClose={() => setMenuAnchorEl(undefined)}
        marginThreshold={0}
        TransitionComponent={Collapse}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem>
          <Avatar css={{ margin: "0px 5px" }} avatar={userInfo.avatar} />
          <div
            css={{
              margin: "15px 5px",
            }}
          >
            {userInfo.avatar.name}
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onClickListBookings();
            setMenuAnchorEl(undefined);
            if (userInfo.domain.buddySyncEnabled) {
              mixpanel.track("Buddy View Dialog Menu Item");
            }
          }}
        >
          {t("my-bookings")}
        </MenuItem>
        {userInfo.domain.buddySyncEnabled && (
          <MenuItem
            onClick={() => {
              props.onClickBuddySync();
              setMenuAnchorEl(undefined);
              mixpanel.track("Buddy Open Dialog");
            }}
          >
            {t("buddy-sync")}
          </MenuItem>
        )}
        {userInfo.domain.scheduledVisitorsEnabled && (
          <MenuItem
            onClick={() => {
              onClickScheduledVisitors();
              setMenuAnchorEl(undefined);
            }}
          >
            {t("scheduled-visitors")}
          </MenuItem>
        )}
        {hasDesks && userInfo.domain.name != "cliffordchance.com" && (
          <MenuItem
            onClick={() => {
              onClickScheduleBook({ group: false });
              setMenuAnchorEl(undefined);
            }}
          >
            {defaultResourceType == RESOURCE_TYPES.DESK
              ? t("book-desks")
              : t("make-booking")}
          </MenuItem>
        )}
        {hasDesks && floorplan?.onBehalfBulkBookingsEnabled && (
          <MenuItem
            onClick={() => {
              onClickScheduleBook({ group: true });
              setMenuAnchorEl(undefined);
            }}
          >
            {t("group-booking")}
            {showNewGroupBookingIcon && (
              <FiberNewIcon color="primary" css={{ paddingLeft: "0.5rem" }} />
            )}
          </MenuItem>
        )}
        {userInfo.isAdmin || userInfo.isManager || userInfo.isUserAdmin ? (
          <MenuItem
            component={Link}
            to={"/admin"}
            onClick={() => {
              setMenuAnchorEl(undefined);
            }}
          >
            {t("admin-panel")}
          </MenuItem>
        ) : null}
        {userInfo.isSuperAdmin || userInfo.isStaff ? (
          <MenuItem
            component={"a"}
            href={"/secret-zynq-admin"}
            onClick={() => {
              setMenuAnchorEl(undefined);
            }}
          >
            {t("superadmin-panel")}
          </MenuItem>
        ) : null}
        {/* TODO: Remove this conditional */}
        {userInfo.domain.buddySyncEnabled && (
          <MenuItem
            component={Link}
            to={"/preferences"}
            onClick={() => {
              setMenuAnchorEl(undefined);
            }}
          >
            {t("preferences")}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            onLogout();
            setMenuAnchorEl(undefined);
          }}
        >
          {t("logout")}
        </MenuItem>
      </Menu>
    </>
  );
}
