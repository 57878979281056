import ReactDOM from "react-dom";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { CaptureConsole, ExtraErrorData } from "@sentry/integrations";
import DateAdapter from "@mui/lab/AdapterLuxon";
import { DateTime, Duration, Settings, Info } from "luxon";
import { setUpMixpanel } from "./tracker";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  setLogger,
} from "react-query";

import { LocalizationProvider } from "@mui/lab";
import "./i18n";

import App from "./app";
import { SENTRY_SESSION_ID, CLIENT_VERSION, IS_DEV } from "./constants";
import { APIError, doLoginRedirect } from "./hooks";

export const browserHistory = createBrowserHistory();

const pageLoadTime = DateTime.now();

browserHistory.listen((l, a) => {
  if (a == "PUSH" || a == "POP") {
    if (DateTime.now().diff(pageLoadTime, "days").days > 3) {
      console.log("Doing a hard reload on navigation!");
      window.location.reload();
    }
  }
});

window.addEventListener("focus", () => {
  if (DateTime.now().diff(pageLoadTime, "days").days > 6) {
    console.log("Doing a hard reload on focus!");
    window.location.reload();
  }
});

import "./index.css";
import i18next from "i18next";
import i18n from "./i18n";

if (process.env.REACT_APP_ZYNQ_ENV === "development") {
  (window as any).DateTime = DateTime;
  (window as any).Duration = Duration;
}

if (process.env.REACT_APP_ZYNQ_ENV !== "development") {
  Sentry.init({
    dsn: "https://c9435acfeeae46e6aa063b7e027db995@o286985.ingest.sentry.io/5306462",
    normalizeDepth: 5,
    release: CLIENT_VERSION,
    attachStacktrace: true,
    integrations: [
      new CaptureConsole({
        levels: ["error"],
      }),
      new ExtraErrorData(),
    ],
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          lang: i18n.language,
        });
      }
      return event;
    },
    // TODO(i18n): Verify this still works with localization.
    ignoreErrors: [
      "Network Error",
      "Error opening popup window. This can happen if you are using IE or if popups are blocked in the browser.",
      "zoom requires valid numbers",
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: Cancelled",
      "Could not reach Cloud Firestore backend. Backend didn't respond within 10 seconds.",
    ],
  });

  Sentry.configureScope((scope) => {
    scope.setTag("session_id", SENTRY_SESSION_ID);
    scope.setTag("client_version", CLIENT_VERSION);
    scope.setTag("locale", i18next.language ?? navigator.language);
  });
}

setUpMixpanel();

// Capture sentry handler
const sentryOnUnhandledRejection = window.onunhandledrejection?.bind(window);
window.onunhandledrejection = function (data: PromiseRejectionEvent) {
  // TODO(i18n): Verify this still works with localization.
  // Ignore login failures and cancelled promises
  if (
    data?.reason?.message == "Login required" ||
    data?.reason == "Ignore cancel" ||
    data?.reason?.name == "TypeError"
  ) {
    data.preventDefault();
  } else if (
    data?.reason?.message == "Refresh required" ||
    data?.reason?.name == "ChunkLoadError"
  ) {
    data.preventDefault();
    window.showError?.(
      i18next.t("site-just-updated-refresh-for-latest-version")
    );
  } else {
    // @ts-ignore
    sentryOnUnhandledRejection?.(data);
  }
};

window.onerror = (msg, url, line, column, error) => {
  const message = {
    message: msg,
    url: url,
    line: line,
    column: column,
    error: JSON.stringify(error),
  };

  if (window.webkit) {
    window.webkit.messageHandlers.error.postMessage(message);
  }
  console.log("Error:", message);
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(err, q) {
      if ((err as { reason?: string }).reason == "login_required") {
        doLoginRedirect(
          true,
          typeof q.queryKey[0] === "string" ? q.queryKey[0] : q.queryHash,
          browserHistory,
          browserHistory.location
        );
      }
    },
  }),
  mutationCache: new MutationCache({
    onError(err, _vars, _ctx, q) {
      if ((err as { reason?: string }).reason == "login_required") {
        doLoginRedirect(
          true,
          q.meta?.["debugInfo"] as string,
          browserHistory,
          browserHistory.location
        );
      }
    },
  }),
  defaultOptions: {
    mutations: {
      useErrorBoundary: false,
      onError: (e) =>
        window.showError?.(
          (e as { reason?: string }).reason ??
            (e as { message?: string }).message
        ),
    },
    queries: {
      useErrorBoundary: false,
      retry: (failureCount, error) => {
        if (
          error instanceof APIError &&
          error?.status >= 400 &&
          error?.status < 500
        ) {
          return false; // Don't retry on 4xxs
        }
        return failureCount < 3;
      },
    },
  },
});

setLogger({
  log: console.log,
  warn: console.warn,
  error: () => {
    // Remove network errors from console in order to prevent sentry from picking them up
  },
});

Settings.defaultLocale = i18next.language ?? navigator.language;

class ExtendedDateAdapter extends DateAdapter {
  offset = -1;

  public getWeekdays = () => {
    const weekdays = [
      ...Info.weekdaysFormat("narrow", { locale: this.locale }),
    ];
    return weekdays.splice(this.offset).concat(weekdays);
  };

  public getWeekArray = (date: DateTime) => {
    const endOfMonth = date.endOf("month");
    const endOfCalendarMonth = endOfMonth.plus({
      days: 7 - ((endOfMonth.weekday - this.offset) % 7),
    });

    const startOfMonth = date.startOf("month");
    const startOfCalendarMonth = startOfMonth.minus({
      days: (startOfMonth.weekday - 1 - this.offset) % 7,
    });

    const { days } = endOfCalendarMonth
      .diff(startOfCalendarMonth, "days")
      .toObject();

    const weeks: DateTime[][] = [];
    new Array<number>(Math.round(days!))
      .fill(0)
      .map((_, i) => i)
      .map((day) => startOfCalendarMonth.plus({ days: day }))
      .forEach((v, i) => {
        if (i === 0 || (i % 7 === 0 && i > 6)) {
          weeks.push([v]);
          return;
        }

        weeks[weeks.length - 1].push(v);
      });
    return weeks;
  };
}

ReactDOM.render(
  <Router history={browserHistory}>
    <LocalizationProvider
      dateAdapter={ExtendedDateAdapter}
      locale={DateTime.now().locale}
    >
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </LocalizationProvider>
  </Router>,
  document.getElementById("root")
);
