import React, { useState, useEffect, useRef } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import constants from "./constants";
import { useAPIRequest, useDebounce, useError } from "./hooks";
import Grid from "@mui/material/Grid";
import {
  LocationOn as LocationOnIcon,
  Domain as DomainIcon,
  MeetingRoom as MeetingRoomIcon,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import type { CalendarDate } from "zynq-shared";
import { assertNever } from "zynq-shared";
import { useAPIQuery } from "./hooks";
import type { FloorplanInfo } from "./types";
import { useTranslation } from "react-i18next";
import type { InferReturnType, Seating } from "zynq-shared";
import Avatar from "./avatar";
import { matchSorter } from "match-sorter";

type OptionKind = InferReturnType<typeof Seating.MapSearch>["searchResults"][0];

function RenderOption(props: {
  option: OptionKind;
  viewingFromFloorplanID?: number;
}) {
  const { t } = useTranslation();

  switch (props.option.type) {
    case "resource":
      return (
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <LocationOnIcon titleAccess={t("location")} />
          </Grid>
          <Grid item xs>
            <span style={{ fontWeight: 700 }}>{props.option.seatName}</span>
            <Typography variant="body2" color="textSecondary">
              {props.option.floorplanName}
            </Typography>
          </Grid>
        </Grid>
      );
    case "room":
      return (
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <MeetingRoomIcon titleAccess={t("room")} />
          </Grid>
          <Grid item xs>
            <span style={{ fontWeight: 700 }}>{props.option.name}</span>
            <Typography variant="body2" color="textSecondary">
              {props.option.floorplanName}
            </Typography>
          </Grid>
        </Grid>
      );
    case "user":
      let subText = "";
      switch (props.option.comingInInfo.type) {
        case "blocked":
          break;
        case "notComingIn":
          subText = t("not-in-the-office");
          break;
        case "dropin":
          subText = props.option.comingInInfo.floorplanName;
          break;
        case "resourceBooking":
        case "resourceAssignedRule":
          return (
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar avatar={props.option.avatar} />
              </Grid>
              <Grid item xs>
                <span style={{ fontWeight: 400 }}>
                  {props.option.avatar.name}
                </span>
                <Typography variant="body2" color="textSecondary">
                  {(subText = props.option.comingInInfo.seatName)}
                </Typography>
                {props.viewingFromFloorplanID &&
                props.viewingFromFloorplanID !=
                  props.option.comingInInfo.floorplanID ? (
                  <Typography variant="body2" color="textSecondary">
                    {props.option.comingInInfo.floorplanName}
                  </Typography>
                ) : undefined}
                {props.option.comingInInfo.type == "resourceAssignedRule" ? (
                  <Typography variant="body2" color="textSecondary">
                    {`${t("not-in-the-office")}`}
                  </Typography>
                ) : undefined}
              </Grid>
            </Grid>
          );
        default:
          assertNever("Unhandled search option");
      }
      return (
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Avatar avatar={props.option.avatar} />
          </Grid>
          <Grid item xs>
            <span style={{ fontWeight: 400 }}>{props.option.avatar.name}</span>

            <Typography variant="body2" color="textSecondary">
              {subText}
            </Typography>
          </Grid>
        </Grid>
      );
    case "floorplan":
      return (
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <DomainIcon titleAccess={t("floorplan")} />
          </Grid>
          <Grid item xs>
            <span style={{ fontWeight: 700 }}>{props.option.name}</span>
            {props.option.parentName && (
              <Typography variant="body2" color="textSecondary">
                {props.option.parentName}
              </Typography>
            )}
          </Grid>
        </Grid>
      );
    case "region":
      return (
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <DomainIcon titleAccess={t("region")} />
          </Grid>
          <Grid item xs>
            <span style={{ fontWeight: 700 }}>{props.option.name}</span>
          </Grid>
        </Grid>
      );
  }
}

function SearchBar(props: {
  selectSeatCallback: (seatID: number | undefined, floorplanID: number) => void;
  selectRoomCallback: (id: number | undefined) => void;
  onSelectFloorplanLoc: (location: { id: number; isRegion: boolean }) => void;
  setExpanded: (expanded: boolean) => void;
  floorplan?: FloorplanInfo;
  expanded: boolean;
  date?: CalendarDate;
}) {
  const [value, setValue] = React.useState<null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const searchQuery = useDebounce(inputValue, 200);
  const { data, isLoading, error } = useAPIQuery<typeof Seating.MapSearch>(
    "/apis/seating/map-search",
    {
      query: searchQuery,
      date: props.date?.toISODate() ?? "",
      floorplanID: props.floorplan?.id ?? 0,
      lowerBoundLimit: 20,
    },
    {
      enabled: !!(
        props.date &&
        props.expanded &&
        props.floorplan &&
        searchQuery.length > 0
      ),
    }
  );

  const { t } = useTranslation();

  // Because of a specificity issue with emotion, fontSize only doesn't get overriden if passed through style.
  const searchIcon = (
    <label htmlFor="user_search">
      <SearchIcon
        css={{
          margin: "0px 10px",
          cursor: "pointer",
          color: "#4A4A4A",
          fontSize: 30,
        }}
        titleAccess={t("user-search")}
      />
    </label>
  );

  const autocomplete = (
    <Autocomplete
      id="user_search"
      className="searchInputBoxVisiblity"
      style={{ width: 220, marginRight: 10 }}
      getOptionLabel={(option) => {
        switch (option.type) {
          case "user":
            return option.avatar.name + `(${option.avatar.email})`;
          case "resource":
            return option.seatName;
          case "floorplan":
          case "region":
          case "room":
            return option.name;
        }
        return ""; // Fallback
      }}
      options={data?.searchResults ?? []}
      filterOptions={(options, { inputValue }) =>
        matchSorter(options, inputValue, {
          keys: ["name", "seatName", "avatar.name", "avatar.email"],
        })
      }
      autoComplete
      autoSelect
      autoHighlight
      freeSolo
      includeInputInList
      blurOnSelect
      clearOnBlur
      clearOnEscape
      loading={isLoading}
      loadingText={t("searching-ellipsized")}
      openOnFocus={false}
      filterSelectedOptions
      value={value}
      inputValue={inputValue}
      onChange={(_event, newValue) => {
        if (newValue != null && typeof newValue != "string") {
          switch (newValue.type) {
            case "user":
              switch (newValue.comingInInfo.type) {
                case "blocked":
                case "notComingIn":
                  // TODO: consider a dialog showing user info
                  break;
                case "dropin":
                  if (
                    newValue.comingInInfo.floorplanID != props.floorplan?.id ??
                    0
                  ) {
                    props.selectSeatCallback(
                      undefined,
                      newValue.comingInInfo.floorplanID
                    );
                  }
                  break;
                case "resourceBooking":
                case "resourceAssignedRule":
                  props.selectSeatCallback(
                    newValue.comingInInfo.seatID,
                    newValue.comingInInfo.floorplanID
                  );
                  break;
                default:
                  assertNever("Unhandled search option");
              }
              break;
            case "resource":
              if (newValue.seatID && newValue.floorplanID) {
                props.selectSeatCallback(newValue.seatID, newValue.floorplanID);
              }
              break;
            case "floorplan":
              props.selectSeatCallback(undefined, newValue.id);
              break;
            case "region":
              props.onSelectFloorplanLoc({ id: newValue.id, isRegion: true });
              break;
            case "room":
              props.selectRoomCallback(newValue.id);
              break;
            default:
              assertNever("Unhandled search option");
          }
          props.setExpanded(false);
          setValue(null);
          setInputValue("");
        }
      }}
      onBlur={() => {
        setValue(null);
        setInputValue("");
        props.setExpanded(false);
      }}
      onClose={() => {
        setValue(null);
        setInputValue("");
        props.setExpanded(false);
      }}
      onFocus={() => props.setExpanded(true)}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={""} variant="standard" fullWidth />
      )}
      renderOption={(renderProps, option) => (
        <li {...renderProps}>
          <RenderOption
            option={option}
            viewingFromFloorplanID={props.floorplan?.id}
          />
        </li>
      )}
    />
  );

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        marginLeft: props.expanded ? "1rem" : 0,
      }}
    >
      <div
        css={{
          transition: "all 0.5s",
          maxHeight: constants.headerHeight,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          cursor: "text",
        }}
      >
        {autocomplete}
        {searchIcon}
      </div>
    </div>
  );
}

export default SearchBar;
