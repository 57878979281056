import { DateTime, Duration, Interval } from "luxon";
import * as luxon from "luxon";

export const SIMPLE_ISO_TIME = {
  suppressSeconds: true,
  suppressMilliseconds: true,
};

type TimeUnits =
  | "millisecond"
  | "milliseconds"
  | "second"
  | "seconds"
  | "minute"
  | "minutes"
  | "hour"
  | "hours";

type CalendarDateDurationObjectUnits = Exclude<
  luxon.DurationObjectUnits,
  TimeUnits
>;

type CalendarDateDurationInput =
  | Duration
  | number
  | (CalendarDateDurationObjectUnits & luxon.DurationOptions);

export class CalendarDate {
  readonly date: DateTime;
  private constructor(dt: DateTime) {
    this.date = dt.startOf("day");
  }
  static zero = CalendarDate.fromDateTime(
    DateTime.fromMillis(0).setZone("utc")
  );
  static fromDateTime(dt: DateTime): CalendarDate {
    return new CalendarDate(
      dt.startOf("day").setZone("utc", { keepLocalTime: true })
    );
  }
  static fromISODate(date: string): CalendarDate {
    return CalendarDate.fromDateTime(DateTime.fromISO(date));
  }
  static fromJSDate(date: globalThis.Date): CalendarDate {
    return CalendarDate.fromDateTime(DateTime.fromJSDate(date));
  }
  static fromObject(
    obj: Pick<luxon.DateObjectUnits, "day" | "month" | "year">
  ): CalendarDate {
    return CalendarDate.fromDateTime(DateTime.fromObject(obj, { zone: "utc" }));
  }
  static today(zone?: string) {
    return CalendarDate.fromDateTime(
      zone ? DateTime.now().setZone(zone) : DateTime.now()
    );
  }

  get isValid() {
    return this.date.isValid;
  }

  get invalidReason() {
    return this.date.invalidReason;
  }

  get invalidExplanation() {
    return this.date.invalidExplanation;
  }

  get day() {
    return this.date.day;
  }

  get weekday() {
    return this.date.weekday;
  }

  valueOf() {
    return this.date.valueOf();
  }

  toISODate(options?: luxon.ToISODateOptions): string {
    return this.date.toISODate(options);
  }
  toPrismaDate(): Date {
    return this.date.toJSDate();
  }
  toDateTime(opts: { zone?: string | luxon.Zone }) {
    return opts.zone
      ? this.date.setZone(opts.zone, { keepLocalTime: true })
      : this.date;
  }

  setLocale(locale: string) {
    return new CalendarDate(this.date.setLocale(locale));
  }

  toLocaleString(
    options?: IntlCalendarDateFormatOptions,
    localeOptions?: luxon.LocaleOptions
  ): string {
    return this.date.toLocaleString(
      {
        ...options,
        timeZoneName: undefined,
        timeZone: undefined,
      },
      localeOptions
    );
  }

  startOf(unit: "year" | "quarter" | "month" | "week") {
    return new CalendarDate(this.date.startOf(unit));
  }

  plus(duration: CalendarDateDurationInput): CalendarDate {
    return new CalendarDate(
      this.date.plus(
        duration instanceof Duration
          ? { days: Math.floor(duration.as("days")) }
          : duration
      )
    );
  }
  minus(duration: CalendarDateDurationInput): CalendarDate {
    return new CalendarDate(
      this.date.minus(
        duration instanceof Duration
          ? { days: Math.floor(duration.as("days")) }
          : duration
      )
    );
  }

  hasSame(
    other: CalendarDate,
    unit: "day" | "week" | "month" | "year"
  ): boolean {
    return this.date.hasSame(other.date, unit);
  }

  equals(other: CalendarDate): boolean {
    return this.hasSame(other, "day");
  }

  diff(
    other: CalendarDate,
    unit?: luxon.DurationUnits,
    opts?: luxon.DiffOptions
  ) {
    return this.date.diff(other.date, unit, opts);
  }

  isWeekend() {
    return this.date.weekday == 6 || this.date.weekday == 7;
  }

  // Returns diff measured in days excluding weekends
  weekdayDiff(other: CalendarDate) {
    const positive = this >= other;
    let start = this < other ? this : other;
    const end = other > this ? other : this;
    let daysBetween = 0;

    while (start < end) {
      if (!start.isWeekend()) {
        daysBetween += 1;
      }
      start = start.plus({ days: 1 });
    }

    return positive ? daysBetween : -daysBetween;
  }
}

type IntlTimeFormatOptions = Omit<
  Intl.DateTimeFormatOptions,
  "weekday" | "era" | "year" | "month" | "day"
>;

type IntlCalendarDateFormatOptions = Omit<
  Intl.DateTimeFormatOptions,
  TimeUnits | "timeZoneName" | "timeZone"
>;

type TimeLike = {
  days?: number;
  hours?: number;
  minutes?: number;
};

const ZERO_DATETIME = DateTime.fromMillis(0, { zone: "UTC" });

export class InvalidLuxonObject<
  Type extends {
    assertValid(defaultValue?: Type): Type;
    orDefault(defaultValue: Type): Type;
  }
> {
  readonly isValid: false = false;
  readonly invalidReason: string;
  readonly invalidExplanation: string;
  private T: {
    fullName: string;
    default(): Type;
  };
  constructor(
    invalidReason: string,
    invalidExplanation: string,
    T: { fullName: string; default(): Type }
  ) {
    this.invalidReason = invalidReason;
    this.invalidExplanation = invalidExplanation;
    this.T = T;
  }
  assertValid(defaultValue?: Type) {
    if (process.env.NODE_ENV == "development") {
      throw new Error(
        `Asserted valid on an invalid '${this.T.fullName}': ${this.invalidReason}, ${this.invalidExplanation} `
      );
    } else {
      console.error(
        `Asserted valid on an invalid '${this.T.fullName}': ${this.invalidReason}, ${this.invalidExplanation}`
      );
    }
    return defaultValue ?? this.T.default();
  }
  orDefault(defaultValue: Type) {
    return defaultValue;
  }
}

/**
 * Days have 24 hours when adding/subtracting but are applied separately.
 */
export class Time {
  readonly isValid: true = true;
  readonly days: number;
  readonly hours: number;
  readonly minutes: number;

  static get fullName() {
    return "Time";
  }

  private constructor(d: Duration);
  private constructor(d: TimeLike);
  private constructor(d: TimeLike) {
    // Use Duration shiftTo function to resolve reasonable bounds on days/hours/mins
    // Only allow negative days. Times are always relative to the beginning of a day.
    const dur = (d instanceof Duration ? d : Duration.fromObject(d)).shiftTo(
      "days",
      "hours",
      "minutes"
    );

    let days: number, hours: number, minutes: number;
    if (+dur < 0) {
      days = Math.floor(dur.as("days"));
      ({ hours = 0, minutes = 0 } = dur.minus(days).normalize().toObject());
    } else {
      ({ days = 0, hours = 0, minutes = 0 } = dur.toObject());
    }

    this.days = days;
    this.hours = hours;
    this.minutes = minutes;
  }

  static zero = new Time({});
  static endOfDay = new Time({ hours: 23, minutes: 59 });

  static now(zone?: string) {
    return this.fromDateTime(
      zone ? DateTime.now().setZone(zone) : DateTime.now()
    );
  }

  static fromObject(o: TimeLike) {
    return new Time(o);
  }

  static fromDuration(d: Duration) {
    return new Time(d);
  }

  static fromISOTime(t: string) {
    const dur = Duration.fromISOTime(t);
    if (!dur.isValid)
      return new InvalidLuxonObject(
        dur.invalidReason!,
        dur.invalidExplanation!,
        Time
      );
    return Time.fromDuration(Duration.fromISOTime(t));
  }

  static fromMillis(ms: number) {
    return Time.fromDateTime(DateTime.fromMillis(ms, { zone: "UTC" }));
  }

  static fromDateTime(dt: DateTime, relativeTo?: DateTime) {
    return new Time({
      hours: dt.hour,
      minutes: dt.minute,
      days: relativeTo
        ? Math.floor(dt.diff(relativeTo.startOf("day"), "days").days)
        : 0,
    });
  }

  static default() {
    return Time.zero;
  }

  static max(first: Time, ...times: Time[]) {
    let max = first;
    for (const t of times) {
      if (t > max) {
        max = t;
      }
    }
    return max;
  }

  static min(first: Time, ...times: Time[]) {
    let min = first;
    for (const t of times) {
      if (t < min) {
        min = t;
      }
    }
    return min;
  }

  // TODO accept CalendarDate rather than DateTime
  toDateTime(dt: DateTime) {
    return dt
      .startOf("day")
      .plus({ days: this.days })
      .set({ hour: this.hours, minute: this.minutes });
  }

  toDuration() {
    return Duration.fromObject({
      days: this.days,
      hours: this.hours,
      minutes: this.minutes,
    });
  }

  toMillis() {
    return this.toDuration().toMillis();
  }

  toISOTime(options?: luxon.ToISOTimeOptions): string {
    return Duration.fromObject(
      {
        hours: this.hours,
        minutes: this.minutes,
      },
      { locale: "en" } // With locale code ar-QA we get arabic numbers when calling toISOTime (seems like a date library bug)
    ).toISOTime(options);
  }

  toISODuration(): string {
    return this.toDuration().toISO();
  }

  toLocaleString(
    options?: luxon.LocaleOptions & IntlTimeFormatOptions
  ): string {
    return ZERO_DATETIME.set({
      hour: this.hours,
      minute: this.minutes,
    }).toLocaleString(options);
  }

  assertValid(): Time {
    return this;
  }

  orDefault(): Time {
    return this;
  }

  set(t: TimeLike) {
    return new Time({
      days: t.days ?? this.days,
      hours: t.hours ?? this.hours,
      minutes: t.minutes ?? this.minutes,
    });
  }

  plus({ days = 0, hours = 0, minutes = 0 }: TimeLike) {
    return new Time({
      days: this.days + days,
      hours: this.hours + hours,
      minutes: this.minutes + minutes,
    });
  }

  minus({ days = 0, hours = 0, minutes = 0 }: TimeLike) {
    return new Time({
      days: this.days - days,
      hours: this.hours - hours,
      minutes: this.minutes - minutes,
    });
  }

  equals(t: Time) {
    return (
      this.days === t.days &&
      this.hours === t.hours &&
      this.minutes === t.minutes
    );
  }

  valueOf() {
    return this.days * 24 * 60 + this.hours * 60 + this.minutes;
  }
}

export class TimeInterval {
  readonly isValid: true = true;
  readonly start: Time;
  readonly end: Time;

  static get fullName() {
    return "TimeInterval";
  }

  private constructor(start: Time, end: Time) {
    this.start = start;
    this.end = end;
  }

  private static createChecked(
    start: Time,
    end: Time
  ): TimeInterval | InvalidLuxonObject<TimeInterval> {
    if (start > end) {
      return new InvalidLuxonObject(
        "end before start",
        `The end of an time interval must be after its start, but you had start=${start.toISODuration()} and end=${end.toISODuration()}`,
        TimeInterval
      );
    }
    return new TimeInterval(start, end);
  }

  static fromTimes(start: Time, end: Time) {
    return TimeInterval.createChecked(start, end);
  }

  // Doesn't perform `end before start` check since an interval already performs this check
  static fromInterval(
    interval: Interval,
    relativeTo?: DateTime | CalendarDate
  ) {
    const r =
      relativeTo instanceof CalendarDate
        ? relativeTo.toDateTime({ zone: interval.start.zone })
        : relativeTo;
    return new TimeInterval(
      Time.fromDateTime(interval.start, r),
      Time.fromDateTime(interval.end, r ?? interval.start)
    );
  }

  static fromDurations(start: Duration, end: Duration) {
    return TimeInterval.createChecked(
      Time.fromDuration(start),
      Time.fromDuration(end)
    );
  }

  /**
   * Parses a TimeInterval from a serialized format.
   * The format uses 2 ISO-formatted durations separated by a '/', as in ISO 8601 intervals
   * ex: PT1H/P1DT4H
   */
  static fromSerialized(s: string) {
    const [startStr, endStr] = s.split("/");
    if (!startStr || !endStr)
      return new InvalidLuxonObject(
        "invalid TimeInterval string",
        `Received invalid string '${s}'`,
        TimeInterval
      );
    const start = Duration.fromISO(startStr);
    const end = Duration.fromISO(endStr);
    if (!start.isValid)
      return new InvalidLuxonObject(
        "invalid TimeInterval string",
        `start of interval '${startStr}' could not be parsed as a Duration`,
        TimeInterval
      );
    if (!end.isValid)
      return new InvalidLuxonObject(
        "invalid TimeInterval string",
        `end of interval '${endStr}' could not be parsed as a Duration`,
        TimeInterval
      );
    return this.fromDurations(start, end);
  }

  static fromDateTimes(
    start: DateTime,
    end: DateTime,
    relativeTo?: DateTime | CalendarDate
  ) {
    const r =
      relativeTo instanceof CalendarDate
        ? relativeTo.toDateTime({ zone: start.zone })
        : relativeTo;
    return TimeInterval.createChecked(
      Time.fromDateTime(start, r),
      Time.fromDateTime(end, r ?? start)
    );
  }

  static default() {
    return TimeInterval.allDay;
  }

  static fromISODatesAndTimes(params: {
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    relativeTo?: DateTime | CalendarDate;
  }) {
    const relativeTo =
      params.relativeTo instanceof CalendarDate
        ? params.relativeTo.toDateTime({})
        : params.relativeTo?.startOf("day");
    const startDate = DateTime.fromISO(params.startDate, {
      zone: relativeTo?.zone,
    });
    const endDate = DateTime.fromISO(params.endDate, {
      zone: relativeTo?.zone,
    });
    const daysToStart = relativeTo
      ? startDate.diff(relativeTo, ["days"]).days
      : 0;
    const daysToEnd = endDate.diff(relativeTo ?? startDate, ["days"]).days;
    const start = Time.fromISOTime(params.startTime)
      .assertValid()
      .plus({ days: daysToStart });
    const end = Time.fromISOTime(params.endTime)
      .assertValid()
      .plus({ days: daysToEnd });
    return TimeInterval.fromTimes(start, end).assertValid();
  }

  static fromISOTimes(start: string, end: string) {
    const t1 = Time.fromISOTime(start);
    if (!t1.isValid)
      return new InvalidLuxonObject(
        t1.invalidReason,
        t1.invalidExplanation,
        TimeInterval
      );
    const t2 = Time.fromISOTime(end);
    if (!t2.isValid)
      return new InvalidLuxonObject(
        t2.invalidReason,
        t2.invalidExplanation,
        TimeInterval
      );
    return TimeInterval.createChecked(t1, t2);
  }

  static fromMillis(start: number, end: number) {
    return TimeInterval.createChecked(
      Time.fromMillis(start),
      Time.fromMillis(end)
    );
  }

  static allDay = new TimeInterval(Time.zero, Time.endOfDay);

  get days() {
    return this.end.days - this.start.days;
  }

  assertValid() {
    return this;
  }

  orDefault() {
    return this;
  }

  toInterval(relativeTo: DateTime): Interval {
    return Interval.fromDateTimes(
      this.start.toDateTime(relativeTo),
      this.end.toDateTime(relativeTo)
    );
  }

  toDuration(
    relativeTo: DateTime,
    unit?: luxon.DurationUnit | luxon.DurationUnit[],
    options?: luxon.DiffOptions
  ) {
    return this.toInterval(relativeTo).toDuration(unit, options);
  }

  toSerialized() {
    return `${this.start.toISODuration()}/${this.end.toISODuration()}`;
  }

  set(values: { start?: Time; end?: Time }) {
    return TimeInterval.createChecked(
      values.start ?? this.start,
      values.end ?? this.end
    );
  }

  isAllDay() {
    return this.start.equals(Time.zero) && this.end.equals(Time.endOfDay);
  }

  isMultiDay() {
    return this.end.days - this.start.days >= 1;
  }

  equals(i: TimeInterval) {
    return this.start.equals(i.start) && this.end.equals(i.end);
  }

  overlaps(i: TimeInterval) {
    return this.toInterval(ZERO_DATETIME).overlaps(i.toInterval(ZERO_DATETIME));
  }

  engulfs(i: TimeInterval) {
    return this.toInterval(ZERO_DATETIME).engulfs(i.toInterval(ZERO_DATETIME));
  }

  constrainBy(i: TimeInterval, fallback: TimeInterval = i): TimeInterval {
    if (!this.overlaps(i)) {
      return fallback;
    } else {
      return new TimeInterval(
        Time.max(this.start, i.start),
        Time.min(this.end, i.end)
      );
    }
  }
}
