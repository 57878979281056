/* eslint-disable @typescript-eslint/no-namespace */
import {
  type,
  string,
  boolean,
  literal,
  number,
  define,
  optional,
  nullable,
  array,
  union,
  coerce,
  is,
  defaulted,
  create,
  record,
  intersection,
  lazy,
  tuple,
  size,
  min,
  partial,
} from "superstruct";
import type { Struct, Infer } from "superstruct";

const ISO8601 = string;

export type DateString<T extends string> = string & { _: T };

import {
  type as typeSS,
  array as arraySS,
  string as stringSS,
  boolean as booleanSS,
  number as numberSS,
  optional as optionalSS,
} from "superstruct";
namespace JSONType {
  export function string() {
    return coerce(stringSS(), stringSS(), (value) =>
      // I'm gonna have to do something about this at some point..
      value.startsWith('"') ? create(JSON.parse(value), stringSS()) : value
    );
  }
  export function number() {
    return coerce(numberSS(), stringSS(), (value) => parseFloat(value));
  }
  export function optional(typ: Struct<any, null>) {
    return coerce(optionalSS(typ), string(), (value) =>
      value == "" ? undefined : create(value, typ)
    );
  }
  export function boolean() {
    return coerce(
      booleanSS(),
      stringSS(),
      (value) => value.toLowerCase() == "true"
    );
  }
  export function type<S extends Record<string, Struct<any, any>>>(schema: S) {
    return coerce(typeSS(schema), stringSS(), (value) =>
      create(JSON.parse(value), typeSS(schema))
    );
  }

  export function array<S extends Struct<any>>(schema: S) {
    return coerce(arraySS(schema), stringSS(), (value) =>
      create(JSON.parse(value), arraySS(schema))
    );
  }

  export function parse<S extends any>(schema: Struct<S, any>) {
    return coerce(schema, stringSS(), (value) =>
      create(JSON.parse(value), schema)
    );
  }
}

function POST<
  T extends { body?: Struct<any>; return?: Struct<any>; multipart?: boolean },
  R extends string
>(route: R, o: T) {
  return { method: "POST" as const, route: route, types: o };
}

function GET<
  T extends { query?: Struct<any>; return?: Struct<any> },
  R extends string
>(route: R, rest: T) {
  return { method: "GET" as const, route: route, types: rest };
}

type MaybeInfer<T, Else = unknown> = T extends Struct<any> ? Infer<T> : Else;
export type InferReturnType<T extends { types: { return?: Struct<any> } }> =
  MaybeInfer<T["types"]["return"]>;
export type InferBodyType<T extends { types: { body?: Struct<any> } }> =
  MaybeInfer<T["types"]["body"]>;
export type InferQueryType<T extends { types: { query?: Struct<any> } }> =
  MaybeInfer<T["types"]["query"]>;

// Unchecked
const DateString: <T extends string>() => Struct<DateString<T>> = () =>
  define("DateString", (s) => is(s, string()));

const domain = type({
  friendlyName: string(),
  name: string(),
  isSandbox: boolean(),
  syncIDP: optional(string()),
  headerColor: string(),
  primaryColor: string(),
  secondaryColor: string(),
  logoSrc: string(),
  logoInvertedSrc: string(),
  workspaceEnabled: boolean(),
  timeBasedBookingsEnabled: boolean(),
  scheduledVisitorsEnabled: boolean(),
  buddySyncEnabled: boolean(),
  meetingWizardEnabled: boolean(),
  managerBookingEnabled: boolean(),
  roomsEnabled: boolean(),
  gsCalAccessGranted: boolean(),
  allowFloorplanMeetingBooking: boolean(),
  showOverdueWarning: boolean(),
  gsEnabled: boolean(),
  defaultWorkingHoursStart: string(),
  defaultWorkingHoursEnd: string(),
});

export namespace Concierge {
  export const PrintJobStatus = union([
    literal("SUBMITTED"),
    literal("SUCCESS"),
    literal("FAILED"),
  ]);
  export type PrintJobStatus = Infer<typeof PrintJobStatus>;

  export const VisitorDeviceMode = union([
    literal("PRINTER"),
    literal("VISITOR"),
  ]);
  export type VisitorDeviceMode = Infer<typeof VisitorDeviceMode>;

  export const Login = POST("/apis/concierge/login", {
    body: type({
      accessCode: optional(string()),
    }),
    return: type({
      deviceType: string(),
      deviceID: string(),
      locationID: string(),
      cameraDir: string(),
      printEnabled: boolean(),
      contactless: boolean(),
      domain: domain,
      // TODO: deprecated
      domainBgColor: string(),
      domainLogoPath: string(),
      domainLogoOnBgPath: string(),
      domainName: string(),
    }),
  });

  export const PrintStatus = GET("/apis/concierge/get-print-status", {
    query: type({
      jobID: JSONType.number(),
      domainCode: optional(string()),
    }),
    return: type({
      printStatus: PrintJobStatus,
      errorMessage: optional(string()),
    }),
  });

  export const SetPrintStatus = POST("/apis/concierge/print-job-status", {
    body: type({
      jobID: number(),
      success: boolean(),
      error: optional(string()),
    }),
    return: type({ success: boolean() }),
  });

  /** @deprecated since we need it to be a multipart */
  export const RecordVisitArrival = POST(
    "/apis/concierge/record-visit-arrival",
    {
      body: type({
        code: optional(string()),
        domainCode: optional(string()),
        name: string(),
        visitType: string(),
        visitorType: optional(string()),
        hostEmail: optional(string()),
      }),
      return: type({ jobID: optional(number()) }),
    }
  );

  export const RecordVisit = POST("/apis/concierge/record-visit", {
    body: type({
      code: optional(string()), // deprecated -- used by old version of app for auth
      domainCode: optional(string()), // deprecated -- used by old version of app for auth
      name: string(),
      visitType: string(),
      visitorType: optional(string()),
      hostEmail: optional(string()),
      email: string(),
      companyName: optional(string()),
    }),
    multipart: true,
    return: type({
      jobID: optional(number()),
      sentVisitorGuide: optional(boolean()),
      visitID: number(),
    }),
  });

  export const AcknowledgeVisitorGuideReceipt = POST("/apis/concierge/acknowledge-visitor-guide-receipt", {
    body: type({
      code: optional(string()), // deprecated -- used by old version of app for auth
      domainCode: optional(string()), // deprecated -- used by old version of app for auth
      visitID: number(),
    }),
    return: type({ success: boolean()}),
});

  export const TriggerBadgePrintChange = POST(
    "/apis/concierge/trigger-badge-print-change",
    {
      body: type({
        floorplanID: number(),
      }),
      return: type({ success: boolean() }),
    }
  );
  export const GetRegisterInfo = GET("/apis/concierge/get-register-info", {
    query: type({
      code: optional(string()),
      domainCode: optional(string()),
    }),
    return: type({
      logo: nullable(string()),
      domainName: string(),
      friendlyName: string(),
      questionnaireEnabled: boolean(),
      cameraDir: string(),
      printEnabled: boolean(),
      users: array(
        type({
          email: string(),
          pictureUrl: nullable(string()),
          name: string(),
        })
      ),
      mode: VisitorDeviceMode,
    }),
  });

  export const RequestPrintJob = POST("/apis/concierge/request-print-job", {
    return: type({
      jobID: optional(number()),
    }),
  });

  export const GetBadgeImage = GET("/apis/concierge/badge-image", {
    query: type({
      jobID: JSONType.number(),
    }),
  });

  export const SearchVisitors = GET("/apis/concierge/search-visitors", {
    query: type({
      name: string(),
      date: string(),
      email: optional(string()),
      hostEmail: optional(string()),
    }),
    return: type({
      visitors: array(
        type({
          name: string(),
          email: string(),
          companyName: optional(string()),
          visitorImage: optional(string()),
          scheduledVisit: optional(
            type({
              host: type({
                name: string(),
                email: string(),
                pictureUrl: nullable(string()),
              }),
              reason: string(),
              checkedIn: boolean(),
            })
          ),
          vaccinationStatus: number(),
        })
      ),
    }),
  });
}

export const SeatingAutobook = POST("/apis/seating/autobook", {
  body: type({
    resourceType: union([literal("DESK"), literal("PARKING")]),
    floorplanID: number(),
    preferredSeatID: number(),
    days: array(DateString<"yyyy-MM-dd">()),
  }),
  return: type({
    failedDays: array(string()),
    bookedDays: array(string()),
    verifyURL: optional(string()),
    warning: optional(string()),
  }),
});

const VisitType = () =>
  type({
    id: number(),
    name: string(),
    email: optional(string()),
    hostName: optional(string()),
    hostEmail: optional(string()),
    reason: string(),
    visitorType: optional(string()),
    acknowledgedTime: optional(ISO8601()),
    companyName: optional(string()),
    arrival: optional(number()),
    leftAt: optional(number()),
    floorplan: type({
      name: string(),
      floorplanID: number(),
      timezone: string(),
      userVisible: boolean(),
    }),
    badgeCode: optional(string()),
    date: DateString<"yyyy-MM-dd">(),
    accepted: optional(boolean()),
    start: ISO8601(),
  });

export namespace Visit {
  export const GetVisits = GET("/apis/visit/get-visits", {
    query: type({
      floorplanID: optional(JSONType.number()),
      buildingID: optional(JSONType.number()),
      allUsers: JSONType.boolean(),
      includePast: JSONType.boolean(),
      filterDenied: optional(JSONType.boolean()), // TODO: remove optional once deployed for some time
    }),
    return: type({
      visits: array(VisitType()),
      visitorBadgeCodeOptions: optional(array(string())),
    }),
  });
  export const GetPastVisitors = GET("/apis/visit/get-past-visitors", {
    return: type({
      visitors: array(type({ name: string(), email: optional(string()) })),
    }),
  });
  export const Create = POST("/apis/visit/create-visit", {
    body: type({
      name: optional(string()), // deprecated
      email: optional(string()), // deprecated
      date: optional(DateString<"yyyy-MM-dd">()), // deprecated
      start: ISO8601(), // deprecated
      badgeCode: optional(string()), // deprecated
      // TODO: make these non-optional
      startDate: optional(DateString<"yyyy-MM-dd">()),
      endDate: optional(DateString<"yyyy-MM-dd">()), // start & end are inclusive
      startTime: optional(string()),
      visitors: optional(
        array(
          type({
            name: string(),
            email: optional(string()),
            badgeCode: optional(string()),
          })
        )
      ),
      floorplanID: number(),
      hostEmail: optional(string()), // admin-only
      reason: defaulted(string(), "OTHER"),
      companyName: optional(string()),
      accepted: optional(boolean()),
      suppressInvitation: optional(boolean()),
      bypassWarnings: optional(boolean()),
    }),
    return: type({
      visit: optional(VisitType()),
      visits: array(VisitType()),
      warning: optional(string()),
    }),
  });
  export const Edit = POST("/apis/visit/edit-visit", {
    body: type({
      id: number(),
      name: string(),
      email: optional(string()),
      date: DateString<"yyyy-MM-dd">(),
      start: ISO8601(),
      floorplanID: number(),
      badgeCode: optional(string()),
      hostEmail: optional(string()),
      reason: defaulted(string(), "OTHER"),
      companyName: optional(string()),
      accepted: optional(boolean()),
      suppressInvitation: optional(boolean()),
    }),
    return: VisitType(),
  });
  export const Delete = POST("/apis/visit/delete-visit", {
    body: type({ id: number() }),
    return: type({ success: literal(true) }),
  });
  export const GetInfo = GET("/apis/visit/get-visit-info", {
    query: type({ visitToken: JSONType.string() }),
    return: type({
      host: nullable(
        type({
          name: string(),
          email: string(),
        })
      ),
      floorplan: type({
        name: string(),
        id: number(),
        timezone: string(),
        buildingName: optional(string()),
      }),
      verifyToken: optional(string()),
      questionnaireEnabled: boolean(),
      date: DateString<"yyyy-MM-dd">(),
      start: optional(ISO8601()), // TODO make this not optional after deploy
    }),
  });
  export const VisitCheckin = POST("/apis/visit/visit-checkin", {
    body: type({ visitToken: string() }),
    return: type({
      verifyToken: optional(string()),
    }),
  });
  export const DownloadCSV = GET("/apis/visit/download-visit-csv", {});
  export const AdminMarkScanned = POST("/apis/visit/admin-mark-scanned", {
    body: type({ visitID: number() }),
    return: type({}),
  });
  export const AdminMarkLeft = POST("/apis/visit/admin-mark-left", {
    body: type({ visitID: number() }),
    return: type({}),
  });
}

const resourceType = union([
  literal("DESK"),
  literal("PARKING"),
  literal("TRAINING_DESK"),
  literal("LOCKER"),
  literal("LAB_BENCH"),
  literal("TC_HOOD"),
  literal("MOTHERS_ROOM"),
  literal("EQUIPMENT"),
]);

const weekDaysEnabled = array(boolean()); // Array should be a multiple of 7

export namespace Seating {
  export const GetQRCode = GET("/apis/seating/qr-code", {
    query: type({ chl: JSONType.string(), w: optional(JSONType.number()) }),
  });

  /// API to expose reservation data (who, what, when) with filters for name, date, desk & floorplan
  export const ReservationsList = GET("/apis/seating/reservations/list", {
    query: type({
      limit: optional(JSONType.number()),
      continuationToken: optional(JSONType.string()),
      employeeEmail: optional(JSONType.string()),
      resourceName: optional(JSONType.string()),
      resourceID: optional(JSONType.number()),
      startDate: optional(JSONType.string()),
      endDate: optional(JSONType.string()),
      floorplanID: optional(JSONType.number()),
      userKind: optional(
        union([literal("domain"), literal("external"), literal("all")])
      ),
    }),
    return: type({
      reservations: array(
        type({
          date: DateString<"yyyy-MM-dd">(),
          employee: type({ name: string(), email: string() }),
          resource: type({ name: string(), type: string(), id: number() }),
          floorplan: type({ name: string(), id: number() }),
          scans: optional(number()),
        })
      ),
      continuationToken: optional(string()),
    }),
  });
  export const VisitsList = GET("/apis/seating/visits/list", {
    query: type({
      limit: optional(JSONType.number()),
      continuationToken: optional(JSONType.string()),
      hostEmail: optional(JSONType.string()),
      visitorEmail: optional(JSONType.string()),
      startDate: optional(JSONType.string()),
      endDate: optional(JSONType.string()),
      floorplanID: optional(JSONType.number()),
    }),
    return: type({
      visits: array(
        type({
          date: DateString<"yyyy-MM-dd">(),
          visitor: type({ name: string(), email: optional(string()) }),
          host: optional(type({ name: string(), email: string() })),
          floorplan: type({ name: string(), id: number() }),
          scans: optional(number()),
          entryScanTime: optional(string()),
          exitScanTime: optional(string()),
          badgeCode: optional(string()),
        })
      ),
      continuationToken: optional(string()),
    }),
  });
  export const DropinsList = GET("/apis/seating/dropins/list", {
    query: type({
      limit: optional(JSONType.number()),
      continuationToken: optional(JSONType.string()),
      employeeEmail: optional(JSONType.string()),
      startDate: optional(JSONType.string()),
      endDate: optional(JSONType.string()),
      floorplanID: optional(JSONType.number()),
    }),
    return: type({
      dropins: array(
        type({
          date: DateString<"yyyy-MM-dd">(),
          employee: type({ name: string(), email: string() }),
          floorplan: type({ name: string(), id: number() }),
          scans: optional(number()),
        })
      ),
      continuationToken: optional(string()),
    }),
  });
  export const Groups = GET("/apis/seating/usergroups/list", {
    return: type({
      groups: array(
        type({
          id: number(),
          parentID: optional(number()),
          name: string(),
          email: optional(string()),
          type: string(),
        })
      ),
    }),
  });
  const FullGroupInfo = type({
    id: number(),
    parentID: optional(number()),
    name: string(),
    email: optional(string()),
    type: string(),
    memberUsers: array(
      type({
        id: number(),
        email: string(),
        name: string(),
        external: boolean(),
        departmentID: optional(number()),
      })
    ),
    memberGroups: array(type({ id: number(), name: string() })),
  });

  export const Group = GET("/apis/seating/usergroups/get", {
    query: type({ groupID: JSONType.number() }),
    return: FullGroupInfo,
  });
  export const DeleteGroup = POST("/apis/seating/usergroups/delete", {
    body: type({ id: number() }),
    return: type({ success: boolean() }),
  });
  export const UpsertGroup = POST("/apis/seating/usergroups/upsert", {
    body: type({ name: string(), id: optional(number()) }),
    return: FullGroupInfo,
  });
  export const RequestAccess = POST("/apis/seating/usergroups/request-access", {
    body: type({ id: number() }),
    return: type({}),
  });
  export const UpdateGroupMembers = POST(
    "/apis/seating/usergroups/update-group-members",
    {
      body: type({
        id: number(),
        addedUserEmails: optional(array(string())),
        removedUserEmails: optional(array(string())),
        addedGroupIDs: optional(array(number())),
        removedGroupIDs: optional(array(number())),
      }),
      return: FullGroupInfo,
    }
  );
  export const Users = GET("/apis/seating/users/list", {
    query: type({
      includeExternal: optional(JSONType.boolean()),
      includeVisitors: optional(JSONType.boolean()),
      includeEmployees: optional(JSONType.boolean()),
      includeRecentlyDeleted: optional(JSONType.boolean()),
    }),
    return: type({
      users: array(
        type({
          visitID: optional(number()),
          name: string(),
          email: string(),
          accessCode: optional(string()),
          badgeCode: optional(string()),
          ldap: optional(string()),
          enabled: boolean(),
          isDeleted: boolean(),
          isVisitor: boolean(),
          isAdmin: boolean(),
          isUserAdmin: boolean(),
          isManager: boolean(),
          building: optional(type({ name: string(), id: number() })),
          floorplan: optional(type({ name: string(), id: number() })),
          department: optional(type({ name: string(), id: number() })),
          jobtitle: optional(string()),
          employeeType: optional(string()),
          employeePolicy: optional(string()),
          zipcode: optional(string()),
          managerEmails: array(string()),
        })
      ),
    }),
  });

  const WeekBookings = array(array(string()));

  export const UserInfo = POST("/apis/seating/userinfo", {
    body: type({
      registrationToken: optional(string()),
    }),
    return: type({
      email: string(),
      name: string(),
      pictureURL: optional(string()),
      isTestUser: boolean(),
      isAdmin: boolean(),
      isUserAdmin: boolean(),
      isManager: boolean(),
      isSuperAdmin: boolean(),
      isStaff: boolean(),
      vaccinationStatus: number(),
      defaultFloorplanID: optional(number()),
      userSchedule: array(boolean()),
      allowedHours: type({ start: string(), end: string() }),
      languageCode: string(),
      domain: domain,
    }),
  });

  const SeatInfoType = type({
    id: number(),
    name: string(),
    events: array(
      type({
        kind: union([
          literal("RESERVED"),
          literal("CHECKIN"),
          literal("YOURS_ALLOWED"),
          literal("OCCUPIED"),
          literal("SHUTDOWN"),
        ]),
        bookingID: optional(number()),
        startDate: string(),
        endDate: string(),
        startTime: string(),
        endTime: string(),
        avatar: optional(
          type({
            name: string(),
            email: string(),
            src: optional(string()),
            groupID: optional(number()),
            isBuddy: optional(boolean()),
          })
        ),
        reason: optional(string()),
        scanCount: optional(number()),
      })
    ),

    enabled: boolean(),
    imgURL: string(),
    description: string(),
    resourceType: resourceType,
    seatType: string(),
    svgX: number(),
    svgY: number(),
    svgAngle: number(),
    svgScale: number(),
  });

  export const FloorplanInfo = GET("/apis/seating/floorplan", {
    query: type({
      date: optional(JSONType.string()),
      timezone: JSONType.string(),
      floorplanID: optional(JSONType.number()),
    }),
    return: type({
      id: number(),
      name: string(),
      shortName: string(),
      buildingName: optional(string()),
      floorplanImg: optional(string()),
      floorplanDimensions: optional(
        type({ width: number(), height: number() })
      ),
      timezone: string(),
      userVisible: boolean(),
      seats: array(SeatInfoType),
      rooms: array(
        type({
          id: number(),
          name: string(),
          bookable: boolean(),
          viewable: boolean(),
          calendarID: string(),
          timezone: string(),
          svgPoints: string(),
          imgURL: string(),
          description: string(),
          maxAllowedMins: optional(number()),
          maxCapacity: optional(number()),
        })
      ),
      myBookings: array(
        type({
          floorplan: type({ id: number(), name: string() }),
          seat: type({
            id: number(),
            name: string(),
            resourceType: resourceType,
          }),
          startTime: ISO8601(),
          endTime: ISO8601(),
          scanCount: optional(number()),
          bookingID: number(),
        })
      ),
      autoReleasedSeat: boolean(),
      weekBookings: type({
        DESK: optional(WeekBookings),
        PARKING: optional(WeekBookings),
        TRAINING_DESK: optional(WeekBookings),
        LOCKER: optional(WeekBookings),
        LAB_BENCH: optional(WeekBookings),
        TC_HOOD: optional(WeekBookings),
        MOTHERS_ROOM: optional(WeekBookings),
        EQUIPMENT: optional(WeekBookings),
      }),
      dropinWeekBookings: WeekBookings,
      settings: type({
        maxWeeklyBookings: number(),
        bookAheadDaysMin: number(),
        bookAheadDaysMax: number(),
        dropinEnabled: boolean(),
        checkinRequired: boolean(),
        maxDailyVisits: number(),
        allowFutureDropin: boolean(),
        hideAutoBookButton: boolean(),
        weekendsBookable: boolean(),
        checkinQREnabled: boolean(),
        nightShiftEnabled: boolean(),
        questionnaireEnabled: boolean(),
        onBehalfBulkBookings: optional(boolean()), // TODO: Delete this after changes are live
        onBehalfBulkBookingsEnabled: boolean(),
        defaultWorkingHoursStart: string(),
        defaultWorkingHoursEnd: string(),
        streetAddress: optional(string()),
      }),
      dropinData: type({
        hasDropin: boolean(),
        needsCheckin: boolean(),
        bookingID: optional(number()),
      }),
      curDay: ISO8601(),
      day: ISO8601(),
      todaysNumVisits: number(),
      verifyQRURL: optional(string()),
    }),
  });

  export const FloorplanName = GET("/apis/seating/floorplan-name", {
    query: type({ floorplanID: optional(JSONType.number()) }),
    return: type({
      name: string(),
    }),
  });

  export const MultiBookFloorplanInfo = GET(
    "/apis/seating/floorplan-multibook",
    {
      query: type({
        floorplanID: JSONType.number(),
        dates: JSONType.string(),
        startTime: JSONType.string(),
        endTime: JSONType.string(),
      }),
      return: type({
        seats: array(SeatInfoType),
      }),
    }
  );

  export const BroadcastInfo = GET("/apis/seating/broadcast-info", {
    return: type({
      broadcastEnabled: boolean(),
      domainFriendlyName: string(),
    }),
  });

  export const NotifyAutoReleasedSeats = POST(
    "/apis/seating/notify-auto-released",
    {
      body: type({
        seatIDs: array(number()),
        reason: string(),
      }),
      return: type({
        success: literal(true),
      }),
    }
  );

  /**
   * Should be called as a background task through CLOUD TASKS from django.
   */
  export const NotifyAccountExists = POST(
    "/apis/seating/notify-account-exists",
    {
      body: type({ email: string() }),
      return: type({ success: literal(true) }),
    }
  );

  /**
   * Can be called as a background task through CLOUD TASKS or directly with admin creds.
   */
  export const SoftDeleteUsers = POST("/apis/seating/soft-delete-users", {
    body: type({
      domainID: optional(number()),
      userIDs: array(number()),
      userEmails: optional(array(string())),
      addSyncException: optional(boolean()),
    }),
    return: type({
      success: boolean(),
    }),
  });

  export const UpdateProfileLanguage = POST(
    "/apis/seating/update-profile-language",
    {
      body: type({
        languageCode: string(),
      }),
      return: type({
        success: literal(true),
      }),
    }
  );

  const FloorplanType = type({
    name: string(),
    id: number(),
    isRegion: literal(false),
    timezone: string(),
    tag: optional(string()),
    userVisible: boolean(),
  });
  type FloorplanType = Infer<typeof FloorplanType>;
  type BuildingType = {
    name: string;
    id: number;
    isRegion: true;
    tag?: string;
    children: (FloorplanType | BuildingType)[];
  };

  const BuildingType: Struct<BuildingType> = type({
    name: string(),
    id: number(),
    isRegion: literal(true),
    tag: optional(string()),
    children: lazy(() => array(union([BuildingType, FloorplanType]))),
  });
  export const LocationPickerInfo = GET("/apis/seating/location_picker_info", {
    query: type({
      excludeDropinOnly: JSONType.boolean(),
    }),
    return: type({
      locations: array(union([BuildingType, FloorplanType])),
    }),
  });

  export const GetLocationSettingsData = GET(
    "/apis/seating/location-settings",
    {
      query: type({
        locationType: JSONType.string(),
        locationID: optional(JSONType.number()),
      }),
      return: type({
        settings: type({
          maxWeeklyBookings: number(),
          bookAheadMax: number(),
          bookAheadMin: number(),
          maxCapacityPerDay: number(),
          defaultWorkHoursStart: string(),
          defaultWorkHoursEnd: string(),
          streetAddress: string(),
          userVisible: optional(boolean()),
          dropinsEnabled: boolean(),
          qrsEnabled: boolean(),
          weekendsBookable: boolean(),
          appCheckinOnDayRequired: boolean(),
          autoReserveAssignments: boolean(),
          foreverQRCodes: boolean(),
          hideAutoBookButton: boolean(),
          futureDropinsAllowed: boolean(),
          createCalendarEvents: boolean(),
          questionnaireReminderEmailForceOff: boolean(),
          scanReminderForceOff: boolean(),
          autoReleaseForceOff: boolean(),
          visitorCheckinEmailForceOff: boolean(),
          questionnaireReminderTimedelta: optional(number()),
          scanReminderTimedelta: optional(number()),
          autoReleaseTimedelta: optional(number()),
          visitorCheckinEmailTimedelta: optional(number()),
        }),
        rawSettings: type({
          maxWeeklyBookings: optional(number()),
          bookAheadMax: optional(number()),
          bookAheadMin: optional(number()),
          maxCapacityPerDay: optional(number()),
          defaultWorkHoursStart: optional(string()),
          defaultWorkHoursEnd: optional(string()),
          streetAddress: string(),
          dropinsEnabled: optional(boolean()),
          qrsEnabled: optional(boolean()),
          weekendsBookable: optional(boolean()),
          appCheckinOnDayRequired: optional(boolean()),
          autoReserveAssignments: optional(boolean()),
          foreverQRCodes: optional(boolean()),
          hideAutoBookButton: optional(boolean()),
          futureDropinsAllowed: optional(boolean()),
          createCalendarEvents: optional(boolean()),
          questionnaireReminderEmailForceOff: optional(boolean()),
          scanReminderForceOff: optional(boolean()),
          autoReleaseForceOff: optional(boolean()),
          visitorCheckinEmailForceOff: optional(boolean()),
          questionnaireReminderTimedelta: optional(number()),
          scanReminderTimedelta: optional(number()),
          autoReleaseTimedelta: optional(number()),
          visitorCheckinEmailTimedelta: optional(number()),
        }),
        parentBuilding: optional(
          type({
            id: number(),
            name: string(),
          })
        ),
        location: optional(union([BuildingType, FloorplanType])),
      }),
    }
  );

  export const SetDefaultLocation = POST("/apis/seating/set-default-location", {
    body: type({
      floorplanID: optional(number()),
    }),
    return: type({
      success: boolean(),
    }),
  });

  export const UpdateSetting = POST("/apis/seating/update-setting", {
    body: intersection([
      type({
        settingsKey: string(),
        location: union([
          type({ locationType: literal("building"), buildingID: number() }),
          type({ locationType: literal("floorplan"), floorplanID: number() }),
          type({ locationType: literal("domain") }),
        ]),
      }),
      union([
        type({
          type: literal("number"),
          newValue: nullable(number()),
        }),
        type({
          type: literal("string"),
          newValue: nullable(string()),
        }),
        type({
          type: literal("boolean"),
          newValue: nullable(boolean()),
        }),
        type({
          type: literal("timedelta"),
          newValue: optional(nullable(number())), // Not pretty but using null to inherit, and undefined to force-off (so it's consistent with the other nullable ones)
        }),
      ]),
    ]),

    return: type({
      success: literal(true),
    }),
  });

  export const ExecuteAutoAssign = POST("/apis/seating/execute-auto-assign", {
    body: type({
      ruleID: number(),
    }),
    return: type({
      success: boolean(),
    }),
  });

  export const GroupSearch = GET("/apis/seating/group-search", {
    query: type({
      query: JSONType.string(),
      limit: optional(JSONType.number()),
    }),
    return: type({
      groups: array(
        type({
          name: string(),
          email: string(),
          groupID: string(),
        })
      ),
    }),
  });

  export const MapSearch = GET("/apis/seating/map-search", {
    query: type({
      query: JSONType.string(),
      floorplanID: JSONType.number(),
      date: JSONType.string(),
      lowerBoundLimit: JSONType.number(),
    }),
    return: type({
      searchResults: array(
        union([
          type({
            type: literal("user"),
            avatar: type({
              name: string(),
              src: optional(string()),
              email: string(),
            }),
            comingInInfo: union([
              type({
                type: literal("dropin"),
                floorplanID: number(),
                floorplanName: string(),
              }),
              type({
                type: literal("resourceBooking"),
                seatID: number(),
                seatName: string(),
                resourceType: resourceType,
                floorplanID: number(),
                floorplanName: string(),
              }),
              type({
                type: literal("resourceAssignedRule"), // Not booked, but that desk is assigned to them.
                seatID: number(),
                seatName: string(),
                resourceType: resourceType,
                floorplanID: number(),
                floorplanName: string(),
              }),
              type({
                type: literal("notComingIn"),
              }),
              type({
                type: literal("blocked"),
              }),
            ]),
          }),
          type({
            type: literal("resource"),
            seatID: number(),
            seatName: string(),
            resourceType: resourceType,
            floorplanID: number(),
            floorplanName: string(),
          }),
          type({
            type: literal("floorplan"),
            name: string(),
            id: number(),
            parentName: optional(string()),
          }),
          type({
            type: literal("region"),
            name: string(),
            id: number(),
          }),
          type({
            type: literal("room"),
            id: number(),
            name: string(),
            email: string(),
            floorplanID: number(),
            floorplanName: string(),
          }),
        ])
      ),
    }),
  });

  export const UserSearch = GET("/apis/seating/user-search", {
    query: type({
      query: JSONType.string(),
      idpOnly: optional(JSONType.boolean()),
      directReportsOnly: optional(JSONType.boolean()),
      sameDepartmentOnly: optional(JSONType.boolean()),
      includeTempVisitors: optional(JSONType.boolean()),
      includeRecentlyDeleted: optional(JSONType.boolean()),
      includeDisabledUsers: optional(JSONType.boolean()),
      limit: optional(JSONType.number()),
    }),
    return: type({
      users: array(
        type({
          name: string(),
          src: optional(string()),
          email: string(),
          jobtitle: optional(string()),
          isDeleted: boolean(),
          visitID: optional(number()),
        })
      ),
    }),
  });

  export const ReleaseBooking = POST("/apis/seating/release-booking", {
    body: type({
      bookingID: number(),
      asAdmin: boolean(),
    }),
    return: type({
      success: boolean(),
    }),
  });

  export const MakeDropin = POST("/apis/seating/make-dropin", {
    body: type({
      floorplanID: number(),
      dates: array(ISO8601()),
      asUserEmail: optional(string()),
      markAsArrived: boolean(),
    }),
    return: type({
      qrURL: optional(string()),
      failedDays: array(ISO8601()),
      bookedDays: array(ISO8601()),
      warning: string(),
    }),
  });

  export const ReleaseDropin = POST("/apis/seating/release-dropin", {
    body: type({
      bookingID: number(),
      asAdmin: boolean(),
    }),
    return: type({
      success: boolean(),
    }),
  });

  export const MakeBookings = POST("/apis/seating/make-bookings", {
    body: type({
      floorplanID: optional(number()),
      days: array(string()),
      seatID: optional(number()),
      canSubstituteSeat: optional(boolean()),
      resourceType: resourceType,
      startTime: string(),
      endTime: string(),
      bookingLengthDays: number(), // This is how many days in a single booking (e.g. 1 used for night shift)
      asUser: optional(string()),
      markAsArrived: optional(boolean()),
      release: optional(boolean()),
    }),
    return: type({
      failedDays: array(string()),
      bookedDays: array(string()),
      verifyURL: optional(string()),
      warnings: optional(string()),
      checkinRequired: boolean(),
    }),
  });

  // This is a bulk version of MakeBookings, for group bookings
  export const MakeBookingsBulk = POST("/apis/seating/make-bookings-bulk", {
    body: type({
      bookings: array(MakeBookings.types.body),
    }),
    return: type({
      outcomes: array(
        intersection([
          type({
            seatID: optional(number()),
            asUser: optional(string()),
          }),
          MakeBookings.types.return,
        ])
      ),
    }),
  });

  export const EmployeesPresentList = GET("/apis/seating/employees-present", {
    query: type({
      date: JSONType.string(),
      floorplanID: optional(JSONType.number()),
      buildingID: optional(JSONType.number()),
    }),
    return: type({
      employees: array(
        type({
          id: number(),
          name: string(),
          src: optional(string()),
          email: string(),
          scanned: boolean(),
          isBuddy: boolean(),
          booking: type({
            bookingID: optional(number()),
            startDate: string(),
            endDate: string(),
            startTime: string(),
            endTime: string(),
            floorplan: type({
              name: string(),
              id: number(),
              timezone: string(),
            }),
            seat: optional(
              type({ name: string(), id: number(), resourceType: resourceType })
            ),
          }),
        })
      ),
    }),
  });
}

export namespace Embrava {
  // Endpoints used for the Embrava device integration (screens that show booking info / let users book desks with their badge)
  export const GetWorkspaceDetails = GET("/apis/embrava/get-desk-details", {
    query: type({
      embravaID: string(),
    }),
    return: type({
      deskID: string(),
      deskName: string(),
      neighborhood: string(),
      floor: string(),
      building: string(),
      timeZone: string(),
      operatingHours: type({
        Mon: array(string()),
        Tue: array(string()),
        Wed: array(string()),
        Thu: array(string()),
        Fri: array(string()),
        Sat: array(string()),
        Sun: array(string()),
      }),
    }),
  });

  export const GetUserDetails = GET("/apis/embrava/get-user-details", {
    query: type({
      badgeCode: string(),
    }),
    return: type({
      userID: string(),
      fullName: string(),
    }),
  });

  export const CreateBooking = POST("/apis/embrava/create-booking", {
    body: type({
      deskID: string(),
      badgeCode: string(),
      start: ISO8601(),
      end: ISO8601(),
    }),
    return: type({
      bookingID: string(),
      fullName: string(),
      badgeCode: string(),
    }),
  });

  export const UpdateBooking = POST("/apis/embrava/update-booking", {
    body: type({
      bookingID: string(),
      start: ISO8601(),
      end: ISO8601(),
    }),
    return: type({
      success: boolean(),
    }),
  });

  export const CheckInBooking = POST("/apis/embrava/check-in-booking", {
    body: type({
      bookingID: string(),
    }),
    return: type({
      success: boolean(),
    }),
  });

  export const CheckOutBooking = POST("/apis/embrava/check-out-booking", {
    body: type({
      bookingID: string(),
    }),
    return: type({
      success: boolean(),
    }),
  });

  export const CancelBooking = POST("/apis/embrava/cancel-booking", {
    body: type({
      bookingID: string(),
    }),
    return: type({
      success: boolean(),
    }),
  });

  export const GetDeskBookings = GET("/apis/embrava/get-desk-bookings", {
    query: type({
      embravaID: string(),
      start: ISO8601(),
      end: ISO8601(),
    }),
    return: type({
      bookings: array(
        type({
          bookingID: string(),
          deskID: string(),
          userID: string(),
          fullName: string(),
          badgeCode: string(),
          start: string(),
          end: string(),
          lastModifiedTimestamp: number(),
          checkedIn: boolean(),
        })
      ),
    }),
  });
}

export namespace AccessControl {
  export const HealthCheck = GET("/apis/access-control/health-check", {
    return: type({ validKey: boolean() }),
  });

  export const BadgeList = GET("/apis/access-control/badge/list", {
    query: type({
      continuationToken: optional(JSONType.string()),
    }),
    return: type({
      badges: array(
        type({
          badgeID: string(),
          userEmail: string(),
        })
      ),
      continuationToken: optional(string()),
    }),
  });
  export const GetToken = GET("/apis/access-control/get-token", {
    return: type({
      token: string(),
      expiresInMinutes: number(),
    }),
  });
  export const BadgeRegister = POST("/apis/access-control/badge/register", {
    body: type({
      badgeID: string(),
      userEmail: string(),
    }),
    return: type({
      success: literal(true),
    }),
  });
  export const BadgeDelete = POST("/apis/access-control/badge/delete", {
    body: type({
      badgeID: string(),
    }),
    return: type({
      success: literal(true),
    }),
  });
  export const BadgeEntry = POST("/apis/access-control/entry", {
    body: type({
      badgeID: optional(string()),
      userEmail: optional(string()),
      timestamp: ISO8601(),
      locationID: string(),
      doorID: optional(string()),
      checkOnly: optional(boolean()),
    }),
    return: type({
      success: boolean(),
    }),
  });
  export const BadgeExit = POST("/apis/access-control/exit", {
    body: type({
      badgeID: optional(string()),
      userEmail: optional(string()),
      timestamp: ISO8601(),
      locationID: string(),
      doorID: optional(string()),
    }),
    return: type({
      success: literal(true),
    }),
  });
  export const BadgeRecordEntries = POST(
    "/apis/access-control/record-entries",
    {
      body: type({
        entries: array(
          type({
            badgeID: optional(string()),
            userEmail: optional(string()),
            timestamp: ISO8601(),
            locationID: string(),
            doorID: optional(string()),
            exit: optional(boolean()),
          })
        ),
      }),
      return: type({
        success: literal(true),
      }),
    }
  );
  export const ProcessBadgeStatus = POST("/apis/access-control/process-badge", {
    body: type({
      userID: number(),
      badgeCode: string(),
    }),
    return: type({
      success: boolean(),
    }),
  });
  export const BadgeAliasList = GET("/apis/access-control/badge-alias/list", {
    query: type({}),
    return: type({
      aliases: array(
        type({
          badgeID: string(),
          aliasID: string(),
        })
      ),
    }),
  });
  export const DeleteBadgeAlias = POST(
    "/apis/access-control/badge-alias/delete",
    {
      body: type({
        aliases: array(
          type({
            badgeID: string(),
            aliasID: string(),
          })
        ),
      }),
      return: type({
        success: boolean(),
      }),
    }
  );
  export const CreateBadgeAlias = POST(
    "/apis/access-control/badge-alias/create",
    {
      body: type({
        aliases: array(
          type({
            badgeID: string(),
            aliasID: string(),
          })
        ),
      }),
      return: type({
        success: boolean(),
      }),
    }
  );
  // https://documentation.mailgun.com/docs/mailgun/user-manual/receive-forward-store/
  export const MailgunWebhook = POST("/apis/access-control/mailgun-webhook", {
    multipart: true,
    body: type({
      recipient: optional(string()),
      Received: optional(string()),
      "body-html": optional(string()),
      "body-plain": optional(string()),
      To: optional(string()),
      subject: optional(string()),
      from: optional(string()),
      "Mime-Version": optional(string()),
      References: optional(string()),
      "In-Reply-To": optional(string()),
      sender: optional(string()),
      "Message-Id": optional(string()),
      Date: optional(string()),
      "User-Agent": optional(string()),
      "Content-Type": optional(string()),
      "attachment-count": optional(string()),
      "content-id-map": optional(string()),
      "stripped-text": optional(string()),
      "stripped-html": optional(string()),
      "stripped-signature": optional(string()),
      token: optional(string()),
      timestamp: optional(string()),
      signature: optional(string()),
    }),
    return: type({
      success: boolean(),
    }),
  });
}

const RoomEventData = type({
  eventID: string(), // String so we can use the Google/Microsoft IDs
  title: optional(string()),
  start: ISO8601(),
  end: ISO8601(),
  pendingCheckin: boolean(),
});

const RoomData = type({
  domainName: string(),
  roomName: string(),
  roomID: number(),
  roomCalendarID: string(),
  dataTimestamp: number(),
  timezone: string(),
  freeBgColor: string(),
  busyBgColor: string(),
  checkinBgColor: string(),
  textColor: string(),
  actionColor: string(),
  bookable: boolean(),
  viewable: boolean(),
  additionalInfo: string(),
  imgURL: string(),
  allowsOverlappingEvents: boolean(),
  // events: array(RoomEventData), // Not needed as we are using Firebase.
});

export namespace Cron {
  export const SendVisitCheckinReminderEmail = POST(
    "/cron/send-visit-checkin-reminder-email",
    {
      query: type({ visitID: JSONType.number() }),
      return: string(),
    }
  );
  export const SendCheckinReminders = GET("/cron/send-checkin-reminders", {
    return: string(),
  });
  export const SnapshotCompany = GET("/cron/snapshot-company", {
    query: type({
      allDomains: optional(JSONType.boolean()),
      domain: optional(string()),
      dateOverride: optional(string()),
    }),
    return: string(),
  });
  export const ExecuteRetentionPolicy = GET("/cron/execute-retention-policy", {
    return: string(),
  });
  export const ExecuteAutoAssigns = GET("/cron/execute-auto-assigns", {
    return: string(),
  });
  export const ExecuteCompanySync = GET("/cron/execute-company-sync", {
    query: type({
      domain: string(),
    }),
    return: string(),
  });
  export const VerifyVisitHealthCheck = GET("/cron/verify-visit-health-check", {
    return: string(),
  });
  export const CheckAssignedSeatTimes = GET("/cron/check-assigned-seat-times", {
    return: string(),
  });
  export const ProcessDemoDomains = GET("/cron/process-demo-domains", {
    return: string(),
  });
  export const NormalizeLocationsGraph = GET(
    "/cron/normalize-locations-graph",
    {
      return: string(),
    }
  );
}

export namespace Rooms {
  export const FirestoreInfo = GET("/apis/rooms/firebase-info", {
    return: type({
      appID: string(),
      endpoint: optional(string()),
      apiKey: string(),
    }),
  });
  export const Login = POST("/apis/rooms/login", {
    body: type({ accessCode: string() }),
    return: type({ token: string() }),
  });
  export const TokenRefresh = POST("/apis/rooms/token-refresh", {
    return: type({ token: string() }),
  });
  export const UserTokenRefresh = POST("/apis/rooms/user-token-refresh", {
    return: type({ token: string() }),
  });
  export const GetData = GET("/apis/rooms/data", {
    return: RoomData,
  });
  export const EditRoom = POST("/apis/rooms/edit-room", {
    body: type({
      roomID: number(),
      name: optional(string()),
      email: optional(string()),
      description: optional(string()),
      bookable: optional(boolean()),
      maxCapacity: optional(nullable(number())),
      eventBodyFooter: optional(string()),
    }),
    return: type({ success: boolean() }),
  });
  export const AllRooms = GET("/apis/rooms/all-rooms", {
    return: type({
      rooms: array(
        type({
          id: number(),
          name: string(),
          email: string(),
          floorplan: optional(type({ id: number(), name: string() })),
          description: string(),
          eventBodyFooter: string(),
          bookable: boolean(),
          tabletPassphrase: string(),
          tabletID: string(),
          maxCapacity: nullable(number()),
        })
      ),
    }),
  });
  export const Checkin = POST("/apis/rooms/checkin", {
    body: type({
      roomID: number(),
      eventID: string(),
      currentEndTime: string(),
    }),
    return: type({ success: boolean() }),
  });
  export const CheckinTimeout = POST("/apis/rooms/checkin-timeout", {
    body: type({ roomID: number(), eventID: string() }),
    return: type({ success: boolean() }),
  });
  export const ExtendEvent = POST("/apis/rooms/extend-event", {
    body: type({
      roomID: number(),
      eventID: string(),
      minutes: optional(number()),
      currentStartTime: string(),
      currentEndTime: string(),
    }),
    return: union([
      type({ success: boolean() }),
      type({ success: boolean(), message: optional(string()) }),
    ]),
  });
  // Deprecated: migrate to BookMeeting.
  export const BookEvent = POST("/apis/rooms/book-event", {
    body: type({
      roomID: number(),
      minutes: optional(number()),
      eventData: optional(
        type({
          subject: string(),
          attendees: array(type({ name: string(), email: string() })),
          start: ISO8601(),
          end: ISO8601(),
        })
      ),
    }),
    return: type({ success: boolean(), eventLink: optional(string()) }),
  });
  export const BookMeeting = POST("/apis/rooms/book-meeting", {
    body: type({
      roomID: optional(number()),
      subject: string(),
      start: ISO8601(),
      end: ISO8601(),
      trackingUid: optional(string()),
      attendees: array(
        type({
          email: string(),
          name: string(),
        })
      ),
    }),
    return: type({ success: boolean(), eventLink: optional(string()) }),
  });
  export const EndEvent = POST("/apis/rooms/end-event", {
    body: type({
      roomID: number(),
      eventID: string(),
      currentStartTime: string(),
      currentEndTime: string(),
      asUser: optional(boolean()),
    }),
    return: type({ success: boolean() }),
  });
  export const SetupWatchers = GET("/apis/rooms/setup-watchers", {
    query: type({
      roomID: string(),
    }),
    return: type({ success: boolean() }),
  });
  export const MicrosoftWebhook = POST("/apis/rooms/microsoft-webhook", {
    body: union([
      type({
        value: array(
          union([
            type({
              clientState: string(),
              subscriptionId: string(),
              subscriptionExpirationDateTime: string(),
              resource: string(),
              resourceData: type({
                "@odata.type": string(),
                id: string(),
              }),
              changeType: string(),
              tenantId: string(),
            }),
            type({
              lifecycleEvent: string(),
              clientState: string(),
              subscriptionId: string(),
              tenantId: string(),
            }),
          ])
        ),
      }),
      type({}),
    ]),
    query: type({
      validationToken: string(),
    }),
    return: string(),
  });
  export const GoogleWebhook = POST("/apis/rooms/google-webhook", {});
  export const GoogleWebhookNgrokProxy = POST(
    "/apis/rooms/google-webhook-ngrok",
    {}
  );
}

export namespace Payments {
  export const StripeWebhook = POST("/apis/payments/stripe-webhook", {
    return: string(),
  });

  export const StripeCreateCheckoutSession = POST(
    "/apis/payments/create-checkout-session",
    {
      return: type({
        sessionURL: string(),
      }),
    }
  );
}

export namespace Meetings {
  export const MyMeetings = GET("/apis/meetings/my-meetings", {
    return: type({
      meetings: array(
        type({
          start: string(),
          end: string(),
          eventID: string(),
          eventICalUId: nullable(string()),
          subject: string(),
          roomName: string(),
          roomID: number(),
          webLink: string(),
        })
      ),
    }),
  });
  export const GetEventLink = GET("/apis/meetings/event-link", {
    query: type({
      iCalUId: string(),
      eventID: string(),
      start: string(), // ISO DateTime
      end: string(), // ISO DateTime
    }),
    return: type({
      webLink: string(),
    }),
  });
  export const MagicMeetingSuggestions = POST(
    "/apis/meetings/magic-suggestions",
    {
      body: type({
        floorplanID: number(), // In the future we could guess this.
        urgency: union([
          literal("urgent"),
          literal("tomorrow"),
          literal("this-week"),
          literal("next-week"),
        ]),
        durationMinutes: number(),
        inPersonOnly: boolean(),
        attendeeEmails: array(string()),
      }),
      return: type({
        suggestions: array(
          type({
            uid: string(),
            start: ISO8601(),
            end: ISO8601(),
            attendees: array(
              type({
                name: string(),
                email: string(),
                present: boolean(),
                free: boolean(),
              })
            ),
            room: optional(
              type({
                name: string(),
                id: number(),
              })
            ),
            virtual: optional(string()),
          })
        ),
      }),
    }
  );
}

export namespace Admin {
  // TODO delete this after deploy
  export const UpdateUser = POST("/apis/admin/update-user", {
    body: type({
      email: string(),
      enabled: boolean(),
      role: optional(string()),
    }),
    return: type({
      success: boolean(),
    }),
  });

  export const AddOrEditUsers = POST("/apis/admin/add-or-edit-users", {
    body: intersection([
      type({
        users: array(type({ name: string(), email: string() })),
        floorplanID: optional(nullable(number())),
        buildingID: optional(nullable(number())),
        badgeCode: optional(nullable(string())),
        ldap: optional(nullable(string())),
        jobtitle: optional(nullable(string())),
        employeeType: optional(nullable(string())),
        employeePolicy: optional(nullable(string())),
        zipcode: optional(nullable(string())),
        managerEmails: optional(array(string())),
        departmentID: optional(nullable(number())),
        role: optional(nullable(string())),
        enabled: optional(boolean()),
      }),
      union([
        type({
          edit: literal(true),
          external: optional(boolean()), // Optional if editing
        }),
        type({
          edit: boolean(),
          external: boolean(),
        }),
      ]),
    ]),
    return: type({
      success: literal(true),
      codes: optional(array(string())),
    }),
  });

  export const GetUserDaysEnabled = GET("/apis/admin/user-days-enabled", {
    query: type({
      email: JSONType.string(),
    }),
    return: type({
      daysEnabled: array(boolean()),
      allowedHours: type({
        start: string(),
        end: string(),
        allDay: boolean(),
      }),
    }),
  });
  export const SetUserDaysEnabled = POST("/apis/admin/set-user-days-enabled", {
    body: type({
      email: string(),
      daysEnabled: array(boolean()),
      startTime: string(),
      endTime: string(),
    }),
    return: type({ success: boolean() }),
  });
  export const Settings = GET("/apis/admin/settings", {
    return: type({
      settings: type({
        admins: array(string()),
        rooms: number(),
        users: number(),
        services: array(string()),
        touchlessMode: boolean(),
      }),
    }),
  });
  export const SetSeatEnabled = POST("/apis/admin/set-seat-enabled", {
    body: type({
      seatIDs: array(number()),
      floorplanID: number(),
      enabled: boolean(),
    }),
    return: type({ success: boolean() }),
  });
  export const OfficeManagement = GET("/apis/admin/office-management", {
    query: type({
      floorplanID: optional(JSONType.number()),
    }),
    return: type({
      floorplanImg: string(),
      floorplanDimensions: optional(
        type({
          width: number(),
          height: number(),
        })
      ),
      floorplanDailyLimit: number(),
      weekendsBookable: boolean(),
      seatTypes: array(
        type({
          id: number(),
          name: string(),
          resourceName: resourceType,
        })
      ),
      seats: array(
        type({
          id: number(),
          label: string(),
          enabled: boolean(),
          seatType: string(),
          seatTypeID: number(),
          seatTypeDescription: string(),
          seatTypeImage: string(),
          resourceType: resourceType,
          svgX: number(),
          svgY: number(),
          svgAngle: number(),
          svgScale: number(),
          schedules: array(
            type({
              id: number(),
              assignedEmail: string(),
              assignedName: string(),
              assignedGroupID: optional(string()),
              start: string(),
              end: string(),
              dayOfMonthStart: optional(string()),
              dayOfMonthEnd: optional(string()),
              validFrom: optional(ISO8601()),
              validTo: optional(ISO8601()),
              daysEnabled: weekDaysEnabled,
              reason: optional(string()),
            })
          ),
        })
      ),
    }),
  });
  export const BroadcastMessage = POST("/apis/admin/broadcast-message", {
    body: type({
      title: size(string(), 1, 50),
      body: size(string(), 1, 1000),
      query: optional(
        union([
          type({ all: literal(true) }),
          type({ onlySelf: literal(true) }),
          type({ floorplanID: number() }),
          type({ buildingID: number() }),
          type({ hasBookingAnywhere: literal(true) }),
          type({ groupIDs: array(string()) }),
          type({ userEmails: array(string()) }),
        ])
      ),
    }),
    return: type({
      success: boolean(),
    }),
  });
  export const EditOrCreateSeatSchedules = POST(
    "/apis/admin/upsert-seat-schedules",
    {
      body: type({
        seatIDs: array(number()),
        scheduleIDs: array(number()),
        floorplanID: number(),
        daysAssigned: weekDaysEnabled,
        startTime: string(),
        endTime: string(),
        validFrom: optional(ISO8601()),
        validTo: optional(ISO8601()),
        reason: optional(string()),
        assignee: optional(
          union([type({ email: string() }), type({ groupID: string() })])
        ), // TODO: delete assignee and only use reservedFor once frontend changes have been live long enough
        reservedFor: optional(
          union([
            type({ email: string(), shutDown: literal(false) }),
            type({ groupID: string(), shutDown: literal(false) }),
            type({ shutDown: literal(true) }),
          ])
        ),
      }),
      return: type({
        schedules: array(
          type({
            seatID: number(),
            scheduleID: number(),
          })
        ),
      }),
    }
  );
  export const DeleteSeatSchedules = POST("/apis/admin/delete-seat-schedules", {
    body: type({
      scheduleIDs: size(array(number()), 1, Infinity),
    }),
    return: type({
      success: boolean(),
    }),
  });
  export const EditSeatTypeInfo = POST("/apis/admin/edit-seat-type-info", {
    multipart: true,
    body: type({
      seatTypeID: optional(JSONType.number()),
      onCreateDeskID: optional(JSONType.number()),
      name: optional(JSONType.string()),
      description: optional(JSONType.string()),
      imgSHA2ContentHash: optional(JSONType.string()),
    }),
    return: type({
      success: boolean(),
    }),
  });
  export const EditSeatType = POST("/apis/admin/EditSeatType", {
    body: type({
      seatIDs: size(array(number()), 1, Infinity),
      seatTypeID: number(),
    }),
    return: type({
      success: boolean(),
    }),
  });
  export const DeleteSeatType = POST("/apis/admin/delete-seat-type", {
    body: type({
      seatTypeID: number(),
    }),
    return: type({
      success: boolean(),
    }),
  });
  export const ConciergeDevices = GET("/apis/admin/concierge-devices", {
    return: type({
      devices: array(
        type({
          deviceID: number(),
          code: string(),
          deviceType: string(),
          buildingName: optional(string()),
          building: optional(
            type({
              name: string(),
              parentsPath: array(number()),
            })
          ),
          floorplan: optional(
            type({
              id: number(),
              name: string(),
              parentsPath: array(number()),
            })
          ),
          cameraDirection: string(),
        })
      ),
    }),
  });
  export const MarkCleaned = POST("/apis/admin/mark-cleaned", {
    return: type({ success: boolean() }),
  });

  export const CleaningData = GET("/apis/admin/cleaning-data", {
    return: type({
      needsCleaning: array(
        type({
          seatID: number(),
          seatName: string(),
          floorplanName: string(),
          lastUsed: string(),
        })
      ),
    }),
  });

  export const AdminInfo = GET("/apis/admin/admin-info", {
    return: type({
      adminInfo: type({
        name: string(),
        email: string(),
        domainLogo: string(),
      }),
      domainSettings: type({
        dropinEnabled: boolean(),
        roomsEnabled: boolean(),
        conciergeEnabled: boolean(),
        officeManagementEnabled: boolean(),
        workspaceEnabled: boolean(),
        buddySyncEnabled: boolean(),
        meetingWizardEnabled: boolean(),
        qrEnabled: boolean(),
        weekendsBookable: boolean(),
        contactTracingEnabled: boolean(),
        externalUsersEnabled: boolean(),
        scheduledVisitorsEnabled: boolean(),
        broadcastEnabled: boolean(),
        questionnaireResultsEnabled: boolean(),
        actionCenterEnabled: boolean(),
        slackEnabledTeams: array(string()),
        slackEnabled: boolean(),
        cleaningEnabled: boolean(),
        accessControlEnabled: boolean(),
        resourceTypes: array(resourceType),
        managersEnabled: boolean(),
        isStripeBilled: boolean(),
        stripeUserPlan: string(),
        stripeRoomPlan: string(),
        stripeVisitorPlan: string(),
      }),
    }),
  });
}

const floorplanActivity = array(
  type({
    date: string(), // TODO: Delete once PR live for > 2 weeks
    start: string(),
    end: optional(string()),
    name: string(),
    email: string(),
    employeeType: string(),
    desk: string(),
    deskType: string(),
    floorplanName: string(),
    floorplanShortName: optional(string()),
    cancelled: boolean(),
    cancelledTime: string(),
    cancelledReason: string(),
    checkedIn: boolean(),
    scans: optional(number()),
    entryScanTime: optional(string()),
  })
);

const resourceCountList = array(
  type({
    date: string(),
    resourceType: union([resourceType, literal("ALL")]),
    seatTypeName: string(),
    floorplanID: number(),
    count: number(),
  })
);

export namespace Analytics {
  export const AnalyticsFilterQuery = type({
    start: JSONType.string(),
    end: JSONType.string(),
    floorplanID: optional(JSONType.number()),
    regionID: optional(JSONType.number()),
    userKind: optional(JSONType.string()),
    includeReleased: optional(JSONType.boolean()),
  });
  export const MeetingRoomSummary = GET(
    "/apis/analytics/meeting-room-summary",
    {
      query: AnalyticsFilterQuery,
      return: type({
        meetingRoomsBookedCount: number(),
      }),
    }
  );
  export const SeatingSummary = GET("/apis/analytics/seating-summary", {
    query: AnalyticsFilterQuery,
    return: type({
      desksBookedCount: number(),
      desksAvailableCount: number(),
      peopleWithBookingsCount: number(),
      scannedBookingsCount: number(),
      dropinsCount: number(),
      peopleWithDropinsCount: number(),
      totalUserCount: number(),
      manualBookingsCount: number(),
      parkingSpotsTotal: optional(number()),
      sqFootageTotal: optional(number()),
      userAssignedDesks: optional(number()),
      userAssignedBookings: optional(number()),
      hotelDesks: optional(number()),
      hotelDeskBookings: optional(number()),
      groupAssignedDesks: optional(number()),
      groupAssignedBookings: optional(number()),
      custom: optional(
        type({
          totalOfficeCount: number(),
          totalWorkstationsCount: number(),
        })
      ),
    }),
  });
  export const AggregateData = GET("/apis/analytics/aggregate-data", {
    query: AnalyticsFilterQuery,
    return: type({
      availableResources: resourceCountList,
      requestedResources: resourceCountList,
      deskTypes: array(
        type({
          seatType: string(),
          resourceType: resourceType,
        })
      ),
      totalQRsToday: number(),
      totalPeopleScannedToday: number(),
      vaccinationStatusCounts: array(
        type({
          status: number(),
          count: number(),
        })
      ),
      meetingRoomHoursData: array(
        type({
          date: string(),
          duration: number(),
        })
      ),
    }),
  });
  export const FloorplanActivity = GET("/apis/analytics/floorplan-activity", {
    query: AnalyticsFilterQuery,
    return: type({
      resources: array(
        type({
          resourceType: resourceType,
          activity: floorplanActivity,
        })
      ),
    }),
  });
  export const AllDeskActivityCSV = GET(
    "/apis/analytics/all-desk-activity-csv",
    {
      query: AnalyticsFilterQuery,
    }
  );
  export const AllResourceActivityCSV = GET(
    "/apis/analytics/all-resource-activity-csv",
    {
      query: intersection([
        AnalyticsFilterQuery,
        type({ resourceType: resourceType }),
      ]),
    }
  );
  export const QuestionnaireResponses = GET(
    "/apis/analytics/questionnaire-responses",
    {
      query: intersection([AnalyticsFilterQuery, type({ timezone: string() })]),
      return: type({
        responses: array(
          type({
            date: string(),
            location: string(),
            name: string(),
            email: string(),
            employeeType: string(),
            result: string(),
            externalUser: boolean(),
          })
        ),
      }),
    }
  );
  export const AllQuestionnaireResponsesCSV = GET(
    "/apis/analytics/all-questionnaire-responses-csv",
    {
      query: intersection([
        AnalyticsFilterQuery,
        type({ timezone: optional(string()) }),
      ]),
    }
  );
  export const VaccinationStatusesCSV = GET(
    "/apis/analytics/vaccination-statuses-csv",
    {
      query: AnalyticsFilterQuery,
    }
  );
}

export namespace SuperAdmin {
  export const SaveSeats = POST("/apis/superadmin/save-seats", {
    body: type({
      domainName: string(),
      floorplanName: string(),
      seatsCSV: string(),
    }),
    return: type({
      success: boolean(),
    }),
  });
  export const GetSeats = GET("/apis/superadmin/get-seats", {
    query: type({
      domainName: string(),
      floorplanName: string(),
    }),
    return: type({
      seatsCSV: string(),
      floorplanImg: string(),
      seatsInfo: array(
        type({
          x: number(),
          y: number(),
          scale: number(),
          id: number(),
        })
      ),
    }),
  });
  export const TestEmail = POST("/apis/superadmin/test-email", {
    body: type({
      kind: string(),
      languageCode: optional(string()),
    }),
    return: type({
      contentHTML: string(),
    }),
  });

  export const EmailKinds = GET("/apis/superadmin/email-kinds", {
    return: type({
      kinds: array(string()),
    }),
  });
  export const GetAdminsCSV = GET("/apis/superadmin/get-admins-csv", {
    query: type({
      domainName: string(),
    }),
  });

  export const AllDomains = GET("/apis/superadmin/all-domains", {
    return: type({
      domains: array(string()),
    }),
  });

  export const AllFloorplans = GET("/apis/superadmin/all-floorplans", {
    return: type({
      domains: array(
        type({
          name: string(),
          floorplans: array(
            type({
              name: string(),
              timezone: string(),
              shortName: string(),
            })
          ),
        })
      ),
    }),
  });

  export const UploadImage = POST("/apis/superadmin/upload-image", {
    multipart: true,
    body: type({
      imgSHA2ContentHash: JSONType.string(),
      type: JSONType.string(),
    }),
    return: type({
      url: string(),
    }),
  });

  export const SaveFloorplan = POST("/apis/superadmin/save-floorplan", {
    multipart: true,
    body: type({
      domain: JSONType.string(),
      floorplanName: JSONType.string(),
      seats: JSONType.parse(
        array(
          type({
            name: string(),
            x: number(),
            y: number(),
            angle: number(),
            scale: number(),
            resourceType: resourceType,
          })
        )
      ),
      rooms: JSONType.array(type({ name: string(), path: string() })),
      height: JSONType.number(),
      width: JSONType.number(),
      bgImageSHA2ContentHash: JSONType.string(),
      shortName: JSONType.string(),
      timezone: JSONType.string(),
      adjacencyDistance: JSONType.number(),
      dryRun: JSONType.boolean(),
    }),
    return: type({
      actions: array(string()),
      detailedReport: array(string()),
      errorEncountered: boolean(),
    }),
  });

  export const VerifyUsersAccess = GET("/apis/superadmin/verify-users-access", {
    query: type({ tid: JSONType.string(), mode: JSONType.optional(string()) }),
    return: type({ result: string() }),
  });

  export const FloorplanSettings = GET("/apis/superadmin/floorplan-settings", {
    query: type({ floorplanID: JSONType.number() }),
    return: type({ result: string() }),
  });

  export const CreateSandboxDomain = POST(
    "/apis/superadmin/create-sandbox-domain",
    {
      body: type({
        name: string(),
        friendlyName: string(),
        templateDomain: string(),
        timezoneOverride: string(),
        users: array(
          type({
            name: string(),
            email: string(),
          })
        ),
      }),
      return: type({ success: boolean() }),
    }
  );
}

export namespace Onboarding {
  const Success = type({
    success: boolean(),
  });

  const User = type({
    name: string(),
    email: string(),
    role: union([literal("employee"), literal("admin")]),
  });
  export const GetUsers = GET("/apis/onboarding/users", {
    return: type({ users: array(User) }),
  });
  export const UpdateUsers = POST("/apis/onboarding/users", {
    body: type({ users: array(User), addAll: optional(boolean()) }),
    return: Success,
  });

  export const CheckAccess = GET("/apis/onboarding/check-access", {
    query: type({
      candidateAdminEmail: optional(string()),
    }),
    return: type({
      adminAccess: boolean(),
      roomsAccess: boolean(),
      stripeCheckedOut: boolean(),
      adminEmail: optional(string()),
    }),
  });

  const Theme = type({
    primaryColor: string(),
    secondaryColor: string(),
    logoURL: string(),
    secondaryLogoURL: string(),
  });
  export const GetTheme = GET("/apis/onboarding/theme", { return: Theme });
  export const UpdateTheme = POST("/apis/onboarding/theme", {
    multipart: true,
    body: type({
      primaryColor: JSONType.string(),
      secondaryColor: JSONType.string(),
      primaryLogoSHA2ContentHash: optional(JSONType.string()),
      secondaryLogoSHA2ContentHash: optional(JSONType.string()),
    }),
    return: Success,
  });

  const BaseConfig = type({
    scanQR: boolean(),
    minBookahead: number(),
    maxBookahead: number(),
    maxVisits: number(),
    weekendsEnabled: boolean(),
    capacity: number(),
    bookingReminderTime: optional(number()),
    checkinWarningTime: optional(number()),
    createCalendarEvent: boolean(),
  });
  export const GetBaseConfig = GET("/apis/onboarding/base-config", {
    return: BaseConfig,
  });
  export const UpdateBaseConfig = POST("/apis/onboarding/base-config", {
    body: partial(BaseConfig),
    return: Success,
  });

  const Floorplan = type({
    id: number(),
    name: string(),
    address: optional(string()),
    hasIPad: boolean(),
    hasVisitorTablets: boolean(),
    numberMeetingRooms: number(),
    blueprintURL: string(),
    notes: string(),
  });
  export const GetFloorplans = GET("/apis/onboarding/floorplans", {
    return: type({ floorplans: array(Floorplan) }),
  });
  export const UpdateFloorplan = POST("/apis/onboarding/floorplan", {
    multipart: true,
    body: type({
      id: JSONType.number(),
      name: JSONType.string(),
      address: optional(JSONType.string()),
      hasIPad: JSONType.boolean(),
      hasVisitorTablets: JSONType.boolean(),
      numberMeetingRooms: JSONType.number(),
      blueprintURL: JSONType.string(),
      notes: JSONType.string(),
      imgSHA2ContentHash: optional(JSONType.string()),
    }),
    return: Floorplan,
  });
  export const DeleteFloorplan = POST("/apis/onboarding/delete-floorplan", {
    body: type({
      floorplanID: number(),
    }),
    return: Success,
  });

  const VisitorConfig = type({
    visitorCodeSendTime: optional(number()),
    adminApprovalRequired: boolean(),
    floorplansWithVisitorTablets: number(),
  });
  export const GetVisitors = GET("/apis/onboarding/visitors", {
    return: VisitorConfig,
  });
  export const UpdateVisitors = POST("/apis/onboarding/visitors", {
    body: VisitorConfig,
    return: Success,
  });
}

export namespace ActionCenter {
  const DepartmentGroup = type({
    name: string(),
    id: number(), // Assigned department values always are a group
  });
  const User = type({
    name: string(),
    email: string(),
    department: optional(DepartmentGroup),
    jobtitle: optional(string()),
    employeeType: optional(string()),
    managerEmails: array(string()),
  });

  export const PendingAction = union([
    type({
      type: literal("JOBTITLE"),
      user: User,
      curJobtitle: string(),
      prevJobtitleSynced: optional(string()),
      syncedJobtitle: string(),
    }),
    type({
      type: literal("DEPARTMENT"),
      user: User,
      approvedDepartmentGroup: optional(DepartmentGroup),
      prevSyncedDepartmentName: optional(string()),
      newDepartmentName: string(),
      newDepartmentGroup: optional(DepartmentGroup), // We try guessing which group the synced value matches.
    }),
    type({
      type: literal("NEWUSER"),
      user: User,
      jobtitle: string(),
      departmentName: string(),
      departmentGroup: optional(DepartmentGroup), // We try guessing which group the synced value matches.
    }),
  ]);
  export type PendingAction = Infer<typeof PendingAction>;

  export const GetPendingActions = GET("/apis/action-center/pending", {
    return: type({
      pendingActions: array(PendingAction),
    }),
  });

  export const ResolveActions = POST("/apis/action-center/resolve", {
    body: type({
      resolveActions: array(
        union([
          type({
            type: literal("JOBTITLE"),
            userEmail: string(),
            approvedJobtitle: optional(string()),
          }),
          type({
            type: literal("DEPARTMENT"),
            userEmail: string(),
            approvedDepartment: optional(DepartmentGroup),
          }),
          type({
            type: literal("NEWUSER"),
            userEmail: string(),
          }),
        ])
      ),
    }),
    return: type({
      success: boolean(),
    }),
  });
}

export namespace DomainSetup {
  export const AuthenticatedMicrosoft = POST("/apis/setup/authenticated-ms", {
    body: type({
      tenantID: string(),
      scope: string(),
    }),
    return: type({ success: boolean() }),
  });
}

export namespace Reports {
  const SeatRow = type({
    seatName: optional(string()),
    location: optional(string()),
    taggedLocations: optional(record(string(), optional(string()))),
    usergroup: optional(array(string())),
    parentGroups: optional(array(string())),
    assignedUserNames: optional(array(string())),
    assignedUserLdaps: optional(array(string())),
    assignedUserJobtitles: optional(array(string())),
    bookingEnabled: optional(boolean()),
    isOffice: optional(boolean()),
    numDaysBooked: optional(number()),
    numHoursBooked: optional(number()),
    assignedToIndividual: optional(boolean()),
  });

  const SeatCols: Struct<keyof Infer<typeof SeatRow>, null> = union([
    literal("seatName"),
    literal("location"),
    literal("taggedLocations"),
    literal("usergroup"),
    literal("parentGroups"),
    literal("assignedUserNames"),
    literal("assignedUserLdaps"),
    literal("assignedUserJobtitles"),
    literal("bookingEnabled"),
    literal("isOffice"),
    literal("assignedToIndividual"),
    literal("numDaysBooked"),
    literal("numHoursBooked"),
  ]);

  const UserRow = type({
    userName: optional(string()),
    email: optional(string()),
    location: optional(string()),
    taggedLocations: optional(record(string(), optional(string()))),
    usergroup: optional(string()),
    parentGroups: optional(array(string())),
    jobtitle: optional(string()),
    userEnabled: optional(boolean()),
    managers: optional(string()),
    daysEnabled: optional(string()),
    employeeType: optional(string()),
    numDaysBooked: optional(number()),
    numHoursBooked: optional(number()),
  });

  const UserCols: Struct<keyof Infer<typeof UserRow>, null> = union([
    literal("userName"),
    literal("email"),
    literal("location"),
    literal("taggedLocations"),
    literal("usergroup"),
    literal("parentGroups"),
    literal("jobtitle"),
    literal("userEnabled"),
    literal("managers"),
    literal("daysEnabled"),
    literal("employeeType"),
    literal("numDaysBooked"),
    literal("numHoursBooked"),
  ]);

  const ColumnType = type({ name: string(), key: string(), data: string() });
  const ReportTypeBase = type({
    name: string(),
    user: optional(
      type({ columns: array(ColumnType), breakdown: array(ColumnType) })
    ),
    seat: optional(
      type({ columns: array(ColumnType), breakdown: array(ColumnType) })
    ),
  });

  const SavedReportType = intersection([
    ReportTypeBase,
    type({ id: number() }),
  ]);
  const ReportTypeOptionalID = intersection([
    ReportTypeBase,
    type({ id: optional(number()) }),
  ]);

  export const GetData = GET("/apis/reports/data", {
    query: type({
      startDate: ISO8601(),
      endDate: ISO8601(),
      chartDef: JSONType.parse(
        type({
          user: optional(type({ columns: array(UserCols) })),
          seat: optional(type({ columns: array(SeatCols) })),
        })
      ),
    }),
    return: type({ user: array(UserRow), seat: array(SeatRow) }),
  });
  export const GetColumnData = GET("/apis/reports/column-data", {
    return: type({ locationTags: array(string()) }),
  });
  export const GetAllReports = GET("/apis/reports/all-reports", {
    return: type({
      reports: array(SavedReportType),
    }),
  });
  export const SaveReport = POST("/apis/reports/save-report", {
    body: ReportTypeOptionalID,
    return: type({ report: SavedReportType }),
  });
  export const DeleteReport = POST("/apis/reports/delete-report", {
    body: type({ id: number() }),
    return: type({}),
  });
}

export namespace Buddy {
  export const Follow = POST("/apis/buddy/follow", {
    body: type({
      email: string(),
      notify: optional(boolean()),
    }),
    return: type({ success: boolean() }),
  });
  export const Unfollow = POST("/apis/buddy/unfollow", {
    body: type({
      email: string(),
    }),
    return: type({ success: boolean() }),
  });
  export const BuddiesInfo = GET("/apis/buddy/buddies-info", {
    return: type({
      buddies: array(
        type({
          email: string(),
          name: string(),
          notify: boolean(),
          bookings: array(
            type({
              startDate: string(),
              endDate: string(),
              startTime: string(),
              endTime: string(),
              floorplan: type({
                name: string(),
                id: number(),
                timezone: string(),
              }),
              seat: optional(type({ name: string(), id: number() })),
            })
          ),
        })
      ),
    }),
  });
  export const Followers = GET("/apis/buddy/followers", {
    return: type({
      followers: array(
        type({
          email: string(),
          name: string(),
        })
      ),
    }),
  });
  export const BlockList = GET("/apis/buddy/block-list", {
    return: type({
      blockedUsers: array(
        type({
          email: string(),
          name: string(),
        })
      ),
    }),
  });
  export const Block = POST("/apis/buddy/block", {
    body: type({
      email: string(),
    }),
    return: type({ success: boolean() }),
  });
  export const Unblock = POST("/apis/buddy/unblock", {
    body: type({
      email: string(),
    }),
    return: type({ success: boolean() }),
  });
  export const UpdateNotify = POST("/apis/buddy/update-notify", {
    body: type({
      email: string(),
      notify: boolean(),
    }),
    return: type({ success: boolean() }),
  });
  export const NotifyBuddiesForUser = POST(
    "/apis/buddy/notify-buddies-for/:userID",
    {
      return: type({ success: boolean() }),
    }
  );
}
