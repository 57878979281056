import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Grid,
  Typography,
  Tooltip,
  Chip,
  useMediaQuery,
  Autocomplete,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  ButtonBase,
  Checkbox,
  FormControlLabel,
  useTheme,
  CircularProgress,
} from "@mui/material";
import {
  Bolt as BoltIcon,
  ChevronLeft,
  ChevronRight,
  ArrowCircleRight as ArrowCircleRightIcon,
  Event as EventIcon,
  EventRepeat as EventRepeatIcon,
  VideoCall as VideoCallIcon,
  CheckCircle,
  CorporateFare,
  VideoCall,
  Info,
  AccessTime,
  People,
  Edit,
  Close,
  Error,
} from "@mui/icons-material";
import { DateTime, Interval } from "luxon";

import { TimePicker } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useAPIMutation, useAPIQuery, useError } from "../hooks";
import type { APIError } from "../hooks";
import type { BookableRoomInfo, Domain, FloorplanInfo } from "../types";
import TimeRange from "react-timeline-range-slider";
import type { InferReturnType, Seating } from "zynq-shared";
import { CalendarDate } from "zynq-shared";
import type { Meetings, Rooms } from "zynq-shared";
import mixpanel from "mixpanel-browser";
import UserSearch from "../userSearch";

type User = {
  name: string;
  email: string;
  src?: string;
};

type MeetingUrgency = "urgent" | "tomorrow" | "this-week" | "next-week";

type MeetingSuggestion = InferReturnType<
  typeof Meetings.MagicMeetingSuggestions
>["suggestions"][number];

type MagicMeetingResults = {
  urgency: MeetingUrgency;
  duration: number;
  inPersonOnly: boolean;
  attendees: User[];
  title: string;
  suggestions: MeetingSuggestion[];
};

function MeetingWizardForm(props: {
  organizer?: User;
  floorplan: FloorplanInfo;
  suggestions?: MagicMeetingResults;
  timezone: string;
  onSuggestions: (results: MagicMeetingResults) => void;
  onBook: (meeting: MeetingSuggestion) => void;
  onClearSuggestions: () => void;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const showError = useError();
  const [urgency, setUrgency] = React.useState<MeetingUrgency>("urgent");
  const [duration, setDuration] = React.useState<number>(30);
  const [attendees, setAttendees] = React.useState<User[]>(
    props.organizer ? [props.organizer] : []
  );
  const [title, setTitle] = React.useState("");
  const [inPersonOnly, setInPersonOnly] = React.useState(false);

  const getSuggestions = useAPIMutation<
    typeof Meetings.MagicMeetingSuggestions
  >("/apis/meetings/magic-suggestions", {
    onSuccess: (data) => {
      props.onSuggestions({
        urgency,
        duration,
        inPersonOnly,
        attendees,
        title,
        suggestions: data.suggestions,
      });
    },
    onError: (err) => {
      showError(err.reason);
    },
  });

  const getMeetingSuggestions = () => {
    getSuggestions.mutate({
      floorplanID: props.floorplan.id,
      urgency: urgency,
      durationMinutes: duration,
      inPersonOnly,
      attendeeEmails: attendees.map((a) => a.email),
    });
  };

  const selectedChipOptionsStyle = {
    "&:hover": {
      backgroundColor: "darkgray",
    },
    backgroundColor: theme.palette.secondary.main,
    color: "white",
  };

  if (props.suggestions) {
    return (
      <MeetingSuggestions
        data={props.suggestions}
        timezone={props.timezone}
        onBook={props.onBook}
        onBack={props.onClearSuggestions}
      />
    );
  }

  return (
    <>
      <div css={{ padding: "2rem" }}>
        <div
          css={{
            display: "flex",
            flewFlox: "row",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <div
            css={{
              marginLeft: "1rem",
              marginRight: "1rem",
              fontSize: "125%",
              width: "100px",
            }}
          >
            {t("attendees")}
          </div>
          <div css={{ marginLeft: "1rem", marginRight: "1rem", flexGrow: 1 }}>
            {props.organizer && (
              <UserSearch
                multiple
                limitTags={2}
                filterSelectedOptions
                getOptionLabel={(o) => o.email}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Tooltip key={option.email} title={option.email} arrow>
                      <Chip
                        label={option.name}
                        {...{
                          ...getTagProps({ index }),
                          ...(option.email === props.organizer?.email && {
                            onDelete: undefined,
                          }),
                        }}
                      />
                    </Tooltip>
                  ))
                }
                renderInput={(props) => (
                  <TextField {...props} variant="outlined" />
                )}
                selection={attendees}
                onSelection={(v) => setAttendees(v)}
              />
            )}
          </div>
        </div>
        <div
          css={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "2rem",
            alignItems: "center",
          }}
        >
          <div
            css={{
              margin: "1rem",
              fontSize: "125%",
              width: "100px",
            }}
          >
            {t("time")}
          </div>

          <div
            css={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              css={{
                display: "flex",
                flexWrap: "wrap",
                gap: "2rem",
              }}
            >
              <MeetingScheduleCard
                icon={<BoltIcon fontSize="large" />}
                title={t("urgent")}
                selected={urgency == "urgent"}
                selectedColor={theme.palette.secondary.main}
                onClick={() => setUrgency("urgent")}
              />
              <MeetingScheduleCard
                icon={<ArrowCircleRightIcon fontSize="large" />}
                title={t("tomorrow")}
                selected={urgency == "tomorrow"}
                selectedColor={theme.palette.secondary.main}
                onClick={() => setUrgency("tomorrow")}
              />
              <MeetingScheduleCard
                icon={<EventIcon fontSize="large" />}
                title={t("this-week")}
                selected={urgency == "this-week"}
                selectedColor={theme.palette.secondary.main}
                onClick={() => setUrgency("this-week")}
              />
              <MeetingScheduleCard
                icon={
                  <EventRepeatIcon
                    fontSize="large"
                    css={{ transform: "scaleX(-1)" }}
                  />
                }
                title={t("next-week")}
                selected={urgency == "next-week"}
                selectedColor={theme.palette.secondary.main}
                onClick={() => setUrgency("next-week")}
              />
            </div>
          </div>
        </div>
        <div
          css={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "3rem",
            alignItems: "center",
          }}
        >
          <div
            css={{
              margin: "1rem",
              fontSize: "125%",
              width: "100px",
            }}
          >
            {t("duration")}
          </div>
          <div
            css={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              css={{
                display: "flex",
                flexWrap: "wrap",
                gap: "2rem",
              }}
            >
              <Chip
                css={{
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  fontSize: "100%",
                  width: "125px",
                  ...(duration == 15 ? selectedChipOptionsStyle : undefined),
                }}
                label={t("duration-minutes", { minutes: 15 })}
                onClick={() => {
                  setDuration(15);
                }}
              />
              <Chip
                css={{
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  fontSize: "100%",
                  width: "125px",
                  ...(duration == 30 ? selectedChipOptionsStyle : undefined),
                }}
                label={t("duration-minutes", { minutes: 30 })}
                onClick={() => {
                  setDuration(30);
                }}
              />
              <Chip
                css={{
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  fontSize: "100%",
                  width: "125px",
                  ...(duration == 60 ? selectedChipOptionsStyle : undefined),
                }}
                label={t("duration-minutes", { minutes: 60 })}
                onClick={() => {
                  setDuration(60);
                }}
              />
            </div>
          </div>
        </div>
        <div
          css={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "3rem",
            alignItems: "center",
          }}
        >
          <div
            css={{
              margin: "1rem",
              fontSize: "125%",
              width: "100px",
            }}
          >
            {t("meeting-title")}
          </div>
          <TextField
            css={{
              marginLeft: "2rem",
              marginRight: "2rem",
              flexGrow: 1,
            }}
            required
            placeholder={t("required")}
            inputProps={{ style: { fontSize: "125%" } }}
            variant="standard"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>
        <div
          css={{
            marginTop: "2rem",
            display: "flex",
            flexFlow: "row-reverse",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={inPersonOnly}
                onChange={(_, checked) => {
                  setInPersonOnly(checked);
                }}
                style={{ color: theme.palette.secondary.main }}
              />
            }
            label={t("in-person-only")}
          />
        </div>
        <div
          css={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ fontSize: "125%", padding: "1rem" }}
            disabled={!duration || !title || getSuggestions.isLoading}
            onClick={getMeetingSuggestions}
          >
            {getSuggestions.isLoading && (
              <CircularProgress
                size="16"
                css={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                color="primary"
              />
            )}
            {t("find-times")}
          </Button>
        </div>
      </div>
    </>
  );
}

function MeetingWizard(props: {
  organizer?: User;
  floorplan: FloorplanInfo;
  timezone: string;
  setShowTabs: (showTabs: boolean) => void;
  onClose: () => void;
}) {
  const showError = useError();
  const [suggestions, setSuggestions] = React.useState<MagicMeetingResults>();
  const [selected, setSelected] = React.useState<MeetingSuggestion>();
  const [bookedLink, setBookedLink] = React.useState<string>();

  const onBookSuccess = (eventLink?: string) => {
    setBookedLink(eventLink);
  };
  const onBookError = (err: APIError) => {
    setBookedLink(undefined);
    showError(err.reason);
  };

  const book = useAPIMutation<typeof Rooms.BookMeeting>(
    "/apis/rooms/book-meeting",
    {
      onSuccess: (data) => onBookSuccess(data.eventLink),
      onError: (err) => onBookError(err),
    }
  );

  const bookMeeting = (meeting: MeetingSuggestion) => {
    if (!suggestions) {
      return;
    }
    book.mutate({
      roomID: meeting.room?.id,
      subject: suggestions.title,
      start: meeting.start,
      end: meeting.end,
      trackingUid: meeting.uid,
      attendees: meeting.attendees.map((a) => ({
        name: a.name,
        email: a.email,
      })),
    });
    setSelected(meeting);

    mixpanel.track("Magic Meeting Suggestion Picked", {
      suggestion: meeting,
    });
  };

  React.useEffect(() => {
    props.setShowTabs(!selected && !suggestions);
  }, [suggestions, selected]);

  return (
    <>
      {selected ? (
        <BookedMeetingDetails
          meeting={selected}
          state={
            book.error ? "error" : book.isSuccess ? "confirmed" : "loading"
          }
          editLink={bookedLink}
          subject={suggestions?.title ?? ""}
          timezone={props.timezone}
          onClose={props.onClose}
          onBack={() => {
            setSelected(undefined);
          }}
        />
      ) : (
        <MeetingWizardForm
          {...props}
          suggestions={suggestions}
          timezone={props.timezone}
          onBook={bookMeeting}
          onClearSuggestions={() => setSuggestions(undefined)}
          onSuggestions={(result) => setSuggestions(result)}
        />
      )}
    </>
  );
}

function MeetingSuggestions(props: {
  data: MagicMeetingResults;
  timezone: string;
  onBook: (meeting: MeetingSuggestion) => void;
  onBack: () => void;
}) {
  const isNotMobile = useMediaQuery("@media (min-width:420px");
  const { t } = useTranslation();
  const [offset, setOffset] = React.useState(0);
  const suggestions = props.data.suggestions;

  return (
    <>
      <div
        css={{
          "@media screen and (max-width: 600px)": {
            flexFlow: "column",
          },
          display: "flex",
          fontSize: "200%",
          justifyContent: "center",
          padding: "1rem",
        }}
      >
        <div>
          <IconButton onClick={props.onBack}>
            <ChevronLeft fontSize="large" />
          </IconButton>
        </div>
        <div
          css={{
            flexGrow: 1,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          <div
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.5rem",
            }}
          >
            {props.data.urgency == "urgent" ? (
              <BoltIcon />
            ) : props.data.urgency == "tomorrow" ? (
              <ArrowCircleRightIcon />
            ) : props.data.urgency == "this-week" ? (
              <EventIcon />
            ) : (
              <EventRepeatIcon />
            )}
            {t(props.data.urgency)}
          </div>
          <div
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.5rem",
            }}
          >
            <AccessTime />
            {t("n-minutes", { minutes: props.data.duration })}
          </div>
          <div
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "0.5rem",
            }}
          >
            <People />
            {t("count-people-abbr", { count: props.data.attendees.length })}
          </div>
        </div>
      </div>
      <div css={{ marginBottom: "2rem" }}>
        <div
          css={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SuggestedMeetingCard
            meeting={suggestions[0]}
            inPersonOnly={props.data.inPersonOnly}
            style="featured"
            timezone={props.timezone}
            onClick={() => {
              props.onBook(suggestions[0]);
            }}
          />
        </div>
        <div css={{ display: "flex", marginRight: "1rem" }}>
          {isNotMobile && (
            <div
              css={{
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                disabled={offset == 0}
                onClick={() => {
                  setOffset(Math.max(0, offset - 3));
                }}
                size="large"
              >
                <ChevronLeft titleAccess={t("previous")} fontSize="large" />
              </IconButton>
            </div>
          )}
          <div
            css={{
              flexGrow: 1,
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              marginTop: "2rem",
              rowGap: "2rem",
            }}
          >
            {(isNotMobile
              ? suggestions.slice(offset + 1, offset + 4)
              : suggestions.slice(1)
            ).map((rec, index) => (
              <SuggestedMeetingCard
                key={index}
                meeting={rec}
                inPersonOnly={props.data.inPersonOnly}
                style="default"
                timezone={props.timezone}
                onClick={() => {
                  props.onBook(rec);
                }}
              />
            ))}
          </div>
          {isNotMobile && (
            <div
              css={{
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                disabled={offset + 4 >= suggestions.length}
                onClick={() => {
                  setOffset(Math.min(offset + 3, suggestions.length - 4));
                }}
                size="large"
              >
                <ChevronRight titleAccess={t("next")} fontSize="large" />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function ManualBooking(props: {
  onClose: () => void;
  timezone: string;
  initialRoom?: BookableRoomInfo;
  rooms: BookableRoomInfo[];
  events: { interval: Interval }[];
  initialDay: CalendarDate;
  initialTimeRange: Interval;
  organizer?: User;
  allowDayToggle?: boolean;
  domainName: string;
}) {
  const { t } = useTranslation();
  const isNotMobile = useMediaQuery("@media (min-width:600px)");
  const minutesGranularity = 15;
  const timezone = props.timezone;
  const showError = useError();
  const bookMeeting = useAPIMutation<typeof Rooms.BookMeeting>(
    "/apis/rooms/book-meeting",
    {
      onSuccess: () => {
        props.onClose();
      },
      onError: (err) => {
        showError(err.reason);
      },
    }
  );
  const [room, setRoom] = React.useState<BookableRoomInfo>();
  const maxCapacity = room?.maxCapacity ?? 0;
  const [now, setNow] = React.useState<DateTime>(
    props.initialDay.toDateTime({ zone: timezone })
  );
  const [day, setDay] = React.useState<CalendarDate>(props.initialDay);
  const dayDT = day.toDateTime({ zone: props.timezone });
  const [attendees, setAttendees] = React.useState<User[]>([]);
  const [title, setTitle] = React.useState("");
  const startMinutesToNextStep =
    minutesGranularity -
    (props.initialTimeRange.start.minute % minutesGranularity);
  const endMinutesToNextStep =
    minutesGranularity -
    (props.initialTimeRange.end.minute % minutesGranularity);

  let [selectedInterval, setSelectedInterval] = React.useState(
    Interval.fromDateTimes(
      DateTime.min(
        props.initialTimeRange.start.plus({ minutes: startMinutesToNextStep }),
        props.initialTimeRange.start.endOf("day")
      ),
      DateTime.min(
        props.initialTimeRange.end.plus({ minutes: endMinutesToNextStep }),
        props.initialTimeRange.end.endOf("day")
      )
    )
  );

  if (selectedInterval.invalidReason) {
    console.error(
      "Invalid interval in meetingBookingDialog:",
      selectedInterval.invalidExplanation
    );
    selectedInterval = Interval.fromDateTimes(
      now.startOf("day"),
      now.endOf("day")
    );
    setSelectedInterval(selectedInterval);
  }

  const todaysPast = Interval.fromDateTimes(
    DateTime.now().setZone(props.timezone).startOf("day"),
    DateTime.now().setZone(props.timezone).minus({ minutes: 1 })
  );
  const dayEvents = Interval.merge(
    (day.hasSame(CalendarDate.today(), "day") && todaysPast.isValid
      ? [todaysPast]
      : []
    ).concat(
      props.events
        .map((e) => e.interval)
        .filter((e) => {
          return e.start.hasSame(dayDT, "day") || e.end.hasSame(dayDT, "day");
        })
    )
  );

  const headers: DateTime[] = [];
  let time = dayDT;
  while (time.hasSame(dayDT, "day")) {
    headers.push(time);
    time = time.plus({ hours: 1 });
  }

  React.useEffect(() => {
    setRoom(props.initialRoom);
  }, [props.initialRoom]);

  React.useEffect(() => {
    setSelectedInterval(
      Interval.fromDateTimes(
        dayDT.set({
          hour: selectedInterval.start.hour,
          minute: selectedInterval.start.minute,
        }),
        dayDT.set({
          hour: selectedInterval.end.hour,
          minute: selectedInterval.end.minute,
        })
      )
    );
  }, [day]);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setNow(DateTime.now().setZone(props.timezone));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const overlapping = dayEvents.some((e) => e.overlaps(selectedInterval));

  const maxCapacityReached = maxCapacity && maxCapacity <= attendees.length;

  const roomName = room?.name ?? t("room");

  return (
    <>
      <h1 css={{ textAlign: "center" }}>
        {t("book-resource", { resource: roomName })}
      </h1>
      {props.allowDayToggle && (
        <div
          css={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            css={{
              display: "flex",
              flexFlow: "row",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                setDay(day.minus({ days: 1 }));
              }}
            >
              <ChevronLeft />
            </IconButton>
            <span>{day.toLocaleString(DateTime.DATE_HUGE)}</span>
            <IconButton
              onClick={() => {
                setDay(day.plus({ days: 1 }));
              }}
            >
              <ChevronRight />
            </IconButton>
          </div>
        </div>
      )}
      <div css={{ width: "max(55%, 20rem)", margin: "0 auto" }}>
        {!props.initialRoom ? (
          <Autocomplete
            value={room}
            onChange={(e, v) => setRoom(v ?? undefined)}
            filterSelectedOptions
            isOptionEqualToValue={(opt, val) => opt.id == val.id}
            noOptionsText={t("no-rooms-found")}
            filterOptions={function (options, state) {
              const searchRegex = new RegExp(
                state.inputValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
                "i"
              );
              return options
                .filter((room) => searchRegex.test(room.name))
                .slice(0, 20);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, room) => (
              <li {...props}>
                <Grid alignItems="center" container={true} spacing={2}>
                  <Grid item={true} xs={true}>
                    <span>{room.name}</span>
                  </Grid>
                </Grid>
              </li>
            )}
            options={props.rooms}
            renderInput={(props) => (
              <TextField
                {...props}
                value={room?.name ?? ""}
                label={t("meeting-room")}
                variant="outlined"
                fullWidth
                required
                css={{ marginBottom: "1rem" }}
              />
            )}
          />
        ) : undefined}

        <TextField
          label={t("meeting-title")}
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          required
        />
        {props.organizer && (
          <UserSearch
            hideOptions={!!maxCapacityReached}
            multiple
            limitTags={2}
            filterSelectedOptions
            getOptionLabel={(o) => o.email}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Tooltip key={option.email} title={option.email} arrow>
                  <Chip
                    label={option.name}
                    {...{
                      ...getTagProps({ index }),
                      ...(option.email === props.organizer?.email && {
                        onDelete: undefined,
                      }),
                    }}
                  />
                </Tooltip>
              ))
            }
            renderInput={(props) => (
              <TextField {...props} label={t("attendees")} variant="outlined" />
            )}
            noOptionsText={
              maxCapacityReached && maxCapacity
                ? t("max-capacity-of", { capacity: maxCapacity })
                : t("no-employees-found")
            }
            selection={attendees}
            onSelection={(v) => setAttendees(v)}
          />
        )}
        <div
          css={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
          }}
        >
          <TimePicker<DateTime>
            label={t("start-time")}
            value={selectedInterval.start}
            onChange={(newStart) => {
              if (newStart && newStart.isValid) {
                let newEnd = selectedInterval.end;
                if (newStart > newEnd) {
                  newEnd = newStart.plus({
                    minutes: selectedInterval.length("minutes"),
                  });
                }
                setSelectedInterval(Interval.fromDateTimes(newStart, newEnd));
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker<DateTime>
            label={t("end-time")}
            value={selectedInterval.end}
            onChange={(newEnd) => {
              if (newEnd && newEnd.isValid) {
                let newStart = selectedInterval.start;
                if (newStart > newEnd) {
                  newStart = newEnd.minus({
                    minutes: selectedInterval.length("minutes"),
                  });
                }
                setSelectedInterval(Interval.fromDateTimes(newStart, newEnd));
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </div>

      <div
        css={{
          margin: "3rem 0",
          display: "flex",
          justifyContent: "center",
          "& > .react_time_range__time_range_container": {
            width: "100%",
            padding: "30px 5% 0",
          },
        }}
      >
        {/* TimeRange component only support naive datetimes with no timezone so we convert going in and out */}
        <TimeRange
          error={overlapping}
          step={minutesGranularity * 60000}
          ticksNumber={isNotMobile ? 48 : 12}
          selectedInterval={[
            selectedInterval.start
              .setZone("system", { keepLocalTime: true })
              .toJSDate(),
            selectedInterval.end
              .setZone("system", { keepLocalTime: true })
              .toJSDate(),
          ]}
          timelineInterval={[
            day.toDateTime({ zone: "system" }).toJSDate(),
            day.plus({ days: 1 }).toDateTime({ zone: "system" }).toJSDate(),
          ]}
          onUpdateCallback={() => undefined}
          onChangeCallback={(selectedInterval) =>
            setSelectedInterval(
              Interval.fromDateTimes(
                DateTime.fromJSDate(selectedInterval[0]).setZone(
                  props.timezone,
                  { keepLocalTime: true }
                ),
                DateTime.fromJSDate(selectedInterval[1]).setZone(
                  props.timezone,
                  { keepLocalTime: true }
                )
              )
            )
          }
          disabledIntervals={dayEvents.map((e) => ({
            start: e.start
              .setZone("system", { keepLocalTime: true })
              .toJSDate(),
            end: e.end.setZone("system", { keepLocalTime: true }).toJSDate(),
          }))}
          formatTick={(ms) =>
            DateTime.fromMillis(ms).toLocaleString(DateTime.TIME_SIMPLE)
          }
        />
      </div>

      {props.domainName == "albertsons.com" && (
        <div
          css={{
            display: "flex",
            margin: "1rem",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <VideoCallIcon css={{ color: "#5057bf" }} /> A Teams Meeting will
          automatically be added.
        </div>
      )}

      <div
        css={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "flex-end",
          margin: "1rem",
        }}
      >
        <Button
          onClick={() => {
            props.onClose();
          }}
          css={{ marginRight: "1rem" }}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          disabled={!title || overlapping || bookMeeting.isLoading}
          onClick={() => {
            if (!room?.id) return;
            const allAttendees = attendees.map((a) => ({
              name: a.name,
              email: a.email,
            }));
            if (
              props.organizer &&
              !allAttendees.some((a) => a.email == props.organizer?.email)
            ) {
              // Add the organizer to the attendees
              allAttendees.push({
                name: props.organizer.name,
                email: props.organizer.email,
              });
            }
            bookMeeting.mutate({
              roomID: room.id,
              subject: title,
              start: selectedInterval.start.toISO(),
              end: selectedInterval.end.toISO(),
              attendees: allAttendees,
            });
          }}
          startIcon={
            bookMeeting.isLoading ? (
              <CircularProgress style={{ marginRight: "10px" }} size={20} />
            ) : null
          }
        >
          {t("book")}
        </Button>
      </div>
    </>
  );
}

/**
 * Booking Dialog for floorplan app and meeting room app.
 */
export default function BookingDialog(props: {
  onClose: () => void;
  loading: boolean;
  open: boolean;
  timezone: string;
  initialRoom?: BookableRoomInfo;
  rooms: BookableRoomInfo[];
  events: { interval: Interval }[];
  initialDay: CalendarDate;
  initialTimeRange: Interval;
  organizer?: User;
  allowDayToggle?: boolean;
  meetingWizardEnabled: boolean;
  domainName: string;
  floorplan?: FloorplanInfo;
}) {
  const { t } = useTranslation();
  const [showTabs, setShowTabs] = React.useState(true);
  const theme = useTheme();

  const [selectedTab, setSelectedTab] = React.useState<"WIZARD" | "CUSTOM">(
    props.meetingWizardEnabled ? "WIZARD" : "CUSTOM"
  );

  React.useEffect(() => {
    if (props.open) {
      setSelectedTab(props.meetingWizardEnabled ? "WIZARD" : "CUSTOM");
    }
  }, [props.open]);

  const selectedOptionsStyle = {
    "&.Mui-selected:hover": {
      backgroundColor: "darkgray",
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: "white",
    },
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      {props.meetingWizardEnabled && showTabs && props.floorplan && (
        <DialogTitle
          css={{
            textAlign: "center",
            fontSize: "200%",
            backgroundColor: "#f9f9f9",
          }}
        >
          <ToggleButtonGroup
            exclusive
            size="large"
            value={selectedTab}
            color="error"
            onChange={(_e, v) =>
              v && setSelectedTab(v == "WIZARD" ? "WIZARD" : "CUSTOM")
            }
          >
            <ToggleButton css={{ ...selectedOptionsStyle }} value="WIZARD">
              {t("auto-schedule")}
            </ToggleButton>
            <ToggleButton css={{ ...selectedOptionsStyle }} value="CUSTOM">
              {t("custom")}
            </ToggleButton>
          </ToggleButtonGroup>
        </DialogTitle>
      )}
      <div css={{ backgroundColor: "#f9f9f9" }}>
        {selectedTab == "WIZARD" && props.floorplan ? (
          <MeetingWizard
            organizer={props.organizer}
            floorplan={props.floorplan}
            timezone={props.timezone}
            setShowTabs={setShowTabs}
            onClose={props.onClose}
          />
        ) : (
          <ManualBooking {...props} />
        )}
      </div>
    </Dialog>
  );
}

function MeetingScheduleCard(props: {
  icon: React.ReactElement;
  title: string;
  selected: boolean;
  selectedColor?: string;
  onClick: () => void;
}) {
  return (
    <Card
      css={{
        backgroundColor: props.selected
          ? props.selectedColor || "lightgray"
          : undefined,
      }}
    >
      <ButtonBase
        css={{
          display: "flex",
          flexDirection: "column",
          height: "120px",
          width: "125px",
        }}
        onClick={props.onClick}
      >
        <div
          css={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: props.selected ? "white" : "black",
          }}
        >
          {props.icon}
        </div>

        <div
          css={{
            fontSize: "125%",
            borderWidth: "1px",
            borderStyle: "solid none none none",
            borderColor: "gray",
            display: "flex",
            width: "100%",
            justifyContent: "center",
            padding: "6px",
            color: props.selected ? "white" : "black",
          }}
        >
          {props.title}
        </div>
      </ButtonBase>
    </Card>
  );
}

function SuggestedMeetingCard(props: {
  meeting: MeetingSuggestion;
  inPersonOnly: boolean;
  style: "default" | "featured";
  timezone: string;
  onClick: () => void;
}) {
  const { t } = useTranslation();
  const start = DateTime.fromISO(props.meeting.start, { zone: props.timezone });
  const dateInformation = getDateShorthand(start, props.timezone);
  const busy = props.meeting.attendees
    .filter((a) => !a.free)
    .map((a) => a.name);
  const absent = props.meeting.attendees
    .filter((a) => !a.present)
    .map((a) => a.name);

  return (
    <Card
      sx={{
        width: props.style == "featured" ? "300px" : "225px",
      }}
    >
      <ButtonBase onClick={props.onClick} css={{ width: "100%" }}>
        <div css={{ width: "100%" }}>
          <div
            css={{
              margin: "0.5rem",
              position: "absolute",
              right: "0",
              top: "0",
            }}
          >
            {props.meeting.room ? (
              <CorporateFare css={{ color: "#975E44" }} />
            ) : (
              <VideoCall color="info" />
            )}
          </div>
          <div css={{ margin: "1.5rem" }}>
            <div css={{ textAlign: "center" }}>
              <Typography fontSize="200%">
                {start.toLocaleString(DateTime.TIME_SIMPLE)}
              </Typography>
            </div>
            <div css={{ textAlign: "center" }}>
              <Typography fontSize="150%">
                {dateInformation} ({start.toLocaleString({ weekday: "short" })})
              </Typography>
            </div>
            <div
              css={{
                fontSize: "100%",
                marginTop: "1.5rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {busy.length == 0 &&
              (!props.inPersonOnly || absent.length == 0) ? (
                <div css={{ display: "flex", alignItems: "center" }}>
                  <CheckCircle
                    fontSize="small"
                    color="success"
                    css={{ marginRight: "0.5rem" }}
                  />{" "}
                  <Typography color="#228B22">Everyone available</Typography>
                </div>
              ) : (
                <div
                  css={{
                    fontSize: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Info
                    color="warning"
                    fontSize="small"
                    css={{ marginRight: "0.5rem" }}
                  />

                  <Typography color="#CC5500">
                    {busy.length > 0 &&
                      t("names-busy", { names: busy.join(", ") })}
                  </Typography>

                  {props.inPersonOnly && absent.length > 0 && (
                    <Typography color="#CC5500">
                      {t("names-out", { names: absent.join(", ") })}
                    </Typography>
                  )}
                </div>
              )}
            </div>
            <div
              css={{
                fontSize: "100%",
                marginTop: "0.5rem",
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-evenly",
              }}
            >
              {props.meeting.room && (
                <div css={{ display: "flex", alignItems: "center" }}>
                  <CorporateFare
                    fontSize="small"
                    css={{ marginRight: "0.4rem", color: "#975E44" }}
                  />
                  <Typography color="#975E44">
                    {props.meeting.room.name}
                  </Typography>
                </div>
              )}

              {props.meeting.virtual && (
                <VirtualConferencing virtual={props.meeting.virtual} />
              )}
            </div>
            {props.style == "featured" && (
              <div
                css={{
                  marginTop: "1.5rem",
                  fontSize: "90%",
                  textAlign: "center",
                }}
              >
                {t("suggested")}
              </div>
            )}
          </div>
        </div>
      </ButtonBase>
    </Card>
  );
}

function VirtualConferencing(props: { virtual: string }) {
  const { t } = useTranslation();
  return (
    <div css={{ display: "flex", alignItems: "center" }}>
      <VideoCall
        color="info"
        fontSize="small"
        css={{ marginRight: "0.4rem" }}
      />
      <Typography color="#00008b">{t("virtual")}</Typography>
    </div>
  );
}

function BookedMeetingDetails(props: {
  meeting: MeetingSuggestion;
  state: "loading" | "confirmed" | "error";
  editLink?: string;
  subject: string;
  timezone: string;
  onClose: () => void;
  onBack: () => void;
}) {
  const { t } = useTranslation();
  const start = DateTime.fromISO(props.meeting.start, { zone: props.timezone });
  const dateInformation = getDateShorthand(start, props.timezone);
  return (
    <div
      css={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "2rem",
        margin: "1rem",
        paddingTop: "1rem",
      }}
    >
      <div css={{ position: "absolute", left: 0, top: 0, margin: "1rem" }}>
        {props.state == "error" ? (
          <IconButton onClick={props.onBack}>
            <ChevronLeft titleAccess={t("back-btn-txt")} fontSize="large" />
          </IconButton>
        ) : (
          <IconButton onClick={props.onClose}>
            <Close titleAccess={t("close")} fontSize="large" />
          </IconButton>
        )}

        <div css={{ flexGrow: 1 }}></div>
      </div>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.state == "confirmed" && (
          <CheckCircle
            color="success"
            fontSize="large"
            css={{ marginRight: "0.5rem", transition: "opacity 0.3s" }}
          />
        )}
        {props.state == "loading" && (
          <CircularProgress
            size="1.5rem"
            css={{ marginRight: "0.5rem", transition: "opacity 0.3s" }}
          />
        )}
        {props.state == "error" && (
          <Error
            color="error"
            fontSize="large"
            css={{ marginRight: "0.5rem", transition: "opacity 0.3s" }}
          />
        )}{" "}
        <Typography color="#228B22" fontSize="200%">
          {props.state == "confirmed"
            ? t("confirmed")
            : props.state == "error"
            ? t("booking-failed")
            : t("booking-in-progress")}
        </Typography>
      </div>
      <Card sx={{ width: "500px", marginBottom: "2rem" }}>
        <div
          css={{
            display: "flex",
            flexFlow: "column",
            margin: "2rem",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <div css={{ fontSize: "200%" }}>{props.subject}</div>
          <div css={{ fontSize: "200%" }}>
            {start.toLocaleString(DateTime.TIME_SIMPLE)}
          </div>
          <div css={{ fontSize: "200%" }}>
            {dateInformation} ({start.toLocaleString({ weekday: "short" })})
          </div>
          <div
            css={{
              marginTop: "1.5rem",
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <People fontSize="medium" />
            {props.meeting.attendees
              .filter((a) => a.free)
              .map((user, index) => (
                <Chip key={index} label={user.name} />
              ))}
          </div>
          <div
            css={{
              fontSize: "125%",
              marginTop: "1rem",
              display: "flex",
              gap: "1.5rem",
            }}
          >
            {props.meeting.room && (
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  color: "#975E44",
                }}
              >
                <CorporateFare
                  fontSize="large"
                  css={{ marginRight: "0.5rem", color: "#975E44" }}
                />
                {props.meeting.room.name}
              </div>
            )}
            {props.meeting.virtual && (
              <VirtualConferencing virtual={props.meeting.virtual} />
            )}
          </div>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            disabled={!props.editLink}
            css={{ marginTop: "2rem" }}
            onClick={() => {
              if (props.editLink) {
                window.open(props.editLink, "_blank", "noreferrer");
              }
            }}
          >
            <Edit fontSize="small" css={{ marginRight: "0.5rem" }} />
            {t("edit")}
          </Button>
        </div>
      </Card>
    </div>
  );
}

// TODO: use DateTime.toRelativeCalendar instead.
function getDateShorthand(date: DateTime, timezone: string) {
  const now = DateTime.now().setZone(timezone);
  if (date.hasSame(now, "day")) {
    return "Today";
  } else if (date.hasSame(now.plus({ days: 1 }), "day")) {
    return "Tomorrow";
  } else if (date.hasSame(now, "week")) {
    return "This Week";
  } else if (date.hasSame(now.plus({ weeks: 1 }), "week")) {
    return "Next Week";
  } else {
    return date.toLocaleString({
      month: "short",
      day: "numeric",
    });
  }
}

/**
 * Do not use this with days >= 7.
 */
function addBusinessDays(date: DateTime, days: number) {
  const newDate = date.plus({ days });
  const weekday = newDate.weekday;
  if (weekday < 6) {
    return newDate;
  } else if (weekday == 6) {
    return newDate.plus({ days: 2 });
  } else {
    return newDate.plus({ days: 1 });
  }
}
