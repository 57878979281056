import type { InitOptions } from "i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { DateTime } from "luxon";

import enTranslation from "../locales/en.json";
import frTranslation from "../locales/fr.json";
import { CalendarDate } from "zynq-shared";

const isProduction = process.env.NODE_ENV === "production";

// const locizeOptions = {
//   projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
//   apiKey: process.env.REACT_APP_LOCIZE_APIKEY, // YOU should not expose your apps API key to production!!!
//   referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
//   version: process.env.REACT_APP_LOCIZE_VERSION,
// };

// if (!isProduction) {
//   // locize-lastused
//   // sets a timestamp of last access on every translation segment on locize
//   // -> safely remove the ones not being touched for weeks/months
//   // https://github.com/locize/locize-lastused
//   i18n.use(LastUsed);
// }

i18n
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend
  // .use(Backend)
  // locize-editor
  // InContext Editor of locize
  // .use(locizePlugin)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !isProduction,
    detection: { order: ["querystring", "navigator"] },
    fallbackLng: "en",
    // saveMissing: !isProduction, // you should not use saveMissing in production
    // keySeparator: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: (value, format, lng) => {
        if (value instanceof Date && lng && format) {
          if (!(format in DateTime)) {
            console.error("Invalid datetime interpolation");
            return value;
          }
          return DateTime.fromJSDate(value)
            .setLocale(lng)
            .toLocaleString((DateTime as any)[format]);
        }
        if (
          (value instanceof DateTime || value instanceof CalendarDate) &&
          lng &&
          format
        ) {
          if (!(format in DateTime)) {
            console.error("Invalid datetime interpolation");
            return value;
          }
          return value.setLocale(lng).toLocaleString((DateTime as any)[format]);
        }
        return value;
      },
    },
    resources: {
      en: { translation: enTranslation },
      fr: { translation: frTranslation },
    },
    // backend: locizeOptions,
    // locizeLastUsed: locizeOptions,
  } as InitOptions);

export default i18n;
