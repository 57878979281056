import React from "react";
import { useTranslation, Trans } from "react-i18next";

import { CircularProgress, useMediaQuery } from "@mui/material";
import constants, { ZYNQ_DEMO_DOMAINS } from "./constants";
import type { RESOURCE_TYPES } from "./constants";

import Blueprint from "./blueprint";
import { GroupBookSidePanel } from "./groupBookSidePanel";
import type {
  UserInfo,
  FloorplanInfo,
  SeatInfo,
  RoomEvent,
  Actions,
} from "./types";
import type { APIRequest } from "./hooks";
import type { TimeInterval, CalendarDate, Dictionary } from "zynq-shared";
import { SeatEventKind } from "./types";
import { useAPIMutation } from "./hooks";
import type { Seating } from "zynq-shared";

type ZoomSeat = {
  id?: number;
  roomID?: number;
  zoom?: boolean;
  sidebarOpen: boolean;
};

export default function GroupBookFloorplanContent(props: {
  roomEvents: Dictionary<string, RoomEvent[]>;
  floorplan: FloorplanInfo;
  groupSeatData: SeatInfo[];
  selectedGroupBookDays: {
    days: CalendarDate[];
    timeInterval: TimeInterval;
    floorplanID: number;
  };
  userInfo: UserInfo;
  loadingAction: boolean;
  zoomSeat: ZoomSeat;
  setZoomSeat: (s: ZoomSeat) => void;
  refreshFloorplan: () => APIRequest<unknown>;
  closeGroupBook: () => void;
  setGroupBookOutcome: (
    outcome: {
      asUser?: string | undefined;
      failedDays: string[];
      bookedDays: string[];
      warnings?: string | undefined;
    }[]
  ) => void;
}) {
  const isNotMobile = useMediaQuery("@media (min-width:600px)");
  const [mounted, setMounted] = React.useState(false);
  const [pendingAssignments, setPendingAssignments] = React.useState<
    Dictionary<
      number, // seatID
      {
        user: { name: string; email: string; src?: string };
        resourceType: RESOURCE_TYPES;
      }
    >
  >({});
  const makeBookingsBulk = useAPIMutation<typeof Seating.MakeBookingsBulk>(
    "/apis/seating/make-bookings-bulk",
    {
      onSuccess: (response) => {
        props.closeGroupBook();
        props.refreshFloorplan();
        console.log();
        props.setGroupBookOutcome(response.outcomes);
      },
    }
  );
  React.useEffect(() => {
    setTimeout(() => setMounted(true), 1);
  }, []);

  const { t } = useTranslation();

  const drawerWidth = isNotMobile ? "400px" : "100%";

  const selectedSeat =
    props.zoomSeat.id && props.floorplan
      ? props.floorplan?.seats.find((s) => s.id == props.zoomSeat.id)
      : undefined;

  function onSelectSeat(seatid: number | null) {
    if (!seatid || seatid === props.zoomSeat.id) {
      // Clear selection on second tap.
      props.setZoomSeat({ sidebarOpen: true });
    } else {
      props.setZoomSeat({ id: seatid, zoom: false, sidebarOpen: true });
    }
  }

  const noop = () => {
    // No-op
  };
  const actions: Actions = {
    onDropin: noop,
    onBook: noop,
    onAdminBook: noop,
    onRelease: noop,
    onReleaseDropin: noop,
    onAdminReleaseDropin: noop,
    onAdminRelease: noop,
    onCheckin: noop,
    onCheckinAll: noop,
    onAutobookToday: noop,
    onBookMeeting: noop,
    onAutobook: noop,
    onSelectSeat: (date, floorplanID, seatID, timeInterval) => {
      if (floorplanID == props.floorplan?.id) {
        props.setZoomSeat({
          sidebarOpen: true,
          zoom: true,
          id: seatID,
        });
      } else {
        // No-op
      }
    },
    refreshFloorplan: props.refreshFloorplan,
    setTimeRange: noop,
    onShowQR: noop,
  };
  return (
    <div
      css={{
        width: "100%",
        marginTop: constants.headerHeight,
        height: `calc(100% - ${constants.headerHeight})`,
        marginBottom: "0px",
        position: "relative",
      }}
    >
      {props.floorplan != null && (
        <div css={{ position: "absolute", width: "100%", height: "100%" }}>
          <Blueprint
            sidebarOpen={props.zoomSeat.sidebarOpen}
            role="main"
            userInfo={props.userInfo}
            date={props.selectedGroupBookDays.days[0]}
            timeRange={props.selectedGroupBookDays.timeInterval}
            useSymbols={
              props.userInfo.domain.name == "sgsco.com" ||
              props.userInfo.domain.name == "roundhouse.org.uk" ||
              ZYNQ_DEMO_DOMAINS.includes(props.userInfo.domain.name)
            }
            onSelectSeatID={onSelectSeat}
            onSelectRoomID={() => {
              // No-op
            }}
            selectedID={
              props.floorplan && props.zoomSeat.id != undefined
                ? {
                    isRoom: false,
                    zoom: !!props.zoomSeat.zoom,
                    id: props.zoomSeat.id,
                  }
                : props.floorplan && props.zoomSeat.roomID != undefined
                ? {
                    isRoom: true,
                    zoom: !!props.zoomSeat.zoom,
                    roomID: props.zoomSeat.roomID,
                  }
                : undefined
            }
            floorplan={props.floorplan}
            seatsData={props.groupSeatData.map((seatData) => {
              const selectedUser = pendingAssignments
                ? pendingAssignments[seatData.id]
                : undefined;
              if (selectedUser) {
                return {
                  ...seatData,
                  events: [
                    // Make fake occupied events so the desks show the assigned person's avatar
                    {
                      kind: SeatEventKind.OCCUPIED,
                      startDate:
                        props.selectedGroupBookDays.days[0].date.toISO(),
                      endDate: props.selectedGroupBookDays.days[0].date.toISO(),
                      startTime:
                        props.selectedGroupBookDays.timeInterval.start.toISOTime(),
                      endTime:
                        props.selectedGroupBookDays.timeInterval.end.toISOTime(),
                      avatar: {
                        ...selectedUser.user,
                        isBuddy: true,
                      },
                    },
                  ],
                };
              }
              return seatData;
            })}
            roomsData={[]}
            allRoomEvents={{}}
            zoom={{
              x: isNotMobile ? -200 : 0,
              y: isNotMobile ? 0 : -350 / 2,
            }}
          />
          <GroupBookSidePanel
            userInfo={props.userInfo}
            width={drawerWidth}
            selectedSeat={selectedSeat}
            floorplan={props.floorplan}
            selectedUser={
              pendingAssignments && selectedSeat
                ? pendingAssignments[selectedSeat.id]?.user
                : undefined
            }
            loadingAction={props.loadingAction || makeBookingsBulk.isLoading}
            open={props.zoomSeat.sidebarOpen}
            setOpen={(open: boolean, clear?: boolean) =>
              clear
                ? props.setZoomSeat({ sidebarOpen: open })
                : props.setZoomSeat({ ...props.zoomSeat, sidebarOpen: open })
            }
            setSelectedUser={(user) => {
              if (!selectedSeat) return;
              console.log(pendingAssignments);
              // Object.keys defaults to the wrong type
              const clearDeskID = (
                Object.keys(pendingAssignments) as any as Array<
                  keyof typeof pendingAssignments
                >
              ).find(
                (seatID: number) =>
                  pendingAssignments[seatID]?.user.email == user?.email
              );
              const clearAssignments = clearDeskID
                ? { [clearDeskID]: undefined }
                : {};
              const a = {
                ...pendingAssignments,
                [selectedSeat.id]: user
                  ? {
                      user: {
                        name: user.name,
                        email: user.email,
                        src: user.src,
                      },
                      resourceType: selectedSeat.resourceType,
                    }
                  : undefined,
              };
              setPendingAssignments(a);
            }}
            makeBooking={
              // Only allow booking if there are any pending assignments
              Object.entries(pendingAssignments).filter(
                ([_key, value]) => !!value
              ).length > 0
                ? () => {
                    const baseBookingReqInfo = {
                      floorplanID: props.floorplan.id,
                      days: props.selectedGroupBookDays.days.map((d) =>
                        d.toISODate()
                      ),
                      startTime:
                        props.selectedGroupBookDays.timeInterval.start.toISOTime(),
                      endTime:
                        props.selectedGroupBookDays.timeInterval.end.toISOTime(),
                      bookingLengthDays: 0,
                    };
                    makeBookingsBulk.mutate({
                      bookings: Object.entries(pendingAssignments).flatMap(
                        ([seatID, assignment]) => {
                          if (!assignment) return [];
                          return [
                            {
                              ...baseBookingReqInfo,
                              resourceType: assignment.resourceType,
                              seatID: +seatID,
                              asUser: assignment.user.email,
                            },
                          ];
                        }
                      ),
                    });
                  }
                : undefined
            }
            cancelBooking={props.closeGroupBook}
          />
        </div>
      )}
      <div
        aria-hidden
        css={{
          position: "relative",
          width: "100%",
          height: "100%",
          background: "radial-gradient(transparent, rgba(22, 42, 48, 0.5))",
          pointerEvents: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: !mounted || props.floorplan ? 0 : 1,
          transition: "opacity 1s",
        }}
      >
        <CircularProgress size="5rem" />
      </div>
    </div>
  );
}
