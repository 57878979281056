import React from "react";
import { Avatar as MUIAvatar } from "@mui/material";

const contrastTextColor = "#000";
const main = "#EDBF24";

function getInitials(name: string) {
  const initials = name
    .replace(/[^\p{L}\s]/giu, "")
    .trim()
    .split(/\s+/)
    .filter((s) => s.length > 0)
    .slice(0, 2)
    .map((s) => s[0].toUpperCase())
    .join("");
  if (initials.length > 0) {
    return initials;
  } else {
    return name.trim()[0] ?? "";
  }
}

export function SVGAvatar(props: {
  avatar: { src?: string; name: string };
  size: number;
}) {
  const size = props.size ?? 275;
  const smallSize = size - 13;
  const fontSize = size - 25;
  return (
    <g>
      <circle
        cx="0"
        cy="0"
        r={size}
        stroke={contrastTextColor}
        strokeWidth="30"
        fill={main}
      />
      {props.avatar.src && (
        <image
          href={props.avatar.src}
          height={smallSize * 2}
          width={smallSize * 2}
          x={-smallSize}
          y={-smallSize}
          clipPath="circle(50%)"
        />
      )}
      {!props.avatar.src && (
        <text
          style={{
            fill: contrastTextColor,
            fontSize: `${fontSize}px`,
            fontWeight: "bold",
            strokeWidth: "0",
          }}
          textAnchor="middle"
          dominantBaseline="middle"
          cy={10}
        >
          {getInitials(props.avatar.name)}
        </text>
      )}
    </g>
  );
}

export default function Avatar(props: {
  avatar: { src?: string; name: string };
  className?: string;
}) {
  return (
    <MUIAvatar
      className={props.className}
      css={{
        "&.MuiAvatar-root": {
          backgroundColor: main,
          color: contrastTextColor,
          border: `2px solid ${contrastTextColor}`,
          fontSize: `1rem`,
          lineHeight: "1.25",
        },
      }}
      alt={props.avatar.name}
      src={props.avatar.src}
    >
      {getInitials(props.avatar.name)}
    </MUIAvatar>
  );
}
