import { Add, Delete } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import React from "react";
import type { SuperAdmin } from "zynq-shared";
import { useAPIMutation, useAPIQuery, useError } from "./hooks";
import type { UserInfo } from "./types";

export default function SandboxCreator(props: { userInfo: UserInfo }) {
  const nameInputRef = React.useRef<HTMLInputElement>(null);
  const [name, setName] = React.useState("");
  const [friendlyName, setFriendlyName] = React.useState("");
  const [timezoneOverride, setTimezoneOverride] = React.useState("");
  const [manualUsers, setManualUsers] = React.useState<
    { name: string; email: string }[]
  >([]);
  const [templateDomain, setTemplateDomain] = React.useState(
    "template-sandbox.com"
  );
  const [newUser, setNewUser] = React.useState({ name: "", email: "" });
  const showError = useError();
  const { mutate: createSandbox, isLoading: isLoading } = useAPIMutation<
    typeof SuperAdmin.CreateSandboxDomain
  >("/apis/superadmin/create-sandbox-domain", {
    onError: (e) => showError(e.reason),
    onSuccess: () => {
      setName("");
      setFriendlyName("");
      setManualUsers([]);
      setTemplateDomain("template-sandbox.com");
      setNewUser({ name: "", email: "" });
    },
  });
  const { data: domains } = useAPIQuery<typeof SuperAdmin.AllDomains>(
    "/apis/superadmin/all-domains",
    {}
  );

  const onAddUser = () => {
    setManualUsers((users) => [
      ...users.filter((u) => u.email != newUser.email),
      newUser,
    ]);
    setNewUser({ name: "", email: "" });
    nameInputRef.current?.focus();
  };

  return (
    <div
      css={{
        margin: "2rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div
        css={{
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
          maxWidth: "560px",
        }}
      >
        <TextField
          label="New Domain"
          fullWidth
          required
          placeholder="company-sandbox.com"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="New Friendly Name"
          fullWidth
          required
          value={friendlyName}
          onChange={(e) => setFriendlyName(e.target.value)}
        />
        <Autocomplete
          fullWidth
          renderInput={(props) => (
            <TextField
              {...props}
              fullWidth
              variant="standard"
              label="Template Domain"
            />
          )}
          value={templateDomain}
          onChange={(e, v) => setTemplateDomain(v ?? "template-sandbox.com")}
          options={domains?.domains ?? []}
        />
        <TextField
          label="Timezone Override"
          fullWidth
          value={timezoneOverride}
          onChange={(e) => setTimezoneOverride(e.target.value)}
        />
        <Card
          css={{
            padding: "1rem",
            margin: "1rem",
            width: "100%",
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Alert severity="info" css={{ margin: "1rem" }}>
            Users, Groups & Permissions will be copied over.
          </Alert>
          <h3 css={{ margin: "1rem" }}>Additional Users</h3>
          <div css={{ display: "flex", gap: "1rem" }}>
            <TextField
              inputRef={nameInputRef}
              label="Name"
              value={newUser.name}
              onChange={(e) =>
                setNewUser((u) => ({ ...u, name: e.target.value }))
              }
            />
            <TextField
              label="Email"
              value={newUser.email}
              onChange={(e) =>
                setNewUser((u) => ({ ...u, email: e.target.value }))
              }
              onKeyPress={(e) => {
                if (e.key == "Enter" && newUser.name && newUser.email) {
                  onAddUser();
                }
              }}
            />
            <IconButton
              size="large"
              onClick={onAddUser}
              disabled={!newUser.name || !newUser.email}
            >
              <Add />
            </IconButton>
          </div>
          {manualUsers.map((user) => (
            <div
              key={user.email}
              css={{ display: "flex", alignItems: "center" }}
            >
              {user.name} ({user.email})
              <IconButton
                onClick={() =>
                  setManualUsers((users) =>
                    users.filter((u) => u.email != user.email)
                  )
                }
              >
                <Delete />
              </IconButton>
            </div>
          ))}
        </Card>
        <Button
          color="primary"
          size="large"
          variant="contained"
          disabled={isLoading || !name || !friendlyName || !templateDomain}
          onClick={() => {
            createSandbox({
              name,
              friendlyName,
              templateDomain,
              timezoneOverride,
              users: manualUsers,
            });
          }}
        >
          {isLoading && <CircularProgress color="primary" size={24} />}
          Create Sandbox
        </Button>
      </div>
    </div>
  );
}
