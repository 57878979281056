import React from "react";
import { useTranslation, Trans } from "react-i18next";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import {
  Business as BusinessIcon,
  CropFree as CropFreeIcon,
} from "@mui/icons-material";
import constants, { RESOURCE_TYPES, ZYNQ_DEMO_DOMAINS } from "./constants";

import Blueprint from "./blueprint";
import { SidePanel, WhosInTab } from "./sidePanel";
import type {
  UserInfo,
  FloorplanInfo,
  SeatInfo,
  SeatEvent,
  RoomEvent,
  BookingSeatEvent,
  BookingSeatInfo,
  Actions,
} from "./types";
import { RelativeDate } from "./types";
import { Alert } from "@mui/material";
import BookForButton from "./bookForButton";
import UpcomingEventDialog from "./upcomingEventDialog";
import type { APIRequest } from "./hooks";
import type { QRDialogInfo } from "./showQRDialog";
import { DateTime } from "luxon";
import i18next from "i18next";
import type { TimeInterval, CalendarDate, Dictionary } from "zynq-shared";
import { isWeekend, weeksSinceBaseDate } from "./luxonUtil";

function FloorplanNameText(props: {
  floorplanName: string;
  buildingName?: string;
}) {
  if (props.buildingName) {
    return (
      <span>
        <b>{props.floorplanName}</b> <i>({props.buildingName})</i>
      </span>
    );
  } else {
    return (
      <span>
        <b>{props.floorplanName}</b>
      </span>
    );
  }
}

export function isBookableInfo(
  date: CalendarDate,
  today: CalendarDate,
  timeRange: TimeInterval,
  floorplan: FloorplanInfo,
  relativeDate: RelativeDate,
  userInfo: UserInfo,
  resourceType: RESOURCE_TYPES = RESOURCE_TYPES.DESK
): {
  isBookable: boolean;
  isDropinBookable: boolean;
  bookingErrorText?: string;
  adminOverrideText?: string;
} {
  // Attempt to fix this over a year boundary
  const weekIndex = weeksSinceBaseDate(date) - weeksSinceBaseDate(today);
  const weekDayIndex = date.weekday % 7;
  const bookings = floorplan.weekBookings[resourceType]?.[weekIndex] ?? [];

  const thisWeeksUserSchedule =
    userInfo.userSchedule[
      weeksSinceBaseDate(date) % userInfo.userSchedule.length
    ];
  if (relativeDate == RelativeDate.Past) {
    const val: string = i18next.t("admin");
    return {
      isBookable: false,
      isDropinBookable: false,
      bookingErrorText: i18next.t("company-blocks-past-bookings"),
      adminOverrideText: i18next.t("company-blocks-past-bookings-can-override"),
    };
    // } else if (floorplan.assignedSeat?.state == SEAT_STATES.YOURS) {
    //   return { isBookable: true, isDropinBookable: true };
  } else if (
    !bookings.some((b) => b.hasSame(date, "day")) &&
    floorplan.maxDailyVisits != null &&
    floorplan.todaysNumVisits >= floorplan.maxDailyVisits
  ) {
    return {
      isBookable: false,
      isDropinBookable: false,
      bookingErrorText: i18next.t("office-at-max-capacity", {
        maxVisits: floorplan.maxDailyVisits,
      }),
      adminOverrideText: i18next.t("office-at-max-capacity-can-override", {
        maxVisits: floorplan.maxDailyVisits,
      }),
    };
  } else if (
    !userInfo.allowedHours.engulfs(timeRange) &&
    !userInfo.allowedHours.isAllDay()
  ) {
    return {
      isBookable: false,
      isDropinBookable: true,
      bookingErrorText: i18next.t("schedule-only-allows-booking-between", {
        start: userInfo.allowedHours.start.toLocaleString(DateTime.TIME_SIMPLE),
        end: userInfo.allowedHours.end.toLocaleString(DateTime.TIME_SIMPLE),
      }),
    };
  } else if (!thisWeeksUserSchedule[weekDayIndex]) {
    return {
      isBookable: false,
      isDropinBookable: true,
      bookingErrorText:
        userInfo.userSchedule.length > 1
          ? i18next.t("days-blocked-by-schedule-this-week", {
              weekday: date.toLocaleString({ weekday: "long" }),
            })
          : i18next.t("days-blocked-by-schedule", {
              weekday: date.toLocaleString({ weekday: "long" }),
            }),
    };
  } else if (!floorplan.weekendsBookable && isWeekend(date)) {
    return {
      isBookable: false,
      isDropinBookable: true,
      bookingErrorText: i18next.t("weekend-bookings-blocked"),
      adminOverrideText: i18next.t("weekend-bookings-blocked-can-override"),
    };
  } else if (date.diff(today, "day").days < floorplan.bookAheadDaysMin) {
    return {
      isBookable: false,
      isDropinBookable: true,
      bookingErrorText: i18next.t("bookings-blocked-min-days-in-advance", {
        count: floorplan.bookAheadDaysMin,
      }),
      adminOverrideText: i18next.t(
        "bookings-blocked-min-days-in-advance-can-override",
        { count: floorplan.bookAheadDaysMin }
      ),
    };
  } else if (date.diff(today, "day").days > floorplan.bookAheadDaysMax) {
    return {
      isBookable: false,
      isDropinBookable: true,
      bookingErrorText: i18next.t("bookings-blocked-max-days-in-advance", {
        count: floorplan.bookAheadDaysMax,
      }),
      adminOverrideText: i18next.t(
        "bookings-blocked-max-days-in-advance-can-override",
        { count: floorplan.bookAheadDaysMax }
      ),
    };
  } else if (
    !bookings.some((b) => b.hasSame(date, "day")) &&
    bookings.length >= floorplan.maxWeeklyBookings
  ) {
    return {
      isBookable: false,
      isDropinBookable: true,
      bookingErrorText: i18next.t("bookings-weekly-limit-reached", {
        count: floorplan.maxWeeklyBookings,
      }),
    };
  } else {
    return { isBookable: true, isDropinBookable: true };
  }
}

type ZoomSeat = {
  id?: number;
  roomID?: number;
  zoom?: boolean;
  sidebarOpen: boolean;
};

export default function FloorplanContent(props: {
  roomEvents: Dictionary<string, RoomEvent[]>;
  timeRange: TimeInterval;
  defaultTimeRange: TimeInterval;
  setTimeRange: (r: TimeInterval, live?: boolean) => void;
  floorplan: FloorplanInfo | undefined;
  userInfo: UserInfo;
  loadingAction: boolean;
  zoomSeat: ZoomSeat;
  setZoomSeat: (s: ZoomSeat) => void;
  today: CalendarDate;
  date: CalendarDate;
  relativeDate: RelativeDate;
  onBook: (seat: SeatInfo, asAdmin: boolean) => void;
  onRelease: (bookingID: number, asAdmin: boolean) => void;
  onReleaseDropin: (bookingID: number, asAdmin: boolean) => void;
  onAutobook: (opts: {
    hint?: SeatInfo;
    dates?: CalendarDate[];
    dropin?: boolean;
    timeRange?: TimeInterval;
    autoselect?: CalendarDate;
  }) => void;
  onCheckin: (s: SeatInfo, e: SeatEvent) => void;
  onCheckinAll: () => void;
  onDropin: () => void;
  onBookMeeting: () => void;
  showQRDialog: (a: QRDialogInfo) => void;
  refreshFloorplan: () => APIRequest<unknown>;
  onNavigate: (
    date: CalendarDate,
    floorplanID: number,
    seatID: number,
    timeInterval?: TimeInterval
  ) => void;
}) {
  const isNotMobile = useMediaQuery("@media (min-width:600px)");
  const [mounted, setMounted] = React.useState(false);
  const [selectedSidebarTab, setSelectedSidebarTab] = React.useState<string>();
  const [showWhosInList, setShowWhosInList] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => setMounted(true), 1);
  }, []);

  const { t } = useTranslation();

  const drawerWidth = isNotMobile ? "400px" : "100%";

  const selectedSeat =
    props.zoomSeat.id && props.floorplan
      ? props.floorplan?.seats.find((s) => s.id == props.zoomSeat.id)
      : undefined;

  const selectedRoom =
    props.zoomSeat.roomID && props.floorplan
      ? props.floorplan?.rooms.find((s) => s.id == props.zoomSeat.roomID)
      : undefined;

  const {
    isBookable = false,
    isDropinBookable = false,
    bookingErrorText = undefined,
    adminOverrideText = undefined,
  } = props.floorplan
    ? isBookableInfo(
        props.date,
        props.today,
        props.timeRange,
        props.floorplan,
        props.relativeDate,
        props.userInfo,
        selectedSeat?.resourceType
      )
    : {};

  function onSelectSeat(seatid: number | null) {
    if (!seatid || seatid === props.zoomSeat.id) {
      // Clear selection on second tap.
      props.setZoomSeat({ sidebarOpen: true });
    } else {
      props.setZoomSeat({ id: seatid, zoom: false, sidebarOpen: true });
    }
  }

  function onSelectRoom(roomID: number | null) {
    if (!roomID || roomID === props.zoomSeat.roomID) {
      // Clear selection on second tap.
      props.setZoomSeat({ sidebarOpen: true });
    } else {
      props.setZoomSeat({ roomID: roomID, zoom: false, sidebarOpen: true });
    }
  }

  const canBookAsManager =
    props.userInfo.isManager && props.userInfo.domain.managerBookingEnabled;
  const dropinBookingID = props.floorplan?.dropinData.bookingID;
  const checkinRequired = !!props.floorplan?.checkinRequired;
  const noFloorplan = props.floorplan != null && props.floorplan.img == null;
  const combinedVerifyURL =
    props.floorplan?.seatVerifyQRURL ??
    props.floorplan?.dropinData.dropinVerifyQRURL;

  const onShowQR = React.useMemo(
    () =>
      combinedVerifyURL != null && props.floorplan?.checkinQREnabled
        ? () =>
            props.showQRDialog({
              open: true,
              verificationURL: combinedVerifyURL,
              date: props.date,
              domainName: props.userInfo.domain.name,
            })
        : undefined,
    [
      combinedVerifyURL,
      props.floorplan?.checkinQREnabled,
      props.date.toISODate(),
    ]
  );

  const actions: Actions = {
    onDropin: props.onDropin,
    onBook: (seat) => props.onBook(seat, false),
    onAdminBook: (seat) => props.onBook(seat, true),
    onRelease: (bookingID) => props.onRelease(bookingID, false),
    onReleaseDropin: (bookingID) => props.onReleaseDropin(bookingID, false),
    onAdminReleaseDropin: (bookingID) => props.onReleaseDropin(bookingID, true),
    onAdminRelease: (bookingID) => props.onRelease(bookingID, true),
    onCheckin: props.onCheckin,
    onCheckinAll: props.onCheckinAll,
    onAutobookToday: (seat) =>
      props.onAutobook({
        hint: seat,
        dates: [props.today],
        dropin: false,
      }),
    onBookMeeting: props.onBookMeeting,
    onAutobook: ({ hint, dropin, addAutoSelectDate }) =>
      props.onAutobook({
        hint,
        dropin,
        autoselect: addAutoSelectDate ? props.date : undefined,
      }),
    onSelectSeat: (date, floorplanID, seatID, timeInterval) => {
      if (floorplanID == props.floorplan?.id) {
        props.setZoomSeat({
          sidebarOpen: true,
          zoom: true,
          id: seatID,
        });
      } else {
        props.onNavigate(date, floorplanID, seatID, timeInterval);
      }
    },
    refreshFloorplan: props.refreshFloorplan,
    setTimeRange: props.setTimeRange,
    onShowQR: onShowQR,
  };
  return (
    <div
      css={{
        width: "100%",
        marginTop: constants.headerHeight,
        height: `calc(100% - ${constants.headerHeight})`,
        marginBottom: "0px",
        position: "relative",
      }}
    >
      {!noFloorplan && props.floorplan != null && (
        <div css={{ position: "absolute", width: "100%", height: "100%" }}>
          <Blueprint
            sidebarOpen={props.zoomSeat.sidebarOpen}
            role="main"
            userInfo={props.userInfo}
            date={props.floorplan.date}
            timeRange={props.timeRange}
            useSymbols={
              props.userInfo.domain.name == "sgsco.com" ||
              props.userInfo.domain.name == "roundhouse.org.uk" ||
              ZYNQ_DEMO_DOMAINS.includes(props.userInfo.domain.name)
            }
            onSelectSeatID={onSelectSeat}
            onSelectRoomID={onSelectRoom}
            selectedID={
              props.floorplan && props.zoomSeat.id != undefined
                ? {
                    isRoom: false,
                    zoom: !!props.zoomSeat.zoom,
                    id: props.zoomSeat.id,
                  }
                : props.floorplan && props.zoomSeat.roomID != undefined
                ? {
                    isRoom: true,
                    zoom: !!props.zoomSeat.zoom,
                    roomID: props.zoomSeat.roomID,
                  }
                : undefined
            }
            floorplan={props.floorplan}
            seatsData={props.floorplan.seats}
            roomsData={props.floorplan.rooms}
            allRoomEvents={props.roomEvents}
            zoom={{
              x: isNotMobile ? -200 : 0,
              y: isNotMobile ? 0 : -350 / 2,
            }}
          />
          <SidePanel
            width={drawerWidth}
            loadingAction={props.loadingAction}
            userInfo={props.userInfo}
            floorplan={props.floorplan}
            open={props.zoomSeat.sidebarOpen}
            selectedTab={selectedSidebarTab}
            setSelectedTab={setSelectedSidebarTab}
            setOpen={(open: boolean, clear?: boolean) =>
              clear
                ? props.setZoomSeat({ sidebarOpen: open })
                : props.setZoomSeat({ ...props.zoomSeat, sidebarOpen: open })
            }
            actions={actions}
            bookableInfo={{
              isBookable: isBookable,
              isDropinBookable: isDropinBookable,
              bookingErrorText: bookingErrorText,
              adminOverrideText: adminOverrideText,
            }}
            selectedSeat={selectedSeat}
            selectedRoom={selectedRoom}
            selectedRoomEvents={
              selectedRoom && props.roomEvents[selectedRoom.calendarID]
            }
            timeRange={props.timeRange}
            defaultTimeRange={props.defaultTimeRange}
          />
        </div>
      )}
      {noFloorplan && props.floorplan != null && (
        <>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "max(5%, 2rem)",
              boxSizing: "border-box",
              position: "absolute",
              width: "100%",
              height: "100%",
              gap: "1rem",
            }}
          >
            {props.loadingAction && (
              <LinearProgress
                css={{
                  width: "100%",
                  position: "absolute",
                  top: 0,
                  height: "0.5rem",
                }}
              />
            )}
            <div css={{ margin: "2rem", textAlign: "center" }}>
              <BusinessIcon
                color="primary"
                css={{
                  fontSize: "2rem",
                  margin: "0.5rem",
                  verticalAlign: "middle",
                }}
              />
              <Trans
                i18nKey={"currently-looking-at"}
                components={[
                  <FloorplanNameText
                    key={props.floorplan.name}
                    floorplanName={props.floorplan.name}
                    buildingName={props.floorplan.buildingName}
                  />,
                ]}
              />
            </div>
            {dropinBookingID &&
              !props.floorplan?.dropinData.needsCheckin &&
              props.relativeDate == RelativeDate.Today && (
                <div>{t("youre-all-set-to-come-into-the-office")}</div>
              )}
            {dropinBookingID &&
              props.floorplan?.dropinData.needsCheckin &&
              props.relativeDate == RelativeDate.Today && (
                <div>
                  <Trans i18nKey={"time-to-check-in"}>
                    Time to check in for your booking today at
                    <b>{{ floorplanName: props.floorplan.name }}</b>!
                  </Trans>
                </div>
              )}
            {dropinBookingID && props.relativeDate == RelativeDate.Future && (
              <div css={{ margin: "1rem" }}>
                <Trans i18nKey="booked-a-spot-on-date">
                  You've booked a spot at{" "}
                  <b>{{ floorplanName: props.floorplan.name }}</b> on{" "}
                  {{
                    date: props.date.toLocaleString({
                      month: "long",
                      day: "numeric",
                    }),
                  }}
                  !
                </Trans>{" "}
                {checkinRequired && t("dont-forget-to-check-in")}
              </div>
            )}
            {!dropinBookingID && (
              <>
                <div>{t("coming-into-office")}</div>
                <div css={{ marginBottom: "2rem" }}>
                  {t("choose-option-below")}
                </div>
              </>
            )}
            {!dropinBookingID && bookingErrorText != null ? (
              <Alert
                severity="info"
                css={{ margin: "1rem" }}
              >{`Note: ${bookingErrorText}.`}</Alert>
            ) : null}
            {dropinBookingID &&
              props.floorplan.dropinEnabled &&
              props.floorplan?.dropinData.needsCheckin &&
              props.relativeDate == RelativeDate.Today && (
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={props.loadingAction}
                  onClick={props.onCheckinAll}
                >
                  {t("check-in")}
                </Button>
              )}
            {!dropinBookingID && props.floorplan.dropinEnabled && (
              <Button
                variant="contained"
                color="primary"
                disabled={!isBookable || props.loadingAction}
                onClick={props.onDropin}
              >
                {t("book-now")}
              </Button>
            )}
            {props.floorplan.dropinData.dropinVerifyQRURL != null && (
              <Button
                onClick={() =>
                  props.showQRDialog({
                    open: true,
                    verificationURL:
                      props.floorplan!.dropinData.dropinVerifyQRURL!,
                    date: props.date,
                    domainName: props.userInfo.domain.name,
                  })
                }
                style={{
                  marginBottom: "2rem",
                  alignSelf: "center",
                }}
                color="secondary"
                variant="contained"
                disabled={props.loadingAction}
              >
                <CropFreeIcon style={{ margin: "0px 10px" }} />
                {t("view-entry-code")}
              </Button>
            )}
            {dropinBookingID && onShowQR && (
              <Button
                onClick={onShowQR}
                style={{
                  marginBottom: "2rem",
                  alignSelf: "center",
                }}
                color="secondary"
                variant="contained"
              >
                <CropFreeIcon style={{ margin: "0px 10px" }} />
                {t("view-entry-code")}
              </Button>
            )}
            {dropinBookingID && (
              <Button
                variant="contained"
                color="secondary"
                disabled={props.loadingAction}
                onClick={() => props.onReleaseDropin(dropinBookingID, false)}
              >
                {t("release")}
              </Button>
            )}
            {props.floorplan.bookAheadDaysMax > 0 && (
              <Button
                variant="contained"
                color="primary"
                disabled={props.loadingAction}
                onClick={() => props.onAutobook({ dropin: true })}
              >
                {t("book-for-later")}
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              disabled={props.loadingAction}
              onClick={() => setShowWhosInList(true)}
            >
              {t("whos-in")}
            </Button>
            {(props.userInfo.isAdmin || canBookAsManager) && (
              <>
                <h3>{props.userInfo.isAdmin ? t("admin") : t("manager")}</h3>
                <BookForButton
                  timeRange={props.timeRange}
                  defaultTimeRange={props.defaultTimeRange}
                  timeBasedBookingEnabled={
                    props.userInfo.domain.timeBasedBookingsEnabled
                  }
                  date={props.date}
                  today={props.floorplan.today}
                  timezone={props.floorplan.timezone}
                  kind={"dropin"}
                  floorplanID={props.floorplan.id}
                  adminOverrideText={adminOverrideText}
                  onBook={() => props.refreshFloorplan().promise}
                  buttonText={t("book-for")}
                  limitToDirectReports={
                    !props.userInfo.isAdmin && canBookAsManager
                  }
                  scanningEnabled={props.floorplan.checkinQREnabled}
                />
              </>
            )}
          </div>
        </>
      )}
      <div
        aria-hidden
        css={{
          position: "relative",
          width: "100%",
          height: "100%",
          background: "radial-gradient(transparent, rgba(22, 42, 48, 0.5))",
          pointerEvents: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: !mounted || props.floorplan ? 0 : 1,
          transition: "opacity 1s",
        }}
      >
        <CircularProgress size="5rem" />
      </div>
      {props.floorplan && (
        <UpcomingEventDialog
          userInfo={props.userInfo}
          eventsByRoomID={props.roomEvents}
          timezone={props.floorplan.timezone}
        />
      )}
      {props.floorplan && (
        <Dialog open={showWhosInList} onClose={() => setShowWhosInList(false)}>
          <DialogContent>
            <WhosInTab
              floorplan={props.floorplan}
              timeRange={props.timeRange}
              actions={actions}
              userInfo={props.userInfo}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
