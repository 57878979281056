import React, { useState } from "react";
import { Alert } from "@mui/material";

import VendorLogin from "./vendorLogin";
import { useLocation, useHistory } from "react-router-dom";

import {
  DEFAULT_LANDING,
  MS_CLIENT_ID,
  GOOGLE_CLIENT_ID,
  ZYNQ_APP_AUTH_CONTEXT_HEADER,
  DEFAULT_ONBOARDING_LANDING,
} from "./constants";
import { CircularProgress } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { AppAuthContext } from "./providers";
import mixpanel from "mixpanel-browser";
import { PublicClientApplication } from "@azure/msal-browser";

import type { AuthError, AuthenticationResult } from "@azure/msal-browser";

function checkIsInRedirectIFrame() {
  const hashContainsRedirectValues =
    window.location.hash.includes("code") ||
    window.location.hash.includes("error");
  try {
    return window.self !== window.top && hashContainsRedirectValues;
  } catch (e) {
    return hashContainsRedirectValues;
  }
}

export function MSFTLoginContents(props: {
  onClick?: () => void;
  text?: string;
  disabled?: boolean;
}) {
  return (
    <button
      onClick={props.onClick}
      css={{
        border: "1px solid lightgray",
        display: "flex",
        alignItems: "center",
        paddingRight: "1.5rem",
        backgroundColor: props.disabled ? "lightgray" : "white",
        ":hover": { backgroundColor: "#F5F5F5" },
        cursor: "pointer",
        fontFamily: "Google Sans, arial, sans-serif",
        fontWeight: "500",
        color: "#5E5E5E",
        fontSize: "14px",
        height: "41px",
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41">
        <path fill="#f25022" d="M13 11h9v9h-9z"></path>
        <path fill="#00a4ef" d="M13 21h9v9h-9z"></path>
        <path fill="#7fba00" d="M23 11h9v9h-9z"></path>
        <path fill="#ffb900" d="M23 21h9v9h-9z"></path>
      </svg>
      {props.text ?? "Continue with Microsoft"}
    </button>
  );
}

function LoginPage({ admin = false }) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const authContext = React.useContext(AppAuthContext);
  const params = new URLSearchParams(location.search);
  const isOnboardingLogin = params.get("onboarding") != null;

  async function login(
    body:
      | { msToken: string }
      | { gToken: string }
      | { accessToken: string; email: string }
  ) {
    setLoading(true);
    try {
      const r = await fetch("/seating/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          [ZYNQ_APP_AUTH_CONTEXT_HEADER]: authContext,
        },
        body: JSON.stringify({
          ...body,
          isOnboarding: isOnboardingLogin,
          plan: params.get("plan"),
        }),
      });
      const res = await r.json();
      if (res.status == "success") {
        if (isOnboardingLogin) {
          history.push(params.get("redirect") || DEFAULT_ONBOARDING_LANDING);
          return;
        }
        if (admin) {
          if (!res.isAdmin) {
            setError(i18next.t("please-sign-in-using-admin-account"));
          } else {
            window.location.href = params.get("redirect") || "/admin";
          }
        } else {
          history.push(params.get("redirect") || DEFAULT_LANDING);
        }
      } else {
        setLoading(false);
        setError(res.reason.toString());
      }
    } catch {
      return setLoading(false);
    }
  }

  const loginHandler = (response: any, other: any) => {
    const error = response?.error ?? other?.error;
    if (error) {
      return setError(
        error.toString() +
          (response.details ? " (" + response.details + ")" : "")
      );
    }

    login({ gToken: response.credential });
  };
  const authHandler = (err?: AuthError, data?: AuthenticationResult) => {
    // Adding logs to debug what's going on with login (only happens in production)
    console.log("err:", err);
    console.log("data:", data);
    window.sessionStorage.clear(); // https://github.com/alexandrtovmach/react-microsoft-login/issues/84
    if (data?.accessToken) {
      login({ msToken: data.accessToken });
    } else {
      if (err?.errorCode === "user_cancelled") return;
      setError(err ? err.toString() : i18next.t("empty-token-response-msft"));
    }
  };

  React.useEffect(() => {
    if (isOnboardingLogin) {
      mixpanel.track("Onboarding Login Page View", {
        redirectingTo: params.get("redirect") ?? "",
      });
    }
  }, []);

  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    document.body.appendChild(script);
  }, []);

  React.useEffect(() => {
    (window as any).googleLoginHandler = loginHandler;
  }, [loginHandler]);

  if (checkIsInRedirectIFrame()) {
    return (
      <div css={{ width: "100%", margin: "2rem", textAlign: "center" }}>
        Detected hidden iFrame, please reach out to support@zynq.io
      </div>
    );
  }

  return (
    <div
      css={{
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        alignItems: "center",
        height: "100%",
        flexDirection: "column",
        padding: "1rem 0.5rem",
        boxSizing: "border-box",
      }}
    >
      <a href="/" target="_blank">
        <img src="/static/img/logo_small.png" alt="" />
      </a>
      <div css={{ height: "5rem", flexShrink: 2 }} />
      <div className="tagline">
        {isOnboardingLogin
          ? t("sign-in-to-setup")
          : admin
          ? t("admin-panel")
          : t("coming-to-office")}
      </div>
      <div css={{ height: "2rem", flexShrink: 2 }} />

      {!loading ? (
        <div className="explainer">
          {admin && !isOnboardingLogin
            ? t("lets-see-some-id")
            : t("login-to-get-started")}
        </div>
      ) : (
        <CircularProgress />
      )}
      <div css={{ height: "2rem", flexShrink: 1 }} />

      <div
        id="g_id_onload"
        data-client_id={GOOGLE_CLIENT_ID}
        data-context="signin"
        data-ux_mode="popup"
        data-callback={"googleLoginHandler"}
        data-auto_prompt="false"
      ></div>

      <div
        className="g_id_signin"
        data-type="standard"
        data-shape="rectangular"
        data-theme="outline"
        data-text={isOnboardingLogin ? "continue_with" : "signin_with"}
        data-size="large"
        data-logo_alignment="left"
      ></div>

      <div css={{ height: "1rem", flexShrink: 1 }} />

      <div
        css={
          loading
            ? { opacity: 0.5, pointerEvents: "none", marginTop: "5px" }
            : { marginTop: "5px" }
        }
      >
        <MSFTLoginContents
          onClick={() => {
            const msalInstance = new PublicClientApplication({
              auth: {
                clientId: MS_CLIENT_ID,
                navigateToLoginRequestUrl: false,
              },
            });

            const loginResponse = msalInstance
              .loginPopup({
                scopes: ["user.read"],
                prompt: "select_account",
              })
              .then((result) => {
                authHandler(undefined, result);
              })
              .catch((err: AuthError) => {
                authHandler(err, undefined);
              });
          }}
          text={
            isOnboardingLogin
              ? "Continue with Microsoft"
              : "Sign in with Microsoft"
          }
          disabled={loading}
        />
      </div>
      <div css={{ height: "1rem", flexShrink: 1 }} />

      {!isOnboardingLogin && <VendorLogin disabled={loading} login={login} />}
      <div css={{ height: "1rem", flexShrink: 1 }} />

      {error && (
        <Alert
          severity="error"
          style={{ whiteSpace: "pre-line", margin: "1rem" }}
        >
          {t("error")}: {error}
        </Alert>
      )}
      <div css={{ height: "10vh", flexShrink: 1 }} />
    </div>
  );
}

export default LoginPage;
