import React, { useEffect, useState } from "react";
import type { PanZoom } from "panzoom";
import panzoom from "panzoom";

import Seat from "./seat";
import Room from "./room";
import { RESOURCE_TYPES, SEAT_STATES } from "./constants";
import type { RoomEvent, RoomInfo, SeatInfo, UserInfo } from "./types";
import {
  SeatWithSymbol,
  TCHoodWithSymbol,
  LabBenchWithSymbol,
  ccSymbols,
  MothersRoomWithSymbol,
  EquipmentWithSymbol,
} from "./seatSymbols";
import type { CalendarDate, Dictionary } from "zynq-shared";
import type { TimeInterval } from "zynq-shared";
import { useTranslation } from "react-i18next";
import type { DateTime } from "luxon";

// temporarily for debugging panzoom issues
function isNaNOrUndefined(value: any) {
  if (value === undefined) return true;
  if (Number.isNaN(value)) {
    return Number.isNaN(value);
  }

  return value !== value;
}

function getNextSibling(elem: Element | null, selector: string) {
  if (!elem) return null;
  let sibling = elem.nextElementSibling;
  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.nextElementSibling;
  }
  return null;
}
function getPreviousSibling(elem: Element | null, selector: string) {
  if (!elem) return null;
  let sibling = elem.previousElementSibling;
  while (sibling) {
    if (sibling.matches(selector)) return sibling;
    sibling = sibling.previousElementSibling;
  }
  return null;
}

function zoomOn(
  mapElement: HTMLElement,
  wrapperElement: HTMLElement,
  targetElement: SVGElement | null,
  panzoomInstance: PanZoom | any, // TODO: fix type
  centerXOffset: number,
  centerYOffset: number,
  smooth = true,
  zoomScale = 1
) {
  if (targetElement == null) {
    return;
  }
  const screenW = wrapperElement.clientWidth;
  const screenH = wrapperElement.clientHeight;

  // Temporary debugging logging
  if (isNaNOrUndefined(screenW / 2) || isNaNOrUndefined(screenH / 2)) {
    console.log("zoomOn zoom NaN: ", screenW, screenH);
  }

  try {
    panzoomInstance.smoothZoomAbs(screenW / 2, screenH / 2, zoomScale, () => {
      const targetRect = targetElement.getBoundingClientRect();
      const mapElement = targetElement.ownerSVGElement;

      if (mapElement == null) {
        return;
      }

      const container = mapElement.getBoundingClientRect();
      const targetX =
        container.x - (targetRect.left + targetRect.width / 2) + centerXOffset;
      const targetY =
        container.y - (targetRect.top + targetRect.height / 2) + centerYOffset;
      try {
        panzoomInstance.moveTo(
          targetX + screenW / 2,
          targetY + screenH / 2,
          smooth
        );
      } catch (e) {
        console.log(e);
      }
    });
  } catch (e) {
    console.log(e);
  }
}

function centerOn(
  mapElement: HTMLElement | null,
  wrapperElement: HTMLElement,
  targetElement: SVGElement | null,
  panzoomInstance: PanZoom | any, // TODO: fix type
  centerXOffset: number,
  centerYOffset: number
) {
  if (targetElement == null) {
    return;
  }
  if (mapElement == null) {
    return;
  }

  const screenW = wrapperElement.clientWidth;
  const screenH = wrapperElement.clientHeight;

  // Temporary debugging logging
  // if (isNaNOrUndefined(screenW / 2) || isNaNOrUndefined(screenH / 2)) {
  //   console.log("zoomOn zoom NaN: ", screenW, screenH, svgH, svgW);
  // }

  // panzoomInstance.smoothZoomAbs(screenW / 2, screenH / 2, zoomScale, () => {
  const targetRect = targetElement.getBoundingClientRect();
  // let mapElement = targetElement.ownerSVGElement;

  const container = mapElement.getBoundingClientRect();
  const targetX = container.x - (targetRect.left + targetRect.width / 2);
  const targetY = container.y - (targetRect.top + targetRect.height / 2);
  try {
    panzoomInstance.moveTo(
      targetX + screenW / 2 + centerXOffset,
      targetY + screenH / 2 + centerYOffset,
      false
    );
  } catch (e) {
    console.log(e);
  }
}

type Props = {
  userInfo?: UserInfo;
  onSelectSeatID: (id: number) => void;
  onSelectRoomID: (id: number) => void;
  selectedID?:
    | { isRoom: true; roomID: number; zoom: boolean }
    | { isRoom: false; id: number; zoom: boolean };
  sidebarOpen?: boolean;
  floorplan?: {
    img: string | null;
    name: string;
    floorplanDimensions?: { height: number; width: number };
  };
  seatsData: SeatInfo[];
  roomsData: RoomInfo[];
  allRoomEvents: Dictionary<string, RoomEvent[]>;
  role?: string;
  useSymbols?: boolean;
  date: CalendarDate;
  timeRange: TimeInterval;
  zoom: {
    x?: number;
    y?: number;
    max?: number;
    min?: number;
  };
};

export default function Blueprint({
  userInfo,
  onSelectSeatID,
  onSelectRoomID,
  selectedID,
  floorplan,
  seatsData,
  roomsData = [],
  allRoomEvents,
  role,
  date,
  timeRange,
  sidebarOpen,
  useSymbols = false,
  zoom: {
    x: centerXOffset = 0,
    y: centerYOffset = 0,
    max: maxZoom = 3,
    min: minZoom = 0.25,
  },
}: Props) {
  const { t } = useTranslation();
  const [viewport, setViewport] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const mouseDownEvent = React.useRef<MouseEvent>();
  const touchStart = React.useRef<Touch>();
  const touchMove = React.useRef<React.Touch>();
  const touchMoveDist = React.useRef(0.0);
  const [panzoomInstance, setPanzoomInstance] = useState<PanZoom>();

  useEffect(() => {
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual";
    }
  }, []);

  useEffect(() => {
    if (floorplan?.img != undefined && !floorplan.floorplanDimensions) {
      const img = new Image();
      img.onload = function () {
        setViewport({
          height: (this as HTMLImageElement).naturalHeight * 0.24,
          width: (this as HTMLImageElement).naturalWidth * 0.24,
        });
      };
      img.src = floorplan.img;
    } else if (floorplan?.floorplanDimensions) {
      setViewport(floorplan.floorplanDimensions);
    }
  }, [floorplan?.img, floorplan?.floorplanDimensions]);

  useEffect(() => {
    if (viewport && panzoomInstance == undefined) {
      const map = document.getElementById("map")!;
      const wrapper = document.getElementById("svg-canvas-wrapper")!;
      const screenW = wrapper.clientWidth;
      const screenH = wrapper.clientHeight;
      const svgW = viewport.width;
      const svgH = viewport.height;

      const fitsVertically = screenW / screenH < svgW / svgH;
      // Attach panzoom with touch/mouse event tracking. We have to go through them since
      // the library needs to prevent default and stop propagation.
      // TODO: Track mouse move events in the same way as the touch move with delta tracking.
      // Result of not doing this is click/draggging around then ending in the exact same spots counts as click.
      const pzInstance = panzoom(map, {
        maxZoom,
        minZoom,
        onTouch: function (e) {
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }
          touchStart.current = e.touches[0];
          return true;
        },
        beforeMouseDown: (e) => {
          mouseDownEvent.current = e;
          return false;
        },
        bounds: true,
        boundsPadding: 0.4,
        disableKeyboardInteraction: true,
      });

      pzInstance.moveBy(
        screenW / 2 -
          svgW / 2 -
          (sidebarOpen && screen.width > 500 && floorplan?.name != "Atlanta 2F"
            ? 200
            : 0),
        screenH / 2 - svgH / 2 - (sidebarOpen && screen.width <= 500 ? 550 : 0),
        false
      );
      pzInstance.zoomAbs(
        screenW / 2,
        screenH / 2,
        Math.min(screenH / svgH, screenW / svgW)
      );

      setPanzoomInstance(pzInstance);
    }
  }, [viewport]);

  useEffect(() => {
    if (
      selectedID &&
      selectedID.zoom &&
      !selectedID.isRoom &&
      panzoomInstance &&
      viewport
    ) {
      const seat = seatsData.find(
        (s) => s.id == selectedID.id && s.resourceType == RESOURCE_TYPES.DESK
      );
      zoomOn(
        document.getElementById("map")!,
        document.getElementById("svg-canvas-wrapper")!,
        document.querySelector(
          `[seatid=\"${selectedID.id}\"].seat-touch-target`
        ) as SVGElement,
        panzoomInstance,
        centerXOffset,
        centerYOffset,
        true,
        seat?.svgScale != undefined ? (1 * 0.06) / seat.svgScale : 1
      );
    }
  }, [selectedID, panzoomInstance]);

  const onTouchCancel = (e: React.TouchEvent<HTMLElement>) => {
    touchMoveDist.current = 0.0;
    touchMove.current = undefined;
  };
  const onTouchMove = (e: React.TouchEvent<HTMLElement>) => {
    const t2 = touchMove.current || touchStart.current;
    const t1 = e.touches[0];
    if (t2) {
      const yDist = Math.abs(t1.clientY - t2.clientY);
      const xDist = Math.abs(t1.clientX - t2.clientX);
      const delta = Math.sqrt(yDist * yDist + xDist * xDist);
      touchMoveDist.current = touchMoveDist.current + delta;
    }
    touchMove.current = t1;
  };
  const onTouchEnd = (e: React.TouchEvent<HTMLElement>) => {
    if (touchMoveDist.current <= 10 && (e.target as HTMLElement).parentNode) {
      const seatTouchTarget = (e.target as HTMLElement).closest(
        ".seat-touch-target"
      );
      const roomTouchTarget = (e.target as HTMLElement).closest(
        ".room-touch-target"
      );
      if (seatTouchTarget) {
        const seatid = seatTouchTarget.getAttribute("seatid");
        if (seatid != null) {
          onSelectSeatID(parseInt(seatid));
          setTimeout(
            () => document.getElementById("seat-info-panel")?.focus(),
            1
          );
        }
      } else if (roomTouchTarget) {
        const roomid = roomTouchTarget.getAttribute("roomid");
        if (roomid != null) {
          onSelectRoomID(parseInt(roomid));
        }
      }
    }
    touchMoveDist.current = 0.0;
    touchMove.current = undefined;
  };
  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    const e2 = mouseDownEvent.current;
    if (!e2) return; // There are race conditions that can lead to this when clicking during mounting, we can ignore those.
    if (
      Math.abs(e.clientX - e2.clientX) < 5 &&
      Math.abs(e.clientY - e2.clientY) < 5
    ) {
      const seatTouchTarget = (e.target as HTMLElement).closest(
        ".seat-touch-target"
      );
      const roomTouchTarget = (e.target as HTMLElement).closest(
        ".room-touch-target"
      );
      if (seatTouchTarget) {
        const seatid = seatTouchTarget.getAttribute("seatid");
        if (seatid != null) {
          onSelectSeatID(parseInt(seatid));
          setTimeout(
            () => document.getElementById("seat-info-panel")?.focus(),
            1
          );
        }
      } else if (roomTouchTarget) {
        const roomid = roomTouchTarget.getAttribute("roomid");
        if (roomid != null) {
          onSelectRoomID(parseInt(roomid));
          setTimeout(
            () => document.getElementById("seat-info-panel")?.focus(),
            1
          );
        }
      }
    }
  };

  return (
    <div
      id="svg-canvas-wrapper"
      role={role}
      css={{
        width: "100%",
        height: "100%",
        cursor: ["move", "grab"],
        "&:active": {
          cursor: "grabbing",
        },
      }}
      onKeyDown={(e) => {
        if (e.key == "Tab") {
          const center = (seatElement: SVGElement) =>
            centerOn(
              document.getElementById("map"),
              document.getElementById("svg-canvas-wrapper")!,
              seatElement,
              panzoomInstance,
              centerXOffset,
              centerYOffset
            );
          if (e.shiftKey) {
            const prev = getPreviousSibling(
              document.activeElement,
              '[tabindex="0"]'
            );
            if (prev && prev instanceof SVGElement) {
              center(prev);
              prev.focus();
              e.preventDefault();
            }
          } else {
            const next = getNextSibling(
              document.activeElement,
              '[tabindex="0"]'
            );
            if (next && next instanceof SVGElement) {
              center(next);
              next.focus();
              e.preventDefault();
            }
          }
        }
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <div
        id="svg-canvas"
        onClick={onClick}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
        css={{ width: "100%", height: "100%" }}
        onTouchCancel={onTouchCancel}
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox={`0 0 ${viewport?.width ?? 0} ${viewport?.height ?? 0}`}
          id="map"
          height={viewport?.height}
          width={viewport?.width}
          overflow="visible"
          preserveAspectRatio="xMinYMin slice"
        >
          <defs>
            <pattern
              id="busy-pattern"
              width="200"
              height="200"
              viewBox="0 0 40 40"
              patternUnits="userSpaceOnUse"
              patternTransform="rotate(45)"
            >
              <rect width="100%" height="100%" fill="rgba(255, 116, 115,1)" />
              <path d="m10 0v40h20v-40z" fill="rgba(194, 79, 79,1)" />
            </pattern>
            <SeatWithSymbol id={RESOURCE_TYPES.DESK} />
            <SeatWithSymbol id={RESOURCE_TYPES.TRAINING_DESK} />
            <TCHoodWithSymbol id={RESOURCE_TYPES.TC_HOOD} />
            <LabBenchWithSymbol id={RESOURCE_TYPES.LAB_BENCH} />
            <MothersRoomWithSymbol id={RESOURCE_TYPES.MOTHERS_ROOM} />
            <EquipmentWithSymbol id={RESOURCE_TYPES.EQUIPMENT} />
            {useSymbols && ccSymbols.map((v) => v.symbol)}
            <g id={RESOURCE_TYPES.PARKING}>
              <rect
                height={100}
                width={50}
                x={-25}
                y={-50}
                css={{
                  strokeWidth: "4px",
                  fillOpacity: 0.6,
                  strokeOpacity: 0.7,
                  strokeLinejoin: "round",
                }}
              ></rect>
            </g>
            <g id={RESOURCE_TYPES.LOCKER}>
              <rect
                height={500}
                width={500}
                x={-250}
                y={-250}
                css={{
                  fillOpacity: 0.6,
                  strokeLinejoin: "round",
                }}
              ></rect>
            </g>
          </defs>
          {floorplan && viewport && (
            <>
              <image
                cx="0"
                cy="0"
                width={viewport.width}
                height={viewport.height}
                xlinkHref={floorplan.img ?? undefined}
              />
              <g role="group" aria-label={t("all-desks")}>
                {seatsData.map((seat) => (
                  <Seat
                    key={seat.id}
                    userInfo={userInfo}
                    seatID={seat.id}
                    highlighted={
                      !selectedID?.isRoom && seat.id === selectedID?.id
                    }
                    events={seat.events}
                    date={date}
                    timeRange={timeRange}
                    seatInfo={seat}
                    onSelectSeatID={onSelectSeatID}
                    useSymbols={useSymbols}
                  />
                ))}
              </g>
              <g role="group" aria-label={t("all-rooms")}>
                {roomsData.map((room) => (
                  <Room
                    key={room.id}
                    date={date}
                    timeRange={timeRange}
                    roomInfo={room}
                    roomEvents={allRoomEvents[room.calendarID] ?? []}
                    highlighted={
                      selectedID?.isRoom === true &&
                      room.id == selectedID?.roomID
                    }
                    onSelectRoomID={onSelectRoomID}
                  />
                ))}
              </g>
            </>
          )}
        </svg>
      </div>
    </div>
  );
}
