import React from "react";
import { Autocomplete } from "@mui/material";
import { TextField, Button } from "@mui/material";
import { useAPIQuery, useAPIRequest } from "./hooks";
import { SuperAdmin } from "zynq-shared";
import { useTranslation } from "react-i18next";

export default function EditSeats({}) {
  const [selectedDomainName, setSelectedDomainName] = React.useState<string>();
  const [floorplanName, setFloorplanName] = React.useState<string>("");
  const [csvText, setCSVText] = React.useState("");
  const [floorplanInfo, setFloorplanInfo] = React.useState<{
    seats: { scale: number; x: number; y: number; id: number }[];
    floorplanImg: string;
  }>();
  const [viewport, setViewport] = React.useState<{
    width: number;
    height: number;
  }>();
  const { t } = useTranslation();
  const api = useAPIRequest();

  const { data: serverInfo } = useAPIQuery<typeof SuperAdmin.AllFloorplans>(
    "/apis/superadmin/all-floorplans",
    {}
  );

  const selectedDomain = serverInfo?.domains.find(
    (d) => d.name == selectedDomainName
  );

  return (
    <div>
      <div
        css={{
          position: "fixed",
          zIndex: 2000,
          pointerEvents: "none",
          backgroundRepeat: "repeat-x",
          transition: "background-position-y 1s",
          width: "100%",
          height: "calc(100% - 85px)",
        }}
      />
      <div
        css={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            width: "20rem",
            margin: "1rem",
            "& > * + *": {
              marginTop: "1rem",
            },
          }}
        >
          <Autocomplete
            value={selectedDomainName ?? null}
            onChange={(_e, v) => setSelectedDomainName(v ?? undefined)}
            renderInput={(props) => (
              <TextField {...props} label={t("domain")} />
            )}
            options={serverInfo?.domains.map((d) => d.name) ?? []}
            loading={serverInfo == undefined}
          />
          <Autocomplete
            value={floorplanName ?? null}
            onChange={(_e, v) => v && setFloorplanName(v)}
            renderInput={(props) => (
              <TextField {...props} label={t("floorplan-name")} />
            )}
            options={(selectedDomain?.floorplans ?? []).map((el) => el.name)}
            loading={serverInfo == undefined}
          />
          <Button
            variant="contained"
            disabled={(selectedDomainName ?? "").length === 0}
            onClick={() => {
              api
                .getNode(SuperAdmin.GetSeats, {
                  floorplanName: floorplanName,
                  domainName: selectedDomainName!,
                })
                .promise.then((res) => {
                  if (res.status == "failed") {
                    return alert("Error: " + res.reason);
                  }
                  setCSVText(res.seatsCSV);
                  setFloorplanInfo({
                    floorplanImg: res.floorplanImg,
                    seats: res.seatsInfo,
                  });
                  const img = new Image();
                  img.onload = function () {
                    setViewport({
                      height: (this as HTMLImageElement).naturalHeight * 0.24,
                      width: (this as HTMLImageElement).naturalWidth * 0.24,
                    });
                  };
                  img.src = res.floorplanImg;
                });
            }}
          >
            {t("load-seats")}
          </Button>
          <Button
            variant="contained"
            disabled={csvText.length === 0 || selectedDomainName === undefined}
            onClick={() => {
              api
                .postNode(SuperAdmin.SaveSeats, {
                  domainName: selectedDomainName!,
                  floorplanName,
                  seatsCSV: csvText,
                })
                .promise.then((res) => {
                  if (res.status == "failed")
                    return alert("Error: " + res.reason);
                  else return alert("success");
                });
            }}
          >
            {t("save-seats")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setSelectedDomainName(undefined);
              setCSVText("");
              setFloorplanName("");
              setViewport(undefined);
              setFloorplanInfo(undefined);
            }}
          >
            {t("clear")}
          </Button>
        </div>
        <textarea
          css={{ margin: "1rem", width: "30rem" }}
          value={csvText}
          onChange={(e) => setCSVText(e.currentTarget.value)}
        />
      </div>
      <div
        css={{
          width: "100%",
          height: "100%",
          maxHeight: "50vh",
          overflow: "scroll",
          borderTop: "2px solid rgb(211, 211, 211)",
          borderBottom: "2px solid rgb(211, 211, 211)",
        }}
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox={`0 0 ${viewport?.width || 0} ${viewport?.height || 0}`}
          id="map"
          width="100%"
          overflow="visible"
          preserveAspectRatio="xMinYMin slice"
        >
          <image
            cx="0"
            cy="0"
            id="map-image"
            width={viewport?.width}
            height={viewport?.height}
            xlinkHref={floorplanInfo?.floorplanImg}
          />
          {(floorplanInfo?.seats ?? []).map((seat) => (
            <g
              key={String(seat.id)}
              transform={`translate(${seat.x} ${seat.y})`}
              css={{ cursor: "pointer" }}
            >
              <title>{seat.id}</title>
              <g transform={`scale(${1.5})`}>
                <circle fill={"blue"} r="15"></circle>
                <text
                  fill="white"
                  textAnchor="middle"
                  dominantBaseline="central"
                >
                  {seat.id}
                </text>
              </g>
            </g>
          ))}
        </svg>
      </div>
    </div>
  );
}
