import type { CSSObject } from "@emotion/react";
import { useTheme } from "@mui/material/styles";
import type { SeatInfo, SeatEvent, UserInfo } from "src/types";
import { SeatEventKind } from "src/types";
import type { CalendarDate } from "zynq-shared";
import { TimeInterval } from "zynq-shared";
import { RESOURCE_TYPES, ZYNQ_DEMO_DOMAINS } from "./constants";
import { SVGAvatar } from "./avatar";
import type { ReactNode } from "react";

function SeatIconWrapper(props: {
  state: SeatEventKind;
  avatar?: UserInfo["avatar"];
  seatInfo: SeatInfo;
  children?: ReactNode;
}) {
  if (
    props.seatInfo.resourceType != RESOURCE_TYPES.MOTHERS_ROOM &&
    (props.state === SeatEventKind.OCCUPIED ||
      props.state === SeatEventKind.CHECKIN) &&
    props.avatar
  ) {
    return (
      <g
        transform={`translate(${props.seatInfo.svgX} ${props.seatInfo.svgY}) rotate(${props.seatInfo.svgAngle})`}
      >
        <g transform={`scale(${Math.min(props.seatInfo.svgScale, 0.08)})`}>
          <g transform={`translate(0, 30)`}>
            <g transform={`rotate(${-props.seatInfo.svgAngle})`}>
              {props.children}
            </g>
          </g>
        </g>
      </g>
    );
  } else {
    return null;
  }
}

export default function Seat(props: {
  seatID: number;
  seatInfo: SeatInfo;
  userInfo?: UserInfo;
  events: SeatEvent[];
  date: CalendarDate;
  timeRange: TimeInterval;
  highlighted: boolean;
  useSymbols?: boolean;
  onSelectSeatID: (id: number) => void;
}) {
  const userInfo = props.userInfo;
  const currentEvents = props.events.filter((e) =>
    TimeInterval.fromISODatesAndTimes({
      ...e,
      relativeTo: props.date,
    }).overlaps(props.timeRange)
  );

  const state =
    currentEvents.find((e) => e.kind == SeatEventKind.OCCUPIED)?.kind ??
    currentEvents.find((e) => e.kind == SeatEventKind.CHECKIN)?.kind ??
    currentEvents[0]?.kind;

  const yours =
    userInfo &&
    currentEvents.some(
      (e) => e.avatar && e.avatar.email == userInfo.avatar.email
    );
  let style: CSSObject = {};
  if (!yours && state === SeatEventKind.OCCUPIED) {
    style = {
      fill: "url(#busy-pattern)",
      stroke: "#1e0e00",
    };
  } else if (
    !props.seatInfo.enabled ||
    (!yours &&
      (state === SeatEventKind.OCCUPIED ||
        state === SeatEventKind.RESERVED ||
        state === SeatEventKind.SHUTDOWN))
  ) {
    style = {
      fill: "#e3e2e2",
      stroke: "#b9b4b1",
    };
  } else if (state === SeatEventKind.OCCUPIED && yours) {
    style = {
      fill: "#67b3f5",
      stroke: "#1e0e00",
    };
  } else if (state === SeatEventKind.CHECKIN) {
    style = {
      fill: "#67b3f5",
      stroke: "#1e0e00",
    };
  } else if (state == null || (state == SeatEventKind.RESERVED && yours)) {
    style = {
      fill: "#3DD8BB",
      stroke: "#1e0e00",
    };
  } else {
    console.error("Encountered unknown seat state");
  }

  let symbolSuffix = "";
  if (props.useSymbols) {
    if (
      props.seatInfo.seatType == "Standing Desk" ||
      props.seatInfo.seatType == "Hot Desk - Standing" ||
      (ZYNQ_DEMO_DOMAINS.includes(props.userInfo?.domain.name ?? "") &&
        props.seatInfo.seatType.toLowerCase().includes("stand"))
    ) {
      symbolSuffix = "-standing";
    } else if (
      ZYNQ_DEMO_DOMAINS.includes(props.userInfo?.domain.name ?? "") &&
      props.seatInfo.seatType.toLowerCase().includes("2 monitors")
    ) {
      symbolSuffix = "-dualmonitor";
      // } else if (props.seatInfo.seatType == "Dual Monitor Sit/Stand Desk") {
      //   symbolSuffix = "-standing-dualmonitor";
      // } else if (props.seatInfo.seatType == "Standard Desk") {
      //   symbolSuffix = "-basic";
    } else if (props.seatInfo.seatType == "Hotel Desk - Hybrid") {
      symbolSuffix = "-dualmonitor";
    } else if (
      props.userInfo?.domain.name == "roundhouse.org.uk" &&
      props.seatInfo.seatType == "Chromebox"
    ) {
      symbolSuffix = "-dualmonitor";
    }
  }

  const avatar = currentEvents.find(
    (e) => !userInfo || e.avatar?.isBuddy
  )?.avatar;
  const seatIconAvatar = yours ? userInfo.avatar : avatar;

  const angle =
    props.seatInfo.resourceType == "EQUIPMENT"
      ? props.seatInfo.svgAngle + 90
      : props.seatInfo.svgAngle;

  return (
    <g
      key={props.seatID}
      role="button"
      tabIndex={0}
      focusable="true"
      aria-label={props.seatInfo.name}
      className="seat-touch-target" // Needs to be here for click to work
      css={[
        style,
        {
          cursor: "pointer",
          outline: "none",
          stroke: props.highlighted ? "#1e0e00" : undefined,
          strokeWidth:
            props.seatInfo.resourceType == "EQUIPMENT"
              ? props.highlighted
                ? "1"
                : "0.4"
              : props.highlighted
              ? "35"
              : "15",
          "&:focus": { stroke: "greenyellow" },
        },
      ]}
      onKeyDown={(e) => {
        if (e.key == " " || e.key == "Spacebar" || e.key == "Enter") {
          props.onSelectSeatID(props.seatID);
          document.getElementById("seat-info-panel")?.focus();
        }
      }}
      {...{ seatid: props.seatInfo.id }} // Needs to be here for click to work
    >
      <g
        transform={`translate(${props.seatInfo.svgX} ${props.seatInfo.svgY}) rotate(${angle})`}
      >
        <use
          href={`#${props.seatInfo.resourceType}${symbolSuffix}`}
          transform={`scale(${props.seatInfo.svgScale} ${props.seatInfo.svgScale})`}
        />
      </g>
      <SeatIconWrapper
        seatInfo={props.seatInfo}
        state={state}
        avatar={seatIconAvatar}
      >
        {seatIconAvatar && <SVGAvatar avatar={seatIconAvatar} size={275} />}
      </SeatIconWrapper>
    </g>
  );
}
