import React from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Block as BlockIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { CalendarPicker, PickersDay } from "@mui/lab";
import type { DateTime } from "luxon";
import { CalendarDate } from "zynq-shared";
import { isWeekend, weeksSinceBaseDate } from "src/luxonUtil";

export type DaysEnabled = boolean[]; // Should be a multiple of 7

declare const ScheduleType: unique symbol;
export type GlobalSchedule = DaysEnabled[] & { [ScheduleType]: "Global" };
export type CurrentWeekFirstSchedule = DaysEnabled[] & {
  [ScheduleType]: "CurrentWeekFirst";
};

export function chunk<T>(arr: T[], len: number): T[][] {
  const chunks = [];
  let i = 0;
  while (i < arr.length) {
    chunks.push(arr.slice(i, (i += len)));
  }
  return chunks;
}

function isBookable(
  today: CalendarDate,
  date: CalendarDate,
  globalSchedule: GlobalSchedule | undefined
) {
  if (globalSchedule == undefined) return true;
  if (date < today) return true;

  const weeks = weeksSinceBaseDate(date);
  const scheduleIndex = weeks % globalSchedule.length;
  return globalSchedule[scheduleIndex][date.weekday % 7];
}

export function convertFromCurrentWeekFirstToGlobalSchedule(
  days: CurrentWeekFirstSchedule
) {
  const weeks = weeksSinceBaseDate(CalendarDate.today());
  const weeksInSchedule = days.length;
  const currentScheduleIndex = weeksInSchedule - (weeks % weeksInSchedule);
  // Rotate the schedule to put the 0 at the currentScheduleIndex
  let sunday = days[0][0];
  const newDays = [...days]
    .reverse()
    .map((item) => {
      const prevSunday = sunday;
      sunday = item[0];
      return [prevSunday, ...item.slice(1)];
    })
    .reverse();

  const after = newDays
    .slice(currentScheduleIndex, newDays.length)
    .concat(newDays.slice(0, currentScheduleIndex)) as GlobalSchedule;
  return after;
}

export function convertFromGlobalScheduleToCurrentWeekFirst(
  schedule: GlobalSchedule
) {
  const weeks = weeksSinceBaseDate(CalendarDate.today());
  const weeksInSchedule = schedule.length;
  const currentScheduleIndex = weeks % weeksInSchedule;

  const rotatedSchedule = schedule
    .slice(currentScheduleIndex, schedule.length)
    .concat(schedule.slice(0, currentScheduleIndex));

  let sunday = rotatedSchedule[rotatedSchedule.length - 1][0];

  const after = rotatedSchedule.map((item) => {
    const prevSunday = sunday;
    sunday = item[0];
    return [prevSunday, ...item.slice(1)];
  }) as CurrentWeekFirstSchedule;
  return after;
}

export function globalFlatDaysToCurrentWeekFirstSchedule(days: boolean[]) {
  return convertFromGlobalScheduleToCurrentWeekFirst(
    chunk(days, 7) as GlobalSchedule
  );
}

export function currentWeekFirstScheduleToGlobalFlatDays(
  schedule: CurrentWeekFirstSchedule
) {
  return convertFromCurrentWeekFirstToGlobalSchedule(schedule).flat();
}

function DayIcon({
  label,
  isChecked,
  small: smallParam,
}: {
  label: string;
  isChecked: boolean;
  small: boolean;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const tinyMobile = useMediaQuery(theme.breakpoints.down(350));
  const small = smallParam || tinyMobile;
  return (
    <div
      css={{
        width: small ? "30px" : "40px",
        height: small ? "30px" : "40px",
        display: "flex",
        overflow: "hidden",
        position: "relative",
        fontSize: "0.8rem",
        alignItems: "center",
        flexShrink: 0,
        lineHeight: "1px",
        userSelect: "none",
        borderRadius: "50%",
        justifyContent: "center",
        backgroundColor: isChecked ? "#353535" : "lightgrey",
        color: isChecked ? "white" : "grey",
      }}
    >
      {label != "All" && !isChecked && (
        <BlockIcon
          css={{
            opacity: "0.3",
            color: "#d90000",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
          }}
        />
      )}
      {label == "All" ? t("all") : label.substr(0, small ? 2 : 3)}
    </div>
  );
}

const DAYS = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as const;

type Props = {
  weekendsBookable: boolean;
  daysEnabled: DaysEnabled;
  setDaysEnabled: (days: DaysEnabled) => void;
  disabled: boolean;
};

export default function WeekdayPicker({
  weekendsBookable,
  daysEnabled,
  setDaysEnabled,
  disabled,
}: Props) {
  const { t } = useTranslation();
  const weekendOffset = weekendsBookable ? 0 : 1;
  return (
    <div
      css={{
        display: "flex",
        width: "100%",
        justifyContent: "space-around",
        ".MuiCheckbox-root": {
          padding: "5px",
          filter: "drop-shadow(0 3px 2px rgb(0 0 0 / 0.5))",
          position: "relative",
          ":hover": {
            top: "-1px",
            filter: "drop-shadow(0 5px 3px rgb(0 0 0 / 0.6))",
          },
          ":active": {
            top: "1px",
            filter: "drop-shadow(0 1px 1px rgb(0 0 0 / 0.5))",
          },
        },
      }}
      aria-label="weekdays"
    >
      <Checkbox
        size={"small"}
        inputProps={{ "aria-label": "all days", title: "All days" }}
        icon={
          <DayIcon label={"All"} isChecked={false} small={weekendsBookable} />
        }
        checkedIcon={
          <DayIcon label={"All"} isChecked={true} small={weekendsBookable} />
        }
        onChange={(e) => {
          setDaysEnabled(
            e.target.checked
              ? weekendsBookable
                ? [true, true, true, true, true, true, true]
                : [false, true, true, true, true, true, false]
              : [false, false, false, false, false, false, false]
          );
        }}
        checked={
          weekendsBookable
            ? daysEnabled.every((d) => d)
            : daysEnabled.slice(1, -1).every((d) => d)
        }
        disabled={disabled}
      />
      {DAYS.filter((dayName) => weekendsBookable || dayName[0] != "s").map(
        (dayName, index) => (
          <Checkbox
            key={dayName}
            size={"small"}
            inputProps={{ "aria-label": dayName, title: dayName }}
            icon={
              <DayIcon
                label={t(dayName) as string}
                isChecked={false}
                small={weekendsBookable}
              />
            }
            checkedIcon={
              <DayIcon
                label={t(dayName)}
                isChecked={true}
                small={weekendsBookable}
              />
            }
            onChange={(e) => {
              setDaysEnabled(
                daysEnabled.map((d, i) =>
                  i == index + weekendOffset ? e.target.checked : d
                ) as DaysEnabled
              );
            }}
            checked={daysEnabled[index + weekendOffset]}
            disabled={disabled}
          />
        )
      )}
    </div>
  );
}

function ScheduleWeek(props: {
  days: DaysEnabled[];
  week: DaysEnabled;
  index: number;
  weekendsBookable: boolean;
  loading: boolean;
  setDaysEnabled: (s: DaysEnabled[]) => void;
}) {
  const { t } = useTranslation();
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...(props.days.length > 1
          ? {
              border:
                props.index == 0 ? "2px solid black" : "1px solid lightgrey",
              borderRadius: "10px",
              padding: "1rem",
              margin: "0.5rem",
            }
          : {}),
      }}
    >
      {props.days!.length > 1 && (
        <>
          <span
            css={{
              alignSelf: "start",
              fontWeight: "bold",
              marginTop: "-2rem",
              backgroundColor: "white",
              padding: "0.5rem",
            }}
          >
            {props.index == 0
              ? t("current-week")
              : props.index == 1
              ? t("next-week")
              : t("week-n", { number: props.index + 1 })}
          </span>
          {props.index != 0 && (
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <Button
                size="small"
                variant="contained"
                onClick={() =>
                  props.setDaysEnabled(
                    props.days
                      .slice(props.index, props.days.length)
                      .concat(props.days.slice(0, props.index))
                  )
                }
              >
                {t("make-current-week")}
              </Button>
              <IconButton
                css={{ marginLeft: "auto" }}
                onClick={() =>
                  props.setDaysEnabled(
                    props.days.filter((v, idx) => props.index != idx)
                  )
                }
                size="large"
              >
                <DeleteIcon
                  css={{ color: "#d90000" }}
                  titleAccess={t("remove-week-n", { number: props.index + 1 })}
                />
              </IconButton>
            </div>
          )}
        </>
      )}

      <WeekdayPicker
        weekendsBookable={props.weekendsBookable}
        daysEnabled={props.week}
        setDaysEnabled={(newDays) =>
          props.setDaysEnabled(
            props.days.map((v, idx) => (props.index == idx ? newDays : v))
          )
        }
        disabled={props.loading}
      />
    </div>
  );
}

export function MultiWeekPicker(props: {
  daysEnabled: CurrentWeekFirstSchedule;
  setDaysEnabled: (d: CurrentWeekFirstSchedule) => void;
  loading?: boolean;
  weekendsBookable?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "auto",
          maxHeight: "calc(80vh - 20rem)",
        }}
      >
        {props.daysEnabled.map((days, i) => (
          <ScheduleWeek
            key={i}
            index={i}
            days={props.daysEnabled}
            week={days}
            loading={!!props.loading}
            weekendsBookable={!!props.weekendsBookable}
            setDaysEnabled={(d) =>
              props.setDaysEnabled(d as CurrentWeekFirstSchedule)
            }
          />
        ))}
      </div>
      {props.daysEnabled.length < 4 && (
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() =>
            props.setDaysEnabled([
              ...props.daysEnabled,
              [
                ...(props.daysEnabled[props.daysEnabled.length] ?? [
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                  true,
                ]),
              ],
            ] as CurrentWeekFirstSchedule)
          }
          css={{ marginTop: "1rem", marginBottom: "0.5rem" }}
        >
          {t("add-week")}
        </Button>
      )}
    </>
  );
}

export function PreviewScheduleDialog(props: {
  open: boolean;
  setOpen: (b: boolean) => void;
  validFrom?: CalendarDate;
  validTo?: CalendarDate;
  weekendsBookable: boolean;
  schedule: GlobalSchedule;
}) {
  const { t } = useTranslation();
  const today = CalendarDate.today();

  return (
    <Dialog open={props.open} onClose={() => props.setOpen(false)}>
      <DialogTitle>{t("preview")}</DialogTitle>
      <DialogContent css={{ paddingBottom: "1rem" }}>
        <CalendarPicker<DateTime>
          date={today.date}
          views={["day"]}
          onChange={() => undefined}
          renderDay={(dateDT, _selected, dayComponentProps) => {
            if (
              today == null ||
              dateDT == null ||
              dayComponentProps.outsideCurrentMonth
            ) {
              return <PickersDay {...dayComponentProps} />;
            }
            const date = CalendarDate.fromDateTime(dateDT);
            return (
              <div
                css={{
                  pointerEvents: "none",
                  display: "flex",
                  color: "black",
                  width: "36px",
                  height: "36px",
                  margin: "0 2px",
                  padding: "0",
                  fontSize: "0.75rem",
                  textDecoration: today.hasSame(date, "day")
                    ? "underline"
                    : undefined,
                  fontWeight: today.hasSame(date, "day") ? 600 : 500,
                  textAlign: "center",
                  opacity:
                    (today < date ||
                      (!props.weekendsBookable && isWeekend(today))) &&
                    !today.hasSame(today, "day")
                      ? "0.5"
                      : "1",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <PickersDay
                  {...dayComponentProps}
                  selected={false}
                  today={false}
                />
                {(!isBookable(today, date, props.schedule) ||
                  (props.validFrom && props.validFrom > date) ||
                  (props.validTo && props.validTo < date)) && (
                  <BlockIcon
                    css={{
                      opacity: "0.3",
                      color: "#d90000",
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                )}
              </div>
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)} variant="contained">
          {t("close-preview")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
