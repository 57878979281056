import { Button } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { SuperAdmin } from "zynq-shared";
import { useAPIRequest } from "./hooks";
import type { UserInfo } from "./types";

export default function UploadImage(props: { userInfo: UserInfo }) {
  const [img, setImg] = React.useState<File>();
  const [url, setURL] = React.useState<string>();
  const [localURL, setLocalURL] = React.useState<string>();
  const imgInput = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const api = useAPIRequest();

  React.useEffect(() => {
    if (img !== undefined) {
      const imgBlob = new Blob([img], {
        type: img.type,
      });
      const localURLCopy = URL.createObjectURL(imgBlob);
      setLocalURL(localURLCopy);
      return () => {
        setLocalURL(undefined);
        URL.revokeObjectURL(localURLCopy);
      };
    }
    return;
  }, [img]);

  if (!props.userInfo.isSuperAdmin && !props.userInfo.isStaff) return <></>;

  return (
    <div
      css={{
        margin: "2rem",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div css={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <input
          ref={imgInput}
          type="file"
          accept=".png,image/png,.jpg,.jpeg,image/jpg,image/jpeg"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              setImg(file);
            } else {
              console.log("GUIS thats not an image");
            }
          }}
        />
        <Button
          variant="contained"
          onClick={() => {
            if (img === undefined) return alert("No bg image");
            setURL(undefined);
            const a = new FileReader();
            a.readAsArrayBuffer(img);
            a.onloadend = function () {
              crypto.subtle
                .digest("SHA-256", a.result as ArrayBuffer)
                .then((hash) => {
                  const hashArray = Array.from(new Uint8Array(hash));
                  const hashHex = hashArray
                    .map((b) => b.toString(16).padStart(2, "0"))
                    .join("");
                  console.log(hashHex);
                  const formData = new FormData();
                  formData.append("imgSHA2ContentHash", hashHex);
                  formData.append("type", img.type);
                  formData.append("img", new Blob([img]));
                  api
                    .postNode(SuperAdmin.UploadImage, formData)
                    .promise.then((res) => {
                      if (res.status == "failed")
                        return alert("Error: " + res.reason);
                      setURL(res.url);
                      imgInput.current && (imgInput.current.value = "");
                      setImg(undefined);
                    });
                });
            };
          }}
        >
          {t("upload")}
        </Button>
      </div>
      {url && (
        <span>
          Uploaded image URL:
          <br />
          <b>{url}</b>
        </span>
      )}
      {localURL && (
        <>
          {t("preview")}:
          <img
            src={localURL}
            alt="uploaded preview"
            css={{ maxWidth: "80%" }}
          />
        </>
      )}
    </div>
  );
}
