import React from "react";

import { useAPIRequest, useError } from "./hooks";
import { SuperAdmin } from "zynq-shared";
import { Refresh } from "@mui/icons-material";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function TestEmail() {
  const [kinds, setKinds] = React.useState<string[]>([]);
  const [language, setLanguage] = React.useState("en");
  const [content, setContent] = React.useState<string>();
  const api = useAPIRequest();
  const history = useHistory();
  const showError = useError();
  const { token } = useParams<{ token?: string }>();
  const { i18n } = useTranslation();

  React.useEffect(() => {
    api.getNode(SuperAdmin.EmailKinds, {}).promise.then((res) => {
      if (res.status == "failed") return showError(res.reason);
      setKinds(res.kinds);
    });
  }, []);

  function fetchEmail() {
    setContent(undefined);
    api
      .postNode(SuperAdmin.TestEmail, {
        kind: token ?? "",
        languageCode: language,
      })
      .promise.then((res) => {
        if (res.status == "failed") return setContent(`Error: ${res.reason}`);
        setContent(res.contentHTML);
      });
  }

  React.useEffect(() => {
    fetchEmail();
  }, [token, language]);

  return (
    <div
      css={{
        p: {
          display: "block",
          marginBlockStart: "1em",
          marginBlockEnd: "1em",
          marginInlineStart: "0px",
          marginInlineEnd: "0px",
        },
      }}
    >
      <div
        css={{
          margin: "1rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FormControl>
          <InputLabel id="language-select-label">Language</InputLabel>
          <Select
            labelId="language-select-label"
            label="Language"
            value={language}
            css={{ minWidth: "10rem" }}
            onChange={(e) => setLanguage(e.target.value)}
          >
            {["en", "fr"].map((language) => (
              <MenuItem value={language} key={language}>
                {language}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div
        css={{
          margin: "1rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FormControl>
          <InputLabel id="email-select-label">Email Kind</InputLabel>
          <Select
            labelId="email-select-label"
            label="Email Kind"
            value={token}
            css={{ minWidth: "10rem" }}
            onChange={(e, v) =>
              history.push(
                token ? e.target.value : "test-email/" + e.target.value
              )
            }
          >
            {kinds.map((name) => (
              <MenuItem value={name} key={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton onClick={fetchEmail} size="large">
          <Refresh />
        </IconButton>
      </div>
      {content ? (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
