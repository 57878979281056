import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Business as BusinessIcon } from "@mui/icons-material";
import type { RoomEvent, UserInfo } from "./types";
import { Trans, useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import type { Dictionary } from "zynq-shared";

export default function UpcomingEventDialog(props: {
  userInfo: UserInfo;
  timezone: string;
  eventsByRoomID: Dictionary<string, RoomEvent[]>;
}) {
  const { t } = useTranslation();

  const [dismissedIDs, setDismissedIDs] = React.useState<string[]>([]);
  const [now, setNow] = React.useState(DateTime.now().setZone(props.timezone));

  let foundEvent: RoomEvent | null = null;
  for (const key in props.eventsByRoomID) {
    const events = props.eventsByRoomID[key];
    if (events) {
      const imminentEvents = events
        .filter((e) => {
          return (
            e.attendees.findIndex(
              (e) => e.email == props.userInfo.avatar.email
            ) != -1
          );
        })
        .filter(
          (e) =>
            e.interval.start < now.plus({ minutes: 10 }) &&
            e.interval.start > now.minus({ minutes: 5 })
        )
        .filter((e) => dismissedIDs.findIndex((id) => e.id == id) == -1);
      if (imminentEvents.length > 0) {
        foundEvent = imminentEvents[0];
        break;
      }
    }
  }

  const event = foundEvent;

  let minutesToEvent = 0;
  if (event) {
    minutesToEvent =
      event.interval.start.diff(now, ["minutes", "seconds"]).minutes + 1;
  }

  React.useEffect(() => {
    const timer = setTimeout(
      () => setNow(DateTime.now().setZone(props.timezone)),
      1000
    );
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {event && (
        <Dialog open>
          <DialogTitle>
            {minutesToEvent >= 0 ? (
              <Trans i18nKey="you-have-a-meeting-in-x-minutes">
                You have a meeting in{" "}
                <b>{{ minutes: minutesToEvent }} minutes</b>
              </Trans>
            ) : (
              <Trans i18nKey="you-have-a-meeting-now">
                You have a meeting <b>now</b>
              </Trans>
            )}
          </DialogTitle>
          <DialogContent>
            <div
              css={{
                display: "flex",
                flexFlow: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <div css={{ textTransform: "uppercase" }}>
                  <BusinessIcon fontSize={"inherit"} /> {event.roomName}
                </div>
                <div>{event.subject}</div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDismissedIDs(dismissedIDs.concat([event.id]))}
              color="primary"
              variant="contained"
            >
              {t("dismiss")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
