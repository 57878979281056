import React from "react";
import Cookies from "js-cookie";
import QRCode from "qrcode.react";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { useParams } from "react-router-dom";
import { CircularProgress, useMediaQuery } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOnOutlined";

import { useAPIRequest, useError } from "./hooks";
import { Trans, useTranslation } from "react-i18next";
import { CalendarDate } from "zynq-shared";

type QRInfo = {
  verificationURL: string;
  date: CalendarDate;
  location: string;
  domainName: string;
};

export default function ShowQRPage(props: { qrInfo?: QRInfo }) {
  const { t } = useTranslation();
  const api = useAPIRequest();
  const { token } = useParams<{ token?: string }>();
  const isNotMobile = useMediaQuery("@media (min-width:600px)");
  const shortScreen = useMediaQuery("@media (max-height:600px)");

  const [registrationDate, setRegistrationDate] = useState(props.qrInfo?.date);
  const [domainName, setDomainName] = useState(props.qrInfo?.domainName);
  const showError = useError();
  const location = props.qrInfo?.location;

  useEffect(() => {
    if (props.qrInfo?.verificationURL == null && token != null) {
      api.get(`/visitor/api/registration_info/${token}`).promise.then((res) => {
        if (res.status == "success") {
          setRegistrationDate(CalendarDate.fromISODate(res.date));
          setDomainName(res.domainName);
        } else {
          showError("Error: " + res.reason);
        }
      });
    }
  }, []);

  const checkCss = {
    fontSize: "50%",
    display: "inline-flex",
    padding: "10px",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "center",
    lineHeight: "100%",
    background: "#3dd8bb",
    borderRadius: "50%",
    color: "#fff",
    marginRight: "10px",
  };

  const verifyURL =
    props.qrInfo?.verificationURL ??
    `${window.location.origin}/visitor/verify/${token!}`;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2
        css={{
          order: 1,
          margin: 0,
          "@media (min-width: 600px)": {
            margin: "1rem",
          },
        }}
      >
        <div css={checkCss}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
        {t("youre-all-set")}
      </h2>
      {!registrationDate ? (
        <div
          css={{
            order: 3,
            padding: "1rem",
            textAlign: "center",
            "@media (min-width: 600px)": {
              padding: "1.5rem",
            },
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <p
          css={{
            order: 3,
            padding: "0 0.5em",
            maxWidth: "25rem",
            textAlign: "center",
            "@media (min-width: 600px)": {
              padding: "0 1rem",
            },
          }}
        >
          {domainName && domainName == "albertsons.com" ? (
            <Trans i18nKey="youll-need-code-when-entering-building-on-date-no-need-to-save-emailed-albertsons">
              You will need to scan this QR code upon entry to the building on{" "}
              <b>
                {{
                  date: registrationDate.toLocaleString({
                    month: "long",
                    day: "numeric",
                  }),
                }}
              </b>
              . A copy has also been emailed to your inbox so you can have it
              handy. Scanning this QR code upon entry of the building will lock
              in your workspace reservation for the day.
            </Trans>
          ) : (
            <Trans i18nKey="youll-need-code-when-entering-building-on-date-no-need-to-save-emailed">
              You'll need this code when entering the building on{" "}
              <b>
                {{
                  date: registrationDate.toLocaleString({
                    month: "long",
                    day: "numeric",
                  }),
                }}
              </b>
              . No need to save it, we've emailed it to you so you can have it
              handy.
            </Trans>
          )}
        </p>
      )}
      {location != null && (
        <p
          css={{
            order: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 0,
          }}
        >
          <LocationOnIcon css={{ marginRight: "0.5rem" }} />
          {location}
        </p>
      )}
      <QRCode
        data-qr={verifyURL}
        value={verifyURL}
        size={Math.min(isNotMobile ? 350 : 250, screen.width - 40)}
        css={{
          order: shortScreen ? 2 : 5,
          margin: "0.5rem",
          "@media (min-width: 600px)": {
            margin: "1rem",
          },
        }}
      />
    </div>
  );
}
