import React from "react";
import { Alert } from "@mui/material";
import { useAPIMutation, useAPIRequest, useError } from "./hooks";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Button,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { CropFree as CropFreeIcon, RestartAlt } from "@mui/icons-material";
import BookingInfoRow from "./bookingInfoRow";
import type { BookingInfo } from "./bookingInfoRow";
import MeetingInfoRow from "./meetingInfoRow";
import type { MeetingInfo } from "./meetingInfoRow";
import { RESOURCE_TYPES } from "./constants";

import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { Meetings, Rooms, CalendarDate } from "zynq-shared";
import type { Seating, TimeInterval } from "zynq-shared";

function useDebouncedCall(fn: TimerHandler, delay: number) {
  const [handler, setHandler] = React.useState<number | null>(null);
  return () => {
    setHandler(setTimeout(fn, delay));
    if (handler) {
      clearTimeout(handler);
    }
  };
}

export default function BookingsListDialog({
  today,
  isOpen,
  onNavigate,
  autoBookResult,
  qrEnabled,
  onShowQR,
  onClose,
  onClickBook,
  onEditDate,
  defaultResourceType,
  hasDesks,
  timeBasedBookingEnabled,
}: {
  timeBasedBookingEnabled: boolean;
  today: CalendarDate;
  isOpen: boolean;
  onNavigate: (
    floorplanID: number,
    date: CalendarDate,
    seatID: number,
    timeInterval?: TimeInterval
  ) => void;
  autoBookResult: {
    floorplanID?: number;
    bookedDays?: CalendarDate[];
    error?: string;
    failedDays?: CalendarDate[];
    warning?: string;
  };
  qrEnabled: boolean;
  onShowQR: (qr: string, date: CalendarDate) => void;
  onClose: () => void;
  onClickBook: () => void;
  onEditDate: (date: CalendarDate) => void;
  defaultResourceType: RESOURCE_TYPES;
  hasDesks: boolean;
}) {
  const { t } = useTranslation();
  const showError = useError();

  const resourceNoun =
    defaultResourceType === RESOURCE_TYPES.PARKING
      ? t("parking-spot")
      : t("desk");
  const isNotMobile = useMediaQuery("@media (min-width:420px)");

  const [bookingsData, setBookingsData] = React.useState<BookingInfo[]>([]);
  const [meetingsData, setMeetingsData] = React.useState<MeetingInfo[]>([]);
  const [loading, setLoading] = React.useState(false);

  const [qrData, setQRData] = React.useState<{
    qr: string;
    date: CalendarDate;
  } | null>(null);

  const api = useAPIRequest();

  const cellStyle = {
    padding: "0.5rem",
    textAlign: "center",
  } as const;

  const refreshMeetingsData = () => {
    return api.getNode(Meetings.MyMeetings, {}).promise.then(function (res) {
      if (res.status == "failed") return showError(res.reason);
      setMeetingsData(
        res.meetings.map((m) => ({
          start: DateTime.fromISO(m.start),
          end: DateTime.fromISO(m.end),
          eventID: m.eventID,
          eventICalUId: m.eventICalUId,
          subject: m.subject,
          roomName: m.roomName,
          roomID: m.roomID,
          eventLink: m.webLink,
        }))
      );
    });
  };

  const refreshBookingsData = () => {
    setLoading(true);
    return api
      .get<{
        bookings: BookingInfo[];
        dropins: BookingInfo[];
        verifyURL?: string;
      }>("/seating/api/list_seat_bookings", {
        today: today.toISODate(),
      })
      .promise.then(function (res) {
        setLoading(false);
        if (res.status == "failed") return showError(res.reason);
        const bookings = res.bookings.map((b: BookingInfo) => ({
          ...b,
          isDropin: false,
        }));
        const dropins = res.dropins.map((b: BookingInfo) => ({
          ...b,
          isDropin: true,
        }));
        const both = bookings
          .concat(dropins)
          .sort((a, b) => a.startDate.localeCompare(b.startDate));
        setBookingsData(both);
        setQRData(
          res.bookings
            ? res.verifyURL
              ? {
                  qr: res.verifyURL,
                  date: CalendarDate.fromISODate(
                    (res.bookings[0] ?? res.dropins[0]).startDate
                  ),
                }
              : null
            : null
        );
      });
  };

  const refresh = () => {
    refreshBookingsData();
    refreshMeetingsData();
  };

  const debouncedRefresh = useDebouncedCall(refresh, 1000);

  const { mutateAsync: releaseBooking } = useAPIMutation<
    typeof Seating.ReleaseBooking
  >("/apis/seating/release-booking", {
    onError: (e) => showError(t("error-with-msg", { msg: e.reason })),
    onSuccess: () => debouncedRefresh(),
  });
  const { mutateAsync: releaseDropin } = useAPIMutation<
    typeof Seating.ReleaseDropin
  >("/apis/seating/release-dropin", {
    onError: (e) => showError(t("error-with-msg", { msg: e.reason })),
  });

  React.useEffect(() => {
    if (isOpen) {
      refresh();
    }
  }, [isOpen]);
  React.useEffect(() => {
    refresh();
  }, [today]);

  let errorMessage = null;
  if (autoBookResult.error) {
    errorMessage = t("error-with-msg", { msg: autoBookResult.error });
  } else if (autoBookResult.failedDays?.length) {
    if (!hasDesks && defaultResourceType === RESOURCE_TYPES.DESK) {
      errorMessage = t("failed-to-book-dropins-on-days", {
        days: autoBookResult.failedDays
          .map((d) =>
            d.toLocaleString({
              month: "long",
              day: "numeric",
            })
          )
          .join(", "),
      });
    } else {
      errorMessage = t("failed-to-book-resource-on-days", {
        resource: resourceNoun,
        days: autoBookResult.failedDays
          .map((d) =>
            d.toLocaleString({
              month: "long",
              day: "numeric",
            })
          )
          .join(", "),
      });
    }
  }

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      scroll="paper"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        css={{
          textAlign: "center",
          ".MuiTypography-h6": {
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          },
        }}
      >
        {loading && bookingsData.length > 0 && (
          <CircularProgress
            style={{ marginRight: "1rem", color: "inherit" }}
            size={25}
          />
        )}
        {t("my-bookings")}
      </DialogTitle>
      {errorMessage && (
        <Alert
          severity="error"
          style={{
            margin: "1rem",
            whiteSpace: "pre-line",
          }}
        >
          {errorMessage}
        </Alert>
      )}
      {autoBookResult.warning && (
        <Alert
          severity="warning"
          style={{
            margin: "1rem",
            whiteSpace: "pre-line",
          }}
        >
          {autoBookResult.warning}
        </Alert>
      )}
      {bookingsData.length == 0 && loading ? (
        <DialogContent dividers={false}>
          <div style={{ padding: "5em", textAlign: "center" }}>
            <CircularProgress />
          </div>
        </DialogContent>
      ) : (
        <DialogContent
          dividers={false}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {qrEnabled ? (
            <Tooltip
              enterTouchDelay={0}
              placement="top"
              title={qrData == null ? t("no-entry-code-for-today") : false}
            >
              <span style={{ display: "flex", flexDirection: "column" }}>
                <Button
                  onClick={() => {
                    if (qrData) {
                      onShowQR(qrData.qr, qrData.date);
                    }
                  }}
                  style={{
                    marginBottom: "2rem",
                    alignSelf: "center",
                  }}
                  color="secondary"
                  disabled={qrData == null}
                  variant="contained"
                >
                  <CropFreeIcon style={{ margin: "0px 10px" }} />
                  {t("todays-entry-code")}
                </Button>
              </span>
            </Tooltip>
          ) : null}
          {bookingsData.length == 0 && (
            <div
              style={{
                display: "flex",
                padding: "2em 4em 4em 4em",
                textAlign: "center",
                flexDirection: "column",
              }}
            >
              <i>{t("you-have-no-bookings")}</i>
              {hasDesks && (
                <Button
                  onClick={function (param) {
                    return onClickBook();
                  }}
                  style={{
                    marginTop: "3em",
                  }}
                  color="primary"
                  variant="contained"
                >
                  {t("book-a-resource", { resource: resourceNoun })}
                </Button>
              )}
              <br />
            </div>
          )}
          <table
            className="bookings_table"
            style={{
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <tbody
              css={{
                "& tr:nth-of-type(even)": { background: "#f5f5f5" },
                "& tr:nth-of-type(odd)": { background: "white" },
              }}
            >
              {bookingsData.map((bookingInfo) => (
                <BookingInfoRow
                  timeBasedBookingEnabled={timeBasedBookingEnabled}
                  bookingInfo={bookingInfo}
                  onNavigate={onNavigate}
                  highlight={
                    (autoBookResult.bookedDays ?? []).includes(
                      CalendarDate.fromISODate(bookingInfo.startDate)
                    ) && autoBookResult.floorplanID == bookingInfo.floorplanID
                  }
                  isMobile={!isNotMobile}
                  onDelete={() => {
                    const onSuccess = () => {
                      debouncedRefresh();
                      onEditDate(
                        CalendarDate.fromISODate(bookingInfo.startDate)
                      );
                    };
                    if (bookingInfo.isDropin) {
                      return releaseDropin(
                        {
                          bookingID: bookingInfo.bookingID,
                          asAdmin: false,
                        },
                        { onSuccess }
                      );
                    } else {
                      return releaseBooking(
                        {
                          bookingID: bookingInfo.bookingID,
                          asAdmin: false,
                        },
                        { onSuccess }
                      );
                    }
                  }}
                  key={
                    bookingInfo.startDate +
                    bookingInfo.seatID +
                    bookingInfo.isDropin
                  }
                />
              ))}
            </tbody>
          </table>
          {meetingsData.length > 0 && (
            <>
              <br />
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "125%",
                }}
              >
                {t("my-meetings")}
              </div>
              <table
                className="meetings_table"
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                }}
              >
                <tbody
                  css={{
                    "& tr:nth-of-type(even)": { background: "#f5f5f5" },
                    "& tr:nth-of-type(odd)": { background: "white" },
                  }}
                >
                  {meetingsData.map((meetingInfo) => (
                    <MeetingInfoRow
                      isMobile={!isNotMobile}
                      meetingInfo={meetingInfo}
                      onDelete={() => {
                        const request = api.postNode(Rooms.EndEvent, {
                          roomID: meetingInfo.roomID,
                          eventID: meetingInfo.eventID,
                          currentStartTime: meetingInfo.start.toISO(),
                          currentEndTime: meetingInfo.end.toISO(),
                        });
                        request.promise.then((res) => {
                          if (res.status === "failed")
                            return showError(
                              t("error-with-msg", { msg: res.reason })
                            );
                          return debouncedRefresh();
                        });
                        return request.promise;
                      }}
                      key={meetingInfo.eventID}
                    />
                  ))}
                </tbody>
              </table>
            </>
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          {t("done")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
