import React from "react";
import { Alert } from "@mui/material";
import { Button, CircularProgress } from "@mui/material";
import { LocationOn as LocationOnIcon } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

import { useAPIRequest } from "./hooks";
import { VACCINATION_STATUS } from "./constants";
import Questionnaire, { initialState } from "./questionnaire";
import QuestionnaireFailure from "./questionnaireFailure";
import BasicHeader from "./basicHeader";
import type { ISODateString, UserInfo } from "./types";
import * as Endpoints from "zynq-shared";
import { DateTime } from "luxon";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

type VisitInfo = {
  questionnaireEnabled: boolean;
  floorplan: {
    name: string;
    id: number;
    timezone: string;
    buildingName?: string;
  };
  host: {
    name: string;
    email: string;
  };
  date: ISODateString;
};

const Style = {
  centered: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default function ScheduledVisitCheckin(props: {
  userInfo: UserInfo;
  visitToken: string | undefined;
}) {
  const { t } = useTranslation();
  const api = useAPIRequest();
  const history = useHistory();

  const [visitInfo, setVisitInfo] = React.useState<VisitInfo>();
  const [failed, setFailed] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const [questionnaireState, setQuestionnaireState] =
    React.useState(initialState);

  React.useEffect(() => {
    if (!props.visitToken) return () => undefined;
    const req = api.getNode(Endpoints.Visit.GetInfo, {
      visitToken: props.visitToken,
    });
    req.promise.then((v) => {
      if (v.status == "failed") return setError(v.reason);
      if (v.verifyToken != undefined) {
        history.push(`/visitor/showQR/${v.verifyToken}`);
      }
      if (!v.host) {
        setError(t("failed-to-parse-visit-information"));
        return;
      }
      setVisitInfo({
        ...v,
        host: { name: v.host.name, email: v.host.email },
      });
    });
    return req.cancel;
  }, []);

  if (error || !props.visitToken) {
    return (
      <div style={Style.centered}>
        <Alert
          severity="error"
          style={{
            margin: "1rem",
            whiteSpace: "pre-line",
          }}
        >
          {t("error-with-msg", { msg: error ?? t("invalid-visit-token") })}
        </Alert>
      </div>
    );
  }

  if (success) {
    return (
      <div style={Style.centered}>
        <Alert
          severity="success"
          style={{ margin: "1rem", alignItems: "center" }}
        >
          <p>{t("successfully-checked-into-your-booking")}</p>
          <p>{t("let-host-know-you-have-arrived")}</p>
          <p>{t("looking-forward-to-seeing-you-soon")}</p>
        </Alert>
      </div>
    );
  }

  const visitDate =
    visitInfo &&
    DateTime.fromISO(visitInfo.date, { zone: visitInfo.floorplan.timezone });

  if (
    visitDate &&
    visitInfo &&
    visitDate <
      DateTime.now().setZone(visitInfo.floorplan.timezone).startOf("day")
  ) {
    return (
      <div style={Style.centered}>
        <Alert
          severity="error"
          style={{
            margin: "1rem",
            whiteSpace: "pre-line",
          }}
        >
          {t("couldnt-check-in-date-of-booking-has-passed", {
            date: visitDate.toLocaleString(DateTime.DATE_FULL),
          })}
        </Alert>
      </div>
    );
  }

  const visitTokenNonNull: string = props.visitToken;

  if (visitInfo == undefined) {
    return (
      <div style={Style.centered}>
        <CircularProgress size="5rem" />
      </div>
    );
  }

  function completedQuestionnaire(passed: boolean) {
    api
      .post("/visitor/api/completed_questionnaire", {
        passed,
        floorplanID: visitInfo!.floorplan.id,
        extraData: questionnaireState.extraData,
        visitToken: visitTokenNonNull,
      })
      .promise.then(function (res) {
        if (res.status == "failed") return setError(res.reason);
      });
  }

  const floorplanQualifiedName = visitInfo.floorplan.buildingName
    ? `${visitInfo.floorplan.name} (${visitInfo.floorplan.buildingName})`
    : visitInfo.floorplan.name;

  return (
    <>
      <BasicHeader userInfo={props.userInfo} />
      <form
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          maxWidth: "25rem",
          padding: "1rem",
          margin: "0.5rem auto",
        }}
        onSubmit={function (e) {
          e.preventDefault();
          if (visitInfo.questionnaireEnabled) {
            completedQuestionnaire(questionnaireState.valid);
          }
          if (!visitInfo.questionnaireEnabled || questionnaireState.valid) {
            api
              .postNode(Endpoints.Visit.VisitCheckin, {
                visitToken: visitTokenNonNull,
              })
              .promise.then((res) => {
                if (res.status == "failed") return setError(res.reason);
                if (res.verifyToken) {
                  return history.push(`/visitor/showQR/${res.verifyToken}`);
                } else {
                  setSuccess(true);
                }
              });
          } else {
            return setFailed(true);
          }
        }}
      >
        <p css={{ textAlign: "center" }}>
          You have a visit scheduled for{" "}
          {DateTime.fromISO(visitInfo.date).toLocaleString(DateTime.DATE_HUGE)}
        </p>
        <p css={{ textAlign: "center" }}>
          Your host is {visitInfo.host.name} ({visitInfo.host.email})
        </p>
        <p
          css={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "4rem",
          }}
        >
          <LocationOnIcon css={{ marginRight: "0.5rem" }} />
          {floorplanQualifiedName}
        </p>
        {visitInfo.questionnaireEnabled && (
          <Questionnaire
            state={questionnaireState}
            setState={setQuestionnaireState}
            domainName={props.userInfo.domain.name}
            floorplanName={visitInfo.floorplan.name}
            userVaccinationStatus={VACCINATION_STATUS.UNKNOWN}
          />
        )}
        {error && (
          <Alert
            severity="error"
            style={{
              margin: "1rem",
              whiteSpace: "pre-line",
            }}
          >
            {t("error-with-msg", { msg: error })}
          </Alert>
        )}
        <div css={{ height: "2rem" }} />
        <Button
          style={{ marginBottom: "1rem" }}
          color="primary"
          disabled={visitInfo.questionnaireEnabled && !questionnaireState.ready}
          type="submit"
          variant="contained"
        >
          {visitInfo.questionnaireEnabled ? t("submit") : t("confirm")}
        </Button>
      </form>
      <QuestionnaireFailure
        isOpen={failed}
        setOpen={setFailed}
        domainName={props.userInfo.domain.name}
        floorplanName={visitInfo.floorplan.name}
      />
    </>
  );
}
