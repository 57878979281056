import React from "react";
import { css } from "@emotion/react";
import { MAX_ROOMS_BOOKAHEAD } from "zynq-shared";
import { Collapse, Menu, MenuItem, Tooltip } from "@mui/material";

import Avatar from "./avatar";
import {
  Button,
  Card,
  Drawer,
  IconButton,
  LinearProgress,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Tab,
  List,
  ListItem,
  Badge,
  Divider,
} from "@mui/material";
import { Alert, AvatarGroup } from "@mui/material";
import {
  AccessTime,
  AddCircleOutline as AddIcon,
  Delete,
  Home,
  LocationOn,
  MoreVert,
  RemoveCircle,
} from "@mui/icons-material";

import {
  TabContext,
  TabList,
  TabPanel,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";

import { useTheme } from "@mui/material/styles";
import {
  ChevronRight,
  ChevronLeft,
  KeyboardArrowUp,
  KeyboardArrowDown,
  CropFree as CropFreeIcon,
} from "@mui/icons-material";

import { resourceName, ZYNQ_DEMO_DOMAINS } from "./constants";
import { DateTime, Duration } from "luxon";
import type {
  FloorplanInfo,
  SeatInfo,
  SeatEvent,
  UserInfo,
  RoomInfo,
  RoomEvent,
  Domain,
  BookingSeatEvent,
  BookingSeatInfo,
  Actions,
} from "./types";
import { SeatEventKind, RelativeDate } from "./types";
import type { APIRequest } from "./hooks";
import { useAPIRequest, useAPIQuery, useAPIMutation } from "./hooks";
import BookForButton from "./bookForButton";
import { CalendarDate, Meetings, partition, Rooms } from "zynq-shared";
import type { Seating, InferReturnType } from "zynq-shared";
import i18next from "i18next";
import { useTranslation, Trans } from "react-i18next";
import {
  toTimeString,
  timeIntervalToString,
  calcRelativeDate,
} from "./luxonUtil";
import { TimeInterval, Time } from "zynq-shared";
import AdminLoader from "./admin/adminLoader";
import DemoConfirmationButton from "./demoConfirmationButton";

function dotColor(s: SeatEvent | null, userInfo: UserInfo) {
  const yours = s && s.avatar && s.avatar.email == userInfo.avatar.email;
  if (s == null || (s.kind == SeatEventKind.RESERVED && yours)) {
    return "#3DD8BB";
  } else if (yours) {
    return "#67b3f5";
  } else {
    return "#F87170";
  }
}

export function DomainLogo(props: { domain: Domain }) {
  return props.domain.logoSrc ? (
    <div
      css={{
        margin: "1rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        css={{ maxHeight: "5rem", maxWidth: "100%" }}
        alt={props.domain.name}
        src={props.domain.logoSrc}
      />
    </div>
  ) : (
    <></>
  );
}

function UserAvatar(props: {
  avatar: Required<SeatEvent>["avatar"];
  timeRange?: TimeInterval;
  timeBasedBookingEnabled?: boolean;
}) {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Avatar avatar={props.avatar} css={{ margin: "15px 5px" }} />
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "13rem",
          div: { margin: "0.25rem 0.5rem" },
        }}
      >
        <div>{props.avatar.name}</div>
        {props.timeBasedBookingEnabled && props.timeRange && (
          <div>{timeIntervalToString(props.timeRange)}</div>
        )}
      </div>
    </div>
  );
}

function RoomEventTimeline(props: {
  events: RoomEvent[];
  setTimeRange: (r: TimeInterval) => void;
}) {
  const { t } = useTranslation();
  return (
    <Timeline>
      {props.events
        .sort(
          (a, b) => a.interval.start.toMillis() - b.interval.start.toMillis()
        )
        .map((e, i) => (
          <TimelineItem key={i}>
            <TimelineOppositeContent>
              <Button
                title="View Time Period"
                onClick={() =>
                  props.setTimeRange(TimeInterval.fromInterval(e.interval))
                }
                css={{
                  ".MuiButton-label": {
                    display: "flex",
                    flexDirection: "column",
                    whiteSpace: "nowrap",
                  },
                  transform: "translateY(-30%)",
                }}
              >
                <Typography
                  css={{ marginRight: "0.5rem" }}
                  color="textSecondary"
                >
                  {e.interval.start.toLocaleString(DateTime.TIME_SIMPLE)}
                </Typography>
                <Typography color="textSecondary">
                  {e.interval.end.toLocaleString(DateTime.TIME_SIMPLE)}
                </Typography>
              </Button>
              {i != props.events.length - 1 &&
                props.events[i + 1].interval.start > e.interval.end && (
                  <Button
                    css={{ transform: "translate(-30%, -45%)" }}
                    onClick={(ev) =>
                      props.setTimeRange(TimeInterval.fromInterval(e.interval))
                    }
                  >
                    <AddIcon css={{ color: "#3DD8BB" }} />
                  </Button>
                )}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
              // css={{
              //   color: "#F87170",
              //   backgroundColor: "white",
              // }}
              // color="inherit"
              />

              {i != props.events.length - 1 && (
                <TimelineConnector
                  sx={{
                    bgcolor:
                      props.events[i + 1].interval.start > e.interval.end
                        ? "#3DD8BB"
                        : undefined,
                  }}
                ></TimelineConnector>
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Button
                title={t("view-time-period")}
                onClick={() =>
                  props.setTimeRange(TimeInterval.fromInterval(e.interval))
                }
                variant="contained"
                css={{
                  textTransform: "initial",
                  ".MuiButton-label": {
                    display: "flex",
                    whiteSpace: "nowrap",
                  },
                  ".MuiButton-root": {
                    padding: "0.5rem",
                  },
                  backgroundColor: "white",
                  transform: "translateY(-30%)",
                  width: "12rem",
                  "@media (min-width:600px)": {
                    width: "13rem",
                  },
                }}
              >
                {/* <Avatar alt={e.host.name} src={e.host.src} /> */}
                <div css={{ marginLeft: "1rem", marginRight: "auto" }}>
                  <div css={{ fontWeight: "bold" }}>{e.subject}</div>
                  {/* <div>{e.host.name}</div> */}
                </div>
              </Button>
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
}

function EventTimeline(props: {
  highlightUserEmail?: string;
  events: SeatEvent[][];
  date: CalendarDate;
  setTimeRange: (r: TimeInterval) => void;
}) {
  const { t } = useTranslation();
  return (
    <Timeline css={{ padding: 0 }}>
      {props.events
        .sort((a, b) => {
          const dateTimeA = `${a[0].startDate}T${a[0].startTime}`;
          const dateTimeB = `${b[0].startDate}T${b[0].startTime}`;
          return new Date(dateTimeA).getTime() - new Date(dateTimeB).getTime();
        })
        .map((e, i) => {
          const timeInterval = TimeInterval.fromISODatesAndTimes({
            ...e[0],
            relativeTo: props.date,
          });
          return (
            <TimelineItem key={e?.[0].bookingID ?? i}>
              <TimelineOppositeContent>
                <Button
                  title={i18next.t("view-time-period")}
                  onClick={() => props.setTimeRange(timeInterval)}
                  css={{
                    marginLeft: "0.5rem",
                    transform: "translateY(-30%)",
                  }}
                >
                  <div css={{ display: "flex", gap: "1rem" }}>
                    <Typography color="textSecondary" variant={"body2"}>
                      {timeInterval.start.toLocaleString(DateTime.TIME_SIMPLE)}
                      {timeInterval.start.days < 0 && "*"}
                    </Typography>
                    <Typography color="textSecondary" variant={"body2"}>
                      {timeInterval.end.toLocaleString(DateTime.TIME_SIMPLE)}
                      {timeInterval.end.days > 0 && "*"}
                    </Typography>
                  </div>
                </Button>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot
                // https://github.com/mui-org/material-ui/issues/27688
                // css={{
                //   color: dotColor(e[0], props.userInfo),
                //   backgroundColor:
                //     e[0].kind == SeatEventKind.RESERVED
                //       ? "white"
                //       : dotColor(e[0], props.userInfo),
                // }}
                // color="inherit"
                />
                {i != props.events.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent css={{ paddingRight: 0 }}>
                <Button
                  title={t("view-time-period")}
                  onClick={() => props.setTimeRange(timeInterval)}
                  variant="contained"
                  css={{
                    ".MuiButton-label": {
                      display: "flex",
                      flexDirection: "column",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    },
                    marginRight: "0.5rem",

                    padding: "0.5rem",
                    backgroundColor: "white",
                    transform: "translateY(-30%)",
                    width: "12rem",
                    "@media (min-width:600px)": {
                      width: "13rem",
                    },
                  }}
                >
                  {e[0].kind == SeatEventKind.RESERVED &&
                  e.some(
                    (e) =>
                      e.avatar && e.avatar.email == props.highlightUserEmail
                  ) ? (
                    <div
                      css={{
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        fontSize: "90%",
                        width: "100%",
                      }}
                    >
                      <span aria-hidden="true" role="img">
                        ✨
                      </span>
                      {t("reserved-for-you")}
                      <span aria-hidden="true" role="img">
                        ✨
                      </span>
                    </div>
                  ) : (
                    <div
                      css={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                        width: "100%",
                        minWidth: 0,
                      }}
                    >
                      <AvatarGroup spacing="medium" max={2}>
                        {e.map(
                          (e, i) =>
                            e.avatar && (
                              <Avatar
                                key={`${String(e.bookingID)}-${i}-${
                                  e.avatar.email
                                }`}
                                avatar={e.avatar}
                              />
                            )
                        )}
                      </AvatarGroup>
                      <div
                        css={{
                          marginLeft: "0.5rem",
                          marginRight: "auto",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {e[0].avatar?.name}
                        {e.length > 1 ? "..." : ""}
                      </div>
                    </div>
                  )}
                </Button>
              </TimelineContent>
            </TimelineItem>
          );
        })}
    </Timeline>
  );
}

type LimitedActions = Pick<
  Actions,
  "onAdminReleaseDropin" | "onAdminRelease" | "onSelectSeat"
>;

type BookableInfo = {
  isBookable: boolean;
  isDropinBookable: boolean;
  bookingErrorText?: string;
  adminOverrideText?: string;
};

function TabbedAutobookContent(props: {
  userInfo: UserInfo;
  floorplan: FloorplanInfo;
  isToday: boolean;
  resourceNoun: string;
  loadingAction: boolean;
  actions: Actions;
  bookableInfo: BookableInfo;
  timeRange: TimeInterval;
  defaultTimeRange: TimeInterval;
  selectedTab?: string;
  setSelectedTab: (t: string) => void;
}) {
  const { t } = useTranslation();

  const tab = props.selectedTab ?? "autobook";

  const tabPanelStyle = css({
    padding: "1rem",
    width: "100%",
    boxSizing: "border-box",
  });
  return (
    <TabContext value={tab}>
      <TabList
        css={{ width: "100%" }}
        onChange={(_e, v) => props.setSelectedTab(v)}
        variant="fullWidth"
        selectionFollowsFocus
      >
        <Tab label={t("auto-book")} value="autobook" />
        <Tab label={t("whos-in")} value="whos-in" />
      </TabList>
      <TabPanel css={tabPanelStyle} value="autobook">
        <div
          css={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <AutobookContent {...props} />
        </div>
      </TabPanel>
      <TabPanel css={tabPanelStyle} value="whos-in">
        <WhosInTab
          userInfo={props.userInfo}
          floorplan={props.floorplan}
          actions={props.actions}
          timeRange={props.timeRange}
        />
      </TabPanel>
    </TabContext>
  );
}

function WhosInList(props: {
  floorplan: FloorplanInfo;
  employees: InferReturnType<typeof Seating.EmployeesPresentList>["employees"];
  isAdmin: boolean;
  actions: LimitedActions;
}) {
  const { t } = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement>();
  const [selected, setSelected] =
    React.useState<typeof props.employees[number]>();
  return (
    <>
      <List css={{ padding: `1rem ${props.isAdmin ? "0" : "1rem"} 1rem 1rem` }}>
        {props.employees.map((u) => {
          const bookingInterval = TimeInterval.fromISODatesAndTimes({
            ...u.booking,
            relativeTo: props.floorplan.date,
          });
          return (
            <ListItem
              key={u.id}
              css={{
                padding: "0.5rem 0",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "center",
                gridTemplateColumns: "1fr 1fr 30px",
                display: "grid",
                width: "100%",
                borderBox: "inline-box",
              }}
            >
              <div
                css={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.75rem",
                }}
              >
                <Avatar avatar={u} />
                <div>
                  <span style={{ fontWeight: 400, lineHeight: 1.4 }}>
                    {u.name}
                  </span>
                </div>
              </div>
              <Button
                css={{
                  display: "flex",
                  flexDirection: "column",
                }}
                fullWidth
                disabled={!u.booking.seat}
                color={"secondary"}
                variant={"outlined"}
                onClick={function () {
                  return (
                    u.booking.seat &&
                    props.actions.onSelectSeat(
                      props.floorplan.date,
                      u.booking.floorplan.id,
                      u.booking.seat.id,
                      bookingInterval
                    )
                  );
                }}
                size="large"
              >
                <div>{u.booking.seat ? u.booking.seat.name : t("drop-in")}</div>
                <div css={{ fontSize: "80%", whiteSpace: "nowrap" }}>
                  {timeIntervalToString(bookingInterval)}
                </div>
              </Button>
              {props.isAdmin && (
                <>
                  <IconButton
                    onClick={(e) => {
                      setMenuAnchorEl(e.currentTarget);
                      setSelected(u);
                    }}
                  >
                    <MoreVert css={{ maxWidth: "24px" }} />
                  </IconButton>
                </>
              )}
            </ListItem>
          );
        })}
      </List>
      <Menu
        anchorEl={menuAnchorEl ?? null}
        open={!!selected && !!menuAnchorEl}
        onClose={() => {
          setMenuAnchorEl(undefined);
          setSelected(undefined);
        }}
      >
        <MenuItem
          onClick={() => {
            if (!selected || !selected.booking.bookingID) {
              return;
            }
            if (selected.booking.seat) {
              props.actions.onAdminRelease(selected.booking.bookingID);
            } else {
              props.actions.onAdminReleaseDropin(selected.booking.bookingID);
            }
            setMenuAnchorEl(undefined);
            setSelected(undefined);
          }}
        >
          <Delete css={{ paddingRight: "0.25rem" }} /> {t("delete")}
        </MenuItem>
      </Menu>
    </>
  );
}

export function WhosInTab(props: {
  floorplan: FloorplanInfo;
  timeRange: TimeInterval;
  actions: LimitedActions;
  userInfo?: UserInfo;
}) {
  const { t } = useTranslation();
  const isAdmin =
    !!props.userInfo && (props.userInfo.isAdmin || props.userInfo.isUserAdmin);

  const whosinQuery = useAPIQuery<typeof Seating.EmployeesPresentList>(
    "/apis/seating/employees-present",
    { date: props.floorplan.date.toISODate(), floorplanID: props.floorplan.id }
  );

  const timeFiltered = whosinQuery.data?.employees.filter(
    (e) =>
      !props.timeRange ||
      props.timeRange.overlaps(TimeInterval.fromISODatesAndTimes(e.booking))
  );

  const { true: buddies, false: others } = partition(
    timeFiltered?.filter(
      (e) => !props.userInfo || e.email != props.userInfo.avatar.email
    ) ?? [],
    (b) => !!props.userInfo && b.isBuddy
  );

  const whosInCount = timeFiltered?.length;

  const userEmail = props.userInfo?.avatar.email;
  const you =
    userEmail && timeFiltered
      ? timeFiltered.filter((e) => e.email == userEmail)
      : [];

  return (
    <div>
      <h3 css={{ textAlign: "center", marginBottom: "0.5rem" }}>
        {t("whos-in-the-office")}
      </h3>
      {whosInCount != null && whosInCount > 0 && (
        <p css={{ textAlign: "center", marginTop: "0" }}>
          {t("count-people", { count: whosInCount })}
        </p>
      )}
      {whosinQuery.isLoading && <AdminLoader />}
      {timeFiltered?.length == 0 && (
        <Alert
          severity={"info"}
          css={{ textAlign: "center", padding: "0.5rem" }}
        >
          {props.timeRange.isAllDay()
            ? t("no-one-is-coming-in-all-day", {
                date: props.floorplan.date,
                floorplanName: props.floorplan.name,
              })
            : t("no-one-is-coming-in", {
                date: props.floorplan.date,
                floorplanName: props.floorplan.name,
                timeRange: timeIntervalToString(props.timeRange),
              })}
        </Alert>
      )}
      {!!buddies?.length && (
        <>
          <h3 css={{ marginBottom: "0" }}>{t("your-buddies")}</h3>
          <WhosInList employees={buddies} isAdmin={isAdmin} {...props} />
        </>
      )}
      {!!others?.length && (
        <>
          {!!buddies?.length && (
            <h3 css={{ marginBottom: "0.5rem" }}>{t("other-colleagues")}</h3>
          )}
          <WhosInList employees={others} isAdmin={isAdmin} {...props} />
        </>
      )}
      {!!you.length && (
        <>
          {!!buddies?.length && (
            <h3 css={{ marginBottom: "0rem" }}>{t("you")}</h3>
          )}
          <WhosInList employees={you} isAdmin={isAdmin} {...props} />
        </>
      )}
    </div>
  );
}

function AutobookContent(props: {
  userInfo: UserInfo;
  floorplan: FloorplanInfo;
  isToday: boolean;
  resourceNoun: string;
  loadingAction: boolean;
  actions: Actions;
  bookableInfo: BookableInfo;
  timeRange: TimeInterval;
  defaultTimeRange: TimeInterval;
}) {
  const { t } = useTranslation();

  const isAdmin = props.userInfo.isAdmin || props.userInfo.isUserAdmin;
  const dropinBookingID = props.floorplan.dropinData.bookingID;
  const canBookAsManager =
    props.userInfo.domain.managerBookingEnabled && props.userInfo.isManager;
  const extraActions = (isAdmin || canBookAsManager) &&
    !props.loadingAction &&
    props.floorplan.dropinEnabled &&
    (props.isToday || props.floorplan.allowFutureDropin) && (
      <>
        <h3>{isAdmin ? t("admin") : t("manager")}</h3>
        <BookForButton
          kind="dropin"
          onBook={() => props.actions.refreshFloorplan().promise}
          date={props.floorplan.date}
          today={props.floorplan.today}
          timezone={props.floorplan.timezone}
          floorplanID={props.floorplan.id}
          disabled={props.loadingAction}
          buttonText={t("drop-in-for")}
          timeRange={props.timeRange}
          defaultTimeRange={props.defaultTimeRange}
          timeBasedBookingEnabled={false}
          limitToDirectReports={!isAdmin && canBookAsManager}
          scanningEnabled={props.floorplan.checkinQREnabled}
        />
      </>
    );
  const myDeskBookings = props.floorplan.myBookings.filter(
    (el) => el.seat.resourceType == "DESK"
  );

  if (
    (props.floorplan.checkinEvents.length > 0 || myDeskBookings.length > 0) &&
    props.isToday
  ) {
    // We determine the most "relevant" booking:
    // on this floor and in selected time range > on this floor > in selected time range > current > soonest upcoming > random
    const fpNow = DateTime.now().setZone(props.floorplan.timezone);
    const myRelevantDeskBooking =
      myDeskBookings.length > 0
        ? myDeskBookings.find(
            (el) =>
              el.floorplan.id == props.floorplan.id &&
              props.timeRange.engulfs(
                TimeInterval.fromDateTimes(
                  el.startTime,
                  el.endTime
                ).assertValid()
              )
          ) ??
          myDeskBookings.find((el) => el.floorplan.id == props.floorplan.id) ??
          myDeskBookings.find((el) =>
            props.timeRange.engulfs(
              TimeInterval.fromDateTimes(el.startTime, el.endTime).assertValid()
            )
          ) ??
          myDeskBookings
            .filter((b) => b.endTime > fpNow)
            .sort(
              (b1, b2) => b1.startTime.valueOf() - b2.startTime.valueOf()
            )[0] ??
          myDeskBookings[0]
        : undefined;
    const myRelBookingTimeInterval =
      myRelevantDeskBooking &&
      TimeInterval.fromDateTimes(
        myRelevantDeskBooking.startTime,
        myRelevantDeskBooking.endTime
      ).assertValid();

    const isMyRelevantBookingInTimeRange =
      !!myRelBookingTimeInterval &&
      myRelBookingTimeInterval.overlaps(props.timeRange);

    const hasCheckins = props.floorplan.checkinEvents.length > 0;
    return (
      <>
        <div css={{ marginBottom: "2rem" }}>
          <div css={{ margin: "25px", textAlign: "center" }}>
            {hasCheckins ? (
              t("youll-need-to-check-in-before-enter")
            ) : isMyRelevantBookingInTimeRange ? (
              props.floorplan.id == myRelevantDeskBooking.floorplan.id ? (
                <Trans i18nKey={"your-booking-details-without-floorplan"}>
                  You have
                  <b>
                    {{ resourceName: myRelevantDeskBooking.seat.name }}
                  </b>{" "}
                  booked{" "}
                  {{
                    timeRange: timeIntervalToString(myRelBookingTimeInterval),
                  }}
                </Trans>
              ) : (
                <Trans i18nKey={"your-booking-details-with-floorplan"}>
                  You have
                  <b>
                    {{ resourceName: myRelevantDeskBooking.seat.name }}
                  </b> in{" "}
                  {{ floorplanName: myRelevantDeskBooking.floorplan.name }}{" "}
                  booked{" "}
                  {{
                    timeRange: timeIntervalToString(myRelBookingTimeInterval),
                  }}
                </Trans>
              )
            ) : (
              ""
            )}
          </div>
          <DomainLogo domain={props.userInfo.domain} />
        </div>
        {hasCheckins && (
          <Button
            variant="contained"
            color="secondary"
            disabled={props.loadingAction}
            onClick={props.actions.onCheckinAll}
            css={{ marginBottom: "1rem" }}
          >
            {t("check-in-to-my-booking")}
            {props.floorplan.checkinEvents.length > 1 ? "s" : ""}
          </Button>
        )}
        {isMyRelevantBookingInTimeRange && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              props.actions.onSelectSeat(
                props.floorplan.date,
                myRelevantDeskBooking.floorplan.id,
                myRelevantDeskBooking.seat.id,
                myRelBookingTimeInterval
              )
            }
            css={{ marginBottom: "1rem" }}
          >
            {t("see-my-resource-type", {
              resourceType: resourceName(
                myRelevantDeskBooking.seat.resourceType
              ),
            })}
          </Button>
        )}
        {!props.loadingAction &&
        props.floorplan.dropinEnabled &&
        (props.isToday || props.floorplan.allowFutureDropin) ? (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => props.actions.onDropin()}
            disabled={
              props.floorplan.dropinData.hasDropin ||
              !props.bookableInfo.isDropinBookable
            }
            css={{ marginBottom: "1rem" }}
          >
            {t("drop-in-visit")}
          </Button>
        ) : null}

        {props.floorplan.hideAutoBookButton || props.loadingAction ? null : (
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              props.actions.onAutobook({
                dropin: false,
                addAutoSelectDate: true,
              })
            }
            css={{ marginBottom: "1rem" }}
          >
            {t("book-a-resource", {
              resource: props.resourceNoun,
            })}
          </Button>
        )}

        {!props.loadingAction &&
          props.floorplan.rooms.length > 0 &&
          props.userInfo.domain.allowFloorplanMeetingBooking && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => props.actions.onBookMeeting()}
              css={{ marginBottom: "1rem" }}
            >
              {t("schedule-a-meeting")}
            </Button>
          )}
        {extraActions}
      </>
    );
  } else if (
    dropinBookingID &&
    !props.floorplan.dropinData.needsCheckin &&
    props.userInfo.domain.name != "jobot.com" // Jobot IP whitelist system makes this unnecessary
  ) {
    // You have a checked in dropin visit
    return (
      <>
        <div css={{ margin: "25px" }}>
          {t("youre-booked-to-come-into-office-without-desk")}
        </div>
        <div css={{ height: "1rem" }} />
        {props.actions.onShowQR && (
          <Button
            onClick={props.actions.onShowQR}
            style={{
              marginBottom: "2rem",
              alignSelf: "center",
            }}
            color="secondary"
            disabled={props.loadingAction}
            variant="contained"
          >
            <CropFreeIcon style={{ margin: "0px 10px" }} />
            {t("view-entry-code")}
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          disabled={props.loadingAction}
          onClick={() => props.actions.onReleaseDropin(dropinBookingID)}
        >
          {t("release-booking")}
        </Button>
        {extraActions}
      </>
    );
  } else if (dropinBookingID && props.floorplan.dropinData.needsCheckin) {
    // You have a dropin visit that needs checkin
    return (
      <>
        <div css={{ margin: "25px", textAlign: "center" }}>
          {props.isToday
            ? t("checking-for-visit-to-office")
            : t("youre-booked-to-come-into-office-without-desk")}
        </div>
        <div css={{ height: "1rem" }} />
        {props.isToday && (
          <>
            <Button
              variant="contained"
              color="primary"
              disabled={props.loadingAction}
              onClick={props.actions.onCheckinAll}
            >
              {t("check-in")}
            </Button>
          </>
        )}
        <div css={{ height: "1rem" }} />
        <Button
          variant="contained"
          color="secondary"
          disabled={props.loadingAction}
          onClick={() => props.actions.onReleaseDropin(dropinBookingID)}
        >
          {t("release-booking")}
        </Button>
        {extraActions}
      </>
    );
  } else {
    return (
      <>
        <div css={{ marginBottom: "2rem" }}>
          <div css={{ margin: "25px", textAlign: "center" }}>
            {props.userInfo.domain.name == "transat.com" && (
              <>
                {t("transat-sidebar-instructions1")}
                <br />
                <br />
                {t("transat-sidebar-instructions2")}
              </>
            )}
            {props.floorplan.streetAddress && (
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <LocationOn
                  css={{
                    color: "#c73828",
                    marginRight: "0.25rem",
                  }}
                />
                <p css={{ maxWidth: "180px" }}>
                  {props.floorplan.streetAddress}
                </p>
              </div>
            )}
          </div>
          <DomainLogo domain={props.userInfo.domain} />
        </div>

        {props.bookableInfo.bookingErrorText && (
          <Alert severity="info" css={{ margin: "1rem" }}>
            {props.bookableInfo.bookingErrorText}
          </Alert>
        )}
        {props.floorplan.hideAutoBookButton || props.loadingAction ? null : (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              props.actions.onAutobook({
                dropin: false,
                addAutoSelectDate: true,
              })
            }
            css={{ marginBottom: "1rem" }}
          >
            {t("book-a-resource", {
              resource: props.resourceNoun,
            })}
          </Button>
        )}

        {props.floorplan.dropinEnabled &&
          !props.loadingAction &&
          (props.isToday || props.floorplan.allowFutureDropin) && (
            <Button
              variant="contained"
              color="secondary"
              disabled={
                props.floorplan.dropinData.hasDropin ||
                !props.bookableInfo.isDropinBookable
              }
              onClick={() => {
                props.actions.onAutobook({
                  dropin: true,
                  addAutoSelectDate: true,
                });
              }}
              css={{ marginBottom: "1rem" }}
            >
              {t("drop-in-visit")}
            </Button>
          )}

        {props.floorplan.rooms.length > 0 &&
          props.userInfo.domain.allowFloorplanMeetingBooking && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => props.actions.onBookMeeting()}
              css={{ marginBottom: "1rem" }}
            >
              {t("schedule-a-meeting")}
            </Button>
          )}
        {extraActions}
        <CustomSidebarInstructions userInfo={props.userInfo} />
      </>
    );
  }
}

function BookableResourceContent(props: {
  userInfo: UserInfo;
  floorplan: FloorplanInfo;
  actions: Actions;
  bookableInfo: BookableInfo;
  selectedSeat: SeatInfo;
  loadingAction: boolean;
  timeRange: TimeInterval;
  defaultTimeRange: TimeInterval;
  reserveEvent?: SeatEvent;
  relativeDate: RelativeDate;
}) {
  const { t } = useTranslation();

  const isAdmin = props.userInfo.isAdmin || props.userInfo.isUserAdmin;
  const canBookAsManager =
    props.userInfo.domain.managerBookingEnabled && props.userInfo.isManager;

  return (
    <>
      {props.reserveEvent && (
        <>
          <div css={{ textAlign: "center" }}>
            <span aria-hidden="true" role="img">
              ✨
            </span>{" "}
            {t("this-is-your-desk")}{" "}
            <span aria-hidden="true" role="img">
              ✨
            </span>
            <p>
              {timeIntervalToString(
                TimeInterval.fromISODatesAndTimes({
                  ...props.reserveEvent,
                  relativeTo: props.floorplan.date,
                })
              )}
            </p>
          </div>
          <div css={{ height: "1rem" }} />
        </>
      )}
      {props.bookableInfo.bookingErrorText && (
        <Alert severity="info" css={{ margin: "1rem" }}>
          {props.bookableInfo.bookingErrorText}
        </Alert>
      )}
      <Button
        variant="contained"
        color="secondary"
        disabled={props.loadingAction || !props.bookableInfo.isBookable}
        onClick={() => props.actions.onBook(props.selectedSeat)}
        css={{ marginBottom: "1rem" }}
      >
        {props.relativeDate == RelativeDate.Future
          ? t("book-for-weekday", {
              weekday: props.floorplan.date.toLocaleString({
                weekday: "long",
              }),
            })
          : props.relativeDate == RelativeDate.Today
          ? t("book-for-today")
          : t("make-booking")}
        {!props.timeRange.isAllDay() && (
          <>
            <br />
            {timeIntervalToString(props.timeRange)}
          </>
        )}
      </Button>
      {props.relativeDate != RelativeDate.Past &&
        props.floorplan.bookAheadDaysMax > 0 && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              props.actions.onAutobook({
                hint: props.selectedSeat,
                dropin: false,
                addAutoSelectDate: false,
              });
            }}
            css={{ marginBottom: "1rem" }}
          >
            {t("book-for-later")}
          </Button>
        )}
      {(isAdmin || canBookAsManager) && (
        <>
          <h3>{isAdmin ? t("admin") : t("manager")}</h3>
          <BookForButton
            disabled={props.loadingAction}
            date={props.floorplan.date}
            today={props.floorplan.today}
            timezone={props.floorplan.timezone}
            kind={"seat"}
            selectedSeatID={props.selectedSeat.id}
            resourceType={props.selectedSeat.resourceType}
            floorplanID={props.floorplan.id}
            adminOverrideText={props.bookableInfo.adminOverrideText}
            onBook={() => props.actions.refreshFloorplan().promise}
            buttonText={t("book-for")}
            timeRange={props.timeRange}
            defaultTimeRange={props.defaultTimeRange}
            timeBasedBookingEnabled={
              props.userInfo.domain.timeBasedBookingsEnabled
            }
            limitToDirectReports={!isAdmin && canBookAsManager}
            scanningEnabled={props.floorplan.checkinQREnabled}
          />
        </>
      )}
    </>
  );
}

// Not called with "YOURS_RESERVED", since that's bookable
function SingleEventContent(props: {
  userInfo: UserInfo;
  floorplan: FloorplanInfo;
  resourceNoun: string;
  loadingAction: boolean;
  actions: Actions;
  bookableInfo: BookableInfo;
  timeRange: TimeInterval;
  defaultTimeRange: TimeInterval;
  selectedSeat: SeatInfo;
  event: SeatEvent[];
  relativeDate: RelativeDate;
}) {
  const yours = props.event.find(
    (e) => e.avatar && e.avatar.email == props.userInfo.avatar.email
  );
  const now = DateTime.now().setZone(props.floorplan.timezone);
  const yoursInterval =
    yours &&
    TimeInterval.fromISODatesAndTimes({
      ...yours,
      relativeTo: now,
    }).toInterval(now);

  const [confirmationState, setConfirmationState] = React.useState<
    | { kind: "CLOSED" }
    | { kind: "OPEN"; groupID: number; groupName: string }
    | { kind: "SENT" }
    | { kind: "ERROR"; msg: string }
  >({ kind: "CLOSED" });
  const { t } = useTranslation();
  const { mutate: sendRequest, isLoading } = useAPIMutation<
    typeof Seating.RequestAccess
  >("/apis/seating/usergroups/request-access", {
    onSuccess: () => setConfirmationState({ kind: "SENT" }),
    onError: (e) => setConfirmationState({ kind: "ERROR", msg: e.message }),
  });

  return (
    <>
      <Dialog
        open={confirmationState.kind != "CLOSED"}
        onClose={() => setConfirmationState({ kind: "CLOSED" })}
      >
        <DialogTitle>
          {isLoading && (
            <CircularProgress
              style={{ marginRight: "1rem", color: "inherit" }}
              size={25}
            />
          )}
          {t("request-access")}
        </DialogTitle>

        {confirmationState.kind == "OPEN" ? (
          <>
            <DialogContent>
              {t("this-will-send-notification-to-administrator-for-group", {
                groupName: confirmationState.groupName,
              })}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                disabled={isLoading}
                onClick={() => setConfirmationState({ kind: "CLOSED" })}
              >
                {t("no")}
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={isLoading}
                onClick={() => sendRequest({ id: confirmationState.groupID })}
              >
                {t("yes-send-request")}
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              {confirmationState.kind == "ERROR"
                ? confirmationState.msg
                : confirmationState.kind == "SENT"
                ? t("request-sent-to-administrator")
                : ""}
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setConfirmationState({ kind: "CLOSED" })}
              >
                {t("close")}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <div css={{ width: "100%", marginBottom: "2rem" }}>
        {props.event.map((event) =>
          event.avatar && event.kind === SeatEventKind.RESERVED ? (
            <Card
              key={`${event.startTime}-${event.startDate}-${
                event.avatar.email ?? event.avatar.groupID
              }-res`}
              css={{
                margin: "25px",
                paddingBottom: "1rem",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  fontStyle: "italic",
                  textAlign: "center",
                  margin: "15px 25px 5px 25px",
                }}
              >
                {t("reserved-for")}
              </div>
              <UserAvatar
                avatar={event.avatar}
                timeRange={TimeInterval.fromISODatesAndTimes({
                  ...event,
                  relativeTo: props.floorplan.date,
                })}
                timeBasedBookingEnabled={
                  props.userInfo.domain.timeBasedBookingsEnabled
                }
              />
              {["zynq.io", "albertsons.com"].includes(
                props.userInfo.domain.name
              ) &&
                event.avatar.groupID != null && (
                  <Button
                    size="small"
                    variant="outlined"
                    css={{
                      marginTop: "0.5rem",
                      marginBottom: "-0.5rem",
                    }}
                    onClick={() => {
                      event.avatar &&
                        event.avatar.groupID != null &&
                        setConfirmationState({
                          kind: "OPEN",
                          groupID: event.avatar.groupID,
                          groupName: event.avatar.name,
                        });
                    }}
                  >
                    {t("request-access")}
                  </Button>
                )}
            </Card>
          ) : event.avatar ? (
            <UserAvatar
              key={`${String(event.bookingID)}-${event.startTime}-${
                event.startDate
              }-${event.avatar.email}`}
              avatar={event.avatar}
              timeRange={TimeInterval.fromISODatesAndTimes({
                ...event,
                relativeTo: props.floorplan.date,
              })}
              timeBasedBookingEnabled={
                props.userInfo.domain.timeBasedBookingsEnabled
              }
            />
          ) : (
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              key={`shutdown ${event.startTime}`}
            >
              {event.kind == "SHUTDOWN" && (
                <RemoveCircle fontSize="large" css={{ margin: "15px 5px" }} />
              )}
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: "13rem",
                  div: { margin: "0.25rem 0.5rem" },
                }}
              >
                <div>{event.reason ?? t("schedule-description-shutdown")}</div>
                {props.userInfo.domain.timeBasedBookingsEnabled && (
                  <div>
                    {timeIntervalToString(
                      TimeInterval.fromISODatesAndTimes({
                        ...event,
                        relativeTo: props.floorplan.date,
                      })
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        )}
      </div>
      {yours &&
        yours.kind == "CHECKIN" &&
        props.relativeDate == RelativeDate.Today && (
          <Button
            variant="contained"
            color="secondary"
            disabled={props.loadingAction}
            onClick={() => props.actions.onCheckin(props.selectedSeat, yours)}
            css={{ marginBottom: "1rem" }}
          >
            {t("check-in")}
          </Button>
        )}
      {yours && yours.kind == "OCCUPIED" && props.actions.onShowQR && (
        <Button
          onClick={() => props.actions.onShowQR!()}
          style={{
            marginBottom: "2rem",
            alignSelf: "center",
          }}
          color="secondary"
          disabled={props.loadingAction}
          variant="contained"
          css={{ marginBottom: "1rem" }}
        >
          <CropFreeIcon style={{ margin: "0px 10px" }} />
          {t("view-entry-code")}
        </Button>
      )}
      {yours &&
      yours.scanCount &&
      yours.scanCount > 0 &&
      !props.userInfo.domain.timeBasedBookingsEnabled ? (
        <Alert severity="info" css={{ margin: "1rem" }}>
          {t("you-cannot-release-a-booking-already-scanned")}
        </Alert>
      ) : undefined}
      {yours && yours.bookingID && yoursInterval && (
        <Button
          variant="contained"
          color="secondary"
          disabled={props.loadingAction || yoursInterval.end < now}
          onClick={() => props.actions.onRelease(yours.bookingID!)}
          css={{ marginBottom: "1rem" }}
        >
          {yours.scanCount != undefined &&
          yours.scanCount > 0 &&
          yoursInterval.contains(now) &&
          props.userInfo.domain.timeBasedBookingsEnabled
            ? t("release-booking-early")
            : t("release-booking")}
        </Button>
      )}
      {(props.userInfo.isAdmin || props.userInfo.isUserAdmin) &&
        props.event.some(
          (e) =>
            e.kind == SeatEventKind.OCCUPIED || e.kind == SeatEventKind.RESERVED
        ) && (
          <>
            <h3>{t("admin")}</h3>
            {!props.event.some((e) => e.kind == SeatEventKind.OCCUPIED) && (
              <BookForButton
                disabled={props.loadingAction}
                date={props.floorplan.date}
                today={props.floorplan.today}
                timezone={props.floorplan.timezone}
                kind={"seat"}
                selectedSeatID={props.selectedSeat.id}
                resourceType={props.selectedSeat.resourceType}
                floorplanID={props.floorplan.id}
                adminOverrideText={props.bookableInfo.adminOverrideText}
                onBook={() => props.actions.refreshFloorplan().promise}
                buttonText={t("book-for")}
                timeRange={props.timeRange}
                defaultTimeRange={props.defaultTimeRange}
                timeBasedBookingEnabled={
                  props.userInfo.domain.timeBasedBookingsEnabled
                }
                scanningEnabled={props.floorplan.checkinQREnabled}
              />
            )}
            {props.event.some((e) => e.kind == SeatEventKind.OCCUPIED) && (
              <Button
                variant="contained"
                color="secondary"
                disabled={props.loadingAction}
                onClick={() =>
                  props.actions.onAdminRelease(
                    props.event.find(
                      (e) => e.kind == SeatEventKind.OCCUPIED && e.bookingID
                    )!.bookingID!
                  )
                }
              >
                {t("force-release")}
              </Button>
            )}
          </>
        )}
    </>
  );
}

function ReadonlySeatContent(props: {
  floorplan: FloorplanInfo;
  resourceNoun: string;
  selectedSeat: SeatInfo;
}) {
  const { t } = useTranslation();
  const combinedEventsMap: Record<string, SeatEvent[]> = {};
  props.selectedSeat.events.forEach(
    (e) =>
      (combinedEventsMap[
        `${e.startTime}-${e.endTime}-${e.startDate}-${e.endDate}`
      ] = [
        e,
        ...(combinedEventsMap[
          `${e.startTime}-${e.endTime}-${e.startDate}-${e.endDate}`
        ] ?? []),
      ])
  );
  const combinedEvents = Object.entries(combinedEventsMap)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map((e) => e[1]);

  return (
    <>
      {combinedEvents.length > 0 && (
        <>
          <h3 css={{ marginTop: "2.5rem" }}>{t("full-schedule")}</h3>
          <EventTimeline
            events={combinedEvents}
            date={props.floorplan.date}
            setTimeRange={() => {
              // noop
            }}
          />
        </>
      )}
    </>
  );
}

function SeatContent(props: {
  userInfo: UserInfo;
  floorplan: FloorplanInfo;
  resourceNoun: string;
  loadingAction: boolean;
  actions: Actions;
  bookableInfo: BookableInfo;
  selectedSeat: SeatInfo;
  timeRange: TimeInterval;
  defaultTimeRange: TimeInterval;
  relativeDate: RelativeDate;
}) {
  const { t } = useTranslation();
  const combinedEventsMap: Record<string, SeatEvent[]> = {};
  props.selectedSeat.events.forEach(
    (e) =>
      (combinedEventsMap[
        `${e.startTime}-${e.endTime}-${e.startDate}-${e.endDate}`
      ] = [
        e,
        ...(combinedEventsMap[
          `${e.startTime}-${e.endTime}-${e.startDate}-${e.endDate}`
        ] ?? []),
      ])
  );
  const combinedEvents = Object.entries(combinedEventsMap)
    .sort((a, b) => a[0].localeCompare(b[0]))
    .map((e) => e[1]);

  const currentEvents = combinedEvents.filter((e) =>
    TimeInterval.fromISODatesAndTimes({
      ...e[0],
      relativeTo: props.floorplan.date,
    }).overlaps(props.timeRange)
  );

  const hasHiddenEvents = combinedEvents.length > currentEvents.length;

  if (currentEvents.length == 0) {
    // Seat is free, show booking buttons
    return (
      <>
        <BookableResourceContent {...props} />
        {hasHiddenEvents && (
          <>
            <h3 css={{ marginTop: "2.5rem" }}>{t("full-schedule")}</h3>
            <EventTimeline
              highlightUserEmail={props.userInfo.avatar.email}
              events={combinedEvents}
              date={props.floorplan.date}
              setTimeRange={props.actions.setTimeRange}
            />
          </>
        )}
      </>
    );
  } else if (
    currentEvents.length == 1 &&
    currentEvents[0][0].avatar?.email == props.userInfo.avatar.email &&
    currentEvents[0][0].kind == SeatEventKind.RESERVED
  ) {
    // Seat has single event, show it and actions
    return (
      <>
        <BookableResourceContent
          {...props}
          reserveEvent={currentEvents[0][0]}
        />
        {hasHiddenEvents && (
          <>
            <h3 css={{ marginTop: "2.5rem" }}>{t("full-schedule")}</h3>
            <EventTimeline
              highlightUserEmail={props.userInfo.avatar.email}
              events={combinedEvents}
              date={props.floorplan.date}
              setTimeRange={props.actions.setTimeRange}
            />
          </>
        )}
      </>
    );
  } else if (currentEvents.length == 1) {
    return (
      <>
        <SingleEventContent {...props} event={currentEvents[0]} />
        {props.selectedSeat.events.length > 1 && (
          <>
            <h3 css={{ marginTop: "2.5rem" }}>{t("full-schedule")}</h3>
            <EventTimeline
              highlightUserEmail={props.userInfo.avatar.email}
              events={combinedEvents}
              date={props.floorplan.date}
              setTimeRange={props.actions.setTimeRange}
            />
          </>
        )}
      </>
    );
  } else {
    return (
      <EventTimeline
        highlightUserEmail={props.userInfo.avatar.email}
        events={currentEvents}
        date={props.floorplan.date}
        setTimeRange={props.actions.setTimeRange}
      />
    );
  }
}

function RoomHeader(props: { room: RoomInfo }) {
  return (
    <>
      <div style={{ width: "100%" }}>
        <img
          alt="" // TODO(a11y) Add alt text for desk images in DB!
          src={props.room.imgURL}
          className="hide-on-mobile"
          style={{ width: "100%" }}
        />
      </div>
      {props.room.description && (
        <div style={{ width: "100%" }}>
          <div css={{ margin: "25px", whiteSpace: "pre-line" }}>
            {props.room.description}
          </div>
        </div>
      )}
    </>
  );
}

function ReadonlyRoomContent(props: {
  floorplan: FloorplanInfo;
  selectedRoom: RoomInfo;
  selectedRoomEvents: RoomEvent[];
}) {
  const { t } = useTranslation();
  return (
    <>
      <RoomHeader room={props.selectedRoom} />
      {props.selectedRoomEvents.length > 0 && (
        <>
          <h3 css={{ marginTop: "2.5rem" }}>{t("full-schedule")}</h3>
          <RoomEventTimeline
            events={props.selectedRoomEvents}
            setTimeRange={() => {
              // noop
            }}
          />
        </>
      )}
    </>
  );
}

function RoomContent(props: {
  userInfo: UserInfo;
  floorplan: FloorplanInfo;
  loadingAction: boolean;
  actions: Actions;
  selectedRoom: RoomInfo;
  selectedRoomEvents: RoomEvent[];
  timeRange: TimeInterval;
}) {
  const { t } = useTranslation();
  const api = useAPIRequest();

  const [eventToDelete, setEventToDelete] = React.useState<RoomEvent>();

  let state: { state: "FREE" | "BUSY"; until: DateTime } | undefined =
    undefined;

  const sortedEvents = props.selectedRoomEvents.sort(
    (a, b) => a.interval.start.toMillis() - b.interval.start.toMillis()
  );

  const now = DateTime.now().setZone(props.selectedRoom.timezone);
  if (
    props.floorplan.today.hasSame(props.floorplan.date, "day") &&
    props.timeRange.toInterval(now.startOf("day")).contains(now)
  ) {
    let isFree = true;
    let until: DateTime | undefined = undefined;
    for (let index = 0; index < sortedEvents.length; index++) {
      const event = sortedEvents[index];
      if (event.interval.contains(now)) {
        isFree = false;
        until = event.interval.end;
      } else if (isFree && event.interval.start > now) {
        until = event.interval.start;
        break;
      } else if (!isFree) {
        if (
          sortedEvents[index - 1].interval.end.toMillis() >=
          event.interval.start.minus({ minutes: 5 }).toMillis()
        ) {
          until = event.interval.end;
        } else {
          break;
        }
      }
    }
    if (until) {
      state = { state: isFree ? "FREE" : "BUSY", until: until };
    }
  }

  const currentEvents = props.selectedRoomEvents.filter((e) =>
    TimeInterval.fromInterval(e.interval, props.floorplan.date).overlaps(
      props.timeRange
    )
  );

  const roomsIdp = props.userInfo.domain.gsEnabled ? "google" : "microsoft";
  if (
    props.floorplan.date
      .diff(CalendarDate.today(props.floorplan.timezone))
      .as("days") >
    MAX_ROOMS_BOOKAHEAD[roomsIdp] - 1
  ) {
    return (
      <div css={{ margin: "1rem" }}>
        <Alert severity="info">
          {t(
            "date-range-selected-too-far-into-future-must-book-with-work-calendar"
          )}
        </Alert>
      </div>
    );
  }

  if (
    ZYNQ_DEMO_DOMAINS.includes(props.userInfo.domain.name) &&
    props.selectedRoom.name == "Town Hall"
  ) {
    return (
      <>
        <RoomHeader room={props.selectedRoom} />
        <div css={{ height: "1rem" }} />
        {props.selectedRoom.bookable && (
          <DemoConfirmationButton
            confirmationText="Your request has been sent, you'll receive an approval or rejection via email."
            confirmationTitle="Access Requested"
            variant="contained"
            color="primary"
          >
            {t("request-access")}
          </DemoConfirmationButton>
        )}
      </>
    );
  }

  const shouldTreatAsDesk =
    props.userInfo.domain.name === "amexgbt.com" &&
    (props.selectedRoom.name.startsWith("Desk ") ||
      props.selectedRoom.name.startsWith("Hot Desk "));

  if (!props.selectedRoom.viewable) {
    return (
      <>
        <RoomHeader room={props.selectedRoom} />
        <div css={{ height: "1rem" }} />
        <p>
          {shouldTreatAsDesk
            ? t("this-desk-is-off")
            : t("this-meeting-room-is-off")}
        </p>
      </>
    );
  } else if (currentEvents.length == 0) {
    return (
      <>
        <RoomHeader room={props.selectedRoom} />
        <div css={{ height: "1rem" }} />
        <p>
          {shouldTreatAsDesk
            ? t("this-desk-is-free")
            : t("this-meeting-room-is-free")}
        </p>
        {props.selectedRoom.bookable && (
          <Button
            onClick={props.actions.onBookMeeting}
            variant="contained"
            color="primary"
          >
            {shouldTreatAsDesk ? t("book-desk") : t("book-a-meeting")}
          </Button>
        )}
        {props.selectedRoomEvents.length > 1 && (
          <>
            <h3 css={{ marginTop: "2.5rem" }}>{t("full-schedule")}</h3>
            <RoomEventTimeline
              events={props.selectedRoomEvents}
              setTimeRange={props.actions.setTimeRange}
            />
          </>
        )}
      </>
    );
  } else if (currentEvents.length == 1) {
    const event = currentEvents[0];
    return (
      <>
        <RoomHeader room={props.selectedRoom} />
        <h3
          css={{
            fontWeight: "bold",
            fontSize: "150%",
            margin: "0.5rem",
            textAlign: "center",
          }}
        >
          {event.subject}
        </h3>
        {timeIntervalToString(TimeInterval.fromInterval(event.interval))}
        {props.userInfo.domain.name == "transat.com" ? (
          <>
            <div css={{ margin: "0.5rem" }} />
          </>
        ) : (
          <>
            <h4 css={{ marginBottom: "0.25rem" }}>
              {t("count-attendee", { count: event.attendees.length })}
            </h4>
            {event.attendees.length > 0 && (
              <ul css={{ marginTop: "0.25rem", listStyle: "none" }}>
                {event.attendees.map((a) => (
                  <li
                    css={{
                      ":before": {
                        content: '""',
                        backgroundColor: "white",
                        display: "inline-block",
                        width: "0.5rem",
                        height: "0.5rem",
                        borderRadius: "1rem",
                        marginLeft: "-1em",
                        marginRight: "0.5em",
                        border: "1px solid black",
                      },
                      marginBottom: "0.5rem",
                    }}
                    key={a.email}
                  >
                    {a.name && a.name.length > 0 ? a.name : a.email}
                    {a.email == event.organizerEmail
                      ? " (" + t("host") + ")"
                      : ""}
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
        {event.organizerEmail == props.userInfo.avatar.email && (
          <>
            <Tooltip
              title={
                !event.webLink && props.userInfo.domain.isSandbox
                  ? t("operation-not-supported-in-sandbox")
                  : ""
              }
            >
              {/* <span> is required for tooltip to show up on disabled button */}
              <span>
                <Button
                  onClick={() => {
                    if (event.webLink) {
                      window.open(event.webLink, "_blank", "noreferrer");
                    } else {
                      api
                        .getNode(Meetings.GetEventLink, {
                          iCalUId: event.iCalUId,
                          eventID: event.id,
                          start: event.interval.start.toISO(),
                          end: event.interval.end.toISO(),
                        })
                        .promise.then((res) => {
                          if (res.status == "failed") {
                            window.open(
                              props.userInfo.domain.gsEnabled
                                ? "https://calendar.google.com/"
                                : "https://outlook.office.com/calendar",
                              "_blank",
                              "noreferrer"
                            );
                          } else {
                            window.open(res.webLink, "_blank", "noreferrer");
                          }
                        });
                    }
                  }}
                  variant="contained"
                  color="primary"
                  css={{ marginBottom: "0.5rem" }}
                >
                  {props.userInfo.domain.gsEnabled ||
                  props.userInfo.domain.name == "cira-sandbox.com"
                    ? t("edit-in-google-calendar")
                    : t("edit-in-outlook")}
                </Button>
              </span>
            </Tooltip>
            <Button
              onClick={() => {
                setEventToDelete(event);
              }}
              variant="contained"
              color="primary"
              css={{ marginBottom: "2rem" }}
            >
              {t("cancel")}
            </Button>
            <Dialog
              open={!!eventToDelete}
              onClose={() => {
                setEventToDelete(undefined);
              }}
            >
              <DialogTitle>
                {t("are-you-sure-you-wish-to-cancel-this-meeting")}
              </DialogTitle>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={(_) => setEventToDelete(undefined)}
                >
                  {t("cancel")}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={(_) => {
                    if (!eventToDelete) return;
                    api
                      .postNode(Rooms.EndEvent, {
                        roomID: eventToDelete.roomID,
                        eventID: eventToDelete.id,
                        currentStartTime: eventToDelete.interval.start.toISO(),
                        currentEndTime: eventToDelete.interval.end.toISO(),
                      })
                      .promise.then((res) => {
                        setEventToDelete(undefined);
                      });
                  }}
                >
                  {t("confirm")}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
        {props.selectedRoom.bookable && (
          <Button
            onClick={props.actions.onBookMeeting}
            variant="contained"
            color="primary"
          >
            {t("book-for-later")}
          </Button>
        )}
        {state && (
          <p>
            {t("state-until-time", {
              state: t(state.state),
              time: state.until.toLocaleString(DateTime.TIME_SIMPLE),
            })}
          </p>
        )}
        {props.selectedRoomEvents.length > 1 && (
          <>
            <h3 css={{ marginTop: "2.5rem" }}>{t("full-schedule")}</h3>
            <RoomEventTimeline
              events={props.selectedRoomEvents}
              setTimeRange={props.actions.setTimeRange}
            />
          </>
        )}
      </>
    );
  } else {
    // Room has multiple events????
    // Just show timeline maybe?
    return (
      <>
        <RoomHeader room={props.selectedRoom} />
        <div css={{ height: "2rem" }} />
        {props.selectedRoom.bookable && (
          <Button
            onClick={props.actions.onBookMeeting}
            variant="contained"
            color="primary"
          >
            {t("book-for-later")}
          </Button>
        )}
        {state && (
          <p>
            {t("state-until-time", {
              state: t(state.state),
              time: state.until.toLocaleString(DateTime.TIME_SIMPLE),
            })}
          </p>
        )}
        <h3 css={{ marginTop: "2.5rem" }}>{t("full-schedule")}</h3>
        <RoomEventTimeline
          events={currentEvents}
          setTimeRange={props.actions.setTimeRange}
        />
      </>
    );
  }
}

export function SidePanelDrawer(props: {
  children?: React.ReactNode;
  open: boolean;
  setOpen: (b: boolean, clear?: boolean) => void;
  width: string;
  selectedSeat?: SeatInfo;
  selectedRoom?: RoomInfo;
  floorplan: FloorplanInfo;
  hasTopOffset: boolean;
  hasActions: boolean;
  loadingAction: boolean;
  autoscroll?: boolean;
}) {
  const { t } = useTranslation();
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const autoscrollRef = React.useRef(props.autoscroll);

  React.useEffect(
    () => scrollRef?.current?.scrollTo(0, 0),
    [props.selectedSeat?.id, props.selectedRoom?.id]
  );

  React.useEffect(() => {
    autoscrollRef.current = props.autoscroll;
    function animate() {
      const scroll = scrollRef.current;
      if (scroll) {
        scroll.scrollTop = scroll.scrollTop + 1;
        if (scroll.scrollTop >= scroll.scrollHeight - scroll.clientHeight) {
          scroll.scrollTop = 0;
        }
      }
      if (autoscrollRef.current) {
        requestAnimationFrame(animate);
      }
    }
    if (props.autoscroll) {
      requestAnimationFrame(animate);
    }
  }, [props.autoscroll]);

  const isNotMobile = useMediaQuery("@media (min-width:600px)");
  const theme = useTheme();

  const ExpandIcon = isNotMobile ? ChevronLeft : KeyboardArrowUp;
  const MinimizeIcon = isNotMobile ? ChevronRight : KeyboardArrowDown;

  return (
    <>
      <div
        css={{
          backgroundColor: theme.palette.header.main,
          borderTopLeftRadius: "30px",
          borderBottomLeftRadius: isNotMobile ? "30px" : undefined,
          borderTopRightRadius: isNotMobile ? undefined : "30px",
          position: "fixed",
          right: isNotMobile ? "0px" : undefined,
          top: isNotMobile ? (props.hasTopOffset ? "65px" : "0px") : undefined,
          left: isNotMobile ? undefined : "0",
          bottom: isNotMobile ? undefined : "0",
          width: "65px",
          height: "65px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 750,
          cursor: "pointer",
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        {!props.open && (
          <IconButton
            title={i18next.t("view-floorplan-actions")}
            onClick={() => props.setOpen(true, true)}
            size="large"
          >
            <ExpandIcon
              fontSize="large"
              css={{ color: theme.palette.header.contrastText }}
            />
          </IconButton>
        )}
      </div>
      <Drawer
        anchor={isNotMobile ? "right" : "bottom"}
        open={props.open}
        variant="persistent"
        PaperProps={{ elevation: 16 }}
        css={{
          ".MuiDrawer-paper": {
            backgroundColor: "#f9f9f9",
            overflowY: "visible",
            zIndex: 1000,
          },
          ".MuiDrawer-paperAnchorDockedBottom": {
            borderTop: "none",
          },
          ".MuiDrawer-paperAnchorDockedRight": {
            borderLeft: "none",
          },
        }}
      >
        <div
          style={{
            width: props.width,
            backgroundColor: "#f9f9f9",
            maxHeight: isNotMobile ? undefined : "350px",
            position: "relative",
          }}
        >
          {isNotMobile && props.hasTopOffset && (
            <div style={{ height: "65px" }} />
          )}
          <div
            style={{
              zIndex: 1001,
              maxWidth: `calc(${props.width} + 30px)`,
              left: isNotMobile ? "-30px" : undefined,
              display: "flex",
              position: "absolute",
              width: "calc(100% + 30px)",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              height: "65px",
              backgroundColor: theme.palette.header.main,
              color: theme.palette.header.contrastText,
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              cursor: "default",
              boxShadow:
                isNotMobile && props.open
                  ? "-3px 0px 4px -1px rgba(0,0,0,0.3)"
                  : undefined,
              borderTopLeftRadius: isNotMobile ? "30px" : undefined,
              borderBottomLeftRadius: isNotMobile ? "30px" : undefined,
            }}
          >
            <IconButton
              size="small"
              id="seat-info-panel"
              title={t("minimize")}
              css={{
                position: "absolute",
                left: isNotMobile ? "20px" : "15px",
              }}
              onClick={() => {
                const selectedSomething = !!(
                  props.selectedSeat || props.selectedRoom
                );
                props.setOpen(selectedSomething, selectedSomething);
              }}
            >
              {props.selectedSeat || props.selectedRoom ? (
                <Home
                  fontSize="large"
                  css={{
                    color: theme.palette.header.contrastText,
                  }}
                />
              ) : (
                <MinimizeIcon
                  fontSize="large"
                  css={{
                    color: theme.palette.header.contrastText,
                  }}
                />
              )}
            </IconButton>
            <h2
              style={{
                textAlign: "center",
                margin: "15px 40px 15px 60px",
              }}
            >
              {props.selectedSeat?.name ??
                props.selectedRoom?.name ??
                (props.hasActions
                  ? t("your-office-visit")
                  : props.floorplan.name)}
            </h2>
          </div>
          <div style={{ height: "65px" }} />
          {props.loadingAction && (
            <LinearProgress css={{ width: props.width }} />
          )}
          <div
            ref={scrollRef}
            css={{
              overflow: "auto",
              cursor: "default",
              height: isNotMobile
                ? props.hasTopOffset
                  ? "calc(100vh - 130px)"
                  : "calc(100vh - 65px)"
                : "calc(350px - 65px)",
            }}
          >
            <div
              css={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                paddingBottom: "1rem",
              }}
            >
              {props.children}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

function RoomsOnlyAutobook(props: {
  loadingAction: boolean;
  floorplan: FloorplanInfo;
  userInfo: UserInfo;
  actions: Actions;
}) {
  const { t } = useTranslation();
  return (
    <div
      css={{
        marginBottom: "2rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <DomainLogo domain={props.userInfo.domain} />
      <div css={{ margin: "25px", textAlign: "center" }}>
        {t("sidebar-instructions-rooms-only")}
      </div>
      <Button
        variant="contained"
        color="secondary"
        disabled={props.loadingAction}
        onClick={() => props.actions.onBookMeeting()}
        css={{ marginBottom: "1rem" }}
      >
        {t("schedule-a-meeting")}
      </Button>
      <CustomSidebarInstructions userInfo={props.userInfo} />
    </div>
  );
}

function RoomsOnlySidepanel(props: {
  open: boolean;
  setOpen: (b: boolean, clear?: boolean) => void;
  width: string;
  selectedRoom?: RoomInfo;
  selectedRoomEvents?: RoomEvent[];
  floorplan: FloorplanInfo;
  userInfo: UserInfo;
  loadingAction: boolean;
  actions: Actions;
  timeRange: TimeInterval;
}) {
  return (
    <SidePanelDrawer {...props} hasTopOffset={true} hasActions={true}>
      {props.selectedRoom ? (
        <RoomContent
          selectedRoom={props.selectedRoom}
          selectedRoomEvents={props.selectedRoomEvents ?? []}
          floorplan={props.floorplan}
          loadingAction={props.loadingAction}
          actions={props.actions}
          userInfo={props.userInfo}
          timeRange={props.timeRange}
        />
      ) : (
        <RoomsOnlyAutobook
          floorplan={props.floorplan}
          userInfo={props.userInfo}
          actions={props.actions}
          loadingAction={props.loadingAction}
        />
      )}
    </SidePanelDrawer>
  );
}

export function ReadonlySidePanel(props: {
  open: boolean;
  setOpen: (b: boolean, clear?: boolean) => void;
  width: string;
  selectedSeat?: SeatInfo;
  selectedRoom?: RoomInfo;
  selectedRoomEvents?: RoomEvent[];
  floorplan: FloorplanInfo;
  actions: LimitedActions;
  autoscroll: boolean;
}) {
  return (
    <SidePanelDrawer
      {...props}
      hasTopOffset={false}
      hasActions={false}
      loadingAction={false}
    >
      {props.selectedSeat?.imgURL != undefined && props.selectedSeat.enabled && (
        <div style={{ width: "100%" }}>
          <img
            alt="" // TODO(a11y) Add alt text for desk images in DB!
            src={props.selectedSeat.imgURL}
            className="hide-on-mobile"
            style={{ width: "100%" }}
          />
        </div>
      )}
      {props.selectedSeat && props.selectedSeat.enabled ? (
        <>
          <div style={{ width: "100%" }}>
            <div css={{ margin: "25px", whiteSpace: "pre-line" }}>
              {props.selectedSeat.description}
            </div>
          </div>
          <ReadonlySeatContent
            resourceNoun={resourceName(props.selectedSeat.resourceType)}
            floorplan={props.floorplan}
            selectedSeat={props.selectedSeat}
          />
        </>
      ) : props.selectedRoom ? (
        <ReadonlyRoomContent
          selectedRoom={props.selectedRoom}
          selectedRoomEvents={props.selectedRoomEvents ?? []}
          floorplan={props.floorplan}
        />
      ) : (
        <WhosInTab
          floorplan={props.floorplan}
          timeRange={TimeInterval.allDay}
          actions={props.actions}
        />
      )}
    </SidePanelDrawer>
  );
}

export function SidePanel(props: {
  open: boolean;
  setOpen: (b: boolean, clear?: boolean) => void;
  width: string;
  loadingAction: boolean;
  selectedSeat?: SeatInfo;
  selectedTab?: string;
  setSelectedTab: (tab: string) => void;
  selectedRoom?: RoomInfo;
  selectedRoomEvents?: RoomEvent[];
  userInfo: UserInfo;
  floorplan: FloorplanInfo;
  actions: Actions;
  bookableInfo: BookableInfo;
  timeRange: TimeInterval;
  defaultTimeRange: TimeInterval;
}) {
  const { t } = useTranslation();
  const isNotMobile = useMediaQuery("@media (min-width:600px)");

  const relativeDate = calcRelativeDate(
    props.floorplan.today,
    props.floorplan.date
  );
  const isToday = relativeDate == RelativeDate.Today;

  if (
    props.floorplan.seats.every((s) => !s.enabled) &&
    props.floorplan.rooms.some((r) => r.bookable) &&
    !props.floorplan.rooms.some(
      (r) => r.name.startsWith("Desk ") || r.name.startsWith("Hot Desk ")
    )
  ) {
    return <RoomsOnlySidepanel {...props} />;
  }

  return (
    <SidePanelDrawer {...props} hasTopOffset={true} hasActions={true}>
      {props.selectedSeat?.imgURL != undefined && props.selectedSeat.enabled && (
        <div style={{ width: "100%" }}>
          <img
            alt="" // TODO(a11y) Add alt text for desk images in DB!
            src={props.selectedSeat.imgURL}
            className="hide-on-mobile"
            style={{ width: "100%" }}
          />
        </div>
      )}
      {props.floorplan.autoReleasedSeat && (
        <Alert
          severity="warning"
          css={isNotMobile ? { margin: "1rem", marginBottom: "1rem" } : {}}
        >
          {t("note-a-booking-on-this-day-automatically-released")}
        </Alert>
      )}
      {props.userInfo.domain.showOverdueWarning && (
        <Alert
          severity="error"
          css={isNotMobile ? { margin: "1rem", marginBottom: "1rem" } : {}}
        >
          {t("payment-overdue-warning", {
            companyName: props.userInfo.domain.name,
          })}
        </Alert>
      )}
      {props.selectedSeat && !props.selectedSeat.enabled && (
        <Alert
          severity="info"
          css={{ margin: "2rem 5rem", textAlign: "center" }}
        >
          {t("unbookable-resource")}
        </Alert>
      )}
      {props.selectedSeat && props.selectedSeat.enabled ? (
        <>
          <div style={{ width: "100%" }}>
            <div css={{ margin: "25px", whiteSpace: "pre-line" }}>
              {props.selectedSeat.description}
            </div>
          </div>
          <SeatContent
            loadingAction={props.loadingAction}
            resourceNoun={resourceName(props.selectedSeat.resourceType)}
            userInfo={props.userInfo}
            floorplan={props.floorplan}
            actions={props.actions}
            bookableInfo={props.bookableInfo}
            selectedSeat={props.selectedSeat}
            timeRange={props.timeRange}
            defaultTimeRange={props.defaultTimeRange}
            relativeDate={relativeDate}
          />
        </>
      ) : props.selectedRoom ? (
        <RoomContent
          userInfo={props.userInfo}
          timeRange={props.timeRange}
          selectedRoom={props.selectedRoom}
          selectedRoomEvents={props.selectedRoomEvents ?? []}
          loadingAction={props.loadingAction}
          floorplan={props.floorplan}
          actions={props.actions}
        />
      ) : (
        !props.selectedSeat && (
          <TabbedAutobookContent
            loadingAction={props.loadingAction}
            resourceNoun={resourceName(props.floorplan.defaultResourceType)}
            userInfo={props.userInfo}
            isToday={isToday}
            floorplan={props.floorplan}
            actions={props.actions}
            bookableInfo={props.bookableInfo}
            timeRange={props.timeRange}
            defaultTimeRange={props.defaultTimeRange}
            selectedTab={props.selectedTab}
            setSelectedTab={props.setSelectedTab}
          />
        )
      )}
    </SidePanelDrawer>
  );
}

function CustomSidebarInstructions(props: { userInfo: UserInfo }) {
  if (props.userInfo.domain.name == "amexgbt.com") {
    return (
      <div css={{ margin: "25px", textAlign: "center" }}>
        To find more information on our offices, orientation guides and
        emergency action plans please{" "}
        <a
          href="https://lounge.amexgbt.com/sites/finance-lounge/real-estate-facilities-physical-security/SitePageModern/374235/global-locations"
          target="_blank"
          rel="noreferrer"
        >
          click here
        </a>
      </div>
    );
  }
  return <></>;
}
