import UserSearch from "./userSearch";
import { Button, CircularProgress } from "@mui/material";
import { Alert } from "@mui/material";

import { resourceName } from "./constants";
import type { FloorplanInfo, SeatInfo, UserInfo } from "./types";
import { useTranslation } from "react-i18next";
import { SidePanelDrawer, DomainLogo } from "./sidePanel";

function HomeTab(props: {
  userInfo: UserInfo;
  floorplan: FloorplanInfo;
  loadingAction: boolean;
  makeBooking?: () => void;
  cancelBooking: () => void;
}) {
  const { t } = useTranslation();

  return (
    <>
      <div css={{ marginBottom: "2rem" }}>
        <DomainLogo domain={props.userInfo.domain} />
        <div css={{ margin: "25px", textAlign: "center" }}>
          {t("group-book-instructions-p1")}
        </div>

        <div css={{ margin: "25px", textAlign: "center" }}>
          {t("group-book-instructions-p2")}
        </div>

        <div css={{ margin: "25px", textAlign: "center" }}>
          {t("group-book-instructions-p3")}
        </div>
      </div>
      <Button
        variant="contained"
        color="secondary"
        disabled={props.loadingAction || !props.makeBooking}
        onClick={props.makeBooking}
        css={{ marginBottom: "1rem" }}
      >
        {props.loadingAction && (
          <CircularProgress size="1rem" css={{ marginRight: "1rem" }} />
        )}
        {t("group-booking-complete")}
      </Button>
      <Button
        variant="contained"
        color="secondary"
        disabled={props.loadingAction}
        onClick={props.cancelBooking}
        css={{ marginBottom: "1rem" }}
      >
        {t("cancel")}
      </Button>
    </>
  );
}

type GroupBookUser = {
  name: string;
  email: string;
  src?: string;
};

function SeatContent(props: {
  userInfo: UserInfo;
  floorplan: FloorplanInfo;
  resourceNoun: string;
  selectedSeat: SeatInfo;
  selectedUser?: GroupBookUser;
  done: () => void;
  setSelectedUser: (user: GroupBookUser | undefined) => void;
}) {
  const { t } = useTranslation();

  return props.selectedSeat.enabled ? (
    <div css={{ margin: "25px", width: "80%", textAlign: "center" }}>
      <h3
        css={{
          fontWeight: "bold",
          fontSize: "150%",
          margin: "0.5rem",
          textAlign: "center",
        }}
      >
        {t("assign-resource-to-user", { resourceNoun: props.resourceNoun })}
      </h3>
      <UserSearch
        fullWidth
        autoComplete
        blurOnSelect
        selection={props.selectedUser ?? null}
        onSelection={(result) => props.setSelectedUser(result ?? undefined)}
      />
      <Button
        style={{ marginTop: "2rem" }}
        variant="contained"
        color="secondary"
        onClick={props.done}
        css={{ marginBottom: "1rem" }}
      >
        {t("done")}
      </Button>
    </div>
  ) : (
    <Alert severity="info" css={{ margin: "2rem 5rem", textAlign: "center" }}>
      {t("resource-not-available", { resourceNoun: props.resourceNoun })}
    </Alert>
  );
}

export function GroupBookSidePanel(props: {
  userInfo: UserInfo;
  width: string;
  floorplan: FloorplanInfo;
  selectedSeat?: SeatInfo;
  open: boolean;
  setOpen: (b: boolean, clear?: boolean) => void;
  selectedUser?: GroupBookUser;
  loadingAction: boolean;
  setSelectedUser: (user: GroupBookUser | undefined) => void;
  makeBooking?: () => void;
  cancelBooking: () => void;
}) {
  return (
    <SidePanelDrawer
      width={props.width}
      floorplan={props.floorplan}
      open={props.open}
      setOpen={props.setOpen}
      hasTopOffset={true}
      hasActions={true}
      loadingAction={props.loadingAction}
      selectedSeat={props.selectedSeat}
    >
      {props.selectedSeat && props.selectedSeat?.imgURL != undefined && (
        <div style={{ width: "100%" }}>
          <img
            alt=""
            src={props.selectedSeat.imgURL}
            className="hide-on-mobile"
            style={{ width: "100%" }}
          />
        </div>
      )}
      {props.selectedSeat ? (
        <>
          <div style={{ width: "100%" }}>
            <div css={{ margin: "25px", whiteSpace: "pre-line" }}>
              {props.selectedSeat.description}
            </div>
          </div>
          <SeatContent
            userInfo={props.userInfo}
            resourceNoun={resourceName(props.selectedSeat.resourceType)}
            floorplan={props.floorplan}
            selectedSeat={props.selectedSeat}
            selectedUser={props.selectedUser}
            done={() => props.setOpen(true, true)}
            setSelectedUser={props.setSelectedUser}
          />
        </>
      ) : (
        <HomeTab
          userInfo={props.userInfo}
          loadingAction={props.loadingAction}
          floorplan={props.floorplan}
          makeBooking={props.makeBooking}
          cancelBooking={props.cancelBooking}
        />
      )}
    </SidePanelDrawer>
  );
}
