import React from "react";
import { useAPIMutation, useAPIQuery } from "./hooks";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  IconButton,
  CircularProgress,
  LinearProgress,
  Alert,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";

import ValidatedTextField, { validateEmail } from "./validatedTextField";

import { KINDS } from "./conciergeRegisterVisitPage";
import type { DateString } from "zynq-shared";
import type { Visit } from "zynq-shared";
import { CalendarDate } from "zynq-shared";
import LocationPicker from "./locationPicker";
import type { ISODateString, UserInfo } from "./types";
import { DateTime, Duration } from "luxon";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

type VisitorFloorplan = { name: string; floorplanID: number; timezone: string };

type VisitorEntry = {
  name: string;
  email: string;
  startDate: CalendarDate;
  endDate: CalendarDate;
  floorplan: VisitorFloorplan;
  reason: typeof KINDS[number]["name"];
  start: string;
};

type Visit = {
  id: number;
  name: string;
  email?: string;
  hostName?: string;
  hostEmail?: string;
  reason: string;
  floorplan: VisitorFloorplan;
  date: DateString<"yyyy-MM-dd">;
  start: DateTime;
};

function createEmptyVisit(
  today: CalendarDate,
  floorplan: VisitorFloorplan,
  defaultStartTime: string
) {
  return {
    name: "",
    email: "",
    reason: "OTHER",
    floorplan: floorplan,
    startDate: today.plus({ days: 1 }),
    endDate: today.plus({ days: 1 }),
    start: defaultStartTime,
  } as const;
}

export default function ScheduledVisitorDialog(props: {
  today: CalendarDate;
  isOpen: boolean;
  floorplan: VisitorFloorplan;
  userInfo: UserInfo;
  onClose: () => void;
  defaultStartTime: string;
}) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [newEntry, setNewEntry] = React.useState<VisitorEntry>(() =>
    createEmptyVisit(props.today, props.floorplan, props.defaultStartTime)
  );

  const multiday = !newEntry.startDate.hasSame(newEntry.endDate, "day");

  const cellStyle = {
    padding: "0.5rem",
    textAlign: "center",
  } as const;

  const scheduledVisitorsQuery = useAPIQuery<typeof Visit.GetVisits>(
    "/apis/visit/get-visits",
    {
      allUsers: false,
      includePast: false,
      filterDenied: true,
    }
  );
  const pastVisitorsQuery = useAPIQuery<typeof Visit.GetPastVisitors>(
    "/apis/visit/get-past-visitors",
    {}
  );
  const deleteMutation = useAPIMutation<typeof Visit.Delete>(
    "/apis/visit/delete-visit",
    {
      onError(e) {
        setErrorMessage(e.reason);
      },
      onSettled() {
        scheduledVisitorsQuery.refetch();
      },
    }
  );

  const createMutation = useAPIMutation<typeof Visit.Create>(
    "/apis/visit/create-visit",
    {
      onError(e) {
        setErrorMessage(e.reason);
      },
      onSuccess(res) {
        scheduledVisitorsQuery.refetch();
        const newVisit = res.visit;
        if (!newVisit) {
          return setErrorMessage(i18next.t("failed-to-schedule-visit"));
        }
        setNewEntry(() =>
          createEmptyVisit(props.today, props.floorplan, props.defaultStartTime)
        );
      },
    }
  );

  React.useEffect(() => {
    if (props.isOpen) {
      setNewEntry(
        createEmptyVisit(props.today, props.floorplan, props.defaultStartTime)
      );
      setErrorMessage(undefined);
    }
  }, [props.isOpen, props.today.toISODate()]);

  function createVisit(visit: VisitorEntry) {
    const dur = Duration.fromISOTime(visit.start);
    createMutation.mutate({
      name: visit.name,
      email: visit.email,
      reason: visit.reason,
      floorplanID: visit.floorplan.floorplanID,
      startDate: visit.startDate.toISODate() as ISODateString,
      endDate: visit.endDate.toISODate() as ISODateString,
      start: visit.startDate
        .toDateTime({ zone: visit.floorplan.timezone })
        .set({ hour: dur.hours, minute: dur.minutes })
        .toISO(),
      bypassWarnings: createMutation.data?.warning != undefined,
    });
  }

  return (
    <Dialog
      onClose={props.onClose}
      open={props.isOpen}
      scroll="paper"
      fullWidth={true}
      maxWidth={"sm"}
    >
      {(scheduledVisitorsQuery.isFetching ||
        createMutation.isLoading ||
        deleteMutation.isLoading) && <LinearProgress />}
      <DialogContent css={{ textAlign: "center" }}>
        {errorMessage && (
          <Alert
            severity="error"
            style={{
              margin: "1rem",
              whiteSpace: "pre-line",
            }}
          >
            {errorMessage}
          </Alert>
        )}
        <h2>{t("schedule-visitor")}</h2>
        <div
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            css={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              ".MuiFormControl-root": {
                width: "unset",
                flexGrow: 1,
              },
            }}
          >
            <DatePicker<DateTime>
              label={multiday ? t("start-date") : t("visit-date")}
              views={["day"]}
              value={newEntry.startDate.date}
              onChange={(v) => {
                if (v != null) {
                  setNewEntry({
                    ...newEntry,
                    startDate: CalendarDate.fromDateTime(v),
                  });
                }
              }}
              minDate={props.today.date}
              maxDate={props.today.plus({ days: 50 }).date}
              renderInput={(params) => (
                <TextField {...params} fullWidth={true} />
              )}
            />
            <FormControlLabel
              checked={multiday}
              css={{
                textAlign: "center",
                ".MuiCheckbox-root": { padding: 0 },
              }}
              onChange={() =>
                newEntry &&
                (multiday
                  ? setNewEntry({
                      ...newEntry,
                      endDate: newEntry.startDate,
                    })
                  : setNewEntry({
                      ...newEntry,
                      endDate: newEntry.startDate.plus({ days: 1 }),
                    }))
              }
              control={<Checkbox />}
              label={t("multiday")}
              labelPlacement="top"
            />
          </div>
          {multiday && (
            <DatePicker<DateTime>
              label={t("end-date")}
              views={["day"]}
              value={newEntry.endDate.date}
              onChange={(v) => {
                if (v != null) {
                  setNewEntry({
                    ...newEntry,
                    endDate: CalendarDate.fromDateTime(v),
                  });
                }
              }}
              minDate={newEntry.startDate.date}
              maxDate={props.today.plus({ days: 50 }).date}
              renderInput={(params) => (
                <TextField {...params} fullWidth={true} />
              )}
            />
          )}
          <TextField
            fullWidth={true}
            css={{ marginTop: "1.5rem" }}
            label={t("arrival-time")}
            value={newEntry.start}
            InputProps={{ inputProps: { pattern: "[0-9]{2}:[0-9]{2}" } }}
            onChange={(v) => {
              setNewEntry({ ...newEntry, start: v.target.value });
            }}
            type="time"
          />
          <Autocomplete
            fullWidth={true}
            popupIcon={null}
            noOptionsText={null}
            freeSolo={true}
            autoComplete={true}
            filterOptions={(opts) =>
              opts.filter((o) =>
                o.name.toLowerCase().includes(newEntry.name.toLowerCase())
              )
            }
            renderInput={(props) => (
              <TextField {...props} label={t("visitor-name")} margin="normal" />
            )}
            getOptionLabel={(user) => user.name}
            renderOption={(props, user) => (
              <li {...props}>
                {user.email ? `${user.name} (${user.email})` : user.name}
              </li>
            )}
            onChange={function (_, user) {
              if (typeof user != "string") {
                setNewEntry({ ...newEntry, ...user });
              }
            }}
            options={pastVisitorsQuery.data?.visitors ?? []}
            onInputChange={(e, v, reason) => {
              reason != "reset" && setNewEntry({ ...newEntry, name: v });
            }}
            inputValue={newEntry.name}
          />
          <Autocomplete
            fullWidth={true}
            popupIcon={null}
            noOptionsText={null}
            freeSolo={true}
            disableClearable={true}
            autoHighlight={true}
            autoComplete={true}
            filterOptions={(opts) =>
              opts.filter((o) =>
                o.email.toLowerCase().includes(newEntry.email.toLowerCase())
              )
            }
            renderInput={(props) => (
              <ValidatedTextField
                {...props}
                value={newEntry.email}
                margin="normal"
                label={t("visitor-email")}
                validator={(email) => {
                  if (!email) return undefined;
                  return email.trim() == "" || validateEmail(email)
                    ? undefined
                    : t("error-invalid-email");
                }}
              />
            )}
            getOptionLabel={(user) => user.email}
            renderOption={(props, user) => (
              <li {...props}>{`${user.email} (${user.name})`}</li>
            )}
            onChange={function (_, user) {
              if (typeof user != "string") {
                setNewEntry({ ...newEntry, ...user });
              }
            }}
            options={
              (pastVisitorsQuery.data?.visitors.filter((v) => !!v.email) ??
                []) as { name: string; email: string }[]
            }
            onInputChange={(e, v, reason) => {
              reason != "reset" && setNewEntry({ ...newEntry, email: v });
            }}
            inputValue={newEntry.email}
          />
          {props.userInfo.domain.name != "deciem.com" && (
            <LocationPicker
              allowRegion={false}
              selected={{
                isRegion: false,
                name: newEntry.floorplan.name,
                id: newEntry.floorplan.floorplanID,
                userVisible: true,
              }}
              onChange={(location) => {
                if (location != null) {
                  setNewEntry({
                    ...newEntry,
                    floorplan: {
                      name: location.name,
                      floorplanID: location.id,
                      timezone: location.timezone,
                    },
                  });
                }
              }}
              css={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            />
          )}
          <ToggleButtonGroup
            css={{ marginBottom: "0.75rem", marginTop: "0.5rem" }}
            value={newEntry.reason}
            exclusive
            onChange={(_e, v) => v && setNewEntry({ ...newEntry, reason: v })}
            aria-label="text alignment"
          >
            {KINDS.map((v) => (
              <ToggleButton key={v.name} value={v.name} aria-label={v.name}>
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    width: "5.5rem",
                  }}
                >
                  <div>{v.icon}</div>
                  <div>{t(v.name)}</div>
                </div>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          {createMutation.data?.warning && (
            <Alert severity="warning">
              {t("warning-with-msg", { msg: createMutation.data.warning })}
            </Alert>
          )}
          <Button
            css={{ width: "12rem" }}
            variant="contained"
            color="primary"
            disabled={
              newEntry.email.length == 0 ||
              newEntry.name.length == 0 ||
              createMutation.isLoading ||
              deleteMutation.isLoading ||
              scheduledVisitorsQuery.isLoading
            }
            onClick={() => createVisit(newEntry)}
          >
            {t("schedule-verb")}
          </Button>
        </div>
        <h2>{t("my-upcoming-visitors")}</h2>
        {scheduledVisitorsQuery.isLoading == undefined && (
          <div css={{ padding: "1rem" }}>
            <CircularProgress />
          </div>
        )}
        {scheduledVisitorsQuery.data &&
          scheduledVisitorsQuery.data.visits.length === 0 && (
            <div css={{ padding: "1rem" }}>
              <i>{t("you-have-no-upcoming-scheduled-visitors")}</i>
            </div>
          )}
        {!!scheduledVisitorsQuery.data?.visits.length && (
          <table
            data-test-id={"scheduled-visitors-table"}
            style={{
              borderCollapse: "collapse",
              boxSizing: "border-box",
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th style={{ padding: "0.5rem", textAlign: "left" }}>
                  {t("name")}
                </th>
                <th style={cellStyle}>{t("location-abbreviation")}</th>
                <th style={cellStyle}>{t("date")}</th>
                <th style={cellStyle}>{t("cancel")}</th>
              </tr>
            </thead>
            <tbody>
              {scheduledVisitorsQuery.data.visits.map((sv, i) => (
                <tr key={sv.email + sv.date + i}>
                  <td>
                    {sv.name}
                    {sv.email ? (
                      <span
                        css={{
                          "@media (max-width: 600px)": {
                            display: "none",
                          },
                        }}
                      >
                        {" "}
                        ({sv.email})
                      </span>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>{`${sv.floorplan.name}`} </td>
                  <td css={{ textAlign: "center" }}>
                    {DateTime.fromISO(sv.start, {
                      zone: sv.floorplan.timezone,
                    }).toLocaleString(DateTime.DATETIME_SHORT)}
                  </td>
                  <td css={{ textAlign: "center" }}>
                    <IconButton
                      onClick={() => deleteMutation.mutate({ id: sv.id })}
                      disabled={
                        deleteMutation.isLoading ||
                        scheduledVisitorsQuery.isFetching
                      }
                      size="large"
                      css={{
                        color: "#d90000",
                        ":disabled": { color: "#ebadae" },
                      }}
                    >
                      <CancelIcon titleAccess={t("cancel")} />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} variant="contained">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
