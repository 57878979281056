import React from "react";

import { TextField, Autocomplete } from "@mui/material";
import type {
  TextFieldProps,
  AutocompleteProps,
  AutocompleteValue,
} from "@mui/material";
import type { Patch } from "zynq-shared";
import { removeField } from "zynq-shared";

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase().trim());
}

export default function ValidatedTextField(
  props: Patch<
    TextFieldProps,
    { value: string; validator: (v: string) => string | undefined }
  >
) {
  const [error, setError] = React.useState<string>();
  React.useEffect(() => {
    if (props.value == "") {
      setError(undefined);
    } else if (error) {
      props.value && setError(props.validator(props.value));
    }
  }, [props.value]);

  return (
    <TextField
      {...removeField(props, "validator")}
      onBlur={() => props.value && setError(props.validator(props.value))}
      error={!!error}
      helperText={error ? error : props.helperText}
    />
  );
}

export function ValidatedAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: Patch<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    {
      validator: (
        v: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo> | string
      ) => string | undefined;
    }
  >
) {
  const [error, setError] = React.useState<string>();
  React.useEffect(() => {
    if (!props.value) {
      setError(undefined);
    }
  }, [props.value]);

  return (
    <Autocomplete
      {...removeField(props, "validator")}
      onChange={(e, v, r) => {
        if (error) {
          console.log("");
          setError(props.validator(v));
        }
        props.onChange?.(e, v, r);
      }}
      onInputChange={(e, v, r) => {
        if (error) {
          console.log("");
          setError(props.validator(v));
        }
        props.onInputChange?.(e, v, r);
      }}
      onBlur={() =>
        setError(
          (props.value ?? props.inputValue) &&
            props.validator(props.value ?? props.inputValue!)
        )
      }
      renderInput={(p) =>
        props.renderInput({
          ...p,
          ...({
            error: !!error,
            helperText: error ? error : undefined,
          } as any), // Sneak in these props
        })
      }
    />
  );
}
