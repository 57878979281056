import React, { useEffect, useRef, useState } from "react";
import { FormGroup, MenuItem, Select } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { USA_DECIEM_FLOORPLAN_NAMES, ZYNQ_DEMO_DOMAINS } from "./constants";
import type { VACCINATION_STATUS } from "./constants";
import type { Dictionary } from "zynq-shared";
import { useLocalStorage } from "./hooks";
import { objectFromEntries } from "zynq-shared";
import { Alert } from "@mui/material";
import i18next from "i18next";

function LanguagePicker(props: {
  title: string;
  currentLanguage: string;
  setCurrentLanguage: (l: string) => void;
  allLanguages: string[];
}) {
  if (props.allLanguages.length <= 1) {
    return null;
  }
  return (
    <div css={{ textAlign: "end" }}>
      {props.title}:
      <Select
        css={{ margin: "0.5rem auto", marginLeft: "1rem" }}
        value={props.currentLanguage}
        onChange={(e) => {
          props.setCurrentLanguage(e.target.value as string);
        }}
      >
        {props.allLanguages.map((lang) => (
          <MenuItem value={lang} key={lang}>
            {lang}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

const formCSS = {
  "& > * + *": {
    marginTop: "0.5rem !important",
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "left",
} as const;

let labelCount = 0;

type Option = string | undefined | [string, any];

function optionLabel(o: Option): string | undefined {
  return Array.isArray(o) ? o[0] : o;
}

function optionValue(o: Option): any | undefined {
  return Array.isArray(o) ? o[1] : undefined;
}
function CheckboxQuestion({
  text,
  extraDataName,
  onChange,
  category,
  options = ["Yes", "No"],
  ignore = false,
  tight = false,
}: {
  text: JSX.Element | string;
  onChange: (state: boolean | null) => void;
  category?: string;
  options?: [Option, Option] | [Option, Option, Option];
  tight?: boolean;
  ignore?: boolean;
  extraDataName?: string;
}) {
  const [state, setState] = useState<null | boolean>(null);
  const [labelName] = useState(() => "question-" + labelCount++);
  const toggleButtonRef = useRef<HTMLElement>(null);

  useEffect(() => onChange(state), [state]);

  return (
    <>
      <label htmlFor={labelName}> {text} </label>
      <ToggleButtonGroup
        ref={toggleButtonRef}
        css={{
          alignSelf: "center",
          paddingTop: "0.5rem",
          paddingBottom: tight ? "1rem" : "2rem",
        }}
        className={
          ignore
            ? "ignoredQuestionToggle"
            : extraDataName
            ? "questionnaireExtraDataToggle"
            : "questionToggle"
        }
        data-name={extraDataName}
        data-category={category}
        data-state={state == null ? "unset" : state}
        {...{ name: labelName }}
        value={state}
        exclusive
        onChange={(e, value) => {
          if (value != null) {
            setState(value);
          }
          toggleButtonRef.current?.nextElementSibling?.scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        {options[0] && (
          <ToggleButton
            value={optionValue(options[0]) ?? true}
            aria-label={optionLabel(options[0])}
          >
            {optionLabel(options[0])}
          </ToggleButton>
        )}
        {options[1] && (
          <ToggleButton
            value={optionValue(options[1]) ?? false}
            aria-label={optionLabel(options[1])}
          >
            {optionLabel(options[1])}
          </ToggleButton>
        )}
        {options[2] && (
          <ToggleButton
            value={optionValue(options[2]) ?? "other"}
            aria-label={optionLabel(options[2])}
          >
            {optionLabel(options[2])}
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </>
  );
}

export const initialState = {
  ready: false,
  valid: false,
  extraData: {} as Dictionary<string, any>,
};

// TODO(i18n): Add more languages.

export default function Questionnaire<T extends typeof initialState>(props: {
  state: T;
  setState: (s: T) => void;
  setTitle?: (language: string) => void;
  domainName: string;
  floorplanName: string;
  userVaccinationStatus: VACCINATION_STATUS;
}) {
  const [viewingPdf, setViewingPdf] = useState<{
    title?: string;
    url?: string;
  }>({});
  const form = React.createRef<HTMLElement>();
  const [isVaccinated, setIsVaccinated] = React.useState<boolean | null>(null);
  const [isVaccinationReported, setIsVaccinationReported] = React.useState<
    boolean | null
  >(null);
  const allLanguages =
    props.domainName == "deciem.com" &&
    USA_DECIEM_FLOORPLAN_NAMES.includes(props.floorplanName)
      ? ["English", "Español"]
      : props.domainName == "lcieducation.com" ||
        props.domainName == "transat.com"
      ? ["Français", "English"]
      : ["English"];
  const defaultLanguage =
    i18next.language.split("-")[0] == "fr" && allLanguages.includes("Français")
      ? "Français"
      : i18next.language.split("-")[0] == "en" &&
        allLanguages.includes("English")
      ? "English"
      : i18next.language.split("-")[0] == "es" &&
        allLanguages.includes("Español")
      ? "Español"
      : allLanguages[0];
  let [currentLanguage, localSetCurrentLanguage] = useLocalStorage(
    "questionnaireLanguage",
    defaultLanguage
  );
  const updateTitle = (language: string) => {
    if (props.setTitle) {
      if (language == "Français") {
        props.setTitle("Travail au bureau?");
      } else {
        props.setTitle(
          props.domainName == "deciem.com"
            ? "Before you arrive at a DECIEM Facility"
            : "Coming in?"
        );
      }
    }
  };
  const setCurrentLanguage = (language: string) => {
    if (currentLanguage != language) {
      updateTitle(language);
    }
    localSetCurrentLanguage(language);
  };
  React.useEffect(() => {
    updateTitle(currentLanguage);
  }, []);
  currentLanguage = allLanguages.includes(currentLanguage)
    ? currentLanguage
    : allLanguages[0];

  const correctAnswerState =
    props.domainName == "24hrcares.com" ? "true" : "false";

  const onChange = () => {
    const questionToggles = Array.from(
      form.current?.getElementsByClassName("questionToggle") ?? []
    );
    const ignoredQuestionToggles = Array.from(
      form.current?.getElementsByClassName("ignoredQuestionToggle") ?? []
    );
    const questionnaireExtraDataToggles = Array.from(
      form.current?.getElementsByClassName("questionnaireExtraDataToggle") ?? []
    );

    const extraData = objectFromEntries(
      questionnaireExtraDataToggles
        .filter((e): e is HTMLElement => e instanceof HTMLElement)
        .map((e: HTMLElement) => [e.dataset.name!, e.dataset.state])
    );

    extraData["categories"] = questionToggles
      .filter((e): e is HTMLElement => e instanceof HTMLElement)
      .map((e: HTMLElement) =>
        e.dataset.state !== correctAnswerState ? e.dataset.category : undefined
      )
      .filter((e) => e != undefined)
      .filter((v, i, arr) => arr.indexOf(v) === i) // unique
      .join(",");

    props.setState({
      ...props.state,
      extraData,
      valid: questionToggles
        .filter((e): e is HTMLElement => e instanceof HTMLElement)

        .map((e: HTMLElement) => e.dataset.state === correctAnswerState)
        .every((e) => e),
      ready: [
        ...questionToggles,
        ...ignoredQuestionToggles,
        ...questionnaireExtraDataToggles,
      ]
        .filter((e): e is HTMLElement => e instanceof HTMLElement)
        .map((e: HTMLElement) => e.dataset.state !== "unset")
        .every((e) => e),
    });
  };

  React.useEffect(() => onChange(), [isVaccinated]);

  if (props.domainName == "sunbeltrentals.com") {
    return (
      <FormGroup ref={form} css={formCSS}>
        <CheckboxQuestion
          onChange={onChange}
          text={
            <>
              To your knowledge, have you experienced any of the following
              symptoms of COVID-19 in the past 14 days?
              <ul
                css={{
                  paddingInlineStart: "20px",
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                }}
              >
                {[
                  "Fever or chills",
                  "Cough",
                  "Shortness of breath",
                  "Fatigue",
                  "Muscle or body aches",
                  "Headache",
                  "New loss of taste or smell",
                  "Sore throat",
                  "Congestion or runny nose",
                  "Nausea or vomiting",
                  "Diarrhea",
                ].map((s, i) => (
                  <li key={i} css={{ margin: "0.25rem", marginLeft: "1rem" }}>
                    {s}
                  </li>
                ))}
              </ul>
            </>
          }
        />
        <CheckboxQuestion
          onChange={onChange}
          text={
            "Please acknowledge that if you are experiencing any of the above-listed symptoms, you MUST reach out to your manager prior to entering the building or customer job site."
          }
          options={[undefined, "Acknowledge"]}
        />
        <p>
          Please note: The Self-Screening process is not meant to be a medical
          diagnostic tool.
        </p>
      </FormGroup>
    );
  } else if (props.domainName == "sgintl.com") {
    return (
      <FormGroup ref={form} css={formCSS}>
        <CheckboxQuestion
          onChange={onChange}
          text={
            <>
              In the last 5 days, have you received a confirmed positive
              diagnosis for coronavirus (COVID-19) by a coronavirus (COVID-19)
              test or from a diagnosis by a health care professional or are you
              waiting for a pending COVID-19 test result?
            </>
          }
        />
        <CheckboxQuestion
          onChange={onChange}
          text={
            <>
              In the last 5 days, have you Traveled internationally and been
              told to quarantine (in accordance with government requirements)?
            </>
          }
        />
        <CheckboxQuestion
          onChange={onChange}
          text={
            <>
              In the last 5 days, have you had close contact (within 6 feet over
              a prolonged period of time) with or cared for someone diagnosed
              with COVID-19 or are you participating in a COVID-19 clinical
              study that includes being exposed to the virus?
            </>
          }
        />
        <CheckboxQuestion
          onChange={onChange}
          text={
            <>
              Have you experienced any cold or flu-like symptoms in the last 14
              days including fever, cough, shortness of breath or difficulty
              breathing, sore throat, pressure in the chest, extreme fatigue,
              earache, persistent headache, diarrhea, vomiting, muscle pain,
              chills, repeated shaking with chills, and persistent loss of smell
              or taste?
              <br />
              <br />
              <i>
                Note: Answer “No” if you have been evaluated by a healthcare
                provider and have been released to return to work or you have
                had a negative COVID-19 test within 14 days after the onset of
                symptoms.
              </i>
            </>
          }
        />
        <CheckboxQuestion
          onChange={onChange}
          text={
            <>
              Has a doctor, health care provider, or public health unit told you
              that you should currently be isolating due to exposure or possible
              exposure to COVID-19?
            </>
          }
        />
        <CheckboxQuestion
          onChange={onChange}
          text={
            <>
              Do any of the following apply:
              <ul
                css={{
                  paddingInlineStart: "20px",
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                }}
              >
                <li>
                  Do you live with someone who is currently isolating because of
                  a positive COVID-19 test
                </li>
                <li>
                  You live with someone who is currently isolating because of
                  COVID-19 symptoms
                </li>
                <li>
                  You live with someone who is waiting for COVID-19 test results{" "}
                </li>
              </ul>
            </>
          }
        />
        <Alert
          css={{
            fontSize: "80%",
            opacity: "75%",
            fontStyle: "italic",
            alignItems: "center",
            margin: "1rem",
          }}
          severity="info"
        >
          <p>
            If your answer to any of the above questions is “yes”, inform your
            Safe-Guard Host that you will not be visiting the facility. You are
            not required to provide to your Safe-Guard Host the reason that you
            will not be visiting.
          </p>
          <p>
            If your answer to each of the above questions is “no”, review the
            Safe-Guard’s Visitor Screening Form 48 hours prior to the actual
            visit. If your answer to any question changes from “no” to “yes”
            before or during your visit, do not enter the facility or
            immediately depart the facility and inform your Safe-Guard Host of
            your action.
          </p>
        </Alert>
        Safe-Guard Hosts are accountable for ensuring visitors follow all
        Safe-Guard COVID-19 Health and Safety protocols. Please reference
        Safe-Guard’s Office Protocols for Visitors.
      </FormGroup>
    );
  } else if (props.domainName == "transat.com") {
    if (currentLanguage == "English") {
      return (
        <FormGroup ref={form} css={formCSS}>
          <LanguagePicker
            title="Language"
            currentLanguage={currentLanguage}
            setCurrentLanguage={setCurrentLanguage}
            allLanguages={allLanguages}
          />
          <p>
            <a
              href="https://transat.service-now.com/sp?id=take_survey&type_id=75ecd085878e3410892dca2acebb3598"
              target="_blank"
              rel="noreferrer"
            >
              This health screener
            </a>{" "}
            must be completed in order to come into the office.
          </p>
          <CheckboxQuestion
            onChange={onChange}
            text="Have you completed the health screener on the above link?"
            options={["No", "Yes"]}
          />
        </FormGroup>
      );
    } else {
      if (currentLanguage != "Français")
        console.error("Unknown language in Transat", currentLanguage);
      return (
        <FormGroup ref={form} css={formCSS}>
          <LanguagePicker
            title="Langue"
            currentLanguage={currentLanguage}
            setCurrentLanguage={setCurrentLanguage}
            allLanguages={allLanguages}
          />
          <p>
            <a
              href="https://transat.service-now.com/sp?id=take_survey&type_id=75ecd085878e3410892dca2acebb3598"
              target="_blank"
              rel="noreferrer"
            >
              Ce questionnaire de santé
            </a>{" "}
            doit être complété pour pouvoir venir au bureau.
          </p>
          <CheckboxQuestion
            onChange={onChange}
            text="Avez-vous rempli le questionnaire de santé
            sur le lien ci-dessus?"
            options={["NON", "OUI"]}
          />
        </FormGroup>
      );
    }
  } else if (props.domainName == "lcieducation.com") {
    if (currentLanguage == "English") {
      return (
        <FormGroup ref={form} css={formCSS}>
          <LanguagePicker
            title="Language"
            currentLanguage={currentLanguage}
            setCurrentLanguage={setCurrentLanguage}
            allLanguages={allLanguages}
          />
          <CheckboxQuestion
            onChange={onChange}
            text="Have you tested positive for COVID-19 in the past 14 days?"
          />
          <CheckboxQuestion
            onChange={onChange}
            text={
              <>
                Have you experienced <b>ANY</b> of the following symptoms in the
                past 14 days?
                <ul
                  css={{
                    paddingInlineStart: "20px",
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                  }}
                >
                  <li>
                    Fever (oral temperature of 38.1 °C [100.6 °F] or more)
                  </li>
                  <li>
                    Sudden loss of smell without nasal congestion (blocked
                    nose), with or without loss of taste
                  </li>
                  <li>Recent cough or worsening of a chronic cough</li>
                  <li>Shortness of breath</li>
                  <li>Difficulty breathing</li>
                  <li>Sore throat</li>
                  <li>
                    Do you have a runny or stuffy nose for unknown reason?
                  </li>
                </ul>
              </>
            }
          />
          <CheckboxQuestion
            onChange={onChange}
            text={
              <>
                Have you experienced at least <b>TWO</b> of the following
                symptoms in the past 14 days?
                <ul
                  css={{
                    paddingInlineStart: "20px",
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                  }}
                >
                  <li>Stomach ache</li>
                  <li>Nausea (queasiness) or vomiting</li>
                  <li>Diarrhea</li>
                  <li>Great fatigue for no obvious reason</li>
                  <li>Significant loss of appetite</li>
                  <li>
                    Generalized muscle pain (not related to physical effort)
                  </li>
                  <li>Unusual headache</li>
                </ul>
              </>
            }
          />
          <CheckboxQuestion
            onChange={onChange}
            text="In the past 14 days, have you been in close contact with anyone who has tested positive for COVID-19 or who would have answered yes to one of the previous questions?"
          />
        </FormGroup>
      );
    } else {
      if (currentLanguage != "Français")
        console.error("Unknown language in LCI", currentLanguage);

      return (
        <FormGroup ref={form} css={formCSS}>
          <LanguagePicker
            title="Langue"
            currentLanguage={currentLanguage}
            setCurrentLanguage={setCurrentLanguage}
            allLanguages={allLanguages}
          />

          <CheckboxQuestion
            onChange={onChange}
            text="Avez-vous reçu un résultat positif à un test COVID-19 au cours de 14 derniers jours?"
            options={["OUI", "NON"]}
          />
          <CheckboxQuestion
            onChange={onChange}
            text={
              <>
                Avez-vous ressenti <b>UN</b> des symptômes suivants au cours des
                14 derniers jours?
                <ul
                  css={{
                    paddingInlineStart: "20px",
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                  }}
                >
                  <li>
                    Fièvre (température buccale de 38,1 °C [100,6 °F] et plus)
                  </li>
                  <li>
                    Perte soudaine de l’odorat sans congestion nasale (nez
                    bouché), avec ou sans perte de goût
                  </li>
                  <li>Toux récente ou aggravation d’une toux chronique</li>
                  <li>Essoufflement</li>
                  <li>Difficulté à respirer</li>
                  <li>Mal de gorge</li>
                  <li>
                    Avez-vous le nez qui coule ou une congestion nasale (nez
                    bouché) de cause inconnue ?
                  </li>
                </ul>
              </>
            }
            options={["OUI", "NON"]}
          />
          <CheckboxQuestion
            onChange={onChange}
            text={
              <>
                Avez-vous ressenti au moins <b>DEUX</b> des symptômes suivants
                au cours des 14 derniers jours ?
                <ul
                  css={{
                    paddingInlineStart: "20px",
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                  }}
                >
                  <li>Maux de ventre</li>
                  <li>Nausées (maux de cœur) ou vomissements</li>
                  <li>Diarrhées</li>
                  <li>Grande fatigue sans raison évidente</li>
                  <li>Perte d’appétit importante</li>
                  <li>
                    Douleurs musculaires généralisées (non liées à un effort
                    physique)
                  </li>
                  <li>Mal de tête inhabituel</li>
                </ul>
              </>
            }
            options={["OUI", "NON"]}
          />
          <CheckboxQuestion
            onChange={onChange}
            text="Au cours des 14 derniers jours, avez-vous été en contact avec une personne qui a reçu un
            résultat positif à un test COVID-19 ou qui aurait répondu oui à une des questions de
            précédentes au sujet des symptômes?"
            options={["OUI", "NON"]}
          />
        </FormGroup>
      );
    }
  } else if (props.domainName == "deciem.com") {
    const isUSA = USA_DECIEM_FLOORPLAN_NAMES.includes(props.floorplanName);

    if (isUSA && currentLanguage == "Español") {
      return (
        <FormGroup ref={form} css={formCSS}>
          {isUSA && (
            <LanguagePicker
              title="Language"
              currentLanguage={currentLanguage}
              setCurrentLanguage={setCurrentLanguage}
              allLanguages={allLanguages}
            />
          )}
          Al visitar la oficina, reconozco que no tengo fiebre ni ningún otro
          signo o síntoma de COVID-19.
        </FormGroup>
      );
    }

    return (
      <FormGroup ref={form} css={formCSS}>
        {isUSA && (
          <LanguagePicker
            title="Language"
            currentLanguage={currentLanguage}
            setCurrentLanguage={setCurrentLanguage}
            allLanguages={allLanguages}
          />
        )}
        By going into the office, I acknowledge that I do not have a fever or
        any other signs and symptoms of COVID-19.
      </FormGroup>
    );
  } else if (
    props.domainName == "sgsco.com" &&
    ["Louisville", "New York"].includes(props.floorplanName)
  ) {
    return (
      <FormGroup ref={form} css={formCSS}>
        <p>
          Employees (including temporary workers) are required to self-monitor
          for COVID-19 symptoms prior to arrival at the workplace and throughout
          the workday. Employees who feel unwell or have COVID-19 symptoms are
          asked to immediately leave the premises and contact their health care
          provider, then follow-up with their supervisor and/or HR Advisor.
        </p>
        <p>
          Your participation is important to help us take precautionary measures
          to protect you and everyone in this building. Please read each
          statement carefully and select the appropriate answer. When in doubt
          please exercise caution and contact your supervisor and/or HR Advisor
          for additional guidance.
        </p>
        <p css={{ backgroundColor: "#bababa", padding: "3px" }}>
          If you <b>ARE</b> fully vaccinated and <b>ALL</b> of the following
          statements are <b>TRUE</b>. Access to this facility is{" "}
          <b css={{ color: "green" }}>APPROVED</b>
        </p>
        <ul
          css={{
            paddingInlineStart: "20px",
            marginBlockStart: 0,
            marginBlockEnd: 0,
          }}
        >
          <li>
            You have not experienced any new or worsening{" "}
            <a
              href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html"
              target="_blank"
              rel="noreferrer"
            >
              symptoms
            </a>{" "}
            in the past 48 hours; and
          </li>
          <li>
            You are not currently isolating or quarantining because you have
            tested positive for COVID-19 or are worried that you may be sick
            with COVID-19.
          </li>
        </ul>
        <p css={{ textAlign: "center", margin: "1em" }}>
          <b>-- OR --</b>
        </p>
        <p css={{ backgroundColor: "#bababa", padding: "3px" }}>
          If you <b>ARE NOT</b> fully vaccinated and <b>ALL</b> of the following
          statements are <b>TRUE</b>. Access to this facility is{" "}
          <b css={{ color: "green" }}>APPROVED</b>
        </p>
        <ul
          css={{
            paddingInlineStart: "20px",
            marginBlockStart: 0,
            marginBlockEnd: 0,
          }}
        >
          <li>
            You have not experienced any new or worsening{" "}
            <a
              href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html"
              target="_blank"
              rel="noreferrer"
            >
              symptoms
            </a>{" "}
            in the past 48 hours; and
          </li>
          <li>
            You are not currently isolating or quarantining because you have
            tested positive for COVID-19 or are worried that you may be sick
            with COVID-19; and
          </li>
          <li>
            You have not been in close physical contact in the last 14 days with
            anyone who is known to have laboratory-confirmed COVID-19 OR anyone
            who has any symptoms consistent with COVID-19; and
          </li>
          <li>
            You are not currently waiting on the results of a COVID-19 test
            (excluding pre-travel or post-travel COVID-19 test); and
          </li>
          <li>You have not travelled internationally in the past 10 days.</li>
        </ul>
        <p css={{ backgroundColor: "#bababa", padding: "3px" }}>
          If{" "}
          <b>
            <u>ANY</u>
          </b>{" "}
          of the above statements are <b>FALSE</b>. Access to this facility
          is&nbsp;
          <b css={{ color: "red" }}>NOT APPROVED</b>.
        </p>
        <p>Here are instructions for what to do next.</p>
        <ol
          css={{
            paddingInlineStart: "20px",
            marginBlockStart: 0,
            marginBlockEnd: 0,
          }}
        >
          <li>
            If you are not already at home, please avoid contact with others and
            go straight home immediately;
          </li>
          <li>
            Call your primary care provider for further instructions, including
            information about COVID-19 testing;
          </li>
          <li>
            Contact your supervisor and/or HR Advisor to discuss options for
            remote work and/or leave.{" "}
          </li>
        </ol>
        <p>Thank you for helping us protect you and others during this time.</p>
      </FormGroup>
    );
  } else if (
    props.domainName == "sgsco.com" &&
    ["Tamworth Ground", "Tamworth 1F"].includes(props.floorplanName)
  ) {
    return (
      <FormGroup ref={form} css={formCSS}>
        <p>
          Employees (including temporary workers) are required to self-monitor
          for COVID-19 symptoms prior to arrival at the workplace and throughout
          the workday. Employees who feel unwell or have COVID-19 symptoms are
          asked to immediately leave the premises, avoiding people if possible,
          and reducing the amount of contact with surfaces etc. Employees, at
          the earliest opportunity, should contact their health care
          provider/country health service for information about COVID-19 testing
          etc. then follow-up with their supervisor and/or HR Advisor.
        </p>
        <p>
          Your participation is important to help us take precautionary measures
          to protect you and everyone in this building. Please read each
          statement carefully and select the appropriate answer. When in doubt
          please exercise caution and contact your supervisor and/or HR Advisor
          for additional guidance.
        </p>
        <p css={{ backgroundColor: "#bababa", padding: "3px" }}>
          If you <b>ARE</b> fully vaccinated and <b>ALL</b> of the following
          statements are <b>TRUE</b>. Access to this facility is{" "}
          <b css={{ color: "green" }}>APPROVED</b>
        </p>
        <ul
          css={{
            paddingInlineStart: "20px",
            marginBlockStart: 0,
            marginBlockEnd: 0,
          }}
        >
          <li>
            You have not experienced any new or worsening{" "}
            <a
              href="https://www.ecdc.europa.eu/en/covid-19/latest-evidence/clinical"
              target="_blank"
              rel="noreferrer"
            >
              symptoms
            </a>{" "}
            in the past 48 hours; and
          </li>
          <li>
            You are not currently isolating or quarantining because you have
            tested positive for COVID-19 or are worried that you may be sick
            with COVID-19.
          </li>
        </ul>
        <p css={{ textAlign: "center", margin: "1em" }}>
          <b>-- OR --</b>
        </p>
        <p css={{ backgroundColor: "#bababa", padding: "3px" }}>
          If you <b>ARE NOT</b> fully vaccinated and <b>ALL</b> of the following
          statements are <b>TRUE</b>. Access to this facility is{" "}
          <b css={{ color: "green" }}>APPROVED</b>
        </p>
        <ul
          css={{
            paddingInlineStart: "20px",
            marginBlockStart: 0,
            marginBlockEnd: 0,
          }}
        >
          <li>
            You have not experienced any new or worsening{" "}
            <a
              href="https://www.ecdc.europa.eu/en/covid-19/latest-evidence/clinical"
              target="_blank"
              rel="noreferrer"
            >
              symptoms
            </a>{" "}
            in the past 48 hours; and
          </li>
          <li>
            You are not currently isolating or quarantining because you have
            tested positive for COVID-19 or are worried that you may be sick
            with COVID-19; and
          </li>
          <li>
            You have not been in close physical contact in the last 14 days with
            anyone who is known to have laboratory-confirmed COVID-19 OR anyone
            who has any symptoms consistent with COVID-19; and
          </li>
          <li>
            You are not currently waiting on the results of a COVID-19 test
            (excluding pre-travel or post-travel COVID-19 test); and
          </li>
          <li>You have not travelled internationally in the past 10 days.</li>
        </ul>
        <p css={{ backgroundColor: "#bababa", padding: "3px" }}>
          If{" "}
          <b>
            <u>ANY</u>
          </b>{" "}
          of the above statements are <b>FALSE</b>. Access to this facility
          is&nbsp;
          <b css={{ color: "red" }}>NOT APPROVED</b>.
        </p>
        <p>Here are instructions for what to do next.</p>
        <ol
          css={{
            paddingInlineStart: "20px",
            marginBlockStart: 0,
            marginBlockEnd: 0,
          }}
        >
          <li>
            If you are not already at home, please avoid contact with others and
            go straight home immediately;
          </li>
          <li>
            Call your primary care provider for further instructions, including
            information about COVID-19 testing;
          </li>
          <li>
            Call your primary care provider/ country health service for further
            instructions, including information about COVID-19 testing;{" "}
          </li>
        </ol>
        <p>Thank you for helping us protect you and others during this time.</p>
      </FormGroup>
    );
  } else if (props.domainName == "sobi.com") {
    return (
      <FormGroup ref={form} css={formCSS}>
        <CheckboxQuestion
          onChange={onChange}
          text={
            "In the past 14 days have you tested positive for Covid-19 or been instructed by NHS to isolate due to exposure to Covid-19?"
          }
        />
      </FormGroup>
    );
  } else if (props.domainName == "24hrcares.com") {
    // The 24hr Cares answers are flipped.
    return (
      <FormGroup ref={form} css={formCSS}>
        <CheckboxQuestion
          onChange={(s) => {
            setIsVaccinated(s);
            onChange();
          }}
          ignore
          text={
            "I have received my full dose of a COVID-19 Vaccine (including boosters)"
          }
        />
        {isVaccinated === true && (
          <CheckboxQuestion
            onChange={(s) => {
              setIsVaccinationReported(s);
              onChange();
            }}
            ignore
            text={
              "Have you reported your vaccinated status to the Safety Team?"
            }
          />
        )}
        {isVaccinated !== null && isVaccinationReported !== null && (
          <>
            <CheckboxQuestion
              onChange={onChange}
              options={["I Agree", "I Disagree"]}
              text={
                "No one in my household has tested positive for COVID-19 in the last 48 hours."
              }
            />
            {isVaccinated === true && (
              <CheckboxQuestion
                onChange={onChange}
                options={["I Agree", "I Disagree"]}
                text={
                  isVaccinationReported
                    ? "I understand that wearing a face mask while indoors is optional"
                    : "I understand that I must wear a face mask while indoors at any branch office. To report your vaccination status email Safety@24hrcares.com"
                }
              />
            )}
            {isVaccinated === false && (
              <>
                <CheckboxQuestion
                  onChange={onChange}
                  options={["I Agree", "I Disagree"]}
                  text={
                    "I am not currently experiencing any symptoms of illness."
                  }
                />
                <CheckboxQuestion
                  onChange={onChange}
                  options={["I Agree", "I Disagree"]}
                  text={
                    "I understand that I must wear a face mask while indoors at any branch office, due to not being vaccinated"
                  }
                />
              </>
            )}
            <CheckboxQuestion
              onChange={onChange}
              options={["I Agree", "I Disagree"]}
              text={
                "If, at any time while in the office, I exhibit fever and/or symptoms, I will immediately leave the office, notify my manager, and Safety Team at safety@24hrcares.com."
              }
            />
          </>
        )}
      </FormGroup>
    );
  } else if (props.domainName == "unionstationhs.org") {
    return (
      <FormGroup ref={form} css={formCSS}>
        <CheckboxQuestion
          onChange={onChange}
          text={"Have you tested positive for covid in the last 10 days?"}
        />
        <CheckboxQuestion
          onChange={onChange}
          text={"Do you have any covid related symptoms?"}
        />
      </FormGroup>
    );
  } else if (ZYNQ_DEMO_DOMAINS.includes(props.domainName)) {
    return (
      <FormGroup ref={form} css={formCSS}>
        <CheckboxQuestion
          onChange={onChange}
          text={"Have you tested positive for COVID-19 in the past 14 days?"}
        />
        <CheckboxQuestion
          onChange={onChange}
          text={
            <>
              To your knowledge, have you experienced any of the following
              symptoms of COVID-19 in the past 14 days?
              <ul
                css={{
                  paddingInlineStart: "20px",
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                }}
              >
                <li>Fever of more than 100° F</li>
                <li>Shortness of breath or chest tightness</li>
                <li>Difficulty breathing</li>
              </ul>
            </>
          }
        />
        <CheckboxQuestion
          onChange={onChange}
          options={["No", "Yes"]}
          text={
            <>
              To attend the office, you must be fully vaccinated. Have you
              uploaded your proof of full vaccination{" "}
              <a
                target="_blank"
                rel="noreferrer"
                css={{ fontWeight: "bold" }}
                href="https://zynq.io"
              >
                HERE
              </a>
              ?
            </>
          }
        />
      </FormGroup>
    );
  } else {
    return (
      <FormGroup ref={form} css={formCSS}>
        <CheckboxQuestion
          onChange={onChange}
          text={"Have you tested positive for COVID-19 in the past 14 days?"}
        />
        <CheckboxQuestion
          onChange={onChange}
          text={
            <>
              To your knowledge, have you experienced any of the following
              symptoms of COVID-19 in the past 14 days?
              <ul
                css={{
                  paddingInlineStart: "20px",
                  marginBlockStart: 0,
                  marginBlockEnd: 0,
                }}
              >
                <li>Fever of more than 100° F</li>
                <li>Shortness of breath or chest tightness</li>
                <li>Difficulty breathing</li>
              </ul>
            </>
          }
        />
        <CheckboxQuestion
          onChange={onChange}
          text="In the past 14 days, have you been in close contact with anyone who has tested positive for COVID-19 or who has experienced any of the above symptoms of COVID-19?"
        />
      </FormGroup>
    );
  }
}
