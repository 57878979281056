import React from "react";
import { Button } from "@mui/material";
import Avatar from "./avatar";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Collapse from "@mui/material/Collapse";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import constants from "./constants";
import { useAPIRequest, useLoginRedirect } from "./hooks";
import type { Domain, UserInfo } from "./types";
import { useTranslation } from "react-i18next";
import { resetMixpanel } from "./tracker";

function isUserInfo(u: { domain: Domain } | UserInfo): u is UserInfo {
  return (u as any).avatar != undefined;
}

function BasicHeader(props: {
  userInfo: { domain: Domain } | UserInfo;
  title?: string;
  colorOverride?: string;
}) {
  const { t } = useTranslation();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement>();
  const theme = useTheme();
  const loginRedirect = useLoginRedirect();
  const { post } = useAPIRequest();

  const onLogout = () => {
    resetMixpanel();
    if (isUserInfo(props.userInfo) ? !props.userInfo.isTestUser : false) {
      post("/seating/api/logout").promise.then((_) => loginRedirect(false));
    } else {
      window.location.href = "/secret-zynq-test/stop";
    }
  };

  if (props.title === undefined) {
    return (
      <div
        css={{
          width: "100%",
          backgroundColor: props.colorOverride ?? theme.palette.header.main,
          color: theme.palette.header.contrastText,
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          alignItems: "center",
          display: "grid",
          gridTemplateColumns: "[first] 20% auto [last] 20%",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
          }}
        >
          <img
            css={{
              marginLeft: "15px",
              maxHeight: `calc(${constants.headerHeight} - 10px)`,
              maxWidth: "80%",
            }}
            src={props.userInfo.domain.logoInvertedSrc}
            alt="Logo"
          />
        </div>
      </div>
    );
  }

  if (!isUserInfo(props.userInfo)) {
    return (
      <div
        css={{
          width: "100%",
          backgroundColor: props.colorOverride ?? theme.palette.header.main,
          color: theme.palette.header.contrastText,
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          alignItems: "center",
          display: "grid",
          gridTemplateColumns: "[first] 20% auto [last] 20%",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
          }}
        >
          <img
            css={{
              marginLeft: "15px",
              maxHeight: `calc(${constants.headerHeight} - 10px)`,
              maxWidth: "80%",
            }}
            src={props.userInfo.domain.logoInvertedSrc}
            alt="Logo"
          />
        </div>
        <div
          css={{
            textAlign: "center",
            whiteSpace: "nowrap",
            fontSize: "200%",
            padding: "1rem",
            overflow: "hidden",
            userSelect: "none",
            "@media (max-width: 600px)": {
              fontSize: "150%",
              fontWeight: "bold",
            },
          }}
        >
          {props.title}
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        css={{
          width: "100%",
          backgroundColor: props.colorOverride ?? theme.palette.header.main,
          color: theme.palette.header.contrastText,
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          "@media (max-width: 600px)": {
            display: "flex",
            justifyContent: "space-between",
          },
          alignItems: "center",
          display: "grid",
          gridTemplateColumns: "[first] 20% auto [last] 20%",
        }}
      >
        <div
          css={{
            display: "flex",
            alignItems: "center",
            width: "130px",
            "@media (max-width: 600px)": {
              display: "none",
            },
          }}
        >
          <img
            css={{
              marginLeft: "15px",
              maxHeight: `calc(${constants.headerHeight} - 10px)`,
              maxWidth: "max(13rem, 35vw)",
            }}
            src={props.userInfo.domain.logoInvertedSrc}
            alt="Logo"
          />
        </div>
        <div
          css={{
            textAlign: "center",
            whiteSpace: "nowrap",
            fontSize: "200%",
            padding: "1rem",
            overflow: "hidden",
            userSelect: "none",
            "@media (max-width: 600px)": {
              fontSize: "150%",
              fontWeight: "bold",
            },
          }}
        >
          {props.title}
        </div>
        <Button
          css={{
            margin: "0px 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={(e) => {
            setMenuAnchorEl(e.currentTarget.firstChild as HTMLElement);
          }}
        >
          <Avatar css={{ margin: "15px 5px" }} avatar={props.userInfo.avatar} />
          <div
            css={{
              "@media (max-width: 600px)": {
                display: "none",
              },
              margin: "15px 5px",
              fontSize: "1rem",
              textTransform: "none",
              letterSpacing: "initial",
              color: theme.palette.header.contrastText,
            }}
            className="header-avatar-name"
          >
            {props.userInfo.avatar.name}
          </div>
        </Button>
      </div>
      <Menu
        anchorEl={menuAnchorEl ?? null}
        keepMounted
        open={!!menuAnchorEl}
        onClose={() => setMenuAnchorEl(undefined)}
        marginThreshold={0}
        TransitionComponent={Collapse}
        style={{
          marginTop: -(menuAnchorEl?.clientHeight ?? 0) - 7,
        }}
      >
        <MenuItem>
          <Avatar css={{ margin: "15px 5px" }} avatar={props.userInfo.avatar} />
          <div
            css={{
              margin: "15px 5px",
            }}
            className="header-avatar-name"
          >
            {props.userInfo.avatar.name}
          </div>
        </MenuItem>
        {props.userInfo.isAdmin ? (
          <MenuItem
            component={Link}
            to={"/admin"}
            onClick={() => {
              setMenuAnchorEl(undefined);
            }}
          >
            {t("admin-panel")}
          </MenuItem>
        ) : null}
        {props.userInfo.isSuperAdmin || props.userInfo.isStaff ? (
          <MenuItem
            component={"a"}
            href={"/secret-zynq-admin"}
            onClick={() => {
              window.location.replace("/secret-zynq-admin");
              setMenuAnchorEl(undefined);
            }}
          >
            {t("superadmin-panel")}
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={() => {
            onLogout();
            setMenuAnchorEl(undefined);
          }}
        >
          {t("logout")}
        </MenuItem>
      </Menu>
    </>
  );
}

export default BasicHeader;
