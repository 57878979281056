import { Interval, Duration } from "luxon";
import { DateTime } from "luxon";
import { RelativeDate } from "./types";
import { CalendarDate } from "zynq-shared";
import type { TimeRange } from "./types";
import type { InvalidLuxonObject, TimeInterval } from "zynq-shared";
import i18next from "i18next";

export function toTimeRange(start: DateTime, end: DateTime): TimeRange;
export function toTimeRange(i: Interval): TimeRange;
export function toTimeRange(
  intOrStart: Interval | DateTime,
  end?: DateTime
): TimeRange {
  const i =
    intOrStart instanceof Interval
      ? intOrStart
      : Interval.fromDateTimes(intOrStart, end!);
  if (!i.isValid) {
    console.error(
      `Invalid interval in toTimeRange: ${i.invalidReason!}: ${i.invalidExplanation!}`,
      i
    );
  }
  return {
    start: i.start.toFormat("HH:mm", { locale: "en-US" }),
    end: i.end.toFormat("HH:mm", { locale: "en-US" }),
    nightShift: i.start.startOf("day") < i.end.startOf("day"),
  };
}

export function toTimeString(d: DateTime): string {
  if (!d.isValid) {
    console.error(
      `Invalid DateTime in toTimeString: ${d.invalidReason!}: ${d.invalidExplanation!}`,
      d
    );
  }
  return d.toFormat("HH:mm", { locale: "en-US" });
}

export function timeRangeToInterval(tr: TimeRange, base: DateTime) {
  const startOfBase = base.startOf("day");
  return Interval.fromDateTimes(
    startOfBase.plus(Duration.fromISOTime(tr.start)),
    startOfBase.plus(
      Duration.fromISOTime(tr.end).plus({ days: tr.nightShift ? 1 : 0 })
    )
  );
}

export function timeIntervalToString(
  i: TimeInterval | InvalidLuxonObject<TimeInterval>
) {
  if (!i.isValid) {
    console.error(
      `Trying to format invalid TimeInterval: ${i.invalidReason}, ${i.invalidExplanation}`
    );
    return "";
  }

  if (i.isAllDay()) {
    return i18next.t("all-day");
  }
  return `${i.start.toLocaleString(DateTime.TIME_SIMPLE)}${
    i.start.days < 0 ? "*" : ""
  } - ${i.end.toLocaleString(DateTime.TIME_SIMPLE)}${
    i.end.days > 0 ? "*" : ""
  }`;
}

export function durationMax(d1: Duration, d2: Duration) {
  return d1 > d2 ? d1 : d2;
}

export function durationMin(d1: Duration, d2: Duration) {
  return d1 < d2 ? d1 : d2;
}

export function isWeekend(dt: DateTime | CalendarDate) {
  return dt.weekday == 6 || dt.weekday == 7;
}

export function weeksSinceBaseDate(dt: CalendarDate) {
  return Math.floor(dt.diff(CalendarDate.zero.startOf("week"), "weeks").weeks);
}

export function calcRelativeDate(today: CalendarDate, date: CalendarDate) {
  if (today.valueOf() == date.valueOf()) {
    return RelativeDate.Today;
  } else if (today.valueOf() < date.valueOf()) {
    return RelativeDate.Future;
  } else {
    return RelativeDate.Past;
  }
}
