import React from "react";
import type { CSSObject } from "@emotion/react";
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

import AdminLoader from "../admin/adminLoader";

import ErrorGuard from "../admin/errorGuard";

import {
  AppBar,
  Avatar,
  Button,
  Collapse,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Settings as SettingsIcon,
  Block as BlockIcon,
  KeyboardArrowLeft,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import General from "./preferencesGeneral";
import Block from "./preferencesBlock";

import { useAPIRequest, useLoginRedirect } from "../hooks";
import type { UserInfo } from "src/types";
import { useTranslation } from "react-i18next";
import { resetMixpanel } from "../tracker";

const DRAWER_WIDTH = 240;

function Header(props: {
  toggleNav: () => void;
  name: string;
  logoPath: string;
  userInfo: UserInfo;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const loginRedirect = useLoginRedirect();
  const api = useAPIRequest();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<Element | null>(null);
  const avatar = props.userInfo.avatar;

  return (
    <>
      <AppBar
        color="default"
        position="fixed"
        css={{
          zIndex: theme.zIndex.drawer + 1,
          maxHeight: "75px",
        }}
      >
        <Toolbar>
          <Hidden mdDown>
            <Button
              startIcon={<KeyboardArrowLeft />}
              css={{ marginRight: "1rem" }}
              onClick={() => {
                history.goBack();
              }}
            >
              {t("back-btn-txt")}
            </Button>
          </Hidden>

          <Hidden mdUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={props.toggleNav}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Typography
            variant="h6"
            css={{
              flex: "0 1 auto",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {t("preferences")}
          </Typography>
          <Button
            css={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(e) => {
              setMenuAnchorEl(e.currentTarget.firstChild as Element);
            }}
          >
            <Avatar
              css={{ margin: "15px 5px" }}
              alt={avatar.name}
              src={avatar.src}
            >
              {avatar.name[0] || ""}
            </Avatar>
            <div
              css={{
                "@media (max-width: 600px)": {
                  display: "none",
                },
                margin: "15px 5px",
                fontSize: "1rem",
                textTransform: "none",
                letterSpacing: "initial",
                color: theme.palette.header.contrastText,
              }}
              className="header-avatar-name"
            >
              {avatar.name}
            </div>
          </Button>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={!!menuAnchorEl}
        onClose={() => setMenuAnchorEl(null)}
        marginThreshold={0}
        TransitionComponent={Collapse}
        style={{
          marginTop: -(menuAnchorEl?.clientHeight ?? 0) - 20,
        }}
      >
        <MenuItem>
          <Avatar
            css={{ margin: "0px 5px" }}
            alt={avatar.name}
            src={avatar.src}
          >
            {avatar.name[0] || ""}
          </Avatar>
          <div
            css={{
              margin: "15px 5px",
            }}
            className="header-avatar-name"
          >
            {avatar.name}
          </div>
        </MenuItem>
        <MenuItem
          component={Link}
          to={"/seating/floorplan"}
          onClick={() => {
            setMenuAnchorEl(null);
          }}
        >
          {t("home")}
        </MenuItem>
        {props.userInfo.isSuperAdmin || props.userInfo.isStaff ? (
          <MenuItem
            component={"a"}
            href={"/secret-zynq-admin"}
            onClick={() => {
              setMenuAnchorEl(null);
            }}
          >
            {t("superadmin-panel")}
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={() => {
            resetMixpanel();
            if (!props.userInfo.isTestUser) {
              api
                .post("/seating/api/logout")
                .promise.then((_) => loginRedirect(false));
            } else {
              window.location.href = "/secret-zynq-test/stop";
            }
            setMenuAnchorEl(null);
          }}
        >
          {t("logout")}
        </MenuItem>
      </Menu>
    </>
  );
}

function NavBar(props: {
  selected: string;
  userInfo: UserInfo;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const nonMobile = useMediaQuery(theme.breakpoints.up("md"));
  const navItems: {
    label: string;
    icon: React.ReactElement;
    slug: string;
  }[] = [];
  function addIfEnabled(
    enabled: boolean,
    label: string,
    icon: React.ReactElement,
    slug: string
  ) {
    enabled && navItems.push({ label, icon, slug });
  }

  navItems.push({
    label: "General",
    slug: "general",
    icon: <SettingsIcon />,
  });
  navItems.push({
    label: "Block",
    slug: "block",
    icon: <BlockIcon />,
  });

  return (
    <Drawer
      variant={nonMobile ? "permanent" : "temporary"}
      anchor="left"
      open={props.open}
      onClose={() => props.setOpen(false)}
      css={{
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
        },
        width: DRAWER_WIDTH,
        zIndex: !nonMobile ? theme.zIndex.drawer + 2 : undefined,
      }}
    >
      <Hidden mdDown>
        <div
          // @ts-ignore ...
          css={{ ...theme.mixins.toolbar, height: "75px" }}
        />
      </Hidden>
      <List>
        <Hidden mdUp>
          <ListItem
            button
            css={{ marginRight: "1rem" }}
            onClick={() => {
              props.setOpen(false);
              history.goBack();
            }}
          >
            <ListItemIcon>
              <KeyboardArrowLeft />
            </ListItemIcon>

            <ListItemText style={{ fontSize: "x-large" }}>
              {t("back-btn-txt")}
            </ListItemText>
          </ListItem>
        </Hidden>

        {navItems.map(({ label, icon: Icon, slug }) => {
          return (
            <ListItem
              button
              component={Link}
              to={"/preferences/" + slug}
              replace
              onClick={() => props.setOpen(false)}
              key={label}
              selected={slug == props.selected}
              style={{ fontSize: "x-large" }}
            >
              <ListItemIcon>{Icon}</ListItemIcon>

              <ListItemText style={{ fontSize: "x-large" }}>
                {label}
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
}

const DEFAULT_PAGE = "/preferences/general";

function IfEnabled({
  enabled,
  children,
}: {
  enabled: boolean;
  children: JSX.Element;
}) {
  return enabled ? children : <Redirect to={"/preferences/"} />;
}

export default function Preferences(props: {
  userInfo: UserInfo;
  reloadUserInfo: () => void;
}) {
  const theme = useTheme();
  const [navOpen, setNavOpen] = React.useState(false);

  const pageIdent = useRouteMatch<{ page?: string }>({
    path: "/preferences/:page",
    strict: true,
  })?.params?.page;

  // if (domainInfo.error) {
  //   return (
  //     <Alert severity="error" style={{ margin: "1rem 0" }}>
  //       {t("were-having-some-trouble-try-again-later")}
  //     </Alert>
  //   );
  // }

  const loading = false;
  return (
    <div
      style={{
        display: "flex",
        margin: "auto",
        height: "100vh",
        width: "100vw",
        flexDirection: "column",
      }}
    >
      <Header
        name={props.userInfo.domain.name}
        logoPath={props.userInfo.domain.logoSrc}
        userInfo={props.userInfo}
        toggleNav={() => setNavOpen((o) => !o)}
      />
      <div
        css={{
          ...(theme.mixins.toolbar as CSSObject),
          minHeight: "75px",
          "@media (min-width: 600px)": { minHeight: "75px" },
          height: "75px",
        }}
      />
      <div
        css={{
          display: "flex",
          maxWidth: "100%",
          flexGrow: 1,
        }}
      >
        {!loading ? (
          <>
            <NavBar
              selected={pageIdent ?? ""}
              userInfo={props.userInfo}
              open={navOpen}
              setOpen={setNavOpen}
            />
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                maxWidth: "100%",
                [theme.breakpoints.up("md")]: {
                  maxWidth: "calc(100% - 240px)",
                },
              }}
            >
              <React.Suspense fallback={<AdminLoader />}>
                <ErrorGuard>
                  <Switch>
                    <Route exact path="/preferences/">
                      <Redirect to={DEFAULT_PAGE} />
                    </Route>
                    <Route exact path="/preferences/general">
                      <General userInfo={props.userInfo} />
                    </Route>
                    <Route exact path="/preferences/block">
                      <Block userInfo={props.userInfo} />
                    </Route>
                    <Route>
                      <h1 style={{ display: "flex", justifyContent: "center" }}>
                        <Redirect to={DEFAULT_PAGE} />
                      </h1>
                    </Route>
                  </Switch>
                </ErrorGuard>
              </React.Suspense>
            </div>
          </>
        ) : (
          <AdminLoader />
        )}
      </div>
    </div>
  );
}
