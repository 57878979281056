import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import type { DateTime } from "luxon";
import ShowQRPage from "./showQRPage";
import { useTranslation } from "react-i18next";
import type { CalendarDate } from "zynq-shared";

export type QRDialogInfo =
  | { open: false }
  | {
      date: CalendarDate;
      verificationURL: string;
      domainName: string;
      open: true;
    };

export default function ShowQRDialog(props: {
  info: QRDialogInfo;
  location: string;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={props.info.open}
      onClose={props.onClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title" css={{ textAlign: "center" }}>
        {t("entry-code")}
      </DialogTitle>
      <DialogContent css={{ maxWidth: "25rem" }}>
        <ShowQRPage
          qrInfo={
            props.info.open
              ? {
                  location: props.location,
                  ...props.info,
                  date: props.info.date,
                  domainName: props.info.domainName,
                }
              : undefined
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="secondary" variant="contained">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
